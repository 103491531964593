import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";

import { Button, Grid, Typography } from "@mui/material";

import {
  ContactUsLink,
  ErrorAlert,
  LoadingButton,
} from "@vesta/components/atoms";
import {
  DetectPolygonButton,
  EditablePolygonMap,
  NewPolygonMap,
} from "@vesta/components/molecules";
import {
  useAppContext,
  useOnboardingSessionV1,
  useScrollToTopOnMount,
  useSession,
  useUpLg,
  useUpSm,
} from "@vesta/lib/react";

const Step4 = ({ onPrevious, onSkip, onNext }) => {
  const upSm = useUpSm();
  const upLg = useUpLg();
  const { t } = useTranslation("onboarding-v1");
  const [, jwtToken] = useSession();
  const [, dispatchAppContext] = useAppContext();
  const [onboardingSession, dispatch] = useOnboardingSessionV1();
  const [points, setPoints] = useState(
    onboardingSession.lastModifiedTerritory?.polygonPoints ?? null
  );
  const [submitting, setSubmitting] = useState(false);
  const [detectError, setDetectError] = useState(false);
  const [createTerritoryError, setCreateTerritoryError] = useState(false);
  const [intersectError, setIntersectError] = useState(false);

  useScrollToTopOnMount();

  const modifyTerritory = async ({ id, name }) => {
    setSubmitting(true);

    const url = new URL(
      `/commands/modify-territory-as-polygon/${id}`,
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        name: name,
        points,
      }),
    });

    if (ok) {
      dispatch({
        type: "modify-territory-as-polygon",
        payload: {
          id,
          name,
          polygonPoints: points,
        },
      });
      await onNext();
    } else {
      setSubmitting(false);
      setCreateTerritoryError(true);
    }
  };

  const createTerritory = async (points) => {
    setSubmitting(true);

    const url = new URL(
      "/commands/create-territory-as-polygon",
      process.env.REACT_APP_API_BASE_URL
    );

    const name = "Mon premier territoire";

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        name,
        points,
      }),
    });

    if (response.ok) {
      const { territoryId: id } = await response.json();
      dispatchAppContext({ type: "create-territory-as-polygon" });
      dispatch({
        type: "create-territory-as-polygon",
        payload: { id, name, polygonPoints: points },
      });
      await onNext();
    } else {
      setSubmitting(false);
      setCreateTerritoryError(true);
    }
  };

  const handleNextClick = async () => {
    if (onboardingSession.lastModifiedTerritory) {
      await modifyTerritory(onboardingSession.lastModifiedTerritory);
    } else {
      await createTerritory(points);
    }
  };

  const canSkip = !onboardingSession.lastModifiedTerritory;

  return (
    <>
      <Grid container direction="column" spacing={4} data-aos="fade-up">
        <Grid item>
          <Typography gutterBottom variant="h1">
            {t("onboardingSteps.step4.title")}
          </Typography>
          <Typography>{t("onboardingSteps.step4.subtitle")}</Typography>
        </Grid>
        <Grid item>
          {points ? (
            <Button variant="outlined" onClick={() => setPoints(null)}>
              {t("onboardingSteps.step4.restart")}
            </Button>
          ) : (
            <DetectPolygonButton
              label={
                upSm
                  ? t("onboardingSteps.step4.detectUpSm")
                  : t("onboardingSteps.step4.detect")
              }
              onDetect={setPoints}
              onError={() => setDetectError(true)}
            />
          )}
        </Grid>
        <Grid
          sx={(theme) => ({
            height: theme.spacing(40),
            [theme.breakpoints.up("md")]: {
              height: theme.spacing(80),
            },
          })}
          item
        >
          {points ? (
            <EditablePolygonMap
              id="first-territory"
              points={points}
              onEdit={setPoints}
              onIntersect={() => setIntersectError(true)}
            />
          ) : (
            <NewPolygonMap id="first-territory" onCreate={setPoints} />
          )}
        </Grid>
        <Grid container justifyContent="space-between" item>
          <Grid item>
            <Button onClick={onPrevious} color="primary" variant="outlined">
              {t("onboardingSteps.step4.actions.previous")}
            </Button>
          </Grid>
          <Grid item>
            {upLg && canSkip && (
              <Button
                sx={(theme) => ({ marginRight: theme.spacing(2) })}
                onClick={onSkip}
              >
                {t("onboardingSteps.step4.actions.skip")}
              </Button>
            )}
            <LoadingButton
              disabled={submitting || !points}
              loading={submitting}
              onClick={handleNextClick}
              variant="contained"
            >
              {t("onboardingSteps.step4.actions.next")}
            </LoadingButton>
          </Grid>
        </Grid>
        {!upLg && canSkip && (
          <Grid container justifyContent="flex-end" item>
            <Grid item>
              <Button onClick={onSkip}>
                {t("onboardingSteps.step4.actions.skip")}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      <ErrorAlert
        open={detectError}
        onClose={() => setDetectError(false)}
        content={t("onboardingSteps.step4.alerts.detectError")}
      />
      <ErrorAlert
        open={createTerritoryError}
        onClose={() => setCreateTerritoryError(false)}
        content={
          <Trans
            i18nKey="onboarding-v1:onboardingSteps.step4.alerts.createTerritoryError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
      <ErrorAlert
        autoHide
        open={intersectError}
        onClose={() => setIntersectError(false)}
        content={t("onboardingSteps.step4.alerts.intersectError")}
      />
    </>
  );
};

Step4.propTypes = {
  onPrevious: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default Step4;
