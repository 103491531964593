import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, Grid, Typography } from "@mui/material";

import {
  DetectPolygonButton,
  EditablePolygonMap,
  NewPolygonMap,
} from "@vesta/components/molecules";
import { useUpSm } from "@vesta/lib/react";

const Step1 = ({
  points,
  onDetect,
  onDetectError,
  onCreate,
  onEdit,
  onIntersect,
  onNext,
}) => {
  const upSm = useUpSm();
  const { t } = useTranslation("add-territory");

  return (
    <Grid container direction="column" spacing={4} data-aos="fade-up">
      <Grid item>
        <Typography gutterBottom variant="h1">
          {t("step1.title")}
        </Typography>
        <Typography>{t("step1.subtitle")}</Typography>
      </Grid>
      <Grid item>
        {points ? (
          <Button variant="outlined" onClick={() => onEdit(null)}>
            {t("step1.restart")}
          </Button>
        ) : (
          <DetectPolygonButton
            label={upSm ? t("step1.detectUpSm") : t("step1.detect")}
            onDetect={onDetect}
            onError={onDetectError}
          />
        )}
      </Grid>
      <Grid
        sx={(theme) => ({
          height: theme.spacing(40),
          [theme.breakpoints.up("md")]: {
            height: theme.spacing(80),
          },
        })}
        item
      >
        {points ? (
          <EditablePolygonMap
            id="add-territory"
            points={points}
            onEdit={onEdit}
            onIntersect={onIntersect}
          />
        ) : (
          <NewPolygonMap id="add-territory" onCreate={onCreate} />
        )}
      </Grid>
      <Grid container justifyContent="flex-end" item>
        <Grid item>
          <Button disabled={!points} onClick={onNext} variant="contained">
            {t("step1.next")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

Step1.propTypes = {
  points: PropTypes.array,
  onDetect: PropTypes.func.isRequired,
  onDetectError: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onIntersect: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default Step1;
