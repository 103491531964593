import PropTypes from "prop-types";
import { Suspense } from "react";

import { CssBaseline, GlobalStyles } from "@mui/material";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import { Loader } from "@vesta/components/atoms";
import { useAos } from "@vesta/lib/react";
import { getTheme } from "@vesta/theme";

const WithLayout = ({ renderLayout, renderView }) => {
  useAos();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getTheme()}>
        <CssBaseline />
        <GlobalStyles styles={{ body: { overflowY: "scroll" } }} />
        {renderLayout({
          view: <Suspense fallback={<Loader />}>{renderView()}</Suspense>,
        })}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

WithLayout.propTypes = {
  renderLayout: PropTypes.func.isRequired,
  renderView: PropTypes.func.isRequired,
};

export default WithLayout;
