import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import * as yup from "yup";

import { Grid } from "@mui/material";

import {
  AppointmentDurationInMinutesSelect,
  BreakDurationAfterEachAppointmentInMinutesSelect,
  MinimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutesSelect,
} from "@vesta/components/atoms";
import { TitledFormPaper } from "@vesta/components/molecules";
import { useUpSm } from "@vesta/lib/react";

const Durations = ({ loading, service, onSave }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("service");
  const [editing, setEditing] = useState(false);

  const validationSchema = yup.object().shape({
    appointmentDurationInMinutes: yup
      .number()
      .min(15, t("durations.appointmentDurationInMinutes.min"))
      .max(480, t("durations.appointmentDurationInMinutes.max"))
      .required(t("durations.appointmentDurationInMinutes.required")),
    breakDurationAfterEachAppointmentInMinutes: yup
      .number()
      .min(0, t("durations.breakDurationAfterEachAppointmentInMinutes.min"))
      .max(120, t("durations.breakDurationAfterEachAppointmentInMinutes.max"))
      .required(
        t("durations.breakDurationAfterEachAppointmentInMinutes.required")
      ),
    minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutes: yup
      .number()
      .min(
        180,
        t(
          "durations.minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutes.min"
        )
      )
      .max(
        10080,
        t(
          "durations.minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutes.max"
        )
      )
      .required(
        t(
          "durations.minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutes.required"
        )
      ),
  });

  const {
    values,
    errors,
    isSubmitting,
    submitCount,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      appointmentDurationInMinutes: 15,
      breakDurationAfterEachAppointmentInMinutes: 0,
      minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutes: 1440,
    },
    validationSchema,
    onSubmit: async ({
      appointmentDurationInMinutes,
      breakDurationAfterEachAppointmentInMinutes,
      minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutes,
    }) => {
      const ok = await onSave({
        appointmentDurationInMinutes,
        breakDurationAfterEachAppointmentInMinutes,
        minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutes,
      });
      if (ok) {
        setEditing(false);
      }
    },
  });

  useEffect(() => {
    if (!editing && service) {
      setFieldValue(
        "appointmentDurationInMinutes",
        service.appointmentDurationInMinutes
      );
      setFieldValue(
        "breakDurationAfterEachAppointmentInMinutes",
        service.breakDurationAfterEachAppointmentInMinutes
      );
      setFieldValue(
        "minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutes",
        service.minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutes
      );
    }
  }, [editing, service]);

  const handleCancel = () => {
    resetForm();
    setFieldValue(
      "appointmentDurationInMinutes",
      service.appointmentDurationInMinutes
    );
    setFieldValue(
      "breakDurationAfterEachAppointmentInMinutes",
      service.breakDurationAfterEachAppointmentInMinutes
    );
    setFieldValue(
      "minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutes",
      service.minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutes
    );
    setEditing(false);
  };

  return (
    <TitledFormPaper
      title={t("durations.title")}
      loading={loading}
      editing={editing}
      saving={isSubmitting}
      onEdit={() => setEditing(true)}
      onCancel={handleCancel}
      onSave={handleSubmit}
    >
      <Grid container spacing={upSm ? 3 : 2}>
        <Grid item xs={12} md={6}>
          <AppointmentDurationInMinutesSelect
            id="appointment-duration-in-minutes"
            readOnly={!editing}
            value={values.appointmentDurationInMinutes}
            onChange={(value) =>
              setFieldValue("appointmentDurationInMinutes", value)
            }
            error={
              submitCount > 0 && Boolean(errors.appointmentDurationInMinutes)
            }
            helperText={
              submitCount > 0 ? errors.appointmentDurationInMinutes : null
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BreakDurationAfterEachAppointmentInMinutesSelect
            id="break-duration-after-each-appointment-in-minutes"
            readOnly={!editing}
            value={values.breakDurationAfterEachAppointmentInMinutes}
            onChange={(value) =>
              setFieldValue("breakDurationAfterEachAppointmentInMinutes", value)
            }
            error={
              submitCount > 0 &&
              Boolean(errors.breakDurationAfterEachAppointmentInMinutes)
            }
            helperText={
              submitCount > 0
                ? errors.breakDurationAfterEachAppointmentInMinutes
                : null
            }
          />
        </Grid>
        <Grid item xs={12}>
          <MinimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutesSelect
            id="minimum-time-between-booking-appointment-and-start-of-appointment-in-minutes"
            readOnly={!editing}
            value={
              values.minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutes
            }
            onChange={(value) =>
              setFieldValue(
                "minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutes",
                value
              )
            }
            error={
              submitCount > 0 &&
              Boolean(
                errors.minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutes
              )
            }
            helperText={
              submitCount > 0
                ? errors.minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutes
                : null
            }
          />
        </Grid>
      </Grid>
    </TitledFormPaper>
  );
};

Durations.propTypes = {
  loading: PropTypes.bool,
  service: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};

export default Durations;
