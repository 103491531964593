export const getResultFileTypeId = (mimeType) => {
  if (mimeType === "application/pdf") {
    return resultFileType.pdf;
  }

  if (mimeType.startsWith("image/")) {
    return resultFileType.image;
  }

  console.error("Unknown result file type", mimeType);
  return null;
};

export const resultFileType = {
  pdf: 1,
  image: 2,
};
