import PropTypes from "prop-types";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import {
  Alert,
  AlertTitle,
  Box,
  IconButton,
  InputAdornment,
  Link as MuiLink,
  TextField,
  Tooltip,
} from "@mui/material";
import { Email, Facebook, FileCopy } from "@mui/icons-material";

import { SuccessAlert } from "@vesta/components/atoms";
import { useSession, useUpSm } from "@vesta/lib/react";

const ShareProfileAlert = ({ onClose }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("home");
  const [session] = useSession();
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const profileFullName = `${session.given_name} ${session.family_name}`;
  const profileUrl = (utmSource) =>
    new URL(
      `/profile/${session.nameid}?` +
        new URLSearchParams({
          utm_source: utmSource,
          utm_campaign: "onboarding-v1",
          utm_content: "share-profile-alert",
        }),
      process.env.REACT_APP_WEB_BASE_URL
    ).toString();

  const facebookUrl = profileUrl("facebook");
  const emailUrl = profileUrl("email");
  const copyToClipboardUrl = profileUrl("link");

  const emailSubject = `subject=${t("shareProfileAlert.emailSubject", {
    profileFullName,
  })}`;

  const emailBody = `body=${t("shareProfileAlert.emailBody", {
    profileFullName: profileFullName,
    profileUrl: encodeURIComponent(emailUrl),
  })}`;

  const handleCopy = () => {
    setCopiedToClipboard(true);
  };

  const copyToClipboard = (
    <CopyToClipboard text={copyToClipboardUrl} onCopy={handleCopy}>
      <Tooltip
        arrow
        placement="bottom"
        title={t("shareProfileAlert.copyToClipboardLabel")}
      >
        <IconButton
          color="primary"
          aria-label={t("shareProfileAlert.copyToClipboardLabel")}
          size="large"
        >
          <FileCopy />
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  );

  return (
    <>
      <Alert
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          "& > .MuiAlert-action": {
            alignItems: "flex-start",
          },
        })}
        component="aside"
        onClose={onClose}
        severity="success"
        variant="outlined"
      >
        <AlertTitle sx={(theme) => ({ marginBottom: theme.spacing(2) })}>
          {t("shareProfileAlert.title")}
        </AlertTitle>
        <p>
          <Trans
            i18nKey="home:shareProfileAlert.text"
            components={{
              availabilitiesLink: (
                <MuiLink
                  component={Link}
                  to="/calendar/availabilities"
                  underline="always"
                />
              ),
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
            }}
          />
        </p>
        <Box
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            maxWidth: theme.spacing(100),
          })}
        >
          <IconButton
            color="primary"
            edge="start"
            aria-label={t("shareProfileAlert.facebookLabel")}
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              facebookUrl
            )}`}
            target="_blank"
            size="large"
          >
            <Tooltip
              arrow
              placement="bottom"
              title={t("shareProfileAlert.facebookLabel")}
            >
              <Facebook />
            </Tooltip>
          </IconButton>
          <IconButton
            color="primary"
            aria-label={t("shareProfileAlert.emailLabel")}
            href={`mailto:?${emailSubject}&${emailBody}`}
            target="_blank"
            size="large"
          >
            <Tooltip
              arrow
              placement="bottom"
              title={t("shareProfileAlert.emailLabel")}
            >
              <Email />
            </Tooltip>
          </IconButton>
          {upSm ? (
            <TextField
              sx={(theme) => ({
                flex: 1,
                marginLeft: theme.spacing(2),
              })}
              defaultValue={copyToClipboardUrl}
              size="small"
              variant="outlined"
              InputProps={{
                sx: (theme) => ({
                  ...theme.typography.body2,
                }),
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {copyToClipboard}
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            copyToClipboard
          )}
        </Box>
      </Alert>
      <SuccessAlert
        open={copiedToClipboard}
        onClose={() => setCopiedToClipboard(false)}
        content={t("shareProfileAlert.copiedToClipboard")}
      />
    </>
  );
};

ShareProfileAlert.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ShareProfileAlert;
