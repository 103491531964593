import L from "leaflet";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const PolygonMap = ({
  id,
  points,
  scrollWheelZoom,
  onAddPolygonToMap = () => {},
}) => {
  const { t } = useTranslation("components");
  const [map, setMap] = useState();
  const [polygon, setPolygon] = useState();

  useEffect(() => {
    const map = L.map(id, { scrollWheelZoom });
    const polygon = L.polygon(points.map((x) => [x[1], x[0]]));
    const tileLayer = L.tileLayer(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        attribution: `&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">${t(
          "molecules.polygonMap.attribution"
        )}</a>`,
      }
    );

    map.fitBounds(polygon.getBounds());
    polygon.addTo(map);
    tileLayer.addTo(map);

    setMap(map);
    setPolygon(polygon);
    onAddPolygonToMap(polygon);
  }, []);

  useEffect(() => {
    if (map) {
      polygon.removeFrom(map);

      const newPolygon = L.polygon(points.map((x) => [x[1], x[0]])).addTo(map);
      setPolygon(newPolygon);
      onAddPolygonToMap(newPolygon);
    }
  }, [map, points]);

  // Avoid the sx prop because it will prevent leaflet from setting its own classes
  return <div id={id} style={{ height: "100%" }} />;
};

PolygonMap.propTypes = {
  id: PropTypes.string.isRequired,
  points: PropTypes.array.isRequired,
  scrollWheelZoom: PropTypes.bool,
  onAddPolygonToMap: PropTypes.func,
};

export default PolygonMap;
