import { addDays } from "date-fns";
import { useEffect, useState } from "react";

import { agreementStatus } from "@vesta/lib/enum";
import { useAuthorizedFetchJson, useSession } from "@vesta/lib/react";

export const useAvailableTerritoriesAndServices = () => {
  const [, jwtToken] = useSession();
  const [fetchJson, json, loading] = useAuthorizedFetchJson(jwtToken);

  useEffect(() => {
    fetchJson(
      new URL(
        "/queries/get-new-availability-options",
        process.env.REACT_APP_API_BASE_URL
      )
    );
  }, []);

  return [json?.territories, json?.services, loading];
};

export const useStart = (initialStart) => {
  const [start, setStart] = useState(initialStart);

  useEffect(() => {
    if (initialStart) {
      setStart(initialStart);
    }
  }, [initialStart]);

  return [start, setStart];
};

export const useEnd = (initialEnd) => {
  const [end, setEnd] = useState(initialEnd);

  useEffect(() => {
    if (initialEnd) {
      setEnd(initialEnd);
    }
  }, [initialEnd]);

  return [end, setEnd];
};

export const useRecurrenceEndsOn = (start) => {
  const [recurrenceEndsOn, setRecurrenceEndsOn] = useState();

  useEffect(() => {
    if (start) {
      setRecurrenceEndsOn(addDays(start, 14));
    }
  }, [start]);

  return [recurrenceEndsOn, setRecurrenceEndsOn];
};

export const useTerritories = (availableTerritories) => {
  const [territories, setTerritories] = useState([]);

  useEffect(() => {
    if (availableTerritories) {
      setTerritories(
        availableTerritories.map((x) => ({
          id: x.territoryId,
          name: x.name,
          include: true,
        }))
      );
    }
  }, [availableTerritories]);

  const error = territories.filter((x) => x.include).length === 0;

  const handleChange = ({ id, include }) =>
    setTerritories(
      territories.map((x) => (x.id === id ? { ...x, include } : { ...x }))
    );

  return [territories, error, handleChange];
};

export const useServices = (availableServices) => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    if (availableServices) {
      setServices(
        availableServices
          .filter((x) => !x.isDeleted)
          .map((x) => ({
            id: x.serviceId,
            name: x.nameFr,
            include: x.agreements.every(
              (y) => y.statusId === agreementStatus.accepted
            ),
            disabled: x.agreements.some(
              (y) => y.statusId !== agreementStatus.accepted
            ),
          }))
      );
    }
  }, [availableServices]);

  const error = services.filter((x) => x.include).length === 0;

  const handleChange = ({ id, include }) => {
    setServices(
      services.map((x) => (x.id === id ? { ...x, include } : { ...x }))
    );
  };

  return [services, error, handleChange];
};
