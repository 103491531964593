import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMatch, useParams } from "react-router-dom";

import { Box, List, Typography } from "@mui/material";
import {
  Directions,
  NoteOutlined,
  PlaylistAddCheck,
} from "@mui/icons-material";

import { SubNavigationListItem } from "@vesta/components/atoms";
import {
  BottomNavigation,
  MainSidebar,
  SecondarySidebar,
} from "@vesta/components/organisms";
import { MainLayout } from "@vesta/layouts";
import { useUpMd } from "@vesta/lib/react";

export const MainLayoutWithAppointmentNavigation = ({ children }) => {
  const upMd = useUpMd();
  const { t } = useTranslation("components");
  const { appointmentId } = useParams();
  const [primarySidebarOpen, setPrimarySidebarOpen] = useState(false);
  const [secondarySidebarOpen, setSecondarySidebarOpen] = useState(false);

  const matchesPreparation = useMatch(
    "/calendar/appointments/:appointmentId/preparation"
  );

  const matchesDirections = useMatch(
    "/calendar/appointments/:appointmentId/directions"
  );

  const matchesNote = useMatch("/calendar/appointments/:appointmentId/note");

  const primarySidebar = (
    <MainSidebar
      isOpen={primarySidebarOpen}
      onOpen={() => setPrimarySidebarOpen(true)}
      onClose={() => setPrimarySidebarOpen(false)}
      subLayout={
        <Box>
          {!upMd && (
            <Box
              sx={(theme) => ({
                margin: theme.spacing(2),
                marginBottom: theme.spacing(0),
              })}
            >
              <Typography component="h1" variant="h3">
                {t(
                  "organisms.app.appRoutes.mainLayoutWithAppointmentNavigation.subLayoutHeaderTitle"
                )}
              </Typography>
            </Box>
          )}
          <List
            sx={(theme) => ({
              [theme.breakpoints.up("md")]: {
                paddingTop: 0,
              },
            })}
          >
            <SubNavigationListItem
              to={`/calendar/appointments/${appointmentId}/preparation`}
              label={t(
                "organisms.app.appRoutes.mainLayoutWithAppointmentNavigation.preparationLabel"
              )}
              icon={<PlaylistAddCheck />}
              selected={!!matchesPreparation}
              onClick={() => setPrimarySidebarOpen(false)}
            />
            <SubNavigationListItem
              to={`/calendar/appointments/${appointmentId}/directions`}
              label={t(
                "organisms.app.appRoutes.mainLayoutWithAppointmentNavigation.directionsLabel"
              )}
              icon={<Directions />}
              selected={!!matchesDirections}
              onClick={() => setPrimarySidebarOpen(false)}
            />
            <SubNavigationListItem
              to={`/calendar/appointments/${appointmentId}/note`}
              label={t(
                "organisms.app.appRoutes.mainLayoutWithAppointmentNavigation.noteLabel"
              )}
              icon={<NoteOutlined />}
              selected={!!matchesNote}
              onClick={() => setPrimarySidebarOpen(false)}
            />
          </List>
        </Box>
      }
    />
  );

  const secondarySidebar = (
    <SecondarySidebar
      isOpen={secondarySidebarOpen}
      onOpen={() => setSecondarySidebarOpen(true)}
      onClose={() => setSecondarySidebarOpen(false)}
    />
  );

  return (
    <MainLayout
      sidebar={primarySidebar}
      onOpenSidebar={() => setPrimarySidebarOpen(true)}
      secondarySidebar={secondarySidebar}
      onOpenSecondarySidebar={() => setSecondarySidebarOpen(true)}
      renderBottomNavigation={({ sx }) => (
        <BottomNavigation
          sx={sx}
          value={
            matchesPreparation
              ? "preparation"
              : matchesDirections
              ? "directions"
              : matchesNote
              ? "note"
              : ""
          }
          actions={[
            {
              label: (
                <Typography variant="caption">
                  {t(
                    "organisms.app.appRoutes.mainLayoutWithAppointmentNavigation.preparationLabel"
                  )}
                </Typography>
              ),
              value: "preparation",
              to: `/calendar/appointments/${appointmentId}/preparation`,
              icon: <PlaylistAddCheck />,
            },
            {
              label: (
                <Typography variant="caption">
                  {t(
                    "organisms.app.appRoutes.mainLayoutWithAppointmentNavigation.directionsLabel"
                  )}
                </Typography>
              ),
              value: "directions",
              to: `/calendar/appointments/${appointmentId}/directions`,
              icon: <Directions />,
            },
            {
              label: (
                <Typography variant="caption">
                  {t(
                    "organisms.app.appRoutes.mainLayoutWithAppointmentNavigation.noteLabel"
                  )}
                </Typography>
              ),
              value: "note",
              to: `/calendar/appointments/${appointmentId}/note`,
              icon: <NoteOutlined />,
            },
          ]}
        />
      )}
    >
      {children}
    </MainLayout>
  );
};

MainLayoutWithAppointmentNavigation.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayoutWithAppointmentNavigation;
