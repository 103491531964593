import parse from "autosuggest-highlight/parse";
import PropTypes from "prop-types";

import { Grid, Typography } from "@mui/material";
import { LocationOn } from "@mui/icons-material";

const AutocompleteOption = ({ option }) => {
  const {
    main_text_matched_substrings: matchedSubstrings,
    main_text: mainText,
    secondary_text: secondaryText,
  } = option.structured_formatting;

  const parts = parse(
    mainText,
    matchedSubstrings.map(({ offset, length }) => [offset, offset + length])
  );

  return (
    <Grid container alignItems="center">
      <Grid item>
        <LocationOn
          sx={(theme) => ({
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(2),
          })}
        />
      </Grid>
      <Grid item xs>
        {parts.map((part, index) => (
          <Typography
            key={index}
            sx={(theme) =>
              part.highlight
                ? {
                    color: theme.palette.secondary.main,
                  }
                : {}
            }
            component="span"
          >
            {part.text}
          </Typography>
        ))}
        <Typography variant="body2" color="textSecondary">
          {secondaryText}
        </Typography>
      </Grid>
    </Grid>
  );
};

AutocompleteOption.propTypes = {
  option: PropTypes.object.isRequired,
};

export default AutocompleteOption;
