import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import {
  AdditionalInformationTextField,
  FormattedServicePriceTextField,
  ServiceNameTextField,
  TitledPaper,
} from "@vesta/components/molecules";
import { useAppointment } from "@vesta/lib/react";

const ServicesForm = () => {
  const { t } = useTranslation("appointment");
  const [appointment] = useAppointment();

  const someService = appointment?.services.length ?? 0;

  return (
    <TitledPaper
      title={t("preparation.servicesForm.title")}
      loading={!appointment}
    >
      <Grid container direction="column" spacing={3}>
        {someService ? (
          appointment?.services.map((service) => (
            <Grid
              key={service.serviceId}
              container
              direction="row"
              item
              spacing={3}
            >
              <Grid item xs={12} sm={8}>
                <ServiceNameTextField value={service.nameFr} readOnly />
              </Grid>
              <Grid item xs={8} sm={4}>
                <FormattedServicePriceTextField value={service} readOnly />
              </Grid>
            </Grid>
          ))
        ) : (
          <Grid container direction="row" item spacing={3}>
            <Grid item xs={12} sm={8}>
              <ServiceNameTextField
                value={t("preparation.servicesForm.noService")}
                readOnly
              />
            </Grid>
            <Grid item xs={8} sm={4}>
              <FormattedServicePriceTextField readOnly />
            </Grid>
          </Grid>
        )}
        <Grid item>
          <AdditionalInformationTextField
            value={appointment?.additionalInformation}
            readOnly
          />
        </Grid>
      </Grid>
    </TitledPaper>
  );
};

export default ServicesForm;
