import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { LoadingButton } from "@vesta/components/atoms";
import { Dialog } from "@vesta/components/molecules";

const ModifyRecurringAvailabilityConfirmationDialog = ({
  open,
  onCancel,
  onModify,
}) => {
  const { t } = useTranslation("calendar");
  const [mode, setMode] = useState(1);
  const [modifying, setModifying] = useState();

  const handleModify = async () => {
    setModifying(true);
    await onModify(mode);
    setModifying(false);
  };

  return (
    <Dialog
      fullScreen={false}
      maxWidth="md"
      open={open}
      onClose={onCancel}
      title={t(
        "availabilities.modifyAvailability.modifyRecurringAvailabilityConfirmationDialog.title"
      )}
      content={
        <RadioGroup
          value={mode}
          onChange={(event) => setMode(parseInt(event.target.value))}
        >
          <FormControlLabel
            value={1}
            control={<Radio />}
            label={t(
              "availabilities.modifyAvailability.modifyRecurringAvailabilityConfirmationDialog.modes.1"
            )}
          />
          <FormControlLabel
            value={2}
            control={<Radio />}
            label={t(
              "availabilities.modifyAvailability.modifyRecurringAvailabilityConfirmationDialog.modes.2"
            )}
          />
          <FormControlLabel
            value={3}
            control={<Radio />}
            label={t(
              "availabilities.modifyAvailability.modifyRecurringAvailabilityConfirmationDialog.modes.3"
            )}
          />
        </RadioGroup>
      }
      actions={
        <>
          <Button onClick={onCancel} size="large" variant="outlined">
            {t(
              "availabilities.modifyAvailability.modifyRecurringAvailabilityConfirmationDialog.cancel"
            )}
          </Button>
          <LoadingButton
            loading={modifying}
            disabled={modifying}
            onClick={handleModify}
            size="large"
            variant="contained"
          >
            {t(
              "availabilities.modifyAvailability.modifyRecurringAvailabilityConfirmationDialog.modify"
            )}
          </LoadingButton>
        </>
      }
    />
  );
};

ModifyRecurringAvailabilityConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
};

export default ModifyRecurringAvailabilityConfirmationDialog;
