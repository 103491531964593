import PropTypes from "prop-types";

import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const EmptyDirectionsIllustration = ({ sx }) => {
  const theme = useTheme();

  return (
    <Box
      sx={sx}
      component="svg"
      viewBox="0 0 435 316"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M374 158C374 244.712 303.93 315 217.5 315C131.07 315 61 244.712 61 158C61 71.2883 131.07 1 217.5 1C303.93 1 374 71.2883 374 158Z"
        fill={theme.palette.grey[50]}
        stroke={theme.palette.grey[400]}
        strokeWidth="2"
      />
      <path
        d="M76.5 217L97.7207 193.333L334.279 185L355 217L294.5 221.5L215.5 217L136 221.5L76.5 217Z"
        fill={theme.palette.grey[300]}
      />
      <path
        d="M265 157L215 147V207L295 222L265 157Z"
        fill={theme.palette.grey[300]}
      />
      <path
        d="M265 157L215 147V207L295 222L265 157Z"
        fill={theme.palette.grey[300]}
      />
      <path
        d="M125 152L165 157L135 222L75 212L125 152Z"
        fill={theme.palette.grey[300]}
      />
      <path
        d="M125 152L165 157L135 222L75 212L125 152Z"
        fill={theme.palette.grey[300]}
      />
      <path
        d="M165 157L215 147V207L135 222L165 157Z"
        fill={theme.palette.grey[100]}
      />
      <path
        d="M165 157L215 147V207L135 222L165 157Z"
        fill={theme.palette.grey[100]}
      />
      <path
        d="M265 157L215 147L165 157L125 152L75 212L135 222L215 207L295 222L355 212L305 152L265 157Z"
        stroke={theme.palette.grey[400]}
        strokeWidth="2"
      />
      <path
        d="M305 152L265 157L295 222L355 212L305 152Z"
        fill={theme.palette.grey[100]}
      />
      <path
        d="M305 152L265 157L295 222L355 212L305 152Z"
        fill={theme.palette.grey[100]}
      />
      <path
        d="M331.5 187L340 197.5L325.5 200L318 189.5L331.5 187Z"
        fill={theme.palette.grey[400]}
      />
      <path
        d="M189 154.5L185.5 168.5L162 171L167 159L189 154.5Z"
        fill={theme.palette.grey[400]}
      />
      <path
        d="M199.5 152.5L197.5 167L190 168L192.5 154L199.5 152.5Z"
        fill={theme.palette.grey[400]}
      />
      <path
        d="M213.5 150L213 156.5L201 158.5L202 152L213.5 150Z"
        fill={theme.palette.grey[400]}
      />
      <path
        d="M213 158V165L200.5 166.5L201 159.5L213 158Z"
        fill={theme.palette.grey[400]}
      />
      <path
        d="M314.5 190.5L321.5 200.5L304 204L309 212L295 214.5L286 195.5L314.5 190.5Z"
        fill={theme.palette.grey[400]}
      />
      <path
        d="M340.5 198.5L350 210L334.5 212.5L326.5 201L340.5 198.5Z"
        fill={theme.palette.grey[400]}
      />
      <path
        d="M180 189.5L215 181.5L239 186.5L250 170L274 177L288.5 171.5"
        stroke={theme.palette.primary.light}
        strokeWidth="2"
        strokeDasharray="6 6"
      />
      <path
        d="M314 130.944C314 119.31 305.681 111 294 111C282.319 111 274 119.31 274 130.944C274 142.577 285.652 150.887 288.985 152.549C290.637 155.873 291.917 171 294 171C296.083 171 297.304 155.873 298.985 152.549C302.304 150.887 314 142.577 314 130.944Z"
        fill={theme.palette.primary.light}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M296 120C300 120 302 122 302 125C302 128 300 129.5 298 129.5C301 129.5 303 132 303 135C303 140 298 140 296 140H287V120H296ZM298.5 125.5C298.5 127.5 297 128 295 128H291V123H295C297 123 298.5 123.5 298.5 125.5ZM295 137C298 137 299.5 136 299.5 134C299.5 132 298 131 295 131H291V137H295Z"
        fill={theme.palette.primary.contrastText}
      />
      <circle cx="140" cy="259" r="3" fill={theme.palette.primary.light} />
      <circle cx="195" cy="234" r="3" fill={theme.palette.grey[300]} />
      <circle cx="190" cy="72" r="3" fill={theme.palette.grey[300]} />
      <circle cx="261" cy="35" r="3" fill={theme.palette.grey[400]} />
      <circle cx="280" cy="253" r="3" fill={theme.palette.grey[300]} />
      <circle cx="251" cy="171" r="3" fill={theme.palette.primary.light} />
      <circle cx="239" cy="186" r="3" fill={theme.palette.primary.light} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157 197C170.255 197 181 186.255 181 173C181 159.745 170.255 149 157 149C143.745 149 133 159.745 133 173C133 186.255 143.745 197 157 197ZM157 189C165.837 189 173 181.837 173 173C173 164.163 165.837 157 157 157C148.163 157 141 164.163 141 173C141 181.837 148.163 189 157 189Z"
        fill={theme.palette.grey[600]}
      />
      <circle
        cx="157"
        cy="173"
        r="16"
        fill={theme.palette.primary.contrastText}
      />
      <circle cx="157" cy="173" r="8" fill={theme.palette.grey[600]} />
      <rect
        x="153"
        y="141"
        width="8"
        height="16"
        fill={theme.palette.grey[600]}
      />
      <rect
        x="173"
        y="169"
        width="16"
        height="8"
        fill={theme.palette.grey[600]}
      />
      <rect
        x="125"
        y="169"
        width="16"
        height="8"
        fill={theme.palette.grey[600]}
      />
      <rect
        x="153"
        y="189"
        width="8"
        height="16"
        fill={theme.palette.grey[600]}
      />
      <rect
        x="1"
        y="58"
        width="142"
        height="78"
        rx="15"
        fill={theme.palette.grey[50]}
        stroke={theme.palette.error.light}
        strokeWidth="2"
      />
      <path
        d="M80 136H122L134 156L80 136Z"
        fill={theme.palette.grey[50]}
        stroke={theme.palette.error.light}
        strokeWidth="2"
      />
      <rect
        x="12"
        y="69"
        width="120"
        height="56"
        rx="28"
        fill={theme.palette.error.light}
      />
      <circle
        cx="40"
        cy="97"
        r="24"
        fill={theme.palette.primary.contrastText}
      />
      <path
        d="M94.3268 105V96.2728M104.123 89.0002H94.3268V96.2728M94.3268 96.2728H101.212M118 89.0001L108.666 89V96.2727M108.666 105L108.666 96.2727M108.666 96.2727L114.735 96.2727M78.0004 91.9092C78.0004 96.2728 78.0004 96.2728 78.0004 100.636C78.0004 101.365 79.633 103.545 82.8983 103.545C86.1636 103.546 87.796 101.365 87.7962 100.636V91.9092C87.796 91.1829 86.1636 89.0002 82.8983 89.0002C79.6331 89.0002 77.9695 91.2408 78.0004 91.9092Z"
        stroke={theme.palette.grey[200]}
        strokeWidth="2"
      />
    </Box>
  );
};

EmptyDirectionsIllustration.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
};

export default EmptyDirectionsIllustration;
