import jwt_decode from "jwt-decode";
import { useMemo } from "react";

import { useAnonymousId, useLocalStorage } from "@vesta/lib/react";

export const useSession = () => {
  const [, resetAnonymousId] = useAnonymousId();
  const [jwtToken, setJwtToken, removeJwtToken] =
    useLocalStorage("vesta-jwt-token");

  const session = useMemo(() => {
    if (jwtToken) {
      return jwt_decode(jwtToken);
    }
  }, [jwtToken]);

  /**
   * In order to handle multiple tracking identities on the same device, resetAnonymousId should be called whenever a user signs out.
   * This will treat subsequent tracking events on the same device as performed by a new user.
   * For more details, see https://help.mixpanel.com/hc/en-us/articles/115004497803-Identity-Management-Best-Practices
   */
  const signOut = () => {
    removeJwtToken();
    resetAnonymousId();
  };

  return [session, jwtToken, setJwtToken, signOut];
};
