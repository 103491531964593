import PropTypes from "prop-types";

import { Box, SwipeableDrawer } from "@mui/material";

import {
  AccountMenuItemAlert,
  AccountMenuItems,
  AccountMenuHeader,
} from "@vesta/components/molecules";

const SecondarySidebar = ({ isOpen, onClose, onOpen }) => (
  <Box
    sx={(theme) => ({
      flexShrink: 0,
      maxWidth: theme.spacing(45),
      [theme.breakpoints.up("md")]: {
        width: theme.spacing(30),
      },
    })}
  >
    <SwipeableDrawer
      variant={"temporary"}
      anchor="right"
      open={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      ModalProps={{
        keepMounted: false,
      }}
      PaperProps={{
        sx: (theme) => ({
          width: "85%",
          maxWidth: theme.spacing(45),
          [theme.breakpoints.up("md")]: {
            width: theme.spacing(30),
          },
        }),
      }}
    >
      <>
        <AccountMenuHeader />
        <AccountMenuItemAlert />
        <AccountMenuItems id="account-menu-items" onClick={() => onClose()} />
      </>
    </SwipeableDrawer>
  </Box>
);

SecondarySidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
};

SecondarySidebar.displayName = "SecondarySidebar";

export default SecondarySidebar;
