import PropTypes from "prop-types";

import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const TerritoryMissingIllustration = ({ sx }) => {
  const theme = useTheme();

  return (
    <Box
      sx={sx}
      component="svg"
      viewBox="0 0 452 317"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        name="cirle"
        fill={theme.palette.primary.contrastText}
        stroke={theme.palette.grey[400]}
        strokeWidth="2"
        d="M383 159c0 86.712-70.07 157-156.5 157S70 245.712 70 159 140.07 2 226.5 2 383 72.288 383 159Z"
      />
      <ellipse
        cx="225.5"
        cy="255.5"
        fill={theme.palette.primary.light}
        stroke={theme.palette.primary.main}
        strokeDasharray="4 4"
        strokeWidth="2"
        rx="71.5"
        ry="19.5"
      />
      <path
        name="pin-mask"
        fill={theme.palette.primary.contrastText}
        d="M295 145.36C295 177.757 238.313 255 226 255c-12.313 0-69-78.215-69-110.339C157 115.331 187.892 86 226 86c38.108 0 69 26.962 69 59.36Z"
      />
      <path
        name="pin-mask"
        fill={theme.palette.grey[300]}
        stroke={theme.palette.grey[400]}
        strokeWidth="2"
        d="M297.052 115.505c0 9.767-3.646 23.034-9.395 37.522-5.738 14.459-13.529 30.031-21.728 44.371-8.2 14.341-16.793 27.424-24.128 36.91-3.669 4.746-7.005 8.567-9.803 11.192-1.401 1.313-2.644 2.305-3.708 2.963-1.087.672-1.88.928-2.407.928-.523 0-1.314-.258-2.402-.938-1.064-.666-2.307-1.67-3.708-3-2.799-2.656-6.135-6.523-9.804-11.322-7.335-9.593-15.929-22.816-24.129-37.286-8.199-14.468-15.991-30.159-21.729-44.684-5.75-14.554-9.396-27.832-9.396-37.515 0-35.566 31.897-71.168 71.168-71.168 39.29 0 71.169 32.706 71.169 72.027Z"
      />
      <circle
        cx="226.5"
        cy="111.5"
        r="24.5"
        fill={theme.palette.grey[100]}
        stroke={theme.palette.grey[400]}
        strokeWidth="2"
      />
      <path
        fill={theme.palette.grey[100]}
        d="M432.327 121.44c1.165-25.538-42.61-32.472-49.297-3.884-27.341-3.311-27.341 32.444-3.96 32.444h53.257c30.17 0 19.007-28.909 0-28.56Z"
      />
      <path
        stroke={theme.palette.grey[400]}
        strokeWidth="2"
        d="M382.317 128.812c-.334-4.229-.055-7.973.713-11.256m49.297 3.884c1.165-25.538-42.61-32.472-49.297-3.884m0 0C355.689 114.245 355.689 150 379.07 150h53.257c35.072 0 14.288-39.066-9.742-25.823"
      />
      <path
        fill={theme.palette.grey[100]}
        d="M147.185 47.64c2.422-53.08-88.57-67.491-102.471-8.073C-12.12 32.687-12.12 107 36.482 107h110.703c62.713 0 39.509-60.084 0-59.36Z"
      />
      <path
        stroke={theme.palette.grey[400]}
        strokeWidth="2"
        d="M43.233 62.962c-.696-8.79-.116-16.571 1.48-23.395m102.472 8.074c2.422-53.08-88.57-67.492-102.471-8.074m0 0C-12.12 32.687-12.12 107 36.482 107h110.703c72.902 0 29.701-81.195-20.25-53.671"
      />
      <path
        fill={theme.palette.grey[100]}
        d="M383.725 190.121c1.702-36.556-62.239-46.481-72.007-5.561-39.937-4.738-39.937 46.44-5.784 46.44h77.791c44.068 0 27.763-41.379 0-40.879Z"
      />
      <path
        stroke={theme.palette.grey[400]}
        strokeWidth="2"
        d="M310.677 200.672c-.489-6.053-.081-11.412 1.041-16.112m72.007 5.561c1.702-36.556-62.239-46.481-72.007-5.561m0 0c-39.937-4.738-39.937 46.44-5.784 46.44h77.791c51.228 0 20.871-55.917-14.23-36.962"
      />
    </Box>
  );
};

TerritoryMissingIllustration.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
};

export default TerritoryMissingIllustration;
