import { useEffect, useReducer } from "react";

import { useAuthorizedFetchJson, useSession } from "@vesta/lib/react";

const useGetProfileBiography = () => {
  const [, jwtToken] = useSession();
  const [fetchJson, json] = useAuthorizedFetchJson(jwtToken);

  useEffect(() => {
    fetchJson(
      new URL(
        "/queries/get-profile-biography",
        process.env.REACT_APP_API_BASE_URL
      )
    );
  }, []);

  return json;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "init": {
      const {
        occupationFr,
        occupationEn,
        biographyFr,
        biographyEn,
        linkedInUri,
        websiteUri,
        otherReferences,
        phoneNumberInE164Format,
        isPhoneNumberApproved,
      } = action.payload;

      return {
        ...action.payload,
        occupationFr: occupationFr ?? "",
        occupationEn: occupationEn ?? "",
        biographyFr: biographyFr ?? "",
        biographyEn: biographyEn ?? "",
        approvedPhoneNumberInE164Format: isPhoneNumberApproved
          ? phoneNumberInE164Format
          : null,
        linkedInUri: linkedInUri ?? "",
        websiteUri: websiteUri ?? "",
        otherReferences: otherReferences ?? {},
      };
    }
    case "modify-profile-picture": {
      const { profilePictureId } = action.payload;

      return {
        ...state,
        profilePictureId,
      };
    }
    case "modify-cover-picture": {
      const {
        coverPictureId,
        coverPictureUnsplashPhotoId,
        coverPictureUnsplashPhotoRawUrl,
      } = action.payload;

      return {
        ...state,
        coverPictureId,
        coverPictureUnsplashPhotoId,
        coverPictureUnsplashPhotoRawUrl,
      };
    }
    case "modify-about-me": {
      const { occupationFr, occupationEn, biographyFr, biographyEn } =
        action.payload;

      return {
        ...state,
        occupationFr,
        occupationEn,
        biographyFr,
        biographyEn,
      };
    }
    case "modify-service-languages": {
      const { serviceLanguageIds } = action.payload;

      return {
        ...state,
        serviceLanguageIds,
      };
    }
    case "modify-contact-information": {
      const { phoneNumberInE164Format, hidePhoneNumber } = action.payload;

      return {
        ...state,
        phoneNumberInE164Format,
        isPhoneNumberApproved:
          phoneNumberInE164Format === state.approvedPhoneNumberInE164Format,
        hidePhoneNumber,
      };
    }
    case "verify-profile-phone-number": {
      const { isPhoneNumberApproved } = action.payload;

      return {
        ...state,
        isPhoneNumberApproved,
        approvedPhoneNumberInE164Format: isPhoneNumberApproved
          ? state.phoneNumberInE164Format
          : state.approvedPhoneNumberInE164Format,
      };
    }
    case "modify-web-references": {
      const { linkedInUri, websiteUri, otherReferences } = action.payload;

      return {
        ...state,
        linkedInUri,
        websiteUri,
        otherReferences,
      };
    }
    default:
      throw new Error(`The action type ${action.type} cannot be handled`);
  }
};

export const useProfileBiographyReducer = () => {
  const biography = useGetProfileBiography();
  const [state, dispatch] = useReducer(reducer);

  useEffect(() => {
    if (biography) {
      dispatch({ type: "init", payload: biography });
    }
  }, [biography]);

  return [state, dispatch];
};
