import PropTypes from "prop-types";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { styled } from "@mui/material/styles";

import { File } from "@vesta/components/molecules";

const StyledImage = styled(LazyLoadImage)(({ theme }) => ({
  objectFit: "cover",
  objectPosition: "center top",
  borderTopLeftRadius: theme.spacing(1),
  borderTopRightRadius: theme.spacing(1),
  borderTop: `1px solid ${theme.palette.action.active}`,
  borderLeft: `1px solid ${theme.palette.action.active}`,
  borderRight: `1px solid ${theme.palette.action.active}`,
}));

const ImageFile = ({ resultFile, canDelete, onDelete }) => {
  const src = new URL(
    `/download-result-file/${resultFile.resultFileId}`,
    process.env.REACT_APP_API_BASE_URL
  ).toString();

  const blurredSrc = new URL(
    `/download-blurred-result-file/${resultFile.resultFileId}`,
    process.env.REACT_APP_API_BASE_URL
  ).toString();

  return (
    <File
      src={src}
      name={resultFile.name}
      uploading={resultFile.uploading}
      uploadingProgress={resultFile.uploadingProgress}
      canDelete={canDelete}
      onDelete={onDelete}
      renderImage={({ dimensionsSx }) => (
        <StyledImage
          sx={dimensionsSx}
          alt={resultFile.name}
          effect="blur"
          src={src}
          placeholderSrc={blurredSrc}
          wrapperProps={{ style: { width: "100%" } }}
        />
      )}
    />
  );
};

ImageFile.propTypes = {
  resultFile: PropTypes.shape({
    resultFileId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    uploading: PropTypes.bool,
    uploadingProgress: PropTypes.number,
  }),
  canDelete: PropTypes.bool,
  onDelete: PropTypes.func,
};

export default ImageFile;
