import PropTypes from "prop-types";

import { Alert, AlertTitle, Button, Link, Typography } from "@mui/material";

const AccountMenuAlert = ({ severity, title, description, action }) => (
  <>
    <Alert severity={severity}>
      <AlertTitle>{title}</AlertTitle>
      <div>
        <Typography variant="caption">{description}</Typography>
      </div>
      <div>
        {action && (
          <Button
            sx={(theme) => ({
              marginLeft: theme.spacing(-1),
              color: theme.palette.primary.main,
            })}
            component={Link}
            underline="always"
            onClick={action.func}
          >
            {action.label}
          </Button>
        )}
      </div>
    </Alert>
  </>
);

AccountMenuAlert.propTypes = {
  severity: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    label: PropTypes.string.isRequired,
    func: PropTypes.func.isRequired,
  }),
};

export default AccountMenuAlert;
