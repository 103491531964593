import { useState } from "react";

export const useLocalStorage = (key, initialValue) => {
  const [value, setValue] = useState(() => {
    const item = window.localStorage.getItem(key);
    if (item) {
      return JSON.parse(item);
    } else if (initialValue) {
      window.localStorage.setItem(key, JSON.stringify(initialValue));
      return initialValue;
    }
  });

  return [
    value,
    (newValue) => {
      window.localStorage.setItem(key, JSON.stringify(newValue));
      setValue(newValue);
    },
    () => {
      window.localStorage.removeItem(key);
      setValue(null);
    },
  ];
};

export const useSessionStorage = (key, initialValue) => {
  const [value, setValue] = useState(() => {
    const item = window.sessionStorage.getItem(key);
    if (item) {
      return JSON.parse(item);
    } else if (initialValue) {
      window.sessionStorage.setItem(key, JSON.stringify(initialValue));
      return initialValue;
    }
  });

  return [
    value,
    (newValue) => {
      window.sessionStorage.setItem(key, JSON.stringify(newValue));
      setValue(newValue);
    },
    () => {
      window.sessionStorage.removeItem(key);
      setValue(null);
    },
  ];
};
