import { useCallback, useEffect, useRef, useState } from "react";

import { useSession } from "@vesta/lib/react";

/*
 * Taken here: https://usehooks.com/useScript/
 */
export const useScript = (src) => {
  const [status, setStatus] = useState(src ? "loading" : "idle");

  useEffect(() => {
    if (!src) {
      setStatus("idle");
      return;
    }

    let script = document.querySelector(`script[src="${src}"]`);
    if (!script) {
      script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.setAttribute("data-status", "loading");
      document.body.appendChild(script);

      const setAttributeFromEvent = (event) => {
        script.setAttribute(
          "data-status",
          event.type === "load" ? "ready" : "error"
        );
      };

      script.addEventListener("load", setAttributeFromEvent);
      script.addEventListener("error", setAttributeFromEvent);
    } else {
      setStatus(script.getAttribute("data-status"));
    }

    const setStateFromEvent = (event) => {
      setStatus(event.type === "load" ? "ready" : "error");
    };

    script.addEventListener("load", setStateFromEvent);
    script.addEventListener("error", setStateFromEvent);

    return () => {
      if (script) {
        script.removeEventListener("load", setStateFromEvent);
        script.removeEventListener("error", setStateFromEvent);
      }
    };
  }, [src]);

  return status;
};

/*
 * Taken here: https://usehooks-ts.com/react-hook/use-timeout
 */
export const useTimeout = (callback, delay) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }

    const id = setTimeout(() => savedCallback.current(), delay);
    return () => clearTimeout(id);
  }, [delay]);
};

/*
 * Taken here: https://usehooks.com/useOnScreen
 */
export const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return isIntersecting;
};

/*
 * Taken here: https://usehooks-ts.com/react-hook/use-is-mounted
 */
export const useIsMounted = () => {
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => (isMountedRef.current = false);
  }, []);

  return useCallback(() => isMountedRef.current, [isMountedRef]);
};

/*
 * Taken here: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

/*
 * Taken here: https://medium.com/trabe/delayed-render-of-react-components-3482f8ad48ad
 */
export const useDelayedRender = (delay) => {
  const [delayed, setDelayed] = useState(true);

  useTimeout(() => setDelayed(false), delay);

  return (render) => render(delayed);
};

/*
 * Taken here: https://thewebdev.info/2021/03/13/how-to-make-the-react-useeffect-hook-not-run-on-initial-render/
 */
export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps);
};

const NurseRole = "Nurse";

export const useIsAuthorized = () => {
  const [session] = useSession();
  if (!session) {
    return false;
  }

  const now = Date.now() / 1000;
  if (now > session.exp) {
    return false;
  }

  const multipleRoles = Array.isArray(session.role);
  if (multipleRoles) {
    return session.role.includes(NurseRole);
  }

  return session.role === NurseRole;
};

export const useScrollToTopOnMount = () =>
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

export * from "./aos";
export * from "./compressor";
export * from "./crop";
export * from "./css";
export * from "./fetch";
export * from "./google";
export * from "./hotjar";
export * from "./media";
export * from "./session";
export * from "./storage";
export * from "./translation";
export * from "./tidio";
export * from "./tracking";
export * from "./visibility";
