import PropTypes from "prop-types";

import { Stack } from "@mui/material";

const RamqHealthInsurance = ({ number, expiration }) => (
  <Stack direction="column" spacing={1}>
    <Stack
      sx={(theme) => ({
        borderStyle: "dotted",
        borderWidth: 1,
        borderColor: theme.palette.text.secondary,
        borderRadius: 4,
        px: 2,
        py: 3,
      })}
      direction={{ xs: "column", md: "row" }}
      spacing={4}
    >
      {number}
      {expiration}
    </Stack>
  </Stack>
);

RamqHealthInsurance.propTypes = {
  number: PropTypes.node.isRequired,
  expiration: PropTypes.node.isRequired,
};

export default RamqHealthInsurance;
