import PropTypes from "prop-types";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Grid, TextField } from "@mui/material";

import { useUpSm } from "@vesta/lib/react";

import { useErrors, useSubmitCount, useValidation, useValue } from "./hooks";

const NewWebReference = ({ onAdd }) => {
  const upSm = useUpSm();
  const nameRef = useRef(null);
  const { t } = useTranslation("components");
  const [value, setValue, resetValue] = useValue();
  const [errors, setErrors, resetErrors] = useErrors();
  const [submitCount, incrementSubmitCount, resetSubmitCount] =
    useSubmitCount();

  const validate = useValidation(t);

  const handleNameChange = async (event) => {
    const name = event.target.value;
    setValue((prevValue) => ({ ...prevValue, name }));

    try {
      await validate({ name, url: value.url });
      resetErrors();
    } catch (e) {
      setErrors(e);
    }
  };

  const handleUrlChange = async (event) => {
    const url = event.target.value;
    setValue((prevValue) => ({ ...prevValue, url }));

    try {
      await validate({ name: value.name, url });
      resetErrors();
    } catch (e) {
      setErrors(e);
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key !== "Enter") {
      return;
    }
    event.preventDefault();
    try {
      await validate(value);
      onAdd(value);
      resetValue();
      resetErrors();
      resetSubmitCount();
      nameRef.current.focus();
    } catch (e) {
      incrementSubmitCount();
      setErrors(e);
    }
  };

  const handleAddClick = async () => {
    try {
      await validate(value);
      onAdd(value);
      resetValue();
      resetErrors();
      resetSubmitCount();
    } catch (e) {
      incrementSubmitCount();
      setErrors(e);
    }
  };

  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing(2),
        borderStyle: "dashed",
        borderWidth: 1,
        borderColor: theme.palette.text.secondary,
        borderRadius: 4,
        [theme.breakpoints.up("sm")]: {
          padding: theme.spacing(3),
        },
      })}
    >
      <Grid container alignItems="center" spacing={upSm ? 3 : 2}>
        <Grid container item xs={12} md spacing={upSm ? 3 : 2}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              variant="standard"
              id="name"
              inputRef={nameRef}
              fullWidth
              label={t("molecules.newWebReference.name.label")}
              placeholder={t("molecules.newWebReference.name.placeholder")}
              value={value.name}
              onChange={handleNameChange}
              onKeyDown={handleKeyDown}
              error={submitCount > 0 && Boolean(errors.name)}
              helperText={submitCount > 0 && errors.name}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <TextField
              variant="standard"
              id="url"
              type="url"
              fullWidth
              label={t("molecules.newWebReference.url.label")}
              placeholder={t("molecules.newWebReference.url.placeholder")}
              value={value.url}
              onChange={handleUrlChange}
              onKeyDown={handleKeyDown}
              error={submitCount > 0 && Boolean(errors.url)}
              helperText={submitCount > 0 && errors.url}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md="auto">
          <Button variant="contained" onClick={handleAddClick}>
            {t("molecules.newWebReference.addLabel")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

NewWebReference.propTypes = {
  onAdd: PropTypes.func.isRequired,
};

export default NewWebReference;
