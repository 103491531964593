import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Fab } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Add } from "@mui/icons-material";

import { useUpSm } from "@vesta/lib/react";

const StyledSpan = styled("span")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const AddAppointmentFloatingButton = ({ sx, onClick }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("calendar");

  return (
    <Fab
      sx={sx}
      color="primary"
      size={upSm ? "large" : "medium"}
      variant="extended"
      onClick={onClick}
    >
      <Add />
      <StyledSpan>
        {t("appointments.addAppointmentFloatingButton.label")}
      </StyledSpan>
    </Fab>
  );
};

AddAppointmentFloatingButton.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  onClick: PropTypes.func.isRequired,
};

export default AddAppointmentFloatingButton;
