import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";

import { LoadingButton } from "@vesta/components/atoms";
import { Dialog } from "@vesta/components/molecules";

const DeleteNonRecurringAvailabilityConfirmationDialog = ({
  open,
  onCancel,
  onDelete,
}) => {
  const { t } = useTranslation("calendar");
  const [deleting, setDeleting] = useState();

  const handleDelete = async () => {
    setDeleting(true);
    await onDelete();
    setDeleting(false);
  };

  return (
    <Dialog
      fullScreen={false}
      maxWidth="sm"
      open={open}
      onClose={onCancel}
      title={t(
        "availabilities.modifyAvailability.deleteNonRecurringAvailabilityConfirmationDialog.title"
      )}
      actions={
        <>
          <Button onClick={onCancel} size="large" variant="outlined">
            {t(
              "availabilities.modifyAvailability.deleteNonRecurringAvailabilityConfirmationDialog.cancel"
            )}
          </Button>
          <LoadingButton
            loading={deleting}
            disabled={deleting}
            onClick={handleDelete}
            size="large"
            variant="contained"
          >
            {t(
              "availabilities.modifyAvailability.deleteNonRecurringAvailabilityConfirmationDialog.delete"
            )}
          </LoadingButton>
        </>
      }
    />
  );
};

DeleteNonRecurringAvailabilityConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteNonRecurringAvailabilityConfirmationDialog;
