import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardActionArea,
  Collapse,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const CollapsibleSection = ({
  expandInitially,
  header,
  loading,
  loadingState,
  emptyState,
  appointments,
  renderAppointment,
}) => {
  const { t } = useTranslation("home");
  const [expanded, setExpanded] = useState(expandInitially);

  return (
    <Card component="section">
      <CardActionArea
        sx={(theme) => ({
          display: "flex",
          padding: theme.spacing(2),
        })}
        component="div"
        onClick={() => setExpanded((prevValue) => !prevValue)}
      >
        {header}
        <IconButton
          sx={(theme) => ({
            transform: "rotate(0deg)",
            marginLeft: "auto",
            transition: theme.transitions.create("transform", {
              duration: theme.transitions.duration.shortest,
            }),
            ...(expanded ? { transform: "rotate(180deg)" } : {}),
          })}
          edge="end"
          aria-expanded={expanded}
          aria-label={
            expanded
              ? t("appointments.collapsibleSection.hide")
              : t("appointments.collapsibleSection.show")
          }
          size="large"
        >
          <ExpandMore />
        </IconButton>
      </CardActionArea>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        {loading ? (
          loadingState
        ) : appointments.length == 0 ? (
          emptyState
        ) : (
          <Grid container direction="column">
            {appointments.map((x) => (
              <Grid key={x.appointmentId} item>
                {renderAppointment(x)}
                <Divider />
              </Grid>
            ))}
          </Grid>
        )}
      </Collapse>
    </Card>
  );
};

CollapsibleSection.propTypes = {
  expandInitially: PropTypes.bool,
  header: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  loadingState: PropTypes.node,
  emptyState: PropTypes.node,
  appointments: PropTypes.arrayOf(
    PropTypes.shape({
      appointmentId: PropTypes.string.isRequired,
    })
  ).isRequired,
  renderAppointment: PropTypes.func.isRequired,
};

export default CollapsibleSection;
