import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { ListItemButton, ListItemText } from "@mui/material";

const MainSidebarNavigationListItem = ({ to, label, selected, onClick }) => (
  <ListItemButton
    sx={(theme) => ({
      "&.Mui-selected": {
        borderLeft: `${theme.palette.primary.contrastText} ${theme.spacing(
          1 / 2
        )} solid`,
        backgroundColor: theme.palette.primary.main,
      },
    })}
    component={Link}
    to={to}
    selected={selected}
    onClick={onClick}
  >
    <ListItemText
      primary={label}
      primaryTypographyProps={{
        sx: { fontWeight: "bold" },
        variant: "body2",
      }}
    />
  </ListItemButton>
);

MainSidebarNavigationListItem.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default MainSidebarNavigationListItem;
