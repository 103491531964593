import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Alert, Button, Grid, Stack, Typography } from "@mui/material";

import { LoadingButton } from "@vesta/components/atoms";
import { AgreementPaper } from "@vesta/components/molecules";
import { agreementStatus } from "@vesta/lib/enum";

const ServiceAgreementPaper = ({
  agreement,
  context,
  requirements,
  onReject,
  onAccept,
}) => {
  const { t } = useTranslation("components");
  const [accepting, setAccepting] = useState(false);

  const handleAccept = async () => {
    setAccepting(true);
    await onAccept();
    setAccepting(false);
  };

  const isPending = agreement.statusId === agreementStatus.pending;

  return (
    <AgreementPaper
      title={t("molecules.serviceAgreementPaper.title")}
      agreement={agreement}
    >
      <Grid container spacing={{ xs: 2, sm: 3 }}>
        {isPending && (
          <Grid item xs={12}>
            <Alert component="aside" severity="warning">
              {t("molecules.serviceAgreementPaper.alert.text")}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Typography gutterBottom component="h3" variant="h5">
            {t("molecules.serviceAgreementPaper.context.title")}
          </Typography>
          <Typography variant="body2">{context}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography gutterBottom component="h3" variant="h5">
            {t("molecules.serviceAgreementPaper.requirement.title")}
          </Typography>
          <Typography variant="body2">{requirements}</Typography>
        </Grid>
        {isPending && (
          <Grid item xs={12}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2 }}
              justifyContent="flex-end"
            >
              <Button
                disabled={accepting}
                onClick={onReject}
                color="error"
                variant="outlined"
              >
                {t("molecules.serviceAgreementPaper.actions.reject")}
              </Button>
              <LoadingButton
                loading={accepting}
                disabled={accepting}
                onClick={handleAccept}
                variant="contained"
              >
                {t("molecules.serviceAgreementPaper.actions.accept")}
              </LoadingButton>
            </Stack>
          </Grid>
        )}
      </Grid>
    </AgreementPaper>
  );
};

ServiceAgreementPaper.propTypes = {
  agreement: PropTypes.object.isRequired,
  context: PropTypes.string.isRequired,
  requirements: PropTypes.string.isRequired,
  onReject: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default ServiceAgreementPaper;
