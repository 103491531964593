import { useFormik } from "formik";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import * as yup from "yup";

import { InputBase } from "@mui/material";

import { Reminder } from "@vesta/components/molecules";

const CustomReminder = ({
  reminder,
  onComplete,
  onRevertComplete,
  onDelete,
  onModify,
  onRemindAboutThis,
  onDoNotRemindAboutThis,
  onPickDateAndTime,
}) => {
  const validationSchema = yup.object().shape({
    title: yup.string(),
    details: yup.string(),
  });

  const { dirty, values, submitForm, handleChange } = useFormik({
    initialValues: {
      title: reminder.title,
      details: reminder.details ?? "",
    },
    validationSchema,
    onSubmit: async ({ title, details }) => onModify({ title, details }),
  });

  const debounceSubmitForm = useCallback(debounce(submitForm, 500), []);

  useEffect(() => {
    if (dirty) {
      debounceSubmitForm();
    }
  }, [dirty, values]);

  return (
    <Reminder
      id={reminder.id}
      title={
        <InputBase
          sx={(theme) => ({ marginTop: theme.spacing(1 / 4) })}
          name="title"
          fullWidth
          multiline
          value={values.title}
          onChange={handleChange}
          onFocus={(event) => event.target.select()}
          inputProps={{
            sx: [
              (theme) => ({
                ...theme.typography.body1,
              }),
              reminder.completed && { textDecoration: "line-through" },
            ],
          }}
        />
      }
      remindOn={reminder.remindOn}
      completed={reminder.completed}
      onComplete={onComplete}
      onRevertComplete={onRevertComplete}
      onDelete={onDelete}
      onRemindAboutThis={onRemindAboutThis}
      onDoNotRemindAboutThis={onDoNotRemindAboutThis}
      onPickDateAndTime={onPickDateAndTime}
    />
  );
};

CustomReminder.propTypes = {
  reminder: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    details: PropTypes.string,
    remindOn: PropTypes.instanceOf(Date),
    completed: PropTypes.instanceOf(Date),
  }).isRequired,
  onComplete: PropTypes.func.isRequired,
  onRevertComplete: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
  onRemindAboutThis: PropTypes.func.isRequired,
  onDoNotRemindAboutThis: PropTypes.func.isRequired,
  onPickDateAndTime: PropTypes.func.isRequired,
};

export default CustomReminder;
