import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link, useMatch } from "react-router-dom";

import { Paper, Tab, Tabs as MuiTabs } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useUpSm } from "@vesta/lib/react";

const tabSx = (theme) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(3 / 2),
  [theme.breakpoints.up("sm")]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
});

const StyledSpan = styled("span")(({ theme }) => ({
  fontSize: 14,
  [theme.breakpoints.up("sm")]: {
    fontSize: 16,
  },
}));

const Tabs = ({ sx }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("profile");
  const matchesBiography = useMatch("/profile/biography/*");
  const matchesTerritories = useMatch("/profile/territories/*");
  const matchesServices = useMatch("/profile/services/*");

  return (
    <Paper
      sx={[
        (theme) => ({
          display: "flex",
          height: theme.spacing(7),
          paddingLeft: theme.spacing(0),
          marginBottom: theme.spacing(2),
          [theme.breakpoints.up("sm")]: {
            paddingLeft: theme.spacing(4),
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <MuiTabs
        sx={{
          alignSelf: "flex-end",
          width: "100%",
        }}
        value={
          matchesBiography
            ? "/profile/biography"
            : matchesTerritories
            ? "/profile/territories"
            : matchesServices
            ? "/profile/services"
            : "/profile/biography"
        }
        centered={!upSm}
        aria-label={t("tabs.label")}
        TabIndicatorProps={{
          style: { transition: "none" },
        }}
      >
        <Tab
          sx={tabSx}
          component={Link}
          label={<StyledSpan>{t("tabs.biographyTab")}</StyledSpan>}
          to="/profile/biography"
          value="/profile/biography"
        />
        <Tab
          sx={tabSx}
          component={Link}
          label={<StyledSpan>{t("tabs.territoriesTab")}</StyledSpan>}
          to="/profile/territories"
          value="/profile/territories"
        />
        (
        <Tab
          sx={tabSx}
          component={Link}
          label={<StyledSpan>{t("tabs.servicesTab")}</StyledSpan>}
          to="/profile/services"
          value="/profile/services"
        />
        )
      </MuiTabs>
    </Paper>
  );
};

Tabs.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
};

export default Tabs;
