import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import * as yup from "yup";

import { Button, Grid, Typography } from "@mui/material";

import {
  ContactUsLink,
  ErrorAlert,
  LoadingButton,
} from "@vesta/components/atoms";
import {
  GroupedForms,
  PermitNumberTextField,
} from "@vesta/components/molecules";
import { PhotoIdentification } from "@vesta/components/organisms";
import { permitIssuingAuthority } from "@vesta/lib/enum";
import {
  useAppContext,
  useCompressor,
  useOnboardingSessionV1,
  useScrollToTopOnMount,
  useSession,
  useUpLg,
} from "@vesta/lib/react";

const maxFileSize = 16777216; // 16 MB

const Step6 = ({ onPrevious, onSkip, onNext }) => {
  const upLg = useUpLg();
  const compress = useCompressor({ quality: 0.4 });
  const { t } = useTranslation("onboarding-v1");
  const [, jwtToken] = useSession();
  const [, dispatchAppContext] = useAppContext();
  const [onboardingSession, dispatch] = useOnboardingSessionV1();
  const [uploading, setUploading] = useState(false);
  const [
    photoIdentificationSizeTooLargeError,
    setPhotoIdentificationSizeTooLargeError,
  ] = useState(false);
  const [uploadPhotoIdentificationError, setUploadPhotoIdentificationError] =
    useState(false);
  const [
    submitPermitAndPhotoIdentificationError,
    setSubmitPermitAndPhotoIdentificationError,
  ] = useState(false);

  useScrollToTopOnMount();

  const validationSchema = yup.object().shape({
    oiiqPermitNumber: yup.string().when("selectedPermitIssuingAuthority", {
      is: permitIssuingAuthority.oiiq,
      then: (schema) =>
        schema.required(t("onboardingSteps.step6.oiiqPermitNumber.required")),
    }),
    oiiaqPermitNumber: yup.string().when("selectedPermitIssuingAuthority", {
      is: permitIssuingAuthority.oiiaq,
      then: (schema) =>
        schema.required(t("onboardingSteps.step6.oiiaqPermitNumber.required")),
    }),
    photoIdentificationId: yup
      .string()
      .required(t("onboardingSteps.step6.photoIdentificationId.required")),
  });

  const {
    values,
    errors,
    isSubmitting,
    submitCount,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues: {
      oiiqPermitNumber: onboardingSession.oiiqPermitNumber ?? "",
      oiiaqPermitNumber: onboardingSession.oiiaqPermitNumber ?? "",
      selectedPermitIssuingAuthority: onboardingSession.oiiaqPermitNumber
        ? permitIssuingAuthority.oiiaq
        : permitIssuingAuthority.oiiq,
      photoIdentificationId: onboardingSession.photoIdentificationId ?? "",
    },
    validationSchema,
    onSubmit: async ({
      oiiqPermitNumber,
      oiiaqPermitNumber,
      selectedPermitIssuingAuthority,
      photoIdentificationId,
    }) => {
      const url = new URL(
        "/commands/submit-permit-and-photo-identification",
        process.env.REACT_APP_API_BASE_URL
      );

      const payload = {
        oiiqPermitNumber:
          selectedPermitIssuingAuthority === permitIssuingAuthority.oiiq
            ? oiiqPermitNumber
            : null,
        oiiaqPermitNumber:
          selectedPermitIssuingAuthority === permitIssuingAuthority.oiiaq
            ? oiiaqPermitNumber
            : null,
        photoIdentificationId,
      };

      const { ok } = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(payload),
      });

      if (ok) {
        dispatchAppContext({
          type: "submit-permit-and-photo-identification",
          payload,
        });
        dispatch({
          type: "submit-permit-and-photo-identification",
          payload,
        });
        await onNext();
      } else {
        setSubmitPermitAndPhotoIdentificationError(true);
      }
    },
  });

  const handleAddFileChange = async (event) => {
    const file = event.target.files[0];
    if (file.size > maxFileSize) {
      setPhotoIdentificationSizeTooLargeError(true);
      return;
    }

    setUploading(true);

    const url = new URL(
      "upload-photo-identification",
      process.env.REACT_APP_API_BASE_URL
    );

    const compressedFile = await compress(file);

    const formData = new FormData();
    formData.append(
      "photo-identification",
      compressedFile,
      compressedFile.name
    );

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      body: formData,
    });

    if (response.ok) {
      const { photoIdentificationId } = await response.json();
      setFieldValue("photoIdentificationId", photoIdentificationId);
    } else {
      setUploadPhotoIdentificationError(true);
    }

    setUploading(false);
  };

  const canSkip =
    !onboardingSession.oiiqPermitNumber ||
    !onboardingSession.photoIdentificationId;

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" spacing={4} data-aos="fade-up">
        <Grid item>
          <Typography gutterBottom variant="h1">
            {t("onboardingSteps.step6.title")}
          </Typography>
          <Typography>
            <Trans
              i18nKey="onboarding-v1:onboardingSteps.step6.subtitle"
              components={{
                divider: (
                  <>
                    <br />
                    <br />
                  </>
                ),
              }}
            />
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <GroupedForms
                sx={(theme) => ({
                  width: "100%",
                  maxWidth: theme.spacing(56),
                })}
                label={t("onboardingSteps.step6.permitNumber.groupsLabel")}
                value={values.selectedPermitIssuingAuthority}
                onChange={(value) =>
                  setFieldValue(
                    "selectedPermitIssuingAuthority",
                    parseInt(value)
                  )
                }
                groups={[
                  {
                    value: permitIssuingAuthority.oiiq,
                    label: t(
                      "onboardingSteps.step6.permitNumber.oiiqGroupLabel"
                    ),
                    form: (
                      <PermitNumberTextField
                        id="oiiqPermitNumber"
                        value={values.oiiqPermitNumber}
                        onChange={handleChange}
                        error={
                          submitCount > 0 && Boolean(errors.oiiqPermitNumber)
                        }
                        helperText={submitCount > 0 && errors.oiiqPermitNumber}
                      />
                    ),
                  },
                  {
                    value: permitIssuingAuthority.oiiaq,
                    label: t(
                      "onboardingSteps.step6.permitNumber.oiiaqGroupLabel"
                    ),
                    form: (
                      <PermitNumberTextField
                        id="oiiaqPermitNumber"
                        value={values.oiiaqPermitNumber}
                        onChange={handleChange}
                        error={
                          submitCount > 0 && Boolean(errors.oiiaqPermitNumber)
                        }
                        helperText={submitCount > 0 && errors.oiiaqPermitNumber}
                      />
                    ),
                  },
                ]}
              />
            </Grid>
            <Grid item>
              <PhotoIdentification
                uploading={uploading}
                submitCount={submitCount}
                values={values}
                errors={errors}
                onAddFileChange={handleAddFileChange}
                onRemoveClick={() => setFieldValue("photoIdentificationId", "")}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" item>
          <Grid item>
            <Button onClick={onPrevious} variant="outlined">
              {t("onboardingSteps.step6.actions.previous")}
            </Button>
          </Grid>
          <Grid item>
            {upLg && canSkip && (
              <Button
                sx={(theme) => ({ marginRight: theme.spacing(2) })}
                onClick={onSkip}
              >
                {t("onboardingSteps.step6.actions.skip")}
              </Button>
            )}
            <LoadingButton
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              variant="contained"
            >
              {t("onboardingSteps.step6.actions.next")}
            </LoadingButton>
          </Grid>
        </Grid>
        {!upLg && canSkip && (
          <Grid container justifyContent="flex-end" item>
            <Grid item>
              <Button onClick={onSkip}>
                {t("onboardingSteps.step6.actions.skip")}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      <ErrorAlert
        autoHide
        open={photoIdentificationSizeTooLargeError}
        onClose={() => setPhotoIdentificationSizeTooLargeError(false)}
        content={t(
          "onboardingSteps.step6.alerts.photoIdentificationSizeTooLargeError"
        )}
      />
      <ErrorAlert
        open={uploadPhotoIdentificationError}
        onClose={() => setUploadPhotoIdentificationError(false)}
        content={
          <Trans
            i18nKey="onboarding-v1:onboardingSteps.step6.alerts.uploadPhotoIdentificationError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
      <ErrorAlert
        open={submitPermitAndPhotoIdentificationError}
        onClose={() => setSubmitPermitAndPhotoIdentificationError(false)}
        content={
          <Trans
            i18nKey="onboarding-v1:onboardingSteps.step6.alerts.submitPermitAndPhotoIdentificationError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
    </form>
  );
};

Step6.propTypes = {
  onPrevious: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default Step6;
