import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { useTranslation } from "react-i18next";

import { Alert, AlertTitle } from "@mui/material";

import { useAppointment } from "@vesta/lib/react";

const CancelledAlert = () => {
  const { t } = useTranslation("appointment");
  const [appointment] = useAppointment();

  return (
    <Alert component="aside" severity="error">
      <AlertTitle>{t("cancelledAlert.title")}</AlertTitle>
      {t("cancelledAlert.text", {
        cancelled: format(appointment.cancelled, "PPPPp", {
          locale: fr,
        }),
      })}
    </Alert>
  );
};

export default CancelledAlert;
