import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useEffect, useMemo } from "react";

import { Box } from "@mui/material";

import { Loader } from "@vesta/components/atoms";
import { MinimalNavigationHeader } from "@vesta/components/organisms";
import { OnboardingSessionV1Context } from "@vesta/lib/react";

import { OnboardingSteps } from "./components";
import { useOnboardingSessionV1Reducer } from "./hooks";

const OnboardingV1View = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("onboarding-v1");
  const [onboardingSession, dispatch] = useOnboardingSessionV1Reducer();

  useEffect(() => {
    if (onboardingSession && onboardingSession.isFinished) {
      navigate("/?finished=v1");
    }
  }, [onboardingSession]);

  const value = useMemo(
    () => [onboardingSession, dispatch],
    [onboardingSession, dispatch]
  );

  return onboardingSession ? (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column",
        height: "100vh",
      }}
    >
      <Helmet>
        <title>{t("title")}</title>
        <meta property="og:title" content={t("title")} />
        <meta property="og:description" content={t("description")} />
      </Helmet>
      <MinimalNavigationHeader />
      <OnboardingSessionV1Context.Provider value={value}>
        <Routes>
          <Route path=":step" element={<OnboardingSteps />} />
          <Route
            path="*"
            element={<Navigate to="/onboarding/v1/1" replace />}
          />
          <Route index element={<Navigate to="/onboarding/v1/1" replace />} />
        </Routes>
      </OnboardingSessionV1Context.Provider>
    </Box>
  ) : (
    <Loader />
  );
};

export default OnboardingV1View;
