import { debounce } from "lodash";
import PropTypes from "prop-types";
import { useCallback } from "react";

import { Grid } from "@mui/material";

import { NoteEditorPlaceholder } from "@vesta/components/atoms";
import {
  NoteEditorFooter,
  RichTextEditor,
  RichTextEditorToolbar,
} from "@vesta/components/molecules";
import { NoteAttachments } from "@vesta/components/organisms";
import { useDidMountEffect, useMedicalRecord } from "@vesta/lib/react";

const NoteEditor = ({
  noteId,
  onRawContentChange,
  onNoteAttachmentChange,
  onDeleteNoteAttachment,
}) => {
  const [medicalRecord, dispatch] = useMedicalRecord();

  const debounceOnRawContentChange = useCallback(
    debounce((rawContent) => onRawContentChange(rawContent), 500),
    []
  );

  const note = medicalRecord.medicalInformation.notes.find(
    (x) => x.noteId === noteId
  );
  const { rawContent, completed } = note;

  useDidMountEffect(() => {
    if (!completed) {
      debounceOnRawContentChange(rawContent);
    }
  }, [completed, rawContent, debounceOnRawContentChange]);

  const readOnly = !!completed;

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <RichTextEditor
          readOnly={readOnly}
          placeholder={<NoteEditorPlaceholder note={note} />}
          toolbar={
            <RichTextEditorToolbar
              sx={(theme) => ({
                zIndex: 11,
                backgroundColor: theme.palette.background.paper,
                [theme.breakpoints.up("sm")]: {
                  backgroundColor: theme.palette.background.default,
                },
              })}
              readOnly={readOnly}
              onAttachmentChange={onNoteAttachmentChange}
            />
          }
          value={rawContent}
          onChange={({ rawContent }) =>
            dispatch({
              type: "change-note-raw-content",
              payload: {
                noteId,
                rawContent,
              },
            })
          }
        />
      </Grid>
      {note.attachments.some((x) => !x.deleted) && (
        <Grid item>
          <NoteAttachments
            note={note}
            onDeleteNoteAttachment={onDeleteNoteAttachment}
          />
        </Grid>
      )}
      <Grid item>
        <NoteEditorFooter note={note} />
      </Grid>
    </Grid>
  );
};

NoteEditor.propTypes = {
  noteId: PropTypes.string.isRequired,
  onRawContentChange: PropTypes.func.isRequired,
  onNoteAttachmentChange: PropTypes.func.isRequired,
  onDeleteNoteAttachment: PropTypes.func.isRequired,
};

export default NoteEditor;
