import PropTypes from "prop-types";
import { useState } from "react";

import { styled } from "@mui/material/styles";

import { File } from "@vesta/components/molecules";
import { noteAttachmentType } from "@vesta/lib/enum";

const getDefaultPdfPreviewSrc = (typeId) => {
  switch (typeId) {
    case noteAttachmentType.pdf:
      return "/default-note-attachment-pdf-preview.png";
    default:
      return "";
  }
};

const StyledImg = styled("img")({
  objectFit: "cover",
  objectPosition: "center top",
});

const Attachment = ({ noteAttachment, canDelete, onDelete }) => {
  const [isPdfPreviewLoaded, setIsPdfPreviewLoaded] = useState(false);

  const src = new URL(
    `/download-note-attachment/${noteAttachment.noteAttachmentId}`,
    process.env.REACT_APP_API_BASE_URL
  ).toString();

  const pdfPreviewSrc = new URL(
    `/download-note-attachment-pdf-preview/${noteAttachment.noteAttachmentId}`,
    process.env.REACT_APP_API_BASE_URL
  ).toString();

  const handleLoad = (e) =>
    setIsPdfPreviewLoaded(e.target.src === pdfPreviewSrc);

  const handleError = (e) => {
    e.target.src = getDefaultPdfPreviewSrc(noteAttachment.typeId);
    setTimeout(() => {
      e.target.src = pdfPreviewSrc;
    }, 2000);
  };

  return (
    <File
      src={src}
      name={noteAttachment.name}
      uploading={noteAttachment.uploading}
      uploadingProgress={noteAttachment.uploadingProgress}
      canDelete={canDelete}
      onDelete={onDelete}
      renderImage={({ dimensionsSx }) => (
        <StyledImg
          sx={[
            isPdfPreviewLoaded &&
              ((theme) => ({
                borderTopLeftRadius: theme.spacing(1),
                borderTopRightRadius: theme.spacing(1),
                borderTop: `1px solid ${theme.palette.action.active}`,
                borderLeft: `1px solid ${theme.palette.action.active}`,
                borderRight: `1px solid ${theme.palette.action.active}`,
              })),
            dimensionsSx,
          ]}
          src={pdfPreviewSrc}
          alt={noteAttachment.name}
          loading="lazy"
          onLoad={handleLoad}
          onError={handleError}
        />
      )}
    />
  );
};

Attachment.propTypes = {
  noteAttachment: PropTypes.shape({
    noteAttachmentId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    typeId: PropTypes.number.isRequired,
    uploading: PropTypes.bool,
    uploadingProgress: PropTypes.number,
  }),
  canDelete: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
};

export default Attachment;
