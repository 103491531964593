import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const width = 199;
const height = 51;

const WideRight = ({ sx }) => {
  const theme = useTheme();
  const { t } = useTranslation("components");

  return (
    <Box
      sx={sx}
      component="svg"
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="logoTitle logoDesc"
      role="img"
    >
      <Link to="/">
        <title id="logoTitle">{t("atoms.logo.variants.wideRight.title")}</title>
        <desc id="logoDesc">
          {t("atoms.logo.variants.wideRight.description")}
        </desc>
        <g>
          <path
            style={{ fill: theme.palette.primary.main }}
            d="M16.456 47h-4.464L1.72 16.232h3.936l.72 3.12 7.824 23.76h.288l8.64-23.568.768-3.312h3.84L16.456 47Zm30.55-3.072.288.288L46.958 47H32.414l.144-7.2-.144-16.704h14.64l.24.288-.288 2.784-6.048-.192-4.992.048-.048 7.248 4.752.048 4.176-.144.24.336-.288 2.832-4.416-.144-4.464.048-.048 3.264.048 4.56 4.704.048 6.384-.192Zm13.323-21.072c1.952 0 3.904.384 5.856 1.152l-.624 3.552-.576.144c-.896-.704-1.744-1.216-2.544-1.536a6.134 6.134 0 0 0-2.496-.528c-1.376 0-2.464.368-3.264 1.104-.8.704-1.2 1.52-1.2 2.448 0 .928.384 1.68 1.152 2.256.8.576 2.016 1.216 3.648 1.92 1.44.64 2.576 1.216 3.408 1.728a7.13 7.13 0 0 1 2.208 2.064c.64.832.96 1.856.96 3.072a6.551 6.551 0 0 1-1.008 3.504c-.672 1.056-1.664 1.92-2.976 2.592-1.28.64-2.816.96-4.608.96-1.12 0-2.304-.128-3.552-.384a14.916 14.916 0 0 1-3.408-1.248l.432-3.792.48-.192a9.959 9.959 0 0 0 3.12 2.112c1.216.48 2.336.72 3.36.72 1.344 0 2.448-.352 3.312-1.056.864-.736 1.296-1.616 1.296-2.64 0-.96-.4-1.728-1.2-2.304-.768-.576-1.984-1.248-3.648-2.016-1.376-.608-2.512-1.168-3.408-1.68a7.363 7.363 0 0 1-2.208-2.016c-.608-.864-.912-1.904-.912-3.12 0-1.28.336-2.432 1.008-3.456.704-1.024 1.68-1.84 2.928-2.448 1.28-.608 2.768-.912 4.464-.912Zm27.705.528-.24 3.024-4.464-.336h-2.4l-.144 13.44L80.93 47h-3.6l.144-7.2-.096-13.728h-2.4l-4.56.336-.288-.336.288-2.976h17.424l.192.288ZM109.866 47h-3.6l-.576-2.592-1.248-3.552-5.712-.048-5.088.048-1.296 3.36L91.674 47h-3.552l9.216-23.904h4.128l8.4 23.904Zm-6.432-9.072L99.45 26.6h-.288l-4.368 11.328 4.224.048 4.416-.048Zm73.688-19.624L142 47h-14l15.138-10.056v-8.261h4.505v5.268L177.122 14 199 33l-21.878-14.696Z"
          />
          <path
            style={{ fill: theme.palette.secondary.dark }}
            d="M128.522 15.794a4.87 4.87 0 0 0 3.104 6.148l10.794 3.552 3.552-10.794a4.87 4.87 0 1 0-9.458-.905 4.87 4.87 0 0 0-7.992 2Z"
          />
          <path
            style={{ fill: theme.palette.secondary.main }}
            d="M111.924 6.55a2.955 2.955 0 0 0 1.883 3.73l6.549 2.155 2.155-6.55a2.955 2.955 0 1 0-5.739-.549 2.954 2.954 0 0 0-4.848 1.213Z"
          />
          <path
            style={{ fill: theme.palette.secondary.light }}
            d="M95.623 4.414a1.991 1.991 0 0 0 1.269 2.514l4.414 1.452 1.452-4.413a1.99 1.99 0 1 0-3.868-.37 1.991 1.991 0 0 0-3.268.817Z"
          />
          <path
            style={{ fill: theme.palette.primary.main }}
            d="M174 27h3v3h-3zm4 0h3v3h-3zm-4 4h3v3h-3zm4 0h3v3h-3z"
          />
        </g>
        <rect width={width} height={height} fill="rgba(255,255,255,0)" />
      </Link>
    </Box>
  );
};

WideRight.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
};

export default WideRight;
