import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { Autocomplete } from "@mui/material";

import { AutocompleteOption } from "./components";
import { useGetSearchablePatientsBySearchTerm } from "./hooks";

const PatientsAutocomplete = ({ className, value, onChange, ...rest }) => {
  const { t } = useTranslation("components");
  const [term, setTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [searchablePatients, loading] =
    useGetSearchablePatientsBySearchTerm(term);

  useEffect(() => {
    let newOptions = value ? [value] : [];
    if (term) {
      newOptions = [
        ...newOptions,
        ...(searchablePatients ? [...searchablePatients] : []),
      ];
    }
    setOptions(newOptions);
  }, [term, value, searchablePatients]);

  const handleGetOptionLabel = (option) => {
    if (option.firstName && option.lastName) {
      const fullName = `${option.firstName} ${option.lastName}`;
      return t(
        option.isDemoPatient
          ? "atoms.patientsAutocomplete.demoPatientFullName"
          : "atoms.patientsAutocomplete.patientFullName",
        { fullName }
      );
    }

    return option.email ?? "";
  };

  const handleChange = (e, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    onChange(e, newValue);
  };

  const handleFilterOptions = (options, { inputValue }) => {
    const addOption = {
      isNewPatient: true,
      email: "",
      firstName: inputValue ? inputValue.split(" ")[0] : "",
      lastName: inputValue ? inputValue.split(" ").slice(1).join("") : "",
      birthday: null,
      sexId: "",
      spokenLanguageIds: [],
      ramqHealthInsuranceNumber: "",
      ramqHealthInsuranceExpiration: null,
      phoneNumberInE164Format: "",
      label: inputValue
        ? t("atoms.patientsAutocomplete.addOption.term", {
            inputValue,
          })
        : t("atoms.patientsAutocomplete.addOption.noTerm"),
    };

    const filteredOptions = [
      ...options.map((x) => ({
        ...x,
        isNewPatient: false,
        firstName: x.firstName ?? "",
        lastName: x.lastName ?? "",
        birthday: x.birthday ? new Date(x.birthday) : null,
        sexId: x.sexId ?? "",
        spokenLanguageIds: x.spokenLanguageIds ?? [],
        ramqHealthInsuranceNumber: x.ramqHealthInsuranceNumber ?? "",
        ramqHealthInsuranceExpiration: x.ramqHealthInsuranceExpiration
          ? new Date(x.ramqHealthInsuranceExpiration)
          : null,
        phoneNumberInE164Format: x.phoneNumberInE164Format ?? "",
      })),
      addOption,
    ];

    return filteredOptions;
  };

  return (
    <Autocomplete
      className={className}
      includeInputInList
      filterSelectedOptions
      disableClearable
      forcePopupIcon={false}
      loading={loading}
      loadingText={t("atoms.patientsAutocomplete.loadingText")}
      value={value}
      options={options}
      getOptionLabel={handleGetOptionLabel}
      onChange={handleChange}
      onInputChange={(_, newTerm) => setTerm(newTerm.trim())}
      isOptionEqualToValue={(option, value) =>
        option.patientId === value.patientId
      }
      renderOption={(props, option) => {
        const key = option.isNewPatient ? "new" : option.patientId;
        return (
          <li {...props} key={key}>
            <AutocompleteOption option={option} />
          </li>
        );
      }}
      filterOptions={handleFilterOptions}
      {...rest}
    />
  );
};

PatientsAutocomplete.propTypes = {
  className: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default PatientsAutocomplete;
