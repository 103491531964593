import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { CircularProgress, Fab } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useUpSm } from "@vesta/lib/react";

const StyledSpan = styled("span")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const LoadingFloatingButton = ({
  sx,
  loading,
  loadingLabel,
  label,
  ...rest
}) => {
  const upSm = useUpSm();
  const { t } = useTranslation("components");

  const floatingButtonSize = upSm ? "large" : "medium";

  return loading ? (
    <Fab
      sx={[
        { backgroundColor: "transparent !important" },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      color="primary"
      disabled
      size={floatingButtonSize}
      variant="extended"
      {...rest}
    >
      <CircularProgress size={upSm ? 24 : 16} />
      <StyledSpan>
        {loadingLabel ?? t("atoms.loadingFloatingButton.loadingLabel")}
      </StyledSpan>
    </Fab>
  ) : (
    <Fab
      sx={sx}
      color="primary"
      size={floatingButtonSize}
      variant="extended"
      {...rest}
    >
      {label}
    </Fab>
  );
};

LoadingFloatingButton.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  loading: PropTypes.bool,
  loadingLabel: PropTypes.string,
  label: PropTypes.node.isRequired,
};

export default LoadingFloatingButton;
