import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Clear, Search } from "@mui/icons-material";

const SearchBar = ({ sx, autofocus, placeholder, value, onChange }) => {
  const inputRef = useRef(null);
  const { t } = useTranslation("components");

  useEffect(() => {
    if (autofocus) {
      inputRef.current.focus();
    }
  }, []);

  const handleClearClick = () => {
    onChange("");
    inputRef.current.focus();
  };

  return (
    <TextField
      sx={[
        (theme) => ({
          backgroundColor: theme.palette.background.paper,
          width: "100%",
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      placeholder={placeholder ?? t("molecules.searchBar.placeholder")}
      size="small"
      variant="outlined"
      inputProps={{
        ref: inputRef,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search color="action" />
          </InputAdornment>
        ),
        endAdornment: value ? (
          <InputAdornment position="end">
            <IconButton
              aria-label={t("molecules.searchBar.clear")}
              onClick={handleClearClick}
              edge="end"
              size="small"
            >
              <Clear color="action" />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
  );
};

SearchBar.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  autofocus: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchBar;
