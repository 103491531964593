import { addHours, addMilliseconds, endOfDay, startOfHour } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Paper } from "@mui/material";

import { SuccessAlert } from "@vesta/components/atoms";
import { StyledCalendar } from "@vesta/components/molecules";
import { useSessionStorage } from "@vesta/lib/react";

import {
  AddAvailabilityFloatingButton,
  CreateAvailabilityDialog,
  Event,
  ModifyAvailability,
} from "./components";
import { useEvents } from "./hooks";

const Availabilities = () => {
  const { t } = useTranslation("calendar");
  const [creating, setCreating] = useState(null);
  const [modifying, setModifying] = useState(null);
  const [saved, setSaved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [date, setDate] = useSessionStorage("vesta-date", new Date());

  const [events, loading] = useEvents(date, dirty);

  const handleSelectSlot = ({ start, end }) => {
    const endTime = end.getTime();
    const endOfDayTime = endOfDay(end).getTime();
    const endsAtMidnight = endTime === endOfDayTime;

    if (endsAtMidnight) {
      const midnight = addMilliseconds(end, 1); // So the availability duration in minutes is a multiple of 15
      setCreating({ start, end: midnight });
    } else {
      setCreating({ start, end });
    }
  };

  const handleFloatingButtonClick = () => {
    const start = startOfHour(new Date(date));
    const end = addHours(start, 1);

    setCreating({ start, end });
  };

  const handleCreate = () => {
    setDirty(true);
    setSaved(true);
    setCreating(null);
  };

  const handleModified = () => {
    setDirty(true);
    setSaved(true);
    setModifying(null);
  };

  const handleDelete = () => {
    setDirty(true);
    setDeleted(true);
    setModifying(null);
  };

  return (
    <Paper
      sx={(theme) => ({
        padding: theme.spacing(2),
        marginBottom: theme.spacing(5),
        [theme.breakpoints.up("sm")]: {
          padding: theme.spacing(4),
          paddingTop: theme.spacing(2),
          marginBottom: theme.spacing(7),
        },
        [theme.breakpoints.up("md")]: {
          marginBottom: theme.spacing(6),
        },
      })}
      component="section"
    >
      <StyledCalendar
        sx={(theme) => ({
          "& .rbc-slot-selection": {
            opacity: "0.8 !important",
            backgroundColor: `${theme.palette.secondary.dark} !important`,
            color: theme.palette.primary.contrastText,
            fontSize: 12,
          },
        })}
        loading={loading && !dirty}
        events={events}
        selectable="ignoreEvents"
        onSelectSlot={handleSelectSlot}
        onSelectEvent={setModifying}
        date={new Date(date)}
        onNavigate={setDate}
        components={{
          event: (props) => <Event {...props} />,
          eventWrapper: ({ children }) => (
            <Box
              sx={(theme) => ({
                "& .rbc-event": {
                  backgroundColor: `${theme.palette.secondary.dark} !important`,
                  border: `0.1px solid ${theme.palette.primary.contrastText} !important`,
                },
              })}
            >
              {children}
            </Box>
          ),
        }}
      />
      <Box
        sx={(theme) => ({
          height: theme.spacing(7),
          [theme.breakpoints.up("md")]: {
            height: theme.spacing(11),
          },
        })}
      />
      <AddAvailabilityFloatingButton
        sx={(theme) => ({
          position: "fixed",
          zIndex: 4,
          right: theme.spacing(2),
          bottom: theme.spacing(9),
          [theme.breakpoints.up("md")]: {
            bottom: theme.spacing(4),
          },
        })}
        onClick={handleFloatingButtonClick}
      />
      <CreateAvailabilityDialog
        open={!!creating}
        initialStart={creating?.start}
        initialEnd={creating?.end}
        onCancel={() => setCreating(null)}
        onCreate={handleCreate}
      />
      <ModifyAvailability
        modifying={!!modifying}
        availabilityId={modifying?.availabilityId}
        occurrenceId={modifying?.occurrenceId}
        onCancel={() => setModifying(null)}
        onModify={handleModified}
        onDelete={handleDelete}
      />
      <SuccessAlert
        open={saved}
        onClose={() => setSaved(false)}
        content={t("availabilities.saved")}
      />
      <SuccessAlert
        open={deleted}
        onClose={() => setDeleted(false)}
        content={t("availabilities.deleted")}
      />
    </Paper>
  );
};

export default Availabilities;
