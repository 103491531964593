import { PropTypes } from "prop-types";

import { Box, Typography } from "@mui/material";

const AmountLabel = ({ label, amount, bold }) => {
  const currencyFormatter = Intl.NumberFormat("fr-CA", {
    currency: "CAD",
    style: "currency",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <Box
      sx={{
        display: "flex",
        alignContent: "flex-end",
        justifyContent: "flex-end",
      }}
    >
      {label && (
        <Typography sx={{ fontWeight: bold ? 600 : 400 }} variant="body1">
          {label}
        </Typography>
      )}
      <Typography
        sx={(theme) => ({
          width: theme.spacing(14),
          textAlign: "end",
          fontWeight: bold ? 600 : 400,
        })}
        variant="body1"
      >
        {currencyFormatter.format(amount)}
      </Typography>
    </Box>
  );
};

AmountLabel.propTypes = {
  label: PropTypes.string,
  amount: PropTypes.number.isRequired,
  bold: PropTypes.bool,
};

AmountLabel.defaultProps = {
  label: null,
  bold: false,
};

export default AmountLabel;
