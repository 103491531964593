import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

import { Loader } from "@vesta/components/atoms";
import { AppContext } from "@vesta/lib/react";

import {
  useAppContextReducer,
  useFirstUnfinishedOnboardingSession,
} from "./hooks";

const AuthorizedElement = ({ children }) => {
  const [isAuthorized, appContext, dispatch] = useAppContextReducer();

  const onboardingSessions = appContext?.onboardingSessions ?? [];
  const firstUnfinishedOnboardingSession =
    useFirstUnfinishedOnboardingSession(onboardingSessions);

  const value = useMemo(() => [appContext, dispatch], [appContext, dispatch]);

  if (!isAuthorized) {
    return <Navigate to="/sign-in" />;
  }

  if (!appContext) {
    return <Loader />;
  }

  if (firstUnfinishedOnboardingSession) {
    const { name, currentStep } = firstUnfinishedOnboardingSession;
    return <Navigate to={`/onboarding/${name}/${currentStep + 1}`} replace />;
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

AuthorizedElement.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthorizedElement;
