import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Link,
  Typography,
} from "@mui/material";
import { FileUpload } from "@mui/icons-material";

import {
  DelayedCircularProgress,
  LoadingButton,
} from "@vesta/components/atoms";
import { Dialog, SearchBar } from "@vesta/components/molecules";
import { useUpMd, useUpSm } from "@vesta/lib/react";

import { useSearchPhotos } from "./hooks";

const ModifyCoverPictureDialog = ({
  open,
  onCancel,
  onSelectFile,
  onSelectUnsplashPhoto,
}) => {
  const upSm = useUpSm();
  const upMd = useUpMd();
  const uploadRef = useRef(null);
  const { t } = useTranslation("profile");
  const [query, setQuery] = useState("");
  const [selecting, setSelecting] = useState(null);

  const [photos, searchingPhotos, trackPhotoDownload] = useSearchPhotos(
    open,
    query
  );

  const handleImageListItemClick = (photo) => async () => {
    if (selecting) {
      return;
    }

    setSelecting({ photoId: photo.id });

    await onSelectUnsplashPhoto(photo);
    await trackPhotoDownload(photo.id);

    setSelecting(null);
  };

  const handleUploadLinkClick = () => {
    if (selecting) {
      return;
    }

    uploadRef.current.click();
  };

  const handleFileChange = async (event) => {
    if (selecting) {
      return;
    }

    const file = event.target.files[0];

    setSelecting({ fileName: file.name });
    await onSelectFile({ file });
    setSelecting(null);
  };

  const loadingState = (
    <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
      <DelayedCircularProgress sx={{ margin: "auto" }} />
    </Box>
  );

  const emptyState = (
    <Box sx={{ margin: "auto" }}>
      <Typography gutterBottom align="center" component="h2" variant="h3">
        {t("biography.coverPicture.modifyCoverPictureDialog.emptyState.title")}
      </Typography>
      <Typography align="center">
        <Trans
          i18nKey="profile:biography.coverPicture.modifyCoverPictureDialog.emptyState.text"
          components={{
            uploadLink: (
              <Link
                sx={{ cursor: "pointer" }}
                onClick={handleUploadLinkClick}
                underline="always"
              />
            ),
          }}
        />
      </Typography>
    </Box>
  );

  const imageList = (
    <>
      <Typography variant="caption">
        <Trans
          i18nKey="profile:biography.coverPicture.modifyCoverPictureDialog.unsplashDisclaimer"
          components={{
            licenseLink: (
              <Link
                underline="always"
                href="https://unsplash.com/fr/licence"
                target="_blank"
              />
            ),
            termsOfServiceLink: (
              <Link
                underline="always"
                href="https://unsplash.com/fr/conditions"
                target="_blank"
              />
            ),
          }}
        />
      </Typography>
      <ImageList cols={upMd ? 4 : upSm ? 2 : 1} gap={8}>
        {photos.map((x) => {
          const src = new URL(x.urls.raw);
          src.searchParams.set("w", 550);
          src.searchParams.set("fit", "crop");
          src.searchParams.set("auto", "format");

          const htmlLink = new URL(x.user.links.html);
          htmlLink.pathname = `/fr${htmlLink.pathname}`;
          htmlLink.searchParams.set("utm_source", "Vesta");
          htmlLink.searchParams.set("utm_medium", "referral");

          return (
            <ImageListItem key={x.id}>
              <img
                style={{ cursor: "pointer" }}
                src={src}
                alt={x.description}
                loading="lazy"
                onClick={handleImageListItemClick(x)}
              />
              <ImageListItemBar
                position="below"
                subtitle={
                  <Trans
                    i18nKey="profile:biography.coverPicture.modifyCoverPictureDialog.photoUserName"
                    components={{
                      htmlLink: (
                        <Link
                          color="inherit"
                          underline="always"
                          href={htmlLink}
                          target="_blank"
                        />
                      ),
                      unsplashLink: (
                        <Link
                          color="inherit"
                          underline="always"
                          href="https://unsplash.com/fr?utm_source=Vesta&utm_medium=referral"
                          target="_blank"
                        />
                      ),
                    }}
                    values={{ name: x.user.name }}
                  />
                }
                actionPosition="left"
                actionIcon={
                  selecting &&
                  selecting.photoId === x.id && (
                    <DelayedCircularProgress
                      sx={(theme) => ({
                        marginRight: theme.spacing(1),
                      })}
                      size={14}
                    />
                  )
                }
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    </>
  );

  return (
    <Dialog
      fullWidth
      dividers
      maxWidth="lg"
      scroll="paper"
      PaperProps={{
        sx: (theme) => ({
          [theme.breakpoints.up("sm")]: {
            height: "95vh",
          },
        }),
      }}
      open={open}
      onClose={onCancel}
      title={t("biography.coverPicture.modifyCoverPictureDialog.title")}
      content={
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <SearchBar
            sx={(theme) => ({ marginBottom: theme.spacing(1) })}
            placeholder={t(
              "biography.coverPicture.modifyCoverPictureDialog.searchBarPlaceholder"
            )}
            value={query}
            onChange={(query) => setQuery(query)}
          />
          {searchingPhotos && photos.length === 0
            ? loadingState
            : photos.length === 0
            ? emptyState
            : imageList}
        </Box>
      }
      actions={
        <>
          <Button
            disabled={!!selecting}
            onClick={onCancel}
            size="large"
            variant="outlined"
          >
            {t("biography.coverPicture.modifyCoverPictureDialog.cancel")}
          </Button>
          <input
            id="edit-cover-picture"
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            onChange={handleFileChange}
          />
          <label htmlFor="edit-cover-picture">
            <LoadingButton
              sx={(theme) => ({ marginLeft: theme.spacing(1) })}
              ref={uploadRef}
              loading={selecting && !!selecting.fileName}
              disabled={!!selecting}
              component="span"
              size="large"
              variant="outlined"
              startIcon={<FileUpload />}
            >
              {upSm
                ? t(
                    "biography.coverPicture.modifyCoverPictureDialog.uploadUpSm"
                  )
                : t("biography.coverPicture.modifyCoverPictureDialog.upload")}
            </LoadingButton>
          </label>
        </>
      }
      TransitionProps={{
        onExit: () => setQuery(""),
      }}
    />
  );
};

ModifyCoverPictureDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelectFile: PropTypes.func.isRequired,
  onSelectUnsplashPhoto: PropTypes.func.isRequired,
};

export default ModifyCoverPictureDialog;
