import { fr } from "date-fns/locale";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Box, Link as MuiLink, Skeleton, Typography } from "@mui/material";

const rootSx = (theme) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2),
});

const fullNameSx = (theme) => ({ marginBottom: theme.spacing(1) });

const localizedDate = (start) => {
  const date = format(start, "PPPP", { locale: fr });
  return date.charAt(0).toUpperCase() + date.slice(1);
};

const Appointment = ({ loading, timeOnly, appointment }) => {
  const { t } = useTranslation("components");

  if (loading) {
    return (
      <Box sx={rootSx} component="section">
        <Typography sx={fullNameSx} variant="body1">
          <Skeleton width="70%" />
        </Typography>
        <Typography variant="caption">
          <Skeleton width="40%" />
        </Typography>
        <Typography variant="caption">
          <Skeleton width="60%" />
        </Typography>
        <Typography variant="caption">
          <Skeleton width="30%" />
        </Typography>
      </Box>
    );
  }

  const fullName = appointment.isAppointmentForSomeoneElse
    ? `${appointment.someoneElse.firstName} ${appointment.someoneElse.lastName}`
    : `${appointment.patientFirstName} ${appointment.patientLastName}`;

  const nbServices = appointment.services.length;

  return (
    <Box sx={rootSx} component="section">
      <MuiLink
        sx={fullNameSx}
        component={Link}
        to={`/calendar/appointments/${appointment.appointmentId}/preparation`}
        underline="always"
        variant="body1"
      >
        {t(
          appointment.isDemoPatient
            ? "molecules.appointment.demoPatientFullName"
            : "molecules.appointment.patientFullName",
          { fullName }
        )}
      </MuiLink>
      <Typography variant="caption">
        {timeOnly
          ? t("molecules.appointment.startTime", {
              value: format(appointment.start, "p", { locale: fr }),
            })
          : t("molecules.appointment.startDateTime", {
              date: localizedDate(appointment.start),
              time: format(appointment.start, "p", { locale: fr }),
            })}
      </Typography>
      <Typography variant="caption">
        {appointment.patientFormattedAddress}
      </Typography>
      {appointment.services.length > 0 && (
        <Typography variant="caption">
          {`${appointment.services[0].nameFr}${
            nbServices > 1 ? ` + ${nbServices - 1}` : ""
          }`}
        </Typography>
      )}
    </Box>
  );
};

Appointment.propTypes = {
  loading: PropTypes.bool,
  timeOnly: PropTypes.bool,
  appointment: PropTypes.shape({
    appointmentId: PropTypes.string.isRequired,
    patientFirstName: PropTypes.string,
    patientLastName: PropTypes.string,
    patientFormattedAddress: PropTypes.string.isRequired,
    isDemoPatient: PropTypes.bool.isRequired,
    isAppointmentForSomeoneElse: PropTypes.bool.isRequired,
    someoneElse: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
    start: PropTypes.instanceOf(Date).isRequired,
    services: PropTypes.arrayOf(
      PropTypes.shape({
        nameFr: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
};

export default Appointment;
