import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { TextField } from "@mui/material";

const FirstNameTextField = ({ sx, readOnly, ...rest }) => {
  const { t } = useTranslation("components");

  return (
    <TextField
      variant="standard"
      autoComplete="fname"
      sx={sx}
      fullWidth
      label={t("molecules.firstNameTextField.label")}
      InputProps={{
        readOnly,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      {...rest}
    />
  );
};

FirstNameTextField.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  readOnly: PropTypes.bool,
};

export default FirstNameTextField;
