import { fr } from "date-fns/locale";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  DesktopDatePicker as MuiDesktopDatePicker,
  frFR,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const DesktopDatePicker = ({
  autoComplete,
  fullWidth,
  error,
  helperText,
  ...rest
}) => {
  const { t } = useTranslation("components");

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={fr}
      localeText={
        frFR.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <MuiDesktopDatePicker
        slotProps={{
          textField: {
            variant: "standard",
            fullWidth,
            error,
            helperText,
            InputLabelProps: {
              shrink: true,
            },
            inputProps: {
              autoComplete,
              placeholder: t("atoms.desktopDatePicker.placeholder"),
            },
          },
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
};

DesktopDatePicker.defaultProps = {
  fullWidth: true,
};

DesktopDatePicker.propTypes = {
  autoComplete: PropTypes.string,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default DesktopDatePicker;
