import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  Card,
  CardActionArea,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

import { useUpSm } from "@vesta/lib/react";

const actionAreaSx = (theme) => ({
  display: "flex",
  padding: theme.spacing(2),
});

const nameSx = (theme) => ({
  marginRight: theme.spacing(2),
  width: "100%",
});

const editSx = { marginLeft: "auto" };

const TerritoryCard = ({ loading, territory }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("profile");
  const [raised, setRaised] = useState(false);

  if (loading) {
    return (
      <Card component="section">
        <CardActionArea sx={actionAreaSx} component="div">
          <Typography sx={nameSx} noWrap={upSm} component="h2" variant="button">
            <Skeleton width="70%" />
          </Typography>
          <IconButton
            sx={editSx}
            aria-label={t("territories.territoryCard.edit")}
            disabled
            color="primary"
            edge="end"
            size="large"
          >
            <ArrowForward />
          </IconButton>
        </CardActionArea>
      </Card>
    );
  }

  return (
    <Card
      component="section"
      raised={raised}
      onMouseOver={() => setRaised(true)}
      onMouseOut={() => setRaised(false)}
    >
      <CardActionArea
        sx={actionAreaSx}
        component={Link}
        to={`/profile/territories/${territory.id}`}
      >
        <Typography sx={nameSx} noWrap={upSm} component="h2" variant="button">
          {territory.name}
        </Typography>
        <IconButton
          sx={editSx}
          aria-label={t("territories.territoryCard.edit")}
          color="primary"
          edge="end"
          size="large"
        >
          <ArrowForward />
        </IconButton>
      </CardActionArea>
    </Card>
  );
};

TerritoryCard.propTypes = {
  loading: PropTypes.bool,
  territory: PropTypes.object,
};

export default TerritoryCard;
