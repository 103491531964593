import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { TextField } from "@mui/material";

const AdditionalInformationTextField = ({ className, readOnly, ...rest }) => {
  const { t } = useTranslation("components");

  return (
    <TextField
      variant="standard"
      className={className}
      fullWidth
      multiline
      label={t("molecules.additionalInformationTextField.label")}
      placeholder={
        readOnly
          ? null
          : t("molecules.additionalInformationTextField.placeholder")
      }
      InputProps={{
        readOnly,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      {...rest}
    />
  );
};

AdditionalInformationTextField.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default AdditionalInformationTextField;
