import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import * as yup from "yup";

import { Grid } from "@mui/material";

import {
  TerritoryNameTextField,
  TitledFormPaper,
} from "@vesta/components/molecules";
import { useUpSm } from "@vesta/lib/react";

const Information = ({ loading, territory, onSave }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("territory");
  const [editing, setEditing] = useState(false);

  const validationSchema = yup.object().shape({
    name: yup.string().required(t("information.name.required")),
  });

  const {
    values,
    errors,
    isSubmitting,
    submitCount,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema,
    onSubmit: async ({ name }) => {
      const ok = await onSave({ name });
      if (ok) {
        setEditing(false);
      }
    },
  });

  useEffect(() => {
    if (!editing && territory) {
      setFieldValue("name", territory.name);
    }
  }, [editing, territory]);

  const handleCancel = () => {
    resetForm();
    setFieldValue("name", territory.name);
    setEditing(false);
  };

  return (
    <TitledFormPaper
      title={t("information.title")}
      loading={loading}
      editing={editing}
      saving={isSubmitting}
      onEdit={() => setEditing(true)}
      onCancel={handleCancel}
      onSave={handleSubmit}
    >
      <Grid container spacing={upSm ? 3 : 2}>
        <Grid item xs={12} md={6}>
          <TerritoryNameTextField
            id="name"
            readOnly={!editing}
            value={values.name}
            onChange={handleChange}
            error={submitCount > 0 && Boolean(errors.name)}
            helperText={submitCount > 0 && errors.name}
          />
        </Grid>
      </Grid>
    </TitledFormPaper>
  );
};

Information.propTypes = {
  loading: PropTypes.bool,
  territory: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};

export default Information;
