import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { Button } from "@mui/material";
import { MyLocation } from "@mui/icons-material";

const degreeToRadian = (degree) => (degree * Math.PI) / 180;

const getLongitudeKmLengthInDegree = (latitude) => {
  const longitudeKmPerDegree = 111.32;

  return 1 / (Math.cos(degreeToRadian(latitude)) * longitudeKmPerDegree);
};

const convertToPolygon = (circle) => {
  // https://www.quora.com/How-can-you-find-the-coordinates-in-a-hexagon
  const shiftLongitude = 0.5; // cos(60°), pre-calculated longitude adjustment
  const shiftLatitude = 0.866; // sin(60°), pre-calculated latitude adjustment

  const oneKmInLatitudeDegree = 0.0090437173295711;
  const oneKmInLongitudeDegree = getLongitudeKmLengthInDegree(
    circle.center.latitude
  );

  return [
    // Right
    [
      circle.center.longitude + circle.radius * oneKmInLongitudeDegree,
      circle.center.latitude,
    ],
    // Top Right
    [
      circle.center.longitude +
        circle.radius * oneKmInLongitudeDegree * shiftLongitude,
      circle.center.latitude +
        circle.radius * oneKmInLatitudeDegree * shiftLatitude,
    ],
    // Top Left
    [
      circle.center.longitude -
        circle.radius * oneKmInLongitudeDegree * shiftLongitude,
      circle.center.latitude +
        circle.radius * oneKmInLatitudeDegree * shiftLatitude,
    ],
    // Left
    [
      circle.center.longitude - circle.radius * oneKmInLongitudeDegree,
      circle.center.latitude,
    ],
    // Bottom Left
    [
      circle.center.longitude -
        circle.radius * oneKmInLongitudeDegree * shiftLongitude,
      circle.center.latitude -
        circle.radius * oneKmInLatitudeDegree * shiftLatitude,
    ],
    // Bottom Right
    [
      circle.center.longitude +
        circle.radius * oneKmInLongitudeDegree * shiftLongitude,
      circle.center.latitude -
        circle.radius * oneKmInLatitudeDegree * shiftLatitude,
    ],
    // Right
    [
      circle.center.longitude + circle.radius * oneKmInLongitudeDegree,
      circle.center.latitude,
    ],
  ];
};

const DetectPolygonButton = ({ label, onDetect, onError }) => {
  const [error, setError] = useState();

  useEffect(() => {
    if (error) {
      onError(error);
    }
  }, [error]);

  const handleSuccess = ({ coords: { longitude, latitude } }) => {
    const circle = {
      radius: 5,
      center: {
        longitude,
        latitude,
      },
    };

    const polygon = convertToPolygon(circle);

    onDetect(polygon);
  };

  const handleDetect = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSuccess, setError);
    }
  };

  return (
    <Button
      disabled={!!error}
      variant="outlined"
      onClick={handleDetect}
      startIcon={<MyLocation />}
    >
      {label}
    </Button>
  );
};

DetectPolygonButton.propTypes = {
  label: PropTypes.string,
  onDetect: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default DetectPolygonButton;
