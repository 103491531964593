import { isFuture, isPast } from "date-fns";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Badge, Box, Grid, Typography } from "@mui/material";

import { ContactUsLink, ErrorAlert } from "@vesta/components/atoms";
import {
  AddReminder,
  CustomReminder,
  TemplateReminder,
} from "@vesta/components/molecules";
import {
  useAppContext,
  useInterval,
  usePageVisibility,
  useSession,
} from "@vesta/lib/react";

import { CollapsibleSection, ReminderDateAndTimePicker } from "./components";

const Reminders = () => {
  const isVisible = usePageVisibility();
  const { t } = useTranslation("home");
  const [, jwtToken] = useSession();
  const [appContext, dispatch] = useAppContext();
  const [saveError, setSaveError] = useState(false);
  const [pickDateAndTimeForReminderId, setPickDateAndTimeForReminderId] =
    useState(null);

  useInterval(
    () => dispatch({ type: "refresh-reminders" }),
    isVisible ? 30000 : null
  );

  const currentReminders = [
    ...appContext.reminders
      .filter((x) => !x.deleted)
      .filter((x) => !x.completed)
      .filter((x) => x.remindOn && isPast(x.remindOn))
      .sort((a, b) => b.remindOn - a.remindOn),
    ...appContext.reminders
      .filter((x) => !x.deleted)
      .filter((x) => !x.completed)
      .filter((x) => !x.remindOn && !x.templateId)
      .sort((a, b) => b.created - a.created),
    ...appContext.reminders
      .filter((x) => !x.deleted)
      .filter((x) => !x.completed)
      .filter((x) => !x.remindOn && x.templateId)
      .sort((a, b) => a.templateId - b.templateId),
  ];

  const upcomingReminders = appContext.reminders
    .filter((x) => !x.deleted)
    .filter((x) => !x.completed)
    .filter((x) => x.remindOn && isFuture(x.remindOn))
    .sort((a, b) => a.remindOn - b.remindOn);

  const completedReminders = appContext.reminders
    .filter((x) => !x.deleted)
    .filter((x) => x.completed)
    .sort((a, b) => b.completed - a.completed);

  const handleAdd = async ({ title }) => {
    const url = new URL(
      "/commands/create-reminder",
      process.env.REACT_APP_API_BASE_URL
    );

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        title,
      }),
    });

    if (response.ok) {
      const { reminderId } = await response.json();
      dispatch({
        type: "create-reminder",
        payload: {
          reminderId,
          created: new Date(),
          title,
        },
      });
    } else {
      setSaveError(true);
    }
  };

  const handleComplete = (reminderId) => async () => {
    const url = new URL(
      `/commands/complete-reminder/${reminderId}`,
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    if (ok) {
      dispatch({
        type: "complete-reminder",
        payload: {
          reminderId,
          completed: new Date(),
        },
      });
    } else {
      setSaveError(true);
    }
  };

  const handleRevertComplete = (reminderId) => async () => {
    const url = new URL(
      `/commands/revert-complete-reminder/${reminderId}`,
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    if (ok) {
      dispatch({
        type: "revert-complete-reminder",
        payload: {
          reminderId,
        },
      });
    } else {
      setSaveError(true);
    }
  };

  const handleDelete = (reminderId) => async () => {
    const url = new URL(
      `/commands/delete-reminder/${reminderId}`,
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    if (ok) {
      dispatch({
        type: "delete-reminder",
        payload: {
          reminderId,
          deleted: new Date(),
        },
      });
    } else {
      setSaveError(true);
    }
  };

  const handleModify =
    (reminderId) =>
    async ({ title, details }) => {
      const url = new URL(
        `/commands/modify-reminder/${reminderId}`,
        process.env.REACT_APP_API_BASE_URL
      );

      const { ok } = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          title,
          details,
        }),
      });

      if (ok) {
        dispatch({
          type: "modify-reminder",
          payload: {
            reminderId,
            title,
            details,
          },
        });
      } else {
        setSaveError(true);
      }
    };

  const handleRemindAboutThis = (reminderId) => async (remindOn) => {
    const url = new URL(
      `/commands/remind-about-this/${reminderId}`,
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        remindOn,
      }),
    });

    if (ok) {
      dispatch({
        type: "remind-about-this",
        payload: {
          reminderId,
          remindOn,
        },
      });
      setPickDateAndTimeForReminderId(null);
    } else {
      setSaveError(true);
    }
  };

  const handleDoNotRemindAboutThis = (reminderId) => async () => {
    const url = new URL(
      `/commands/do-not-remind-about-this/${reminderId}`,
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    if (ok) {
      dispatch({
        type: "do-not-remind-about-this",
        payload: {
          reminderId,
        },
      });
    } else {
      setSaveError(true);
    }
  };

  const renderReminder = (x) =>
    x.templateId ? (
      <TemplateReminder
        reminder={x}
        onComplete={handleComplete(x.id)}
        onRevertComplete={handleRevertComplete(x.id)}
        onDelete={handleDelete(x.id)}
        onModify={handleModify(x.id)}
        onRemindAboutThis={handleRemindAboutThis(x.id)}
        onDoNotRemindAboutThis={handleDoNotRemindAboutThis(x.id)}
        onPickDateAndTime={() => setPickDateAndTimeForReminderId(x.id)}
      />
    ) : (
      <CustomReminder
        reminder={x}
        onComplete={handleComplete(x.id)}
        onRevertComplete={handleRevertComplete(x.id)}
        onDelete={handleDelete(x.id)}
        onModify={handleModify(x.id)}
        onRemindAboutThis={handleRemindAboutThis(x.id)}
        onDoNotRemindAboutThis={handleDoNotRemindAboutThis(x.id)}
        onPickDateAndTime={() => setPickDateAndTimeForReminderId(x.id)}
      />
    );

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <CollapsibleSection
            expandInitially
            header={
              <Badge
                badgeContent={currentReminders.length}
                color="primary"
                overlap="rectangular"
              >
                <Typography component="h2" variant="h3">
                  {t("reminders.title")}
                </Typography>
              </Badge>
            }
            actions={<AddReminder onAdd={handleAdd} />}
            reminders={currentReminders}
            renderReminder={renderReminder}
            emptyState={
              <Box p={2}>
                <Typography variant="body2">
                  {t("reminders.emptyState")}
                </Typography>
              </Box>
            }
          />
        </Grid>
        <Grid item>
          <CollapsibleSection
            header={
              <Badge
                badgeContent={upcomingReminders.length}
                color="primary"
                overlap="rectangular"
              >
                <Typography component="h2" variant="h3">
                  {t("reminders.upcoming.title")}
                </Typography>
              </Badge>
            }
            reminders={upcomingReminders}
            renderReminder={renderReminder}
            emptyState={
              <Box p={2}>
                <Typography variant="body2">
                  {t("reminders.upcoming.emptyState")}
                </Typography>
              </Box>
            }
          />
        </Grid>
        <Grid item>
          <CollapsibleSection
            header={
              <Typography component="h2" variant="h3">
                {t("reminders.completed.title")}
              </Typography>
            }
            reminders={completedReminders}
            renderReminder={renderReminder}
            emptyState={
              <Box p={2}>
                <Typography variant="body2">
                  {t("reminders.completed.emptyState")}
                </Typography>
              </Box>
            }
          />
        </Grid>
      </Grid>
      <ReminderDateAndTimePicker
        open={!!pickDateAndTimeForReminderId}
        onCancel={() => setPickDateAndTimeForReminderId(null)}
        onPick={handleRemindAboutThis(pickDateAndTimeForReminderId)}
      />
      <ErrorAlert
        open={saveError}
        onClose={() => setSaveError(false)}
        content={
          <Trans
            i18nKey="home:reminders.alerts.saveError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
    </>
  );
};

export default Reminders;
