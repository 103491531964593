import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Email } from "@mui/icons-material";

const EmailTextField = ({ sx, readOnly, value, ...rest }) => {
  const { t } = useTranslation("components");

  const startAdornment = readOnly ? (
    <InputAdornment position="start">
      <IconButton
        color="primary"
        component="a"
        edge="start"
        target="_blank"
        href={`mailto:${value}`}
        aria-label={t("molecules.phoneNumberTextField.call")}
        size="small"
      >
        <Email />
      </IconButton>
    </InputAdornment>
  ) : null;

  return (
    <TextField
      variant="standard"
      autoComplete="email"
      sx={sx}
      fullWidth
      label={t("molecules.emailTextField.label")}
      placeholder={t("molecules.emailTextField.placeholder")}
      value={value}
      InputProps={{
        startAdornment,
        readOnly,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      {...rest}
    />
  );
};

EmailTextField.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  readOnly: PropTypes.bool,
  value: PropTypes.string,
};

export default EmailTextField;
