import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Grid, IconButton, Link, Typography } from "@mui/material";
import { Delete, MoreHoriz } from "@mui/icons-material";

import { useUpSm } from "@vesta/lib/react";

const WebReference = ({ editing, name, url, onDelete }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("components");

  return (
    <Grid container item alignItems="center" spacing={upSm ? 3 : 2}>
      <Grid item>
        <MoreHoriz fontSize="large" />
      </Grid>
      <Grid item xs>
        <Typography>{name}</Typography>
        <Link
          color="textSecondary"
          underline="always"
          variant="caption"
          href={url}
          target="_blank"
        >
          {url}
        </Link>
      </Grid>
      {editing && (
        <Grid item>
          <IconButton
            edge="end"
            aria-label={t("molecules.webReference.deleteAriaLabel")}
            onClick={onDelete}
            size="large"
          >
            <Delete color="primary" />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

WebReference.propTypes = {
  editing: PropTypes.bool,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default WebReference;
