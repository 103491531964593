import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Avatar, Card, CardHeader, Link, Skeleton, Stack } from "@mui/material";

const DestinationCard = ({ sx, appointment }) => {
  const { t } = useTranslation("appointment");

  return (
    <Card sx={sx}>
      <CardHeader
        avatar={
          <Avatar
            sx={(theme) => ({ bgcolor: theme.palette.error.main })}
            alt={t("directions.destinationCard.title")}
          >
            B
          </Avatar>
        }
        title={t("directions.destinationCard.title")}
        subheader={
          <Stack direction="column">
            <span>
              {appointment ? (
                <Link
                  target="_blank"
                  color="inherit"
                  href={`https://www.google.com/maps/search/?api=1&query=${appointment?.patientFormattedAddress}`}
                >
                  {t("directions.destinationCard.openGoogleMaps")}
                </Link>
              ) : (
                <Skeleton width="70%" />
              )}
            </span>
            <span>
              {appointment ? (
                appointment.patientFormattedAddress
              ) : (
                <Skeleton width="70%" />
              )}
            </span>
            <span>
              {appointment ? (
                appointment.patientApartmentUnitSuiteOrFloorNb
              ) : (
                <Skeleton width="40%" />
              )}
            </span>
          </Stack>
        }
      />
    </Card>
  );
};

DestinationCard.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  appointment: PropTypes.shape({
    patientFormattedAddress: PropTypes.string.isRequired,
    patientApartmentUnitSuiteOrFloorNb: PropTypes.string,
  }),
};

export default DestinationCard;
