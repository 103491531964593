export const light = {
  alternate: {
    main: "rgb(247, 249, 250)",
    dark: "#E8EAF6",
  },
  cardShadow: "rgba(23, 70, 161, .11)",
  type: "light",
  primary: {
    main: "#0277BD",
    light: "#58A5F0",
    dark: "#004C8C",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#FFA000",
    light: "#FFD149",
    dark: "#C67100",
    contrastText: "rgba(0, 0, 0, 0.95)",
  },
  text: {
    primary: "#2D3748",
    secondary: "#718096",
  },
  divider: "rgba(0, 0, 0, 0.12)",
  background: {
    paper: "#FFFFFF",
    default: "#F9F9F9",
    level2: "#F5F5F5",
    level1: "#FFFFFF",
    footer: "#1B1642",
  },
  alternateTextColor: "#FF0000",
};

export const dark = {};
