import { useTranslation } from "react-i18next";

import {
  sexIds,
  serviceLanguageIds,
  spokenLanguageIds,
} from "@vesta/lib/utils";

export const useSexTranslation = () => {
  const { t } = useTranslation("common");

  return Object.fromEntries(sexIds.map((id) => [id, t(`sex.${id}`)]));
};

export const useServiceLanguageTranslation = () => {
  const { t } = useTranslation("common");

  return Object.fromEntries(
    serviceLanguageIds.map((id) => [id, t(`serviceLanguage.${id}`)])
  );
};

export const useSpokenLanguageTranslation = () => {
  const { t } = useTranslation("common");

  return Object.fromEntries(
    spokenLanguageIds.map((id) => [id, t(`spokenLanguage.${id}`)])
  );
};
