import PropTypes from "prop-types";

import { IconButton } from "@mui/material";

import { DelayedCircularProgress } from "@vesta/components/atoms";

const LoadingIconButton = ({ loading, children, ...rest }) => {
  return (
    <IconButton {...rest}>
      {loading ? <DelayedCircularProgress size={24} /> : children}
    </IconButton>
  );
};

LoadingIconButton.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
};

export default LoadingIconButton;
