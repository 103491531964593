import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

import { Box } from "@mui/material";

import { useGoogleMapsScript } from "@vesta/lib/react";

const DirectionsMap = ({ sx, directions }) => {
  const directionsMapRef = useRef(null);
  const status = useGoogleMapsScript();

  useEffect(() => {
    if (status !== "ready") {
      return;
    }

    const map = new window.google.maps.Map(directionsMapRef.current);
    map.setOptions({ mapTypeControl: false });

    const directionsRenderer = new window.google.maps.DirectionsRenderer();
    directionsRenderer.setMap(map);
    directionsRenderer.setDirections(directions);
  }, [status, directions]);

  return (
    <Box
      sx={[
        (theme) => ({ height: theme.spacing(60) }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      ref={directionsMapRef}
    />
  );
};

DirectionsMap.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.array]),
  directions: PropTypes.object.isRequired,
};

export default DirectionsMap;
