export const sexIds = [1, 2];

export const serviceLanguageIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const spokenLanguageIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const numberedArray = (length, callback) =>
  Array.from({ length }, (_, i) => i).map(callback);

export const joinOverlappingEvents = (events) => {
  let joinedOverlappingEvents = [];
  let currentEvent = null;

  for (let i = 0; i < events.length; i++) {
    if (!currentEvent) {
      currentEvent = events[i];
    }

    if (currentEvent.end < events[i].start) {
      joinedOverlappingEvents = [...joinedOverlappingEvents, currentEvent];
      currentEvent = events[i];
    } else if (currentEvent.end < events[i].end) {
      currentEvent.end = events[i].end;
    }
  }

  if (currentEvent) {
    joinedOverlappingEvents = [...joinedOverlappingEvents, currentEvent];
  }

  return joinedOverlappingEvents;
};
