import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { Box, Container, Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import { homepageBackground } from "@vesta/assets/images";
import { Header } from "@vesta/components/molecules";
import { useAppContext, useLocalStorage, useUpMd } from "@vesta/lib/react";

import {
  Appointments,
  PublishProfileAlert,
  Reminders,
  ShareProfileAlert,
} from "./components";

const StyledImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  objectPosition: "right",
});

const HomeView = () => {
  const upMd = useUpMd();
  const { t } = useTranslation("home");
  const [appContext] = useAppContext();
  const [shareProfileAlertClosed, setShareProfileAlertClosed] = useLocalStorage(
    "vesta-share-profile-alert-closed",
    false
  );

  return (
    <>
      <Helmet>
        <title>{t("title")}</title>
        <meta property="og:title" content={t("title")} />
        <meta property="og:description" content={t("description")} />
      </Helmet>
      <Header
        sx={(theme) => ({
          position: "relative",
          marginBottom: theme.spacing(5),
          zIndex: 1,
        })}
        title={t("header.title")}
      />
      {upMd && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
          }}
        >
          <StyledImage src={homepageBackground} alt={t("backgroundImgAlt")} />
        </Box>
      )}
      <Container
        sx={(theme) => ({
          position: "relative",
          marginBottom: theme.spacing(5),
        })}
        maxWidth="lg"
      >
        <Grid container spacing={4}>
          {appContext.subscription &&
            appContext.subscription.hasSubscription &&
            !appContext.subscription.actionRequired && (
              <>
                {!appContext.isSearchable ? (
                  <Grid item xs={12}>
                    <PublishProfileAlert />
                  </Grid>
                ) : shareProfileAlertClosed ? null : (
                  <Grid item xs={12}>
                    <ShareProfileAlert
                      onClose={() => setShareProfileAlertClosed(true)}
                    />
                  </Grid>
                )}
              </>
            )}
          <Grid item xs={12} md={6} lg={7}>
            <Reminders />
          </Grid>
          {!upMd && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
          <Grid item xs={12} md={6} lg={5}>
            <Appointments />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default HomeView;
