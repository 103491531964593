import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { TextField } from "@mui/material";

const AddressTextField = ({
  className,
  readOnly,
  InputProps,
  InputLabelProps,
  ...rest
}) => {
  const { t } = useTranslation("components");

  return (
    <TextField
      variant="standard"
      autoComplete="address-line1"
      className={className}
      fullWidth
      label={t("molecules.addressTextField.label")}
      InputProps={{
        ...InputProps,
        readOnly,
      }}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
      }}
      {...rest}
    />
  );
};

AddressTextField.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  InputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
};

export default AddressTextField;
