import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import * as yup from "yup";

import {
  Alert,
  AlertTitle,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  Typography,
} from "@mui/material";

import {
  GroupedForms,
  ServiceDescriptionTextField,
  ServiceNameTextField,
  TitledFormPaper,
} from "@vesta/components/molecules";
import { useUpSm } from "@vesta/lib/react";

const GeneralInformation = ({ loading, service, onSave }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("service");
  const [editing, setEditing] = useState(false);
  const [servicePricesValue, setServicePricesValue] = useState(1);

  const validationSchema = yup.object().shape({
    nameFr: yup.string().required(t("generalInformation.name.required")),
    nameEn: yup.string().required(t("generalInformation.name.required")),
    descriptionFr: yup
      .string()
      .required(t("generalInformation.description.required")),
    descriptionEn: yup
      .string()
      .required(t("generalInformation.description.required")),
    price: yup
      .number()
      .test(
        "fixed-price-min-validation",
        t("generalInformation.servicePrices.fixedPrice.minValidation"),
        (value) => (servicePricesValue === 1 ? value >= 0 : true)
      )
      .nullable(),
    minimumPrice: yup
      .number()
      .test(
        "minimum-price-min-validation",
        t(
          "generalInformation.servicePrices.variablePrice.minimumPrice.minValidation"
        ),
        (value) => (servicePricesValue === 2 ? value >= 0 : true)
      )
      .test(
        "minimum-price-range-validation",
        t(
          "generalInformation.servicePrices.variablePrice.minimumPrice.rangeValidation"
        ),
        (value, ctx) =>
          servicePricesValue === 2 ? value < ctx.parent.maximumPrice : true
      )
      .nullable(),
    maximumPrice: yup.number().nullable(),
  });

  const {
    values,
    errors,
    isSubmitting,
    submitCount,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      nameFr: "",
      nameEn: "",
      descriptionFr: "",
      descriptionEn: "",
      price: 0,
      minimumPrice: 0,
      maximumPrice: 0,
    },
    validationSchema,
    onSubmit: async ({
      nameFr,
      nameEn,
      descriptionFr,
      descriptionEn,
      price,
      minimumPrice,
      maximumPrice,
    }) => {
      const ok = await onSave({
        nameFr,
        nameEn,
        descriptionFr,
        descriptionEn,
        price: servicePricesValue === 1 ? price : minimumPrice,
        maximumPrice: servicePricesValue === 1 ? null : maximumPrice,
      });
      if (ok) {
        setEditing(false);
      }
    },
  });

  useEffect(() => {
    if (!editing && service) {
      setFieldValue("nameFr", service.nameFr);
      setFieldValue("nameEn", service.nameEn);
      setFieldValue("descriptionFr", service.descriptionFr);
      setFieldValue("descriptionEn", service.descriptionEn);
      if (service.maximumPrice) {
        setFieldValue("price", service.price);
        setFieldValue("minimumPrice", service.price);
        setFieldValue("maximumPrice", service.maximumPrice);
        setServicePricesValue(2);
      } else {
        setFieldValue("price", service.price);
        setFieldValue("minimumPrice", service.price);
        setFieldValue("maximumPrice", service.price + 10);
        setServicePricesValue(1);
      }
    }
  }, [editing, service]);

  const handleCancel = () => {
    resetForm();
    setFieldValue("nameFr", service.nameFr);
    setFieldValue("nameEn", service.nameEn);
    setFieldValue("descriptionFr", service.descriptionFr);
    setFieldValue("descriptionEn", service.descriptionEn);
    setFieldValue("price", service.price);
    if (service.maximumPrice) {
      setFieldValue("price", service.price);
      setFieldValue("minimumPrice", service.price);
      setFieldValue("maximumPrice", service.maximumPrice);
      setServicePricesValue(2);
    } else {
      setFieldValue("price", service.price);
      setFieldValue("minimumPrice", service.price);
      setFieldValue("maximumPrice", service.price + 10);
      setServicePricesValue(1);
    }
    setEditing(false);
  };

  return (
    <TitledFormPaper
      title={t("generalInformation.title")}
      loading={loading}
      editing={editing}
      saving={isSubmitting}
      onEdit={() => setEditing(true)}
      onCancel={handleCancel}
      onSave={handleSubmit}
    >
      <Grid container spacing={upSm ? 3 : 2}>
        <Grid item xs={12} md={6}>
          <ServiceNameTextField
            id="nameFr"
            disabled
            readOnly={!editing}
            lang="fr"
            value={values.nameFr}
            onChange={handleChange}
            error={submitCount > 0 && Boolean(errors.nameFr)}
            helperText={submitCount > 0 && errors.nameFr}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ServiceNameTextField
            id="nameEn"
            disabled
            readOnly={!editing}
            lang="en"
            value={values.nameEn}
            onChange={handleChange}
            error={submitCount > 0 && Boolean(errors.nameEn)}
            helperText={submitCount > 0 && errors.nameEn}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ServiceDescriptionTextField
            id="descriptionFr"
            readOnly={!editing}
            lang="fr"
            value={values.descriptionFr}
            onChange={handleChange}
            error={submitCount > 0 && Boolean(errors.descriptionFr)}
            helperText={submitCount > 0 && errors.descriptionFr}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ServiceDescriptionTextField
            id="descriptionEn"
            readOnly={!editing}
            lang="en"
            value={values.descriptionEn}
            onChange={handleChange}
            error={submitCount > 0 && Boolean(errors.descriptionEn)}
            helperText={submitCount > 0 && errors.descriptionEn}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row-reverse" spacing={upSm ? 3 : 2}>
            <Grid item xs={12} md={6}>
              <Alert component="aside" severity="info">
                <AlertTitle>
                  {t("generalInformation.servicePrices.alert.title")}
                </AlertTitle>
                {t("generalInformation.servicePrices.alert.text")}
              </Alert>
            </Grid>
            <Grid item xs={12} md={6}>
              <GroupedForms
                sx={{ width: "100%" }}
                readOnly={!editing}
                label={t("generalInformation.servicePrices.label")}
                value={servicePricesValue}
                onChange={(value) => setServicePricesValue(parseInt(value))}
                groups={[
                  {
                    value: 1,
                    label: t(
                      "generalInformation.servicePrices.fixedPrice.label"
                    ),
                    form: (
                      <FormControl
                        variant="standard"
                        sx={(theme) => ({
                          width: theme.spacing(10),
                          [theme.breakpoints.up("sm")]: {
                            width: theme.spacing(16),
                          },
                        })}
                        error={submitCount > 0 && Boolean(errors.price)}
                      >
                        <Input
                          id="price"
                          type="number"
                          disabled={!editing}
                          readOnly={!editing}
                          value={values.price}
                          onChange={handleChange}
                          inputProps={{
                            "aria-label": t(
                              "generalInformation.servicePrices.fixedPrice.label"
                            ),
                            step: 1,
                          }}
                          startAdornment={
                            <InputAdornment
                              sx={(theme) => ({
                                color: editing
                                  ? theme.palette.text.primary
                                  : theme.palette.text.disabled,
                              })}
                              disableTypography
                              position="start"
                            >
                              $
                            </InputAdornment>
                          }
                        />
                        {submitCount > 0 && (
                          <FormHelperText>{errors.price}</FormHelperText>
                        )}
                      </FormControl>
                    ),
                  },
                  {
                    value: 2,
                    label: t(
                      "generalInformation.servicePrices.variablePrice.label"
                    ),
                    form: (
                      <Grid container direction="column">
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item>
                            <FormControl
                              variant="standard"
                              sx={(theme) => ({
                                width: theme.spacing(10),
                                [theme.breakpoints.up("sm")]: {
                                  width: theme.spacing(16),
                                },
                              })}
                              error={
                                submitCount > 0 &&
                                (Boolean(errors.minimumPrice) ||
                                  Boolean(errors.maximumPrice))
                              }
                            >
                              <Input
                                id="minimumPrice"
                                type="number"
                                disabled={!editing}
                                readOnly={!editing}
                                value={values.minimumPrice}
                                onChange={handleChange}
                                inputProps={{
                                  "aria-label": t(
                                    "generalInformation.servicePrices.variablePrice.minimumPrice.label"
                                  ),
                                  step: 1,
                                }}
                                startAdornment={
                                  <InputAdornment
                                    sx={(theme) => ({
                                      color: editing
                                        ? theme.palette.text.primary
                                        : theme.palette.text.disabled,
                                    })}
                                    disableTypography
                                    position="start"
                                  >
                                    $
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <Typography
                              sx={(theme) => ({
                                color: editing
                                  ? theme.palette.text.primary
                                  : theme.palette.text.disabled,
                              })}
                            >
                              {t(
                                "generalInformation.servicePrices.variablePrice.separator"
                              )}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <FormControl
                              variant="standard"
                              sx={(theme) => ({
                                width: theme.spacing(10),
                                [theme.breakpoints.up("sm")]: {
                                  width: theme.spacing(16),
                                },
                              })}
                              error={
                                submitCount > 0 &&
                                (Boolean(errors.minimumPrice) ||
                                  Boolean(errors.maximumPrice))
                              }
                            >
                              <Input
                                id="maximumPrice"
                                type="number"
                                disabled={!editing}
                                readOnly={!editing}
                                value={values.maximumPrice}
                                onChange={handleChange}
                                inputProps={{
                                  "aria-label": t(
                                    "generalInformation.servicePrices.variablePrice.maximumPrice.label"
                                  ),
                                  step: 1,
                                }}
                                startAdornment={
                                  <InputAdornment
                                    sx={(theme) => ({
                                      color: editing
                                        ? theme.palette.text.primary
                                        : theme.palette.text.disabled,
                                    })}
                                    disableTypography
                                    position="start"
                                  >
                                    $
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        {submitCount > 0 && (
                          <Grid item>
                            <FormHelperText error>
                              {errors.minimumPrice
                                ? errors.minimumPrice
                                : errors.maximumPrice
                                ? errors.maximumPrice
                                : ""}
                            </FormHelperText>
                          </Grid>
                        )}
                      </Grid>
                    ),
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TitledFormPaper>
  );
};

GeneralInformation.propTypes = {
  loading: PropTypes.bool,
  service: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};

export default GeneralInformation;
