import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const NoteEditorPlaceholder = ({ note }) => {
  const { t } = useTranslation("components");

  const { completed, addendumTo } = note;

  const placeholder = completed
    ? ""
    : addendumTo
    ? t("atoms.noteEditorPlaceholder.addendumPlaceholder")
    : t("atoms.noteEditorPlaceholder.placeholder");

  return placeholder;
};

NoteEditorPlaceholder.propTypes = {
  note: PropTypes.shape({
    addendumTo: PropTypes.string,
    completed: PropTypes.instanceOf(Date),
  }).isRequired,
};

export default NoteEditorPlaceholder;
