import { useTranslation } from "react-i18next";

import {
  Alert,
  AlertTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Check } from "@mui/icons-material";

import { cloneElementIntoKeyedArray } from "@vesta/lib/react";

const ProfilePictureTipAlert = () => {
  const { t } = useTranslation("profile");

  return (
    <Alert component="aside" severity="info">
      <AlertTitle>
        {t("biography.profilePicture.profilePictureTipAlert.title")}
      </AlertTitle>
      {t("biography.profilePicture.profilePictureTipAlert.text")}
      <List>
        {cloneElementIntoKeyedArray(4, (i) => (
          <ListItem dense disableGutters>
            <ListItemIcon
              sx={(theme) => ({
                minWidth: "auto",
                marginRight: theme.spacing(3 / 2),
              })}
            >
              <Check fontSize="small" />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={t(
                `biography.profilePicture.profilePictureTipAlert.rules.${i + 1}`
              )}
            />
          </ListItem>
        ))}
      </List>
    </Alert>
  );
};

export default ProfilePictureTipAlert;
