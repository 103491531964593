import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { TextField } from "@mui/material";

const LastNameTextField = ({ sx, readOnly, ...rest }) => {
  const { t } = useTranslation("components");

  return (
    <TextField
      variant="standard"
      autoComplete="lname"
      sx={sx}
      fullWidth
      label={t("molecules.lastNameTextField.label")}
      InputProps={{
        readOnly,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      {...rest}
    />
  );
};

LastNameTextField.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  readOnly: PropTypes.bool,
};

export default LastNameTextField;
