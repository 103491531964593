import PropTypes from "prop-types";
import { forwardRef } from "react";
import { IMaskInput } from "react-imask";

const RamqHealthInsuranceExpirationMask = forwardRef(
  ({ onChange, id, name, ...rest }, ref) => (
    <IMaskInput
      {...rest}
      id={id}
      name={name}
      mask="0000 00"
      inputRef={ref}
      onChange={(e) =>
        onChange({ target: { id, name, value: e.target.value } })
      }
      onAccept={(value) => onChange({ target: { id, name, value } })}
      overwrite
    />
  )
);

RamqHealthInsuranceExpirationMask.displayName =
  "RamqHealthInsuranceExpirationMask";

RamqHealthInsuranceExpirationMask.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default RamqHealthInsuranceExpirationMask;
