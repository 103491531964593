import { useCallback, useState } from "react";

export const useFetchJson = () => {
  const [json, setJson] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const callback = useCallback(async (url) => {
    try {
      setLoading(true);
      const response = await fetch(url);
      setJson(await response.json());
      return response;
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return [callback, json, loading, error];
};

export const useAuthorizedFetchJson = (jwtToken) => {
  const [json, setJson] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const callback = useCallback(
    async (url) => {
      try {
        setLoading(true);
        const response = await fetch(url, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        });
        setJson(await response.json());
        return response;
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [jwtToken]
  );

  return [callback, json, loading, error];
};

export const usePostJson = () => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const callback = useCallback(async (url, body) => {
    try {
      setLoading(true);
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      setResponse(response);
      return response;
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return [callback, response, loading, error];
};

export const useAuthorizedPostJson = (jwtToken) => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const callback = useCallback(
    async (url, body) => {
      try {
        setLoading(true);
        const response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
        setResponse(response);
        return response;
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [jwtToken]
  );

  return [callback, response, loading, error];
};
