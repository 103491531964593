import { useEffect } from "react";
import { hotjar } from "react-hotjar";

export const useHotjar = () => {
  const siteId = process.env.REACT_APP_HOTJAR_HJID;
  const trackingCodeVersion = process.env.REACT_APP_HOTJAR_HJSV;

  useEffect(() => {
    if (siteId && trackingCodeVersion) {
      hotjar.initialize(siteId, trackingCodeVersion);
    }
  }, []);
};
