import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, IconButton, Stack } from "@mui/material";
import { Cancel, Edit, Save } from "@mui/icons-material";

import { LoadingButton, LoadingIconButton } from "@vesta/components/atoms";
import { TitledPaper } from "@vesta/components/molecules";

const TitledFormPaper = ({
  sx,
  title,
  loading,
  children,
  editing,
  saving,
  saveLabel,
  onCancel,
  onEdit,
  onSave,
}) => {
  const { t } = useTranslation("components");

  const handleSave = (event) => {
    event.preventDefault();
    onSave();
  };

  const editingActions = (
    <>
      <Stack
        sx={{ display: { xs: "none", sm: "block" } }}
        direction="row"
        spacing={1}
      >
        <Button
          sx={{ mr: 1 }}
          disabled={loading || saving}
          onClick={onCancel}
          variant="outlined"
        >
          {t("molecules.titledFormPaper.actions.cancel")}
        </Button>
        <LoadingButton
          type="submit"
          loading={saving}
          disabled={loading || saving}
          variant="contained"
        >
          {saveLabel ?? t("molecules.titledFormPaper.actions.save")}
        </LoadingButton>
      </Stack>
      <Stack sx={{ display: { xs: "block", sm: "none" } }} direction="row">
        {!saving && (
          <IconButton
            disabled={loading}
            aria-label={t("molecules.titledFormPaper.actions.cancel")}
            onClick={onCancel}
            color="primary"
            size="small"
          >
            <Cancel />
          </IconButton>
        )}
        <LoadingIconButton
          type="submit"
          edge="end"
          loading={saving}
          disabled={loading || saving}
          aria-label={saveLabel ?? t("molecules.titledFormPaper.actions.save")}
          color="primary"
          size="small"
        >
          <Save />
        </LoadingIconButton>
      </Stack>
    </>
  );

  const notEditingActions = (
    <>
      <Stack
        sx={{ display: { xs: "none", sm: "block" } }}
        direction="row"
        spacing={1}
      >
        <Button disabled={loading} onClick={onEdit} variant="contained">
          {t("molecules.titledFormPaper.actions.edit")}
        </Button>
      </Stack>
      <Stack
        sx={{ display: { xs: "block", sm: "none" } }}
        direction="row"
        spacing={1}
      >
        <IconButton
          edge="end"
          disabled={loading}
          aria-label={t("molecules.titledFormPaper.actions.edit")}
          onClick={onEdit}
          color="primary"
          size="small"
        >
          <Edit />
        </IconButton>
      </Stack>
    </>
  );

  const content = (
    <TitledPaper
      sx={sx}
      loading={loading}
      title={title}
      actions={editing ? editingActions : notEditingActions}
    >
      {children}
    </TitledPaper>
  );

  return editing ? <form onSubmit={handleSave}>{content}</form> : content;
};

TitledFormPaper.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  title: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
  editing: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  saveLabel: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default TitledFormPaper;
