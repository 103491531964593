import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import { File, TitledPaper } from "@vesta/components/molecules";
import { useAppointment } from "@vesta/lib/react";

const StyledImage = styled(LazyLoadImage)(({ theme }) => ({
  objectFit: "cover",
  objectPosition: "center top",
  borderTopLeftRadius: theme.spacing(1),
  borderTopRightRadius: theme.spacing(1),
  borderTop: `1px solid ${theme.palette.action.active}`,
  borderLeft: `1px solid ${theme.palette.action.active}`,
  borderRight: `1px solid ${theme.palette.action.active}`,
}));

const MedicalPrescriptionFiles = () => {
  const { t } = useTranslation("appointment");
  const [appointment] = useAppointment();

  return (
    <TitledPaper
      title={t("preparation.medicalPrescriptionFiles.title")}
      loading={!appointment}
    >
      <Grid container spacing={3}>
        {appointment?.medicalPrescriptionFiles.map((x) => {
          const src = new URL(
            `/download-medical-prescription-file/${x.medicalPrescriptionFileId}`,
            process.env.REACT_APP_API_BASE_URL
          ).toString();

          const blurredSrc = new URL(
            `/download-blurred-medical-prescription-file/${x.medicalPrescriptionFileId}`,
            process.env.REACT_APP_API_BASE_URL
          ).toString();

          return (
            <Grid
              key={x.medicalPrescriptionFileId}
              item
              xs={12}
              sm={6}
              lg={4}
              xl={3}
            >
              <File
                src={src}
                name={t("preparation.medicalPrescriptionFiles.fileName")}
                renderImage={({ dimensionsSx }) => (
                  <StyledImage
                    sx={dimensionsSx}
                    alt={t("preparation.medicalPrescriptionFiles.imageAlt")}
                    effect="blur"
                    src={src}
                    placeholderSrc={blurredSrc}
                    wrapperProps={{ style: { width: "100%" } }}
                  />
                )}
              />
            </Grid>
          );
        })}
      </Grid>
    </TitledPaper>
  );
};

export default MedicalPrescriptionFiles;
