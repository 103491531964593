import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { TextField } from "@mui/material";

const FormattedFullNameTextField = ({
  className,
  readOnly,
  InputProps,
  InputLabelProps,
  firstName,
  lastName,
  ...rest
}) => {
  const { t } = useTranslation("components");

  return (
    <TextField
      variant="standard"
      autoComplete="name"
      className={className}
      fullWidth
      label={t("molecules.formattedFullNameTextField.label")}
      InputProps={{
        ...InputProps,
        readOnly,
      }}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
      }}
      value={`${lastName}, ${firstName}`}
      {...rest}
    />
  );
};

FormattedFullNameTextField.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  InputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

export default FormattedFullNameTextField;
