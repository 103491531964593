import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import * as yup from "yup";

import { Button, DialogContentText, Grid } from "@mui/material";

import {
  ContactUsLink,
  ErrorAlert,
  LoadingButton,
} from "@vesta/components/atoms";
import {
  Dialog,
  GroupedForms,
  PermitNumberTextField,
} from "@vesta/components/molecules";
import { PhotoIdentification } from "@vesta/components/organisms";
import { permitIssuingAuthority } from "@vesta/lib/enum";
import { useAppContext, useCompressor, useSession } from "@vesta/lib/react";

const maxFileSize = 16777216; // 16 MB

const Step2 = ({ loading, open, onClose, onPrevious, onNext }) => {
  const compress = useCompressor({ quality: 0.4 });
  const { t } = useTranslation("global-modals");
  const [, jwtToken] = useSession();
  const [appContext, dispatch] = useAppContext();
  const [uploading, setUploading] = useState(false);
  const [
    photoIdentificationSizeTooLargeError,
    setPhotoIdentificationSizeTooLargeError,
  ] = useState(false);
  const [uploadPhotoIdentificationError, setUploadPhotoIdentificationError] =
    useState(false);
  const [
    submitPermitAndPhotoIdentificationError,
    setSubmitPermitAndPhotoIdentificationError,
  ] = useState(false);

  const validationSchema = yup.object().shape({
    oiiqPermitNumber: yup.string().when("selectedPermitIssuingAuthority", {
      is: permitIssuingAuthority.oiiq,
      then: (schema) =>
        schema.required(t("choosePlanDialog.step2.oiiqPermitNumber.required")),
    }),
    oiiaqPermitNumber: yup.string().when("selectedPermitIssuingAuthority", {
      is: permitIssuingAuthority.oiiaq,
      then: (schema) =>
        schema.required(t("choosePlanDialog.step2.oiiaqPermitNumber.required")),
    }),
    photoIdentificationId: yup
      .string()
      .required(t("choosePlanDialog.step2.photoIdentificationId.required")),
  });

  const {
    values,
    errors,
    isSubmitting,
    submitCount,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues: {
      oiiqPermitNumber: "",
      oiiaqPermitNumber: "",
      selectedPermitIssuingAuthority: permitIssuingAuthority.oiiq,
      photoIdentificationId: "",
    },
    validationSchema,
    onSubmit: async ({
      oiiqPermitNumber,
      oiiaqPermitNumber,
      selectedPermitIssuingAuthority,
      photoIdentificationId,
    }) => {
      const url = new URL(
        "/commands/submit-permit-and-photo-identification",
        process.env.REACT_APP_API_BASE_URL
      );

      const payload = {
        oiiqPermitNumber:
          selectedPermitIssuingAuthority === permitIssuingAuthority.oiiq
            ? oiiqPermitNumber
            : null,
        oiiaqPermitNumber:
          selectedPermitIssuingAuthority === permitIssuingAuthority.oiiaq
            ? oiiaqPermitNumber
            : null,
        photoIdentificationId,
      };

      const { ok } = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(payload),
      });

      if (ok) {
        dispatch({
          type: "submit-permit-and-photo-identification",
          payload,
        });
        onNext();
      } else {
        setSubmitPermitAndPhotoIdentificationError(true);
      }
    },
  });

  const { oiiqPermitNumber, oiiaqPermitNumber, photoIdentificationId } =
    appContext;

  useEffect(() => {
    setFieldValue("oiiqPermitNumber", oiiqPermitNumber ?? "");
    setFieldValue("oiiaqPermitNumber", oiiaqPermitNumber ?? "");
    setFieldValue(
      "selectedPermitIssuingAuthority",
      oiiaqPermitNumber
        ? permitIssuingAuthority.oiiaq
        : permitIssuingAuthority.oiiq
    );
    setFieldValue("photoIdentificationId", photoIdentificationId ?? "");
  }, [oiiqPermitNumber, oiiaqPermitNumber, photoIdentificationId]);

  const handleAddFileChange = async (event) => {
    const file = event.target.files[0];
    if (file.size > maxFileSize) {
      setPhotoIdentificationSizeTooLargeError(true);
      return;
    }

    setUploading(true);

    const url = new URL(
      "upload-photo-identification",
      process.env.REACT_APP_API_BASE_URL
    );

    const compressedFile = await compress(file);

    const formData = new FormData();
    formData.append(
      "photo-identification",
      compressedFile,
      compressedFile.name
    );

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      body: formData,
    });

    if (response.ok) {
      const { photoIdentificationId } = await response.json();
      setFieldValue("photoIdentificationId", photoIdentificationId);
    } else {
      setUploadPhotoIdentificationError(true);
    }

    setUploading(false);
  };

  return (
    <>
      <Dialog
        loading={loading}
        fullScreen={false}
        fullWidth
        maxWidth="md"
        open={open}
        onClose={onClose}
        onSubmit={handleSubmit}
        title={t("choosePlanDialog.step2.title")}
        content={
          <>
            <DialogContentText sx={{ mb: 4 }}>
              {t("choosePlanDialog.step2.text")}
            </DialogContentText>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <GroupedForms
                  sx={(theme) => ({
                    width: "100%",
                    maxWidth: theme.spacing(56),
                  })}
                  label={t("choosePlanDialog.step2.permitNumber.groupsLabel")}
                  value={values.selectedPermitIssuingAuthority}
                  onChange={(value) =>
                    setFieldValue(
                      "selectedPermitIssuingAuthority",
                      parseInt(value)
                    )
                  }
                  groups={[
                    {
                      value: permitIssuingAuthority.oiiq,
                      label: t(
                        "choosePlanDialog.step2.permitNumber.oiiqGroupLabel"
                      ),
                      form: (
                        <PermitNumberTextField
                          id="oiiqPermitNumber"
                          value={values.oiiqPermitNumber}
                          onChange={handleChange}
                          error={
                            submitCount > 0 && Boolean(errors.oiiqPermitNumber)
                          }
                          helperText={
                            submitCount > 0 && errors.oiiqPermitNumber
                          }
                        />
                      ),
                    },
                    {
                      value: permitIssuingAuthority.oiiaq,
                      label: t(
                        "choosePlanDialog.step2.permitNumber.oiiaqGroupLabel"
                      ),
                      form: (
                        <PermitNumberTextField
                          id="oiiaqPermitNumber"
                          value={values.oiiaqPermitNumber}
                          onChange={handleChange}
                          error={
                            submitCount > 0 && Boolean(errors.oiiaqPermitNumber)
                          }
                          helperText={
                            submitCount > 0 && errors.oiiaqPermitNumber
                          }
                        />
                      ),
                    },
                  ]}
                />
              </Grid>
              <Grid item>
                <PhotoIdentification
                  uploading={uploading}
                  submitCount={submitCount}
                  values={values}
                  errors={errors}
                  onAddFileChange={handleAddFileChange}
                  onRemoveClick={() =>
                    setFieldValue("photoIdentificationId", "")
                  }
                />
              </Grid>
            </Grid>
          </>
        }
        actions={
          <>
            <Button onClick={onPrevious} size="large" variant="outlined">
              {t("choosePlanDialog.step2.previous")}
            </Button>
            <LoadingButton
              disabled={isSubmitting}
              loading={isSubmitting}
              type="submit"
              size="large"
              variant="contained"
            >
              {t("choosePlanDialog.step2.next")}
            </LoadingButton>
          </>
        }
      />
      <ErrorAlert
        autoHide
        open={photoIdentificationSizeTooLargeError}
        onClose={() => setPhotoIdentificationSizeTooLargeError(false)}
        content={t(
          "choosePlanDialog.step2.alerts.photoIdentificationSizeTooLargeError"
        )}
      />
      <ErrorAlert
        open={uploadPhotoIdentificationError}
        onClose={() => setUploadPhotoIdentificationError(false)}
        content={
          <Trans
            i18nKey="global-modals:choosePlanDialog.step2.alerts.uploadPhotoIdentificationError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
      <ErrorAlert
        open={submitPermitAndPhotoIdentificationError}
        onClose={() => setSubmitPermitAndPhotoIdentificationError(false)}
        content={
          <Trans
            i18nKey="global-modals:choosePlanDialog.step2.alerts.submitPermitAndPhotoIdentificationError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
    </>
  );
};

Step2.propTypes = {
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default Step2;
