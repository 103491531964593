import { fr } from "date-fns/locale";
import { format, formatDistanceToNow, isFuture, isToday } from "date-fns";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Chip } from "@mui/material";

const RemindOnChip = ({ sx, completed, remindOn }) => {
  const { t } = useTranslation("components");

  const today = isToday(remindOn);
  const upcoming = isFuture(remindOn);

  const label =
    upcoming || completed
      ? today
        ? t("molecules.reminder.remindOnChip.todayAt", {
            time: format(remindOn, "p", { locale: fr }),
          })
        : format(remindOn, "PPP", { locale: fr })
      : today
      ? t("molecules.reminder.remindOnChip.today")
      : t("molecules.reminder.remindOnChip.past", {
          distanceToNow: formatDistanceToNow(remindOn, { locale: fr }),
        });

  return (
    <Chip
      sx={[
        !completed &&
          !today &&
          !upcoming &&
          ((theme) => ({
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.getContrastText(theme.palette.error.dark),
          })),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      label={label}
      size="small"
    />
  );
};

RemindOnChip.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  remindOn: PropTypes.instanceOf(Date).isRequired,
  completed: PropTypes.instanceOf(Date),
};

export default RemindOnChip;
