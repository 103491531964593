import { useEffect } from "react";

import { useSession } from "@vesta/lib/react";

export const useTidio = () => {
  const [session] = useSession();

  useEffect(() => {
    document.tidioChatLang = "fr";
  }, []);

  useEffect(() => {
    const onClose = () => window.tidioChatApi.display(false);

    if (window.tidioChatApi) {
      window.tidioChatApi.on("close", onClose);
    } else {
      window.addEventListener("tidioChat-close", onClose);
      return () => window.removeEventListener("tidioChat-close", onClose);
    }
  }, []);

  useEffect(() => {
    const onReady = () => {
      window.tidioChatApi.display(false);
      if (session) {
        window.tidioChatApi.setVisitorData({
          distinct_id: session.nameid,
          email: session.email,
          name: `${session.given_name} ${session.family_name}`,
        });
      }
    };

    if (window.tidioChatApi) {
      window.tidioChatApi.on("ready", onReady);
    } else {
      window.addEventListener("tidioChat-ready", onReady);
      return () => window.removeEventListener("tidioChat-ready", onReady);
    }
  }, [session]);
};
