import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Fab } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Add } from "@mui/icons-material";

import { useUpSm } from "@vesta/lib/react";

const StyledSpan = styled("span")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const AddTerritoryFloatingButton = ({ sx, ...rest }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("profile");

  return (
    <Fab
      sx={sx}
      color="primary"
      size={upSm ? "large" : "medium"}
      variant="extended"
      {...rest}
    >
      <Add />
      <StyledSpan>
        {t("territories.addTerritoryFloatingButton.label")}
      </StyledSpan>
    </Fab>
  );
};

AddTerritoryFloatingButton.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
};

export default AddTerritoryFloatingButton;
