import PropTypes from "prop-types";

import { LogoTop, WideRight } from "./components";

import LogoVariant from "./LogoVariant";

const Logo = ({ sx, variant }) => {
  switch (variant) {
    case LogoVariant.LogoTop:
      return <LogoTop sx={sx} />;
    case LogoVariant.WideRight:
      return <WideRight sx={sx} />;
    default:
      return <LogoTop sx={sx} />;
  }
};

Logo.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  variant: PropTypes.string,
};

export default Logo;
