import { useCallback } from "react";

/*
 * Adapted from this example: https://codesandbox.io/s/react-image-crop-demo-with-react-hooks-y831o?file=/src/canvasPreview.ts
 */
export const useToBlob = (image, completedCrop, maxSize) => {
  const cropImage = useCallback(() => {
    const canvas = document.createElement("canvas");

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = Math.floor(completedCrop.width * scaleX);
    canvas.height = Math.floor(completedCrop.height * scaleY);

    const context = canvas.getContext("2d");

    const cropX = completedCrop.x * scaleX;
    const cropY = completedCrop.y * scaleY;

    context.translate(-cropX, -cropY);

    context.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    );

    return canvas;
  }, [image, completedCrop]);

  const resizeImage = useCallback(() => {
    const canvasContainingCroppedImage = cropImage();

    let width = canvasContainingCroppedImage.width;
    let height = canvasContainingCroppedImage.height;

    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }
    } else {
      if (height > maxSize) {
        width *= maxSize / height;
        height = maxSize;
      }
    }

    const canvas = document.createElement("canvas");

    canvas.width = width;
    canvas.height = height;

    const context = canvas.getContext("2d");

    context.drawImage(canvasContainingCroppedImage, 0, 0, width, height);

    return canvas;
  }, [cropImage, maxSize]);

  const toBlob = useCallback(
    () => new Promise((resolve) => resizeImage().toBlob(resolve)),
    [resizeImage]
  );

  return toBlob;
};
