import { useTranslation } from "react-i18next";
import { Link, useMatch } from "react-router-dom";

import { Tab, Tabs as MuiTabs } from "@mui/material";

const Tabs = () => {
  const { t } = useTranslation("layouts");
  const matchesHome = useMatch("/");
  const matchesCalendar = useMatch("/calendar/*");
  const matchesPatient = useMatch("/patients/*");
  const matchesProfile = useMatch("/profile/*");

  return (
    <MuiTabs
      sx={{ alignSelf: "flex-end" }}
      value={
        matchesHome
          ? "/"
          : matchesCalendar
          ? "/calendar"
          : matchesPatient
          ? "/patients"
          : matchesProfile
          ? "/profile"
          : "/calendar"
      }
      aria-label={t("mainLayout.header.tabs.ariaLabel")}
      TabIndicatorProps={{
        style: { transition: "none" },
      }}
    >
      <Tab
        component={Link}
        label={t("mainLayout.header.tabs.homeLabel")}
        to="/"
        value="/"
      />
      <Tab
        component={Link}
        label={t("mainLayout.header.tabs.calendarLabel")}
        to="/calendar/appointments"
        value="/calendar"
      />
      <Tab
        component={Link}
        label={t("mainLayout.header.tabs.patientsLabel")}
        to="/patients"
        value="/patients"
      />
      <Tab
        component={Link}
        label={t("mainLayout.header.tabs.profileLabel")}
        to="/profile/biography"
        value="/profile"
      />
    </MuiTabs>
  );
};

export default Tabs;
