import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";

import { Box, Grid, Link as MuiLink, Typography } from "@mui/material";

import { TerritoryMissingIllustration } from "@vesta/components/atoms";
import {
  useAuthorizedFetchJson,
  useScrollToTopOnMount,
  useSession,
} from "@vesta/lib/react";

import { AddTerritoryFloatingButton, TerritoryCard } from "./components";

const useProfileTerritories = () => {
  const [, jwtToken] = useSession();
  const [fetchJson, json, loading] = useAuthorizedFetchJson(jwtToken);

  useScrollToTopOnMount();

  useEffect(() => {
    fetchJson(
      new URL(
        "/queries/get-profile-territories",
        process.env.REACT_APP_API_BASE_URL
      )
    );
  }, []);

  return [json?.territories, loading];
};

const Territories = () => {
  const { t } = useTranslation("profile");
  const [searchParams] = useSearchParams();
  const [territories, loading] = useProfileTerritories();

  if (!loading && !territories) {
    return null;
  }

  const term = searchParams.get("term") ?? "";

  const loadingState = (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <TerritoryCard loading />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TerritoryCard loading />
      </Grid>
    </>
  );

  const emptyState = (
    <>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        item
        xs={12}
      >
        <TerritoryMissingIllustration
          sx={(theme) => ({
            marginTop: theme.spacing(2),
            width: 240,
            [theme.breakpoints.up("sm")]: {
              width: 345,
            },
          })}
        />
        <Typography
          sx={(theme) => ({ marginTop: theme.spacing(4) })}
          gutterBottom
          align="center"
          component="h2"
          variant="h3"
        >
          {t("territories.emptyState.title")}
        </Typography>
        <Typography
          sx={(theme) => ({ maxWidth: theme.spacing(80) })}
          align="center"
        >
          <Trans
            i18nKey="profile:territories.emptyState.text"
            components={{
              addTerritoryLink: (
                <MuiLink
                  component={Link}
                  to="/profile/add-territory/1"
                  underline="always"
                />
              ),
            }}
          />
        </Typography>
      </Grid>
    </>
  );

  const filteredTerritories = territories?.filter((x) =>
    x.name.toLowerCase().includes(term.toLowerCase())
  );

  return (
    <>
      <Grid container component="section" spacing={2}>
        {loading
          ? loadingState
          : territories.length === 0
          ? emptyState
          : filteredTerritories
              .sort((a, b) => a.name.localeCompare(b.name, "fr"))
              .map((territory) => (
                <Grid key={territory.id} item xs={12} sm={6} md={4}>
                  <TerritoryCard territory={territory} />
                </Grid>
              ))}
      </Grid>
      <Box
        sx={(theme) => ({
          height: theme.spacing(7),
          [theme.breakpoints.up("md")]: {
            height: theme.spacing(11),
          },
        })}
      />
      <AddTerritoryFloatingButton
        sx={(theme) => ({
          position: "fixed",
          zIndex: 4,
          right: theme.spacing(2),
          bottom: theme.spacing(9),
          [theme.breakpoints.up("md")]: {
            bottom: theme.spacing(4),
          },
        })}
        component={Link}
        to="/profile/add-territory"
      />
    </>
  );
};

export default Territories;
