import { useTranslation } from "react-i18next";

import { Alert, AlertTitle, Typography } from "@mui/material";

import { useAppointment } from "@vesta/lib/react";

const RelationshipAlert = () => {
  const { t } = useTranslation("appointment");
  const [appointment] = useAppointment();

  return (
    <Alert component="aside" severity="info">
      <AlertTitle>{t("relationshipAlert.title")}</AlertTitle>
      <Typography component="span" variant="body2">
        {t("relationshipAlert.text", {
          firstName: appointment.someoneElse.firstName,
        })}
      </Typography>
      <Typography sx={{ fontStyle: "italic" }} component="span" variant="body2">
        {appointment.someoneElse.relationship}
      </Typography>
    </Alert>
  );
};

export default RelationshipAlert;
