export const useNotes = (noteId, medicalRecord) => {
  const primaryNote = medicalRecord?.medicalInformation.notes.find(
    (x) => x.noteId === noteId
  );

  if (!primaryNote) {
    return [];
  }

  const findNoteAddenda = (noteId) => {
    const addenda = medicalRecord.medicalInformation.notes.filter(
      (x) => x.addendumTo === noteId
    );

    const addendaOfAddenda = addenda
      .map((x) => findNoteAddenda(x.noteId))
      .flatMap((x) => x);

    return [...addenda, ...addendaOfAddenda];
  };

  const addendaOfPrimaryNote = findNoteAddenda(primaryNote.noteId);

  const primaryNoteAndAddenda = [primaryNote, ...addendaOfPrimaryNote].sort(
    (a, b) => a.created - b.created
  );

  return [primaryNote, primaryNoteAndAddenda];
};
