import { Box, Typography } from "@mui/material";

import { useSession } from "@vesta/lib/react";

const AccountMenuHeader = () => {
  const [session] = useSession();

  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.grey[200],
        color: theme.palette.text.primary,
        padding: theme.spacing(2),
        borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
      })}
    >
      <Typography variant="body2">{`${session.given_name} ${session.family_name}`}</Typography>
      <Typography variant="caption">{session.email}</Typography>
    </Box>
  );
};

export default AccountMenuHeader;
