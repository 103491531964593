import PropTypes from "prop-types";

import { Box } from "@mui/material";

import { GlobalModalContextProvider } from "@vesta/components/organisms";

const MinimalLayout = ({ children }) => (
  <Box component="main" sx={{ height: "100%" }}>
    <GlobalModalContextProvider>{children}</GlobalModalContextProvider>
  </Box>
);

MinimalLayout.propTypes = {
  children: PropTypes.node,
};

export default MinimalLayout;
