import { useGlobalModalContext } from "@vesta/lib/react";
import { useTranslation } from "react-i18next";

import { AccountMenuAlert } from "../";

const AccountMenuSubscriptionRequiredAlert = () => {
  const { t } = useTranslation("components");
  const { openChooseSubscriptionDialog } = useGlobalModalContext();

  return (
    <AccountMenuAlert
      severity={"info"}
      title={t(
        "molecules.accountMenu.accountMenuSubscriptionRequiredAlert.title"
      )}
      description={t(
        "molecules.accountMenu.accountMenuSubscriptionRequiredAlert.description"
      )}
      action={{
        label: t(
          "molecules.accountMenu.accountMenuSubscriptionRequiredAlert.ctaLabel"
        ),
        func: () => openChooseSubscriptionDialog(),
      }}
    />
  );
};

export default AccountMenuSubscriptionRequiredAlert;
