import { startOfDay } from "date-fns";
import { useFormik } from "formik";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input/input";
import * as yup from "yup";

import { agreementStatus } from "@vesta/lib/enum";
import { useAuthorizedFetchJson } from "@vesta/lib/react";

const initialValues = {
  searched: null,
  patient: {
    isNewPatient: false,
    isDemoPatient: false,
    email: "",
    firstName: "",
    lastName: "",
    birthday: null,
    sexId: "",
    spokenLanguageIds: [],
    ramqHealthInsuranceNumber: "",
    ramqHealthInsuranceExpiration: "",
    phoneNumberInE164Format: "",
  },
  services: [],
  additionalInformation: "",
  start: startOfDay(Date.now()),
  place: null,
  apartmentUnitSuiteOrFloorNb: "",
};

export const useForm = (jwtToken, open, initialStart, onSubmit) => {
  const { t } = useTranslation("calendar");
  const [fetchJson, json, loading] = useAuthorizedFetchJson(jwtToken);

  useEffect(() => {
    fetchJson(
      new URL(
        "/queries/get-new-appointment-options",
        process.env.REACT_APP_API_BASE_URL
      )
    );
  }, []);

  const patientObjectShape = {
    isNewPatient: yup.bool(),
    isDemoPatient: yup.bool(),
    email: yup
      .string()
      .email(t("appointments.createAppointmentDialog.patient.emailValid"))
      .required(
        t("appointments.createAppointmentDialog.patient.emailRequired")
      ),
    firstName: yup
      .string()
      .required(
        t("appointments.createAppointmentDialog.patient.firstNameRequired")
      ),
    lastName: yup
      .string()
      .required(
        t("appointments.createAppointmentDialog.patient.lastNameRequired")
      ),
    birthday: yup
      .date()
      .nullable()
      .typeError(
        t("appointments.createAppointmentDialog.patient.birthdayValid")
      )
      .max(
        new Date(),
        t("appointments.createAppointmentDialog.patient.birthdayMax")
      )
      .required(
        t("appointments.createAppointmentDialog.patient.birthdayRequired")
      ),
    sexId: yup
      .number()
      .required(
        t("appointments.createAppointmentDialog.patient.sexIdRequired")
      ),
    spokenLanguageIds: yup
      .array()
      .min(
        1,
        t(
          "appointments.createAppointmentDialog.patient.spokenLanguageIdsRequired"
        )
      ),
    ramqHealthInsuranceNumber: yup
      .string()
      .nullable()
      .matches(
        /^[A-Z]{4} [0-9]{4} [0-9]{4}$/,
        t(
          "appointments.createAppointmentDialog.patient.ramqHealthInsuranceNumberValidation"
        )
      ),
    ramqHealthInsuranceExpiration: yup
      .string()
      .nullable()
      .test(
        "required-if-health-insurance-number-not-empty",
        t(
          "appointments.createAppointmentDialog.patient.ramqHealthInsuranceExpirationRequired"
        ),
        (value, context) =>
          context.parent.ramqHealthInsuranceNumber ? !!value : true
      )
      .matches(
        /^\d{4} (0[1-9]|1[012])$/,
        t(
          "appointments.createAppointmentDialog.patient.ramqHealthInsuranceExpirationValidation"
        )
      ),
    phoneNumberInE164Format: yup.string().test(
      "is-phone-number-valid",
      <Trans
        i18nKey="calendar:appointments.createAppointmentDialog.patient.phoneNumberValid"
        components={{
          emphasize: <b />,
        }}
      />,
      (value) => isValidPhoneNumber(value ?? "")
    ),
  };

  const validationSchema = yup.object().shape({
    searched: yup
      .bool()
      .nullable()
      .required(t("appointments.createAppointmentDialog.searchedRequired")),
    patient: yup.object().when("searched", {
      is: true,
      then: yup.object().shape(patientObjectShape),
    }),
    services: yup
      .array()
      .test(
        "at-least-1-service",
        t("appointments.createAppointmentDialog.atLeast1ServiceValidation"),
        (value) => value.some((x) => x.include)
      ),
    additionalInformation: yup.string(),
    start: yup.date().nullable().required(),
    place: yup
      .object()
      .nullable()
      .required(t("appointments.createAppointmentDialog.placeRequired")),
    apartmentUnitSuiteOrFloorNb: yup.string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { resetForm, setFieldValue } = formik;

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open, resetForm]);

  useEffect(() => {
    if (initialStart) {
      setFieldValue("start", initialStart);
    }
  }, [initialStart, setFieldValue]);

  useEffect(() => {
    if (json?.services) {
      const services = json.services
        .filter((x) => !x.isDeleted)
        .map((x) => ({
          id: x.serviceId,
          name: x.nameFr,
          include: false,
          disabled: x.agreements.some(
            (y) => y.statusId !== agreementStatus.accepted
          ),
        }));

      setFieldValue("services", services);
    }
  }, [open, json, setFieldValue]);

  return [loading, formik];
};
