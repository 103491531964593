import PropTypes from "prop-types";

import { InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";

const PatientSearchBar = ({ label, InputProps, ...rest }) => (
  <TextField
    label={label}
    variant="outlined"
    InputProps={{
      ...InputProps,
      startAdornment: (
        <InputAdornment position="start">
          <Search color="action" />
        </InputAdornment>
      ),
    }}
    {...rest}
  />
);

PatientSearchBar.propTypes = {
  label: PropTypes.string,
  InputProps: PropTypes.object,
};

export default PatientSearchBar;
