import { lazy, Suspense, useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useParams } from "react-router-dom";

import { Container, Grid } from "@mui/material";

import { BackButton, Loader } from "@vesta/components/atoms";
import { MedicalRecordContext, useUpSm } from "@vesta/lib/react";

import { DemoPatientAlert, PatientHeader } from "./components";
import { useMedicalRecordReducer } from "./hooks";

const Notes = lazy(() => import("./components/Notes"));
const Results = lazy(() => import("./components/Results"));
const Appointments = lazy(() => import("./components/Appointments"));

const PatientView = () => {
  const upSm = useUpSm();
  const { t } = useTranslation("patient");
  const { patientId, someoneElseId } = useParams();
  const [medicalRecord, dispatch] = useMedicalRecordReducer(
    patientId,
    someoneElseId
  );

  const value = useMemo(
    () => [medicalRecord, dispatch],
    [medicalRecord, dispatch]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [patientId, someoneElseId]);

  return (
    <>
      <Helmet>
        <title>
          {t("title", {
            fullName: medicalRecord?.personalInformation.fullName ?? "",
          })}
        </title>
        <meta
          property="og:title"
          content={t("title", {
            fullName: medicalRecord?.personalInformation.fullName ?? "",
          })}
        />
        <meta property="og:description" content={t("description")} />
      </Helmet>
      <MedicalRecordContext.Provider value={value}>
        <PatientHeader />
        <Container
          sx={(theme) => ({
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up("sm")]: {
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(3),
            },
          })}
          maxWidth={false}
        >
          <Grid container direction="column" spacing={upSm ? 3 : 2}>
            <Grid item>
              <BackButton to="/patients" label={t("backLabel")} />
            </Grid>
            {medicalRecord?.isDemoPatient && (
              <Grid item>
                <DemoPatientAlert />
              </Grid>
            )}
            <Grid item>
              <Suspense fallback={<Loader />}>
                <Routes>
                  <Route path="/notes" element={<Notes />} />
                  <Route path="/results" element={<Results />} />
                  <Route path="/appointments" element={<Appointments />} />
                  <Route
                    path="*"
                    element={
                      <Navigate
                        to={
                          someoneElseId
                            ? `/patients/${patientId}/s/${someoneElseId}/notes`
                            : `/patients/${patientId}/notes`
                        }
                        replace
                      />
                    }
                  />
                </Routes>
              </Suspense>
            </Grid>
          </Grid>
        </Container>
      </MedicalRecordContext.Provider>
    </>
  );
};

export default PatientView;
