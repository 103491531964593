import { fr } from "date-fns/locale";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Checkbox,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  IconButton,
  ListItemIcon,
  ListSubheader,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from "@mui/material";
import { Clear, Delete, Event, MoreVert } from "@mui/icons-material";

import { RemindOnChip } from "./components";
import { useRemindOnOptions } from "./hooks";

const chipSx = (theme) => ({
  marginBottom: theme.spacing(1 / 2),
  "&:not(:last-of-type)": {
    marginRight: theme.spacing(1 / 2),
  },
});

const templateSx = (theme) => ({
  backgroundColor: theme.palette.secondary.light,
});

const localizedDayOfWeek = (date) => {
  const dayOfWeek = format(date, "eee", { locale: fr });
  return dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
};

const formatDate = (date) =>
  `${localizedDayOfWeek(date)} ${format(date, "p", {
    locale: fr,
  })}`;

const Reminder = ({
  id,
  sx,
  template,
  title,
  details,
  remindOn,
  completed,
  onComplete,
  onRevertComplete,
  onDelete,
  onRemindAboutThis,
  onDoNotRemindAboutThis,
  onPickDateAndTime,
}) => {
  const anchorRef = useRef(null);
  const { t } = useTranslation("components");
  const {
    laterToday,
    tomorrow,
    laterThisWeek,
    thisWeekend,
    nextWeek,
    nextWeekend,
  } = useRemindOnOptions();
  const [open, setOpen] = useState(false);

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      onComplete();
    } else {
      onRevertComplete();
    }
  };

  const handleClickAway = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current.focus(); // return focus to the button when we transitioned from !open -> open
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box
      sx={[
        (theme) => ({
          display: "flex",
          alignItems: "start",
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      component="section"
    >
      <Tooltip
        arrow
        placement="bottom-end"
        title={
          completed
            ? t("molecules.reminder.revertComplete")
            : t("molecules.reminder.complete")
        }
      >
        <Checkbox
          sx={(theme) => ({ marginRight: theme.spacing(1) })}
          aria-label={
            completed
              ? t("molecules.reminder.revertComplete")
              : t("molecules.reminder.complete")
          }
          onChange={handleCheckboxChange}
          checked={!!completed}
        />
      </Tooltip>
      <Grid container direction="column" spacing={1}>
        <Grid item>{title}</Grid>
        {details && <Grid item>{details}</Grid>}
        {remindOn ? (
          <Grid item>
            <RemindOnChip
              sx={chipSx}
              completed={completed}
              remindOn={remindOn}
            />
            {template && (
              <Chip
                sx={[chipSx, templateSx]}
                color="secondary"
                label={t("molecules.reminder.template")}
                size="small"
              />
            )}
          </Grid>
        ) : (
          template && (
            <Grid item>
              <Chip
                sx={[chipSx, templateSx]}
                color="secondary"
                label={t("molecules.reminder.template")}
                size="small"
              />
            </Grid>
          )
        )}
      </Grid>
      <IconButton
        ref={anchorRef}
        edge="end"
        aria-label={t("molecules.reminder.more")}
        aria-haspopup="true"
        aria-controls={open ? `more-menu-${id}` : undefined}
        onClick={() => setOpen((prevValue) => !prevValue)}
        size="large"
      >
        <MoreVert />
      </IconButton>
      <Popper
        sx={(theme) => ({ zIndex: theme.zIndex.appBar + 1 })}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} sx={{ transformOrigin: "center top" }}>
            <Paper elevation={4}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <MenuList
                  id={`more-menu-${id}`}
                  dense
                  autoFocus={open}
                  onKeyDown={handleKeyDown}
                  onClick={() => setOpen(false)}
                >
                  <MenuItem onClick={onDelete}>
                    <ListItemIcon>
                      <Delete />
                    </ListItemIcon>
                    {t("molecules.reminder.moreMenu.delete")}
                  </MenuItem>
                  <ListSubheader disableSticky>
                    {t("molecules.reminder.moreMenu.remindOn")}
                  </ListSubheader>
                  {laterToday && (
                    <MenuItem onClick={() => onRemindAboutThis(laterToday)}>
                      {t("molecules.reminder.moreMenu.laterToday", {
                        date: formatDate(laterToday),
                      })}
                    </MenuItem>
                  )}
                  <MenuItem onClick={() => onRemindAboutThis(tomorrow)}>
                    {t("molecules.reminder.moreMenu.tomorrow", {
                      date: formatDate(tomorrow),
                    })}
                  </MenuItem>
                  {laterThisWeek && (
                    <MenuItem onClick={() => onRemindAboutThis(laterThisWeek)}>
                      {t("molecules.reminder.moreMenu.laterThisWeek", {
                        date: formatDate(laterThisWeek),
                      })}
                    </MenuItem>
                  )}
                  {thisWeekend && (
                    <MenuItem onClick={() => onRemindAboutThis(thisWeekend)}>
                      {t("molecules.reminder.moreMenu.thisWeekend", {
                        date: formatDate(thisWeekend),
                      })}
                    </MenuItem>
                  )}
                  {nextWeek && (
                    <MenuItem onClick={() => onRemindAboutThis(nextWeek)}>
                      {t("molecules.reminder.moreMenu.nextWeek", {
                        date: formatDate(nextWeek),
                      })}
                    </MenuItem>
                  )}
                  {nextWeekend && (
                    <MenuItem onClick={() => onRemindAboutThis(nextWeekend)}>
                      {t("molecules.reminder.moreMenu.nextWeekend", {
                        date: formatDate(nextWeekend),
                      })}
                    </MenuItem>
                  )}
                  <Divider
                    sx={(theme) => ({
                      marginTop: theme.spacing(1),
                      marginBottom: theme.spacing(1),
                    })}
                  />
                  <MenuItem onClick={onPickDateAndTime}>
                    <ListItemIcon>
                      <Event />
                    </ListItemIcon>
                    {t("molecules.reminder.moreMenu.pickDateAndTime")}
                  </MenuItem>
                  {remindOn && (
                    <MenuItem onClick={onDoNotRemindAboutThis}>
                      <ListItemIcon>
                        <Clear />
                      </ListItemIcon>
                      {t("molecules.reminder.moreMenu.doNotRemindAboutThis")}
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

Reminder.propTypes = {
  id: PropTypes.string.isRequired,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  template: PropTypes.bool,
  title: PropTypes.node.isRequired,
  details: PropTypes.node,
  remindOn: PropTypes.instanceOf(Date),
  completed: PropTypes.instanceOf(Date),
  onComplete: PropTypes.func.isRequired,
  onRevertComplete: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRemindAboutThis: PropTypes.func.isRequired,
  onDoNotRemindAboutThis: PropTypes.func.isRequired,
  onPickDateAndTime: PropTypes.func.isRequired,
};

export default Reminder;
