import { fr } from "date-fns/locale";
import { differenceInMinutes, format } from "date-fns";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
}));

const eventSx = {
  height: "100%",
  "&:hover": {
    cursor: "pointer",
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
};

const cancelledSx = (theme) => ({
  color: theme.palette.error.contrastText,
  textDecoration: "line-through",
});

const lineSx = (theme) => ({
  fontSize: 12,
  color: theme.palette.primary.contrastText,
});

const Event = ({ event }) => {
  const { t } = useTranslation("calendar");

  if (event.isBackgroundEvent) {
    return null;
  }

  const {
    appointmentId,
    patientFirstName,
    patientLastName,
    isAppointmentForSomeoneElse,
    someoneElse,
    start,
    end,
    cancelled,
  } = event;

  const to = `/calendar/appointments/${appointmentId}/preparation`;

  const appointmentDurationInMinutes = differenceInMinutes(
    new Date(end),
    new Date(start)
  );

  const formattedStart = format(event.start ?? start, "p", { locale: fr });
  const formattedEnd = format(event.end ?? end, "p", { locale: fr });
  const patientName = isAppointmentForSomeoneElse
    ? `${someoneElse?.firstName} ${someoneElse?.lastName}`
    : `${patientFirstName} ${patientLastName}`;

  if (appointmentDurationInMinutes > 45) {
    return (
      <StyledLink to={to}>
        <Box sx={[eventSx, cancelled && cancelledSx]}>
          <Typography sx={lineSx} noWrap variant="caption">
            {patientName}
          </Typography>
          <Typography sx={lineSx} noWrap variant="caption">
            {t("appointments.event.time", {
              start: formattedStart,
              end: formattedEnd,
            })}
          </Typography>
        </Box>
      </StyledLink>
    );
  }

  if (appointmentDurationInMinutes >= 15) {
    return (
      <StyledLink to={to}>
        <Box
          sx={[
            { transform: "translateY(-3px)" },
            eventSx,
            cancelled && cancelledSx,
          ]}
        >
          <Typography sx={lineSx} noWrap variant="caption">
            {`${patientName}, ${formattedStart}`}
          </Typography>
        </Box>
      </StyledLink>
    );
  }
};

Event.propTypes = {
  event: PropTypes.object.isRequired,
};

export default Event;
