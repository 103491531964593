import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Container, Grid } from "@mui/material";

import { BackButton } from "@vesta/components/atoms";

const Body = ({
  sx,
  alert,
  agreements,
  generalInformation,
  durations,
  healthInsuranceRequirements,
}) => {
  const { t } = useTranslation("service");

  return (
    <Container sx={sx} maxWidth="xl">
      <Grid container direction="column" spacing={{ xs: 2, sm: 3 }}>
        <Grid item>
          <BackButton to="/profile/services" label={t("body.backLabel")} />
        </Grid>
        {alert && <Grid item>{alert}</Grid>}
        {agreements.map((x, i) => (
          <Grid key={i} item>
            {x}
          </Grid>
        ))}
        <Grid item>{generalInformation}</Grid>
        <Grid item>{durations}</Grid>
        <Grid item>{healthInsuranceRequirements}</Grid>
      </Grid>
    </Container>
  );
};

Body.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  alert: PropTypes.node,
  agreements: PropTypes.arrayOf(PropTypes.node).isRequired,
  generalInformation: PropTypes.node.isRequired,
  durations: PropTypes.node.isRequired,
  healthInsuranceRequirements: PropTypes.node.isRequired,
};

export default Body;
