import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Box, Divider, Grid, Skeleton } from "@mui/material";

import {
  ContactUsLink,
  ErrorAlert,
  SuccessAlert,
} from "@vesta/components/atoms";
import { NursingDocumentationStandardAlert } from "@vesta/components/molecules";
import { noteAttachmentType } from "@vesta/lib/enum";
import {
  useAppointment,
  useScrollToTopOnMount,
  useSession,
  useUpSm,
} from "@vesta/lib/react";
import { defaultRawContent } from "@vesta/lib/slate";

import {
  AddNoteAddendumFloatingButton,
  CompleteNoteAddendumFloatingButton,
  CompleteNoteFloatingButton,
  NoteEditor,
} from "./components";
import { useAddNoteToPatientMedicalRecord } from "./hooks";

const maxFileSize = 16777216; // 16 MB

const floatingButtonSx = (theme) => ({
  position: "fixed",
  zIndex: 12,
  right: theme.spacing(2),
  bottom: theme.spacing(9),
  [theme.breakpoints.up("md")]: {
    bottom: theme.spacing(4),
  },
});

const Note = () => {
  const upSm = useUpSm();
  const { t } = useTranslation("appointment");
  const [session, jwtToken] = useSession();
  const [appointment, dispatch] = useAppointment();
  const [noteAttachmentDeleted, setNoteAttachmentDeleted] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [noteAttachmentSizeTooLargeAlert, setNoteAttachmentSizeTooLargeAlert] =
    useState(false);
  const [uploadNoteAttachmentFailed, setUploadNoteAttachmentFailed] =
    useState(false);
  const [
    addNoteAttachmentToPatientMedicalRecordFailed,
    setAddNoteAttachmentToPatientMedicalRecordFailed,
  ] = useState(false);
  const [
    deleteNoteAttachmentFromPatientMedicalRecordFailed,
    setDeleteNoteAttachmentFromPatientMedicalRecordFailed,
  ] = useState(false);
  const [
    completeNoteInPatientMedicalRecordFailed,
    setCompleteNoteInPatientMedicalRecordFailed,
  ] = useState(false);

  useAddNoteToPatientMedicalRecord(jwtToken, appointment, dispatch);

  useScrollToTopOnMount();

  if (!appointment || appointment.notes.length === 0) {
    return (
      <>
        <Skeleton width="70%" />
        <Skeleton width="40%" />
      </>
    );
  }

  const handleRawContentChange = (noteId) => async (rawContent) => {
    dispatch({
      type: "adding-note-version-to-patient-medical-record",
      payload: {
        noteId,
      },
    });

    const url = new URL(
      "/commands/add-note-version-to-patient-medical-record",
      process.env.REACT_APP_API_BASE_URL
    );

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        patientId: appointment.patientId,
        someoneElseId: appointment.someoneElse?.someoneElseId,
        noteId,
        rawContent: JSON.stringify(rawContent),
      }),
    });

    if (response.ok) {
      const { noteVersionId } = await response.json();

      dispatch({
        type: "add-note-version-to-patient-medical-record",
        payload: {
          noteId,
          noteVersionId,
          rawContent,
          modified: new Date(),
        },
      });
    }
  };

  const handleNoteAttachmentChange = (noteId) => async (event) => {
    const files = [...event.target.files];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > maxFileSize) {
        setNoteAttachmentSizeTooLargeAlert(true);
        continue;
      }

      dispatch({
        type: "uploading-note-attachment-to-patient-medical-record",
        payload: {
          noteId,
          noteAttachment: {
            noteAttachmentId: file.name,
            name: file.name,
            typeId: noteAttachmentType.pdf,
            created: new Date(),
          },
        },
      });

      const uploadNoteAttachment = () =>
        new Promise((resolve, reject) => {
          const url = new URL(
            "upload-note-attachment",
            process.env.REACT_APP_API_BASE_URL
          );

          const request = new XMLHttpRequest();
          request.open("POST", url);
          request.setRequestHeader("Authorization", `Bearer ${jwtToken}`);

          // The fetch API does not support progress as of this writing, which is why we use XMLHttpRequest
          request.upload.addEventListener("progress", (event) => {
            const progress = Math.round((event.loaded / event.total) * 100);
            dispatch({
              type: "continue-uploading-note-attachment-to-patient-medical-record",
              payload: {
                noteId,
                noteAttachment: {
                  noteAttachmentId: file.name,
                  uploadingProgress: progress,
                },
              },
            });
          });

          request.addEventListener("readystatechange", () => {
            if (request.readyState !== 4) {
              return;
            }
            if (request.status === 200) {
              const { noteAttachmentId } = JSON.parse(request.responseText);
              resolve(noteAttachmentId);
            } else {
              reject();
            }
          });

          const formData = new FormData();
          formData.append("note-attachment", file, file.name);

          request.send(formData);
        });

      let noteAttachmentId;
      try {
        noteAttachmentId = await uploadNoteAttachment();
      } catch (e) {
        setUploadNoteAttachmentFailed(true);
        dispatch({
          type: "cancel-uploading-note-attachment-to-patient-medical-record",
          payload: {
            noteId,
            noteAttachment: {
              noteAttachmentId: file.name,
            },
          },
        });
        continue;
      }

      const addNoteAttachmentToPatientMedicalRecordResponse = await fetch(
        new URL(
          "commands/add-note-attachment-to-patient-medical-record",
          process.env.REACT_APP_API_BASE_URL
        ),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({
            patientId: appointment.patientId,
            someoneElseId: appointment.someoneElse?.someoneElseId,
            noteId,
            noteAttachmentId,
            noteAttachmentName: file.name,
            noteAttachmentTypeId: noteAttachmentType.pdf,
          }),
        }
      );

      if (!addNoteAttachmentToPatientMedicalRecordResponse.ok) {
        setAddNoteAttachmentToPatientMedicalRecordFailed(true);
        continue;
      }

      dispatch({
        type: "add-note-attachment-to-patient-medical-record",
        payload: {
          noteId,
          modified: new Date(),
          noteAttachment: {
            noteAttachmentId: file.name,
            newNoteAttachmentId: noteAttachmentId,
          },
        },
      });
    }
  };

  const handleDeleteNoteAttachment = (noteId) => async (noteAttachmentId) => {
    const url = new URL(
      "commands/delete-note-attachment-from-patient-medical-record",
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        patientId: appointment.patientId,
        someoneElseId: appointment.someoneElse?.someoneElseId,
        noteId,
        noteAttachmentId,
      }),
    });

    if (ok) {
      setNoteAttachmentDeleted(true);
      dispatch({
        type: "delete-note-attachment-from-patient-medical-record",
        payload: {
          noteId,
          modified: new Date(),
          noteAttachment: {
            noteAttachmentId,
            deleted: new Date(),
          },
        },
      });
    } else {
      setDeleteNoteAttachmentFromPatientMedicalRecordFailed(true);
    }
  };

  const handleCompleteNote = (noteId) => async () => {
    dispatch({
      type: "completing-note-in-patient-medical-record",
      payload: {
        noteId,
      },
    });

    const url = new URL(
      "commands/complete-note-in-patient-medical-record",
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        patientId: appointment.patientId,
        someoneElseId: appointment.someoneElse?.someoneElseId,
        noteId,
      }),
    });

    if (ok) {
      setCompleted(true);
      dispatch({
        type: "complete-note-in-patient-medical-record",
        payload: {
          noteId,
          modified: new Date(),
          completed: new Date(),
          completedBy: {
            userId: session.nameid,
            firstName: session.given_name,
            lastName: session.family_name,
          },
        },
      });
    } else {
      setCompleteNoteInPatientMedicalRecordFailed(true);
    }
  };

  const handleAddNoteAddendum = (addendumTo) => async () => {
    dispatch({ type: "adding-note-to-patient-medical-record" });

    const url = new URL(
      "/commands/add-note-to-patient-medical-record",
      process.env.REACT_APP_API_BASE_URL
    );

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        patientId: appointment.patientId,
        someoneElseId: appointment.someoneElse?.someoneElseId,
        addendumTo,
        appointmentId: appointment.appointmentId,
        rawContent: JSON.stringify(defaultRawContent),
      }),
    });

    if (response.ok) {
      const { noteId, noteVersionId } = await response.json();

      dispatch({
        type: "add-note-to-patient-medical-record",
        payload: {
          noteId,
          noteVersionId,
          addendumTo,
          appointmentId: appointment.appointmentId,
          rawContent: defaultRawContent,
          attachments: [],
          created: new Date(),
          modified: new Date(),
        },
      });

      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const noteToComplete = appointment.notes.find((x) => !x.completed);

  const addendaToComplete = appointment.notes
    .filter((x) => x.addendumTo)
    .filter((x) => !x.completed);

  const completedNotes = appointment.notes
    .filter((x) => x.completed)
    .sort((a, b) => b.completed - a.completed);

  return (
    <>
      <Grid container direction="column" spacing={4}>
        {appointment.notes.some((x) => !x.completed) && (
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            item
          >
            <NursingDocumentationStandardAlert
              sx={(theme) => ({
                width: "100%",
                maxWidth: theme.spacing(100),
              })}
            />
          </Grid>
        )}
        {appointment.notes
          .sort((a, b) => a.created - b.created)
          .map((x, i, { length }) => (
            <Grid
              key={x.noteId}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              item
            >
              <Box
                sx={(theme) => ({
                  width: "100%",
                  maxWidth: theme.spacing(100),
                  [theme.breakpoints.up("sm")]: {
                    backgroundColor: theme.palette.background.paper,
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: theme.palette.divider,
                    padding: theme.spacing(3),
                  },
                })}
              >
                <NoteEditor
                  noteId={x.noteId}
                  onRawContentChange={handleRawContentChange(x.noteId)}
                  onNoteAttachmentChange={handleNoteAttachmentChange(x.noteId)}
                  onDeleteNoteAttachment={handleDeleteNoteAttachment(x.noteId)}
                />
              </Box>
              {i + 1 !== length && length > 1 && !upSm && (
                <Divider
                  sx={(theme) => ({
                    marginTop: theme.spacing(4),
                    width: "100%",
                  })}
                  light
                />
              )}
            </Grid>
          ))}
      </Grid>
      <Box
        sx={(theme) => ({
          height: theme.spacing(7),
          [theme.breakpoints.up("md")]: {
            height: theme.spacing(11),
          },
        })}
      />
      {noteToComplete && noteToComplete.addendumTo && (
        <CompleteNoteAddendumFloatingButton
          sx={floatingButtonSx}
          saving={noteToComplete.addingVersion || noteToComplete.completing}
          onClick={handleCompleteNote(noteToComplete.noteId)}
        />
      )}
      {noteToComplete && !noteToComplete.addendumTo && (
        <CompleteNoteFloatingButton
          sx={floatingButtonSx}
          saving={noteToComplete.addingVersion || noteToComplete.completing}
          onClick={handleCompleteNote(noteToComplete.noteId)}
        />
      )}
      {addendaToComplete.length === 0 && completedNotes.length > 0 && (
        <AddNoteAddendumFloatingButton
          sx={floatingButtonSx}
          saving={appointment.addingNote}
          onClick={handleAddNoteAddendum(completedNotes[0].noteId)}
        />
      )}
      <SuccessAlert
        open={noteAttachmentDeleted}
        onClose={() => setNoteAttachmentDeleted(false)}
        content={t("note.noteAttachmentDeletedAlert")}
      />
      <SuccessAlert
        open={completed}
        onClose={() => setCompleted(false)}
        content={t("note.completedAlert")}
      />
      <ErrorAlert
        autoHide
        open={noteAttachmentSizeTooLargeAlert}
        onClose={() => setNoteAttachmentSizeTooLargeAlert(false)}
        content={t("note.noteAttachmentSizeTooLargeAlert")}
      />
      <ErrorAlert
        open={uploadNoteAttachmentFailed}
        onClose={() => setUploadNoteAttachmentFailed(false)}
        content={t("note.uploadNoteAttachmentFailedAlert")}
      />
      <ErrorAlert
        open={addNoteAttachmentToPatientMedicalRecordFailed}
        onClose={() => setAddNoteAttachmentToPatientMedicalRecordFailed(false)}
        content={
          <Trans
            i18nKey="appointment:note.addNoteAttachmentToPatientMedicalRecordFailedAlert"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
      <ErrorAlert
        open={deleteNoteAttachmentFromPatientMedicalRecordFailed}
        onClose={() =>
          setDeleteNoteAttachmentFromPatientMedicalRecordFailed(false)
        }
        content={
          <Trans
            i18nKey="appointment:note.deleteNoteAttachmentFromPatientMedicalRecordFailedAlert"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
      <ErrorAlert
        open={completeNoteInPatientMedicalRecordFailed}
        onClose={() => setCompleteNoteInPatientMedicalRecordFailed(false)}
        content={
          <Trans
            i18nKey="appointment:note.completeNoteInPatientMedicalRecordFailedAlert"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
    </>
  );
};

export default Note;
