import { cloneElement } from "react";

import { numberedArray } from "@vesta/lib/utils";

export const cloneElementIntoKeyedArray = (length, renderElement) =>
  numberedArray(length, (i) =>
    cloneElement(renderElement(i), {
      key: i,
    })
  );

export * from "./contexts";
export * from "./hooks";
