import { fr } from "date-fns/locale";
import { format } from "date-fns";
import PropTypes from "prop-types";

import { Box, Typography } from "@mui/material";

const CalendarHeader = ({ date }) => {
  const dayOfWeek = format(date, "eee", { locale: fr });
  const dayOfMonth = format(date, "d", { locale: fr });

  return (
    <Box sx={{ py: 1 / 2 }}>
      <Typography sx={{ textTransform: "capitalize" }} variant="caption">
        {`${dayOfWeek} ${dayOfMonth}`}
      </Typography>
    </Box>
  );
};

CalendarHeader.propTypes = {
  date: PropTypes.object.isRequired,
};

export default CalendarHeader;
