import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import { CheckboxFilters } from "@vesta/components/molecules";
import AdditionalInformationTextField from "../AdditionalInformationTextField";

const AppointmentServices = ({
  formik: {
    values: { services, additionalInformation },
    errors,
    submitCount,
    handleChange,
    setFieldValue,
  },
}) => {
  const { t } = useTranslation("components");

  const handleServiceChange = ({ id, include }) => {
    setFieldValue(
      "services",
      services.map((x) =>
        x.id === id
          ? {
              ...x,
              include,
            }
          : { ...x }
      )
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h3">
          {t("molecules.appointmentServices.title")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CheckboxFilters
          label={t("molecules.appointmentServices.servicesLabel")}
          values={services}
          onChange={handleServiceChange}
          error={submitCount > 0 && Boolean(errors.services)}
          errorText={errors.services}
        />
      </Grid>
      <Grid item xs={12}>
        <AdditionalInformationTextField
          id="additionalInformation"
          value={additionalInformation}
          onChange={handleChange}
          error={submitCount > 0 && Boolean(errors.additionalInformation)}
          helperText={submitCount > 0 ? errors.additionalInformation : null}
        />
      </Grid>
    </Grid>
  );
};

AppointmentServices.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      services: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          include: PropTypes.bool.isRequired,
        })
      ),
      additionalInformation: PropTypes.string,
    }).isRequired,
    errors: PropTypes.object.isRequired,
    submitCount: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
};

export default AppointmentServices;
