import { useCallback, useEffect, useReducer } from "react";

import { useAuthorizedFetchJson, useSession } from "@vesta/lib/react";

const useGetProfileOnboardingSessionV1 = () => {
  const [, jwtToken] = useSession();
  const [fetchJson, json] = useAuthorizedFetchJson(jwtToken);

  const getProfileOnboardingSessionV1 = useCallback(
    () =>
      fetchJson(
        new URL(
          "queries/get-profile-onboarding-session-v1",
          process.env.REACT_APP_API_BASE_URL
        )
      ),
    []
  );

  useEffect(() => {
    getProfileOnboardingSessionV1();
  }, []);

  return json;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "init": {
      const {
        created,
        currentStep,
        nbSteps,
        phoneNumberInE164Format,
        isPhoneNumberApproved,
      } = action.payload;

      return {
        ...action.payload,
        created: new Date(created),
        progress: ((currentStep + 1) / nbSteps) * 100,
        approvedPhoneNumberInE164Format: isPhoneNumberApproved
          ? phoneNumberInE164Format
          : null,
      };
    }
    case "go-to-previous-onboarding-step": {
      const currentStep = state.currentStep - 1;

      return {
        ...state,
        currentStep,
        progress: ((currentStep + 1) / state.nbSteps) * 100,
      };
    }
    case "go-to-next-onboarding-step": {
      const currentStep = state.currentStep + 1;

      return {
        ...state,
        currentStep,
        progress: ((currentStep + 1) / state.nbSteps) * 100,
      };
    }
    case "finish-onboarding-session": {
      return {
        ...state,
        isFinished: true,
      };
    }
    case "modify-contact-information": {
      const { phoneNumberInE164Format, hidePhoneNumber } = action.payload;

      return {
        ...state,
        phoneNumberInE164Format,
        isPhoneNumberApproved:
          phoneNumberInE164Format === state.approvedPhoneNumberInE164Format,
        hidePhoneNumber,
      };
    }
    case "verify-profile-phone-number": {
      const { isPhoneNumberApproved } = action.payload;

      return {
        ...state,
        isPhoneNumberApproved,
        approvedPhoneNumberInE164Format: isPhoneNumberApproved
          ? state.phoneNumberInE164Format
          : state.approvedPhoneNumberInE164Format,
      };
    }
    case "modify-service-languages": {
      const { serviceLanguageIds } = action.payload;

      return {
        ...state,
        serviceLanguageIds,
      };
    }
    case "create-territory-as-polygon": {
      const { id, name, polygonPoints } = action.payload;

      return {
        ...state,
        lastModifiedTerritory: {
          id,
          name,
          polygonPoints,
          shapeId: 2,
        },
      };
    }
    case "modify-territory-as-polygon": {
      const { id, name, polygonPoints } = action.payload;

      return {
        ...state,
        lastModifiedTerritory: {
          ...state.lastModifiedTerritory,
          id,
          name,
          polygonPoints,
          shapeId: 2,
        },
      };
    }
    case "bulk-create-services-from-service-templates-and-delete-services": {
      const { createdServices, deletedServices } = action.payload;

      return {
        ...state,
        services: [
          ...state.services.filter(
            (x) => !deletedServices.some((y) => y.id === x.id)
          ),
          ...createdServices,
        ],
      };
    }
    case "upload-profile-picture": {
      const { profilePictureId } = action.payload;

      return {
        ...state,
        uploadedProfilePictureId: profilePictureId,
      };
    }
    case "modify-profile-picture": {
      const { profilePictureId } = action.payload;

      return {
        ...state,
        profilePictureId,
      };
    }
    case "remove-profile-picture": {
      return {
        ...state,
        profilePictureId: null,
      };
    }
    case "submit-permit-and-photo-identification": {
      const { oiiqPermitNumber, oiiaqPermitNumber, photoIdentificationId } =
        action.payload;

      return {
        ...state,
        oiiqPermitNumber,
        oiiaqPermitNumber,
        photoIdentificationId,
      };
    }
    default:
      throw new Error(`The action type ${action.type} cannot be handled`);
  }
};

export const useOnboardingSessionV1Reducer = () => {
  const onboardingSessionV1 = useGetProfileOnboardingSessionV1();
  const [state, dispatch] = useReducer(reducer);

  useEffect(() => {
    if (onboardingSessionV1) {
      dispatch({ type: "init", payload: onboardingSessionV1 });
    }
  }, [onboardingSessionV1]);

  return [state, dispatch];
};
