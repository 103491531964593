import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { Avatar, Button, Grid, IconButton, Stack } from "@mui/material";
import { Add, Edit } from "@mui/icons-material";

import { TitledPaper } from "@vesta/components/molecules";
import { CropProfilePictureDialog } from "@vesta/components/organisms";
import { useUpSm } from "@vesta/lib/react";

import { ProfilePictureTipAlert } from "./components";

const ProfilePicture = ({ loading, biography, onSave }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("profile");
  const [imgSrc, setImgSrc] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [savedProfilePictureBlob, setSavedProfilePictureBlob] = useState();
  const [savedProfilePictureUrl, setSavedProfilePictureUrl] = useState();

  useEffect(() => {
    if (savedProfilePictureBlob) {
      const url = URL.createObjectURL(savedProfilePictureBlob);
      setSavedProfilePictureUrl(url);

      return () => URL.revokeObjectURL(url);
    }
  }, [savedProfilePictureBlob]);

  const handleFileChange = (event) => {
    const reader = new FileReader();
    reader.addEventListener("load", (loadEvent) => {
      setImgSrc(loadEvent.target.result);
      setOpenDialog(true);
    });
    reader.readAsDataURL(event.target.files[0]);
  };

  const actions = (
    <>
      <Stack
        sx={{ display: { xs: "none", sm: "block" } }}
        direction="row"
        spacing={1}
      >
        <input
          id="edit-profile-picture"
          style={{ display: "none" }}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
        />
        <label htmlFor="edit-profile-picture">
          <Button disabled={loading} component="span" variant="contained">
            {biography?.profilePictureId
              ? t("biography.profilePicture.actions.edit")
              : t("biography.profilePicture.actions.add")}
          </Button>
        </label>
      </Stack>
      <Stack
        sx={{ display: { xs: "block", sm: "none" } }}
        direction="row"
        spacing={1}
      >
        <input
          id="edit-profile-picture"
          style={{ display: "none" }}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
        />
        <label htmlFor="edit-profile-picture">
          <IconButton
            disabled={loading}
            aria-label={
              biography?.profilePictureId
                ? t("biography.profilePicture.actions.edit")
                : t("biography.profilePicture.actions.add")
            }
            component="span"
            color="primary"
            size="small"
          >
            {biography?.profilePictureId ? <Edit /> : <Add />}
          </IconButton>
        </label>
      </Stack>
    </>
  );

  const handleSave = async ({ blob }) => {
    const ok = await onSave({ blob });
    if (ok) {
      setSavedProfilePictureBlob(blob);
      setOpenDialog(false);
    }
  };

  return (
    <>
      <TitledPaper
        title={t("biography.profilePicture.title")}
        loading={loading}
        actions={actions}
      >
        <Grid container direction="row-reverse" spacing={upSm ? 3 : 2}>
          <Grid item xs={12} md={6} lg={7}>
            <ProfilePictureTipAlert />
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            item
            xs={12}
            md={6}
            lg={5}
          >
            {biography && (
              <Avatar
                sx={(theme) => ({
                  margin: theme.spacing(4),
                  height: theme.spacing(24),
                  width: theme.spacing(24),
                  [theme.breakpoints.up("sm")]: {
                    height: theme.spacing(32),
                    width: theme.spacing(32),
                  },
                  [theme.breakpoints.up("md")]: {
                    height: theme.spacing(40),
                    width: theme.spacing(40),
                  },
                })}
                src={
                  savedProfilePictureUrl ??
                  (biography.profilePictureId
                    ? new URL(
                        `/download-profile-picture/md/${biography.profilePictureId}`,
                        process.env.REACT_APP_API_BASE_URL
                      ).toString()
                    : null)
                }
              />
            )}
          </Grid>
        </Grid>
      </TitledPaper>
      <CropProfilePictureDialog
        open={openDialog}
        imgSrc={imgSrc}
        onSave={handleSave}
        onCancel={() => setOpenDialog(false)}
      />
    </>
  );
};

ProfilePicture.propTypes = {
  loading: PropTypes.bool,
  biography: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};

export default ProfilePicture;
