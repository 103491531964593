import { startOfHour } from "date-fns";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Paper } from "@mui/material";

import { StyledCalendar } from "@vesta/components/molecules";
import { useSession, useSessionStorage } from "@vesta/lib/react";

import {
  AddAppointmentFloatingButton,
  CreateAppointmentDialog,
  Event,
} from "./components";
import { useBackgroundEvents, useEvents } from "./hooks";

const Appointments = () => {
  const navigate = useNavigate();
  const [, jwtToken] = useSession();
  const [date, setDate] = useSessionStorage("vesta-date", new Date());
  const [creating, setCreating] = useState(null);

  const [events, loadingEvents] = useEvents(date, jwtToken);
  const [backgroundEvents, loadingBackgroundEvents] = useBackgroundEvents(
    date,
    jwtToken
  );

  const handleSelecting = () => false; // To disable drag selection

  const handleSelectSlot = ({ start }) => setCreating({ start });

  const handleFloatingButtonClick = () =>
    setCreating({ start: startOfHour(new Date(date)) });

  const handleCreate = (appointmentId) =>
    navigate(`/calendar/appointments/${appointmentId}/preparation?created`);

  return (
    <Paper
      sx={(theme) => ({
        padding: theme.spacing(2),
        marginBottom: theme.spacing(5),
        [theme.breakpoints.up("sm")]: {
          padding: theme.spacing(4),
          paddingTop: theme.spacing(2),
          marginBottom: theme.spacing(7),
        },
        [theme.breakpoints.up("md")]: {
          marginBottom: theme.spacing(6),
        },
      })}
      component="section"
    >
      <StyledCalendar
        loading={loadingEvents || loadingBackgroundEvents}
        events={events}
        backgroundEvents={backgroundEvents}
        selectable="ignoreEvents"
        onSelecting={handleSelecting}
        onSelectSlot={handleSelectSlot}
        date={new Date(date)}
        onNavigate={setDate}
        components={{
          event: (props) => <Event {...props} />,
          eventWrapper: ({ event, children }) => (
            <Box
              sx={[
                (theme) => ({
                  "& .rbc-event": {
                    backgroundColor: `${theme.palette.primary.dark} !important`,
                    border: `0.1px solid ${theme.palette.primary.contrastText} !important`,
                    ...(event.cancelled
                      ? {
                          backgroundColor: `${theme.palette.error.dark} !important`,
                          border: `0.1px solid ${theme.palette.error.contrastText} !important`,
                        }
                      : {}),
                  },
                  "& .rbc-background-event": {
                    backgroundColor: `${theme.palette.secondary.main} !important`,
                    opacity: "0.6 !important",
                    border: "none !important",
                    borderRadius: "0 !important",
                    cursor: "default !important",
                  },
                }),
              ]}
            >
              {children}
            </Box>
          ),
        }}
      />
      <Box
        sx={(theme) => ({
          height: theme.spacing(7),
          [theme.breakpoints.up("md")]: {
            height: theme.spacing(11),
          },
        })}
      />
      <AddAppointmentFloatingButton
        sx={(theme) => ({
          position: "fixed",
          zIndex: 4,
          right: theme.spacing(2),
          bottom: theme.spacing(9),
          [theme.breakpoints.up("md")]: {
            bottom: theme.spacing(4),
          },
        })}
        onClick={handleFloatingButtonClick}
      />
      <CreateAppointmentDialog
        open={!!creating}
        initialStart={creating?.start}
        onCancel={() => setCreating(null)}
        onCreate={handleCreate}
      />
    </Paper>
  );
};

export default Appointments;
