import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import { MobileDatePicker, TimeSelect } from "@vesta/components/atoms";

const AppointmentTime = ({
  formik: {
    values: { start },
    setFieldValue,
  },
}) => {
  const { t } = useTranslation("components");

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h3">
          {t("molecules.appointmentTime.title")}
        </Typography>
      </Grid>
      <Grid item>
        <MobileDatePicker
          label={t("molecules.appointmentTime.startDateLabel")}
          value={start}
          onChange={(value) => setFieldValue("start", value)}
        />
      </Grid>
      <Grid item>
        <TimeSelect
          sx={(theme) => ({ minWidth: theme.spacing(10) })}
          id="appointment-start"
          label={t("molecules.appointmentTime.startTimeLabel")}
          value={start}
          onChange={(value) => setFieldValue("start", value)}
        />
      </Grid>
    </Grid>
  );
};

AppointmentTime.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      start: PropTypes.instanceOf(Date),
    }).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
};

export default AppointmentTime;
