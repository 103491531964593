import { addWeeks, startOfWeek } from "date-fns";
import { useCallback, useEffect, useState } from "react";

import { joinOverlappingEvents } from "@vesta/lib/utils";

const weekStartsOn = 1; // Monday

export const useEvents = (date, jwtToken) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const getEvents = useCallback(async () => {
    const start = startOfWeek(new Date(date), { weekStartsOn });
    const end = startOfWeek(addWeeks(new Date(date), 1), { weekStartsOn });

    const url = new URL(
      `/queries/get-calendar-appointments-between-two-dates?` +
        new URLSearchParams({
          start: start.toISOString(),
          end: end.toISOString(),
        }),
      process.env.REACT_APP_API_BASE_URL
    );

    setLoading(true);

    const response = await fetch(url, {
      headers: { Authorization: `Bearer ${jwtToken}` },
    });

    setLoading(false);

    if (!response.ok) {
      throw new Error("An error occurred while getting appointments");
    }

    const events = (await response.json())
      .filter((x) => !x.isDeleted)
      .map((x) => ({
        appointmentId: x.appointmentId,
        patientFirstName: x.patientFirstName,
        patientLastName: x.patientLastName,
        isAppointmentForSomeoneElse: x.isAppointmentForSomeoneElse,
        someoneElse: x.someoneElse,
        start: new Date(x.start),
        end: new Date(x.end),
        cancelled: x.cancelled ? new Date(x.cancelled) : null,
      }));

    setEvents(events);
  }, [date, jwtToken]);

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  return [events, loading];
};

export const useBackgroundEvents = (date, jwtToken) => {
  const [backgroundEvents, setBackgroundEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const getBackgroundEvents = useCallback(async () => {
    const start = startOfWeek(new Date(date), { weekStartsOn });
    const end = startOfWeek(addWeeks(new Date(date), 1), { weekStartsOn });

    const url = new URL(
      `/queries/get-calendar-availabilities-between-two-dates?` +
        new URLSearchParams({
          start: start.toISOString(),
          end: end.toISOString(),
        }),
      process.env.REACT_APP_API_BASE_URL
    );

    setLoading(true);

    const response = await fetch(url, {
      headers: { Authorization: `Bearer ${jwtToken}` },
    });

    setLoading(false);

    if (!response.ok) {
      throw new Error("An error occurred while getting availabilities");
    }

    const backgroundEvents = (await response.json())
      .filter((x) => !x.isDeleted)
      .map((x) => ({
        isBackgroundEvent: true,
        start: new Date(x.start),
        end: new Date(x.end),
      }))
      .sort((a, b) => a.start - b.start);

    setBackgroundEvents(joinOverlappingEvents(backgroundEvents));
  }, [date, jwtToken]);

  useEffect(() => {
    getBackgroundEvents();
  }, [getBackgroundEvents]);

  return [backgroundEvents, loading];
};
