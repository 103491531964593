import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Navigate, Views } from "react-big-calendar";

import {
  Box,
  Button,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Today,
} from "@mui/icons-material";

import { useUpSm } from "@vesta/lib/react";

const CalendarToolbar = ({ sx, title, view, onNavigate, onView }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("components");

  const handleToday = () => onNavigate(Navigate.TODAY);
  const handlePrevious = () => onNavigate(Navigate.PREVIOUS);
  const handleNext = () => onNavigate(Navigate.NEXT);
  const handleViewChange = (_, newView) => onView(newView);

  return (
    <Grid
      sx={sx}
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      wrap="nowrap"
    >
      <Grid item>
        <IconButton
          sx={{ display: { xs: "block", sm: "none" } }}
          aria-label={t("molecules.calendarToolbar.today")}
          edge="start"
          size="small"
          onClick={handleToday}
        >
          <Today />
        </IconButton>
        <Box sx={{ display: { xs: "none", sm: "flex" } }}>
          <Button
            sx={(theme) => ({
              marginRight: theme.spacing(3 / 2),
            })}
            color="info"
            size="small"
            variant="outlined"
            onClick={handleToday}
          >
            {t("molecules.calendarToolbar.today")}
          </Button>
          <ToggleButtonGroup
            aria-label={t("molecules.calendarToolbar.view")}
            color="primary"
            exclusive
            size="small"
            value={view}
            onChange={handleViewChange}
          >
            <ToggleButton value={Views.DAY}>
              {t("molecules.calendarToolbar.dayView")}
            </ToggleButton>
            <ToggleButton value={Views.WEEK}>
              {t("molecules.calendarToolbar.weekView")}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={upSm ? 1 : 0}
        >
          <Grid item>{title}</Grid>
          <Grid item>
            <IconButton
              sx={(theme) => ({
                marginRight: theme.spacing(1),
              })}
              size="small"
              aria-label={t("molecules.calendarToolbar.prevAriaLabel")}
              onClick={handlePrevious}
            >
              <ArrowBackIosOutlined />
            </IconButton>
            <IconButton
              edge="end"
              size="small"
              aria-label={t("molecules.calendarToolbar.nextAriaLabel")}
              onClick={handleNext}
            >
              <ArrowForwardIosOutlined />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

CalendarToolbar.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  title: PropTypes.node.isRequired,
  view: PropTypes.string,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
};

export default CalendarToolbar;
