import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Alert, AlertTitle, Box, Grid, Typography } from "@mui/material";
import { EmojiObjectsOutlined } from "@mui/icons-material";

import { ContactUsLink, ErrorAlert } from "@vesta/components/atoms";
import { AddNewServiceCard } from "@vesta/components/molecules";
import {
  BodyWithTips,
  MinimalNavigationHeader,
} from "@vesta/components/organisms";
import {
  useAppContext,
  useAuthorizedFetchJson,
  useSession,
} from "@vesta/lib/react";

const useServiceTemplates = (jwtToken) => {
  const [fetchJson, json, loading] = useAuthorizedFetchJson(jwtToken);

  useEffect(() => {
    fetchJson(
      new URL(
        "/queries/get-service-templates",
        process.env.REACT_APP_API_BASE_URL
      )
    );
  }, []);

  return [json?.filter((x) => !x.isDeleted) ?? [], loading];
};

const AddServiceView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("add-service");
  const [, jwtToken] = useSession();
  const [, dispatch] = useAppContext();
  const [serviceTemplates, loading] = useServiceTemplates(jwtToken);
  const [, setCreating] = useState(false);
  const [createServiceError, setCreateServiceError] = useState(false);

  const loadingState = (
    <>
      <Grid item xs={12}>
        <AddNewServiceCard loading />
      </Grid>
      <Grid item xs={12}>
        <AddNewServiceCard loading />
      </Grid>
    </>
  );

  const handleClick = (serviceTemplateId) => async () => {
    setCreating(true);

    const url = new URL(
      `/commands/create-service-from-service-template/${serviceTemplateId}`,
      process.env.REACT_APP_API_BASE_URL
    );

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    if (response.ok) {
      const { serviceId } = await response.json();
      dispatch({ type: "create-service" });
      navigate(`/profile/services/${serviceId}?added`);
    } else {
      setCreating(false);
      setCreateServiceError(true);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column",
        height: "100vh",
      }}
    >
      <Helmet>
        <title>{t("title")}</title>
        <meta property="og:title" content={t("title")} />
        <meta property="og:description" content={t("description")} />
      </Helmet>
      <MinimalNavigationHeader
        backTo="/profile/services"
        backLabel={t("backLabel")}
      />
      <BodyWithTips
        progressValue={0}
        tips={[
          <Alert
            key="tip"
            sx={{ whiteSpace: "pre-line" }}
            component="aside"
            icon={<EmojiObjectsOutlined fontSize="inherit" />}
            severity="info"
          >
            <AlertTitle>{t("tip.title")}</AlertTitle>
            {t("tip.text")}
          </Alert>,
        ]}
      >
        <Grid container direction="column" spacing={4} data-aos="fade-up">
          <Grid item>
            <Typography gutterBottom variant="h1">
              {t("step1.title")}
            </Typography>
            <Typography>
              <Trans
                i18nKey="add-service:step1.subtitle"
                components={{
                  contactUsLink: (
                    <ContactUsLink color="primary" variant="body1" />
                  ),
                }}
              />
            </Typography>
          </Grid>
          <Grid container component="section" spacing={2} item>
            {loading
              ? loadingState
              : serviceTemplates
                  .sort((a, b) => a.nameFr.localeCompare(b.nameFr, "fr"))
                  .map((serviceTemplate) => (
                    <Grid key={serviceTemplate.serviceTemplateId} item xs={12}>
                      <AddNewServiceCard
                        serviceTemplate={serviceTemplate}
                        onClick={handleClick(serviceTemplate.serviceTemplateId)}
                      />
                    </Grid>
                  ))}
          </Grid>
        </Grid>
      </BodyWithTips>
      <ErrorAlert
        open={createServiceError}
        onClose={() => setCreateServiceError(false)}
        content={
          <Trans
            i18nKey="add-service:alerts.createServiceError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
    </Box>
  );
};

export default AddServiceView;
