import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ELEMENT_IMAGE,
  getPluginType,
  insertNodes,
  ToolbarButton,
  usePlateEditorRef,
} from "@udecode/plate";

import { ErrorAlert } from "@vesta/components/atoms";
import { useCompressor, useSession } from "@vesta/lib/react";

const maxFileSize = 16777216; // 16 MB

const ImageToolbarButton = ({ ...props }) => {
  const compress = useCompressor({ quality: 0.4 });
  const editor = usePlateEditorRef();
  const inputRef = useRef(null);
  const { t } = useTranslation("components");
  const [, jwtToken] = useSession();
  const [fileSizeTooLargeError, setFileSizeTooLargeError] = useState(false);

  const handleChange = async (event) => {
    const file = event.target.files[0];
    if (file.size > maxFileSize) {
      setFileSizeTooLargeError(true);
      return;
    }

    const url = new URL("upload-image", process.env.REACT_APP_API_BASE_URL);

    const compressedFile = await compress(file);

    const formData = new FormData();
    formData.append("image", compressedFile, compressedFile.name);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      body: formData,
    });

    if (response.ok) {
      const { imageId } = await response.json();

      const imageUrl = new URL(
        `download-image/${imageId}`,
        process.env.REACT_APP_API_BASE_URL
      ).toString();

      const img = new Image();
      img.onload = () => {
        const text = { text: "" };
        const image = {
          type: getPluginType(editor, ELEMENT_IMAGE),
          url: imageUrl,
          width: img.width < 320 ? img.width : undefined,
          children: [text],
        };

        insertNodes(editor, image);
      };

      img.src = imageUrl;
    }
  };

  return (
    <>
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={handleChange}
      />
      <ToolbarButton onMouseDown={() => inputRef.current.click()} {...props} />
      <ErrorAlert
        autoHide
        open={fileSizeTooLargeError}
        onClose={() => setFileSizeTooLargeError(false)}
        content={t(
          "molecules.richTextEditorToolbar.imageToolbarButton.fileSizeTooLargeError"
        )}
      />
    </>
  );
};

export default ImageToolbarButton;
