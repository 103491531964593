import PropTypes from "prop-types";
import { useState } from "react";

import { styled } from "@mui/material/styles";

import { File } from "@vesta/components/molecules";

const StyledImg = styled("img")({
  objectFit: "cover",
  objectPosition: "center top",
});

const PdfFile = ({ resultFile, canDelete, onDelete }) => {
  const [isPdfPreviewLoaded, setIsPdfPreviewLoaded] = useState(false);

  const src = new URL(
    `/download-result-file/${resultFile.resultFileId}`,
    process.env.REACT_APP_API_BASE_URL
  ).toString();

  const pdfPreviewSrc = new URL(
    `/download-result-file-pdf-preview/${resultFile.resultFileId}`,
    process.env.REACT_APP_API_BASE_URL
  ).toString();

  const handleLoad = (e) =>
    setIsPdfPreviewLoaded(e.target.src === pdfPreviewSrc);

  const handleError = (e) => {
    e.target.src = "/default-result-file-pdf-preview.png";
    setTimeout(() => {
      e.target.src = pdfPreviewSrc;
    }, 2000);
  };

  return (
    <File
      src={src}
      name={resultFile.name}
      uploading={resultFile.uploading}
      uploadingProgress={resultFile.uploadingProgress}
      canDelete={canDelete}
      onDelete={onDelete}
      renderImage={({ dimensionsSx }) => (
        <StyledImg
          sx={[
            isPdfPreviewLoaded &&
              ((theme) => ({
                borderTopLeftRadius: theme.spacing(1),
                borderTopRightRadius: theme.spacing(1),
                borderTop: `1px solid ${theme.palette.action.active}`,
                borderLeft: `1px solid ${theme.palette.action.active}`,
                borderRight: `1px solid ${theme.palette.action.active}`,
              })),
            dimensionsSx,
          ]}
          src={pdfPreviewSrc}
          alt={resultFile.name}
          loading="lazy"
          onLoad={handleLoad}
          onError={handleError}
        />
      )}
    />
  );
};

PdfFile.propTypes = {
  resultFile: PropTypes.shape({
    resultFileId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    uploading: PropTypes.bool,
    uploadingProgress: PropTypes.number,
  }),
  canDelete: PropTypes.bool,
  onDelete: PropTypes.func,
};

export default PdfFile;
