import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { Button, Grid, Typography } from "@mui/material";

import { LoadingButton } from "@vesta/components/atoms";
import { TerritoryNameTextField } from "@vesta/components/molecules";

const Step2 = ({ onBack, onSubmit }) => {
  const { t } = useTranslation("add-territory");

  const validationSchema = yup.object().shape({
    name: yup.string().required(t("step2.name.required")),
  });

  const {
    values,
    errors,
    isSubmitting,
    submitCount,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema,
    onSubmit,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" spacing={4} data-aos="fade-up">
        <Grid item>
          <Typography variant="h1">{t("step2.title")}</Typography>
        </Grid>
        <Grid item>
          <TerritoryNameTextField
            id="name"
            sx={(theme) => ({
              [theme.breakpoints.up("sm")]: {
                maxWidth: theme.spacing(80),
              },
            })}
            value={values.name}
            onChange={handleChange}
            error={submitCount > 0 && Boolean(errors.name)}
            helperText={submitCount > 0 && errors.name}
          />
        </Grid>
        <Grid container justifyContent="space-between" item>
          <Grid item>
            <Button disabled={isSubmitting} onClick={onBack} variant="outlined">
              {t("step2.back")}
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              variant="contained"
            >
              {t("step2.submit")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

Step2.propTypes = {
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Step2;
