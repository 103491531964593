import PropTypes from "prop-types";
import { forwardRef } from "react";
import { IMaskInput } from "react-imask";

const RamqHealthInsuranceNumberMask = forwardRef(
  ({ onChange, id, name, ...rest }, ref) => (
    <IMaskInput
      {...rest}
      id={id}
      name={name}
      mask="AAAA 1111 1111"
      definitions={{
        A: /[a-zA-Z]/,
        1: /[0-9]/,
      }}
      inputRef={ref}
      onChange={(e) =>
        onChange({ target: { id, name, value: e.target.value.toUpperCase() } })
      }
      onAccept={(value) =>
        onChange({ target: { id, name, value: value.toUpperCase() } })
      }
      overwrite
    />
  )
);

RamqHealthInsuranceNumberMask.displayName = "RamqHealthInsuranceNumberMask";

RamqHealthInsuranceNumberMask.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default RamqHealthInsuranceNumberMask;
