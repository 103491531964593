import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import { PlacesAutocomplete } from "@vesta/components/atoms";
import {
  AddressTextField,
  ApartmentUnitSuiteOrFloorNbTextField,
} from "@vesta/components/molecules";

const AppointmentAddress = ({
  sessionToken,
  formik: {
    values: { place, apartmentUnitSuiteOrFloorNb },
    errors,
    submitCount,
    handleChange,
    setFieldValue,
  },
}) => {
  const { t } = useTranslation("components");

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h3">
          {t("molecules.appointmentAddress.title")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <PlacesAutocomplete
          sessionToken={sessionToken}
          value={place}
          onChange={(_, newValue) => setFieldValue("place", newValue)}
          renderInput={(params) => (
            <AddressTextField
              error={submitCount > 0 && Boolean(errors.place)}
              helperText={submitCount > 0 ? errors.place : null}
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // Workaround to avoid autofill in Chrome: https://mui.com/components/autocomplete/#autocomplete-autofill
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ApartmentUnitSuiteOrFloorNbTextField
          id="apartmentUnitSuiteOrFloorNb"
          value={apartmentUnitSuiteOrFloorNb}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};

AppointmentAddress.propTypes = {
  sessionToken: PropTypes.string.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      place: PropTypes.object,
      apartmentUnitSuiteOrFloorNb: PropTypes.string,
    }).isRequired,
    errors: PropTypes.object.isRequired,
    submitCount: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
};

export default AppointmentAddress;
