import { fr } from "date-fns/locale";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import {
  Alert,
  Link as MuiLink,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Check } from "@mui/icons-material";

import { useMedicalRecord } from "@vesta/lib/react";

const AppointmentAlert = ({ sx, appointmentId }) => {
  const [medicalRecord] = useMedicalRecord();

  const appointment = medicalRecord.medicalInformation.appointments.find(
    (x) => x.appointmentId === appointmentId
  );

  return (
    <Alert sx={sx} component="aside" severity="info" variant="outlined">
      <Trans
        i18nKey="patient:notes.noteCard.appointmentAlert.text"
        components={{
          appointmentLink: (
            <MuiLink
              component={Link}
              underline="always"
              variant="body2"
              to={`/calendar/appointments/${appointmentId}/preparation`}
            />
          ),
        }}
        values={{
          date: format(appointment.start, "PPP", { locale: fr }),
          firstName: medicalRecord.personalInformation.firstName,
          count: appointment.services.length,
        }}
      />
      {appointment.services.length > 1 && (
        <List dense>
          {appointment.services.map((x) => (
            <ListItem key={x.serviceId} disableGutters>
              <ListItemIcon
                sx={(theme) => ({
                  minWidth: "auto",
                  marginRight: theme.spacing(2),
                })}
              >
                <Check />
              </ListItemIcon>
              <ListItemText primary={x.nameFr} />
            </ListItem>
          ))}
        </List>
      )}
    </Alert>
  );
};

AppointmentAlert.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  appointmentId: PropTypes.string.isRequired,
};

export default AppointmentAlert;
