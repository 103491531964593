import PropTypes from "prop-types";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Alert, Button, Grid } from "@mui/material";

import {
  ContactUsLink,
  ErrorAlert,
  LoadingButton,
} from "@vesta/components/atoms";
import { Dialog } from "@vesta/components/molecules";
import { ResultFiles } from "@vesta/components/organisms";
import { getResultFileTypeId } from "@vesta/lib/enum";
import { useCompressor, useSession } from "@vesta/lib/react";

import { useAddResultToPatientMedicalRecord } from "./hooks";

const maxFileSize = 16777216; // 16 MB

const AddResultDialog = ({ open, onCancel, onAdd }) => {
  const compress = useCompressor({ quality: 0.4 });
  const { t } = useTranslation("patient");
  const [, jwtToken] = useSession();
  const [resultFiles, setResultFiles] = useState([]);
  const [resultFileSizeTooLargeError, setResultFilesizeTooLargeError] =
    useState(false);
  const [openUploadError, setOpenUploadError] = useState(false);
  const [openAddResultError, setOpenAddResultError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const addResultToPatientMedicalRecord =
    useAddResultToPatientMedicalRecord(jwtToken);

  const handleAddResultFiles = async (event) => {
    const files = [...event.target.files];
    if (files.some((x) => x.size > maxFileSize)) {
      setResultFilesizeTooLargeError(true);
      return;
    }

    setResultFiles((prevValue) => [
      ...prevValue,
      ...files.map((x) => ({
        resultFileId: x.name,
        name: x.name,
        typeId: getResultFileTypeId(x.type),
        created: new Date(),
        uploading: true,
        uploadingProgress: 0,
      })),
    ]);

    const compressedFiles = await Promise.all(
      files.map(async (x) =>
        x.type.startsWith("image/") ? await compress(x) : x
      )
    );

    await Promise.all(
      compressedFiles.map(
        async (x) =>
          new Promise((resolve, reject) => {
            const url = new URL(
              "upload-result-file",
              process.env.REACT_APP_API_BASE_URL
            );

            const request = new XMLHttpRequest();
            request.open("POST", url);
            request.setRequestHeader("Authorization", `Bearer ${jwtToken}`);

            // The fetch API does not support progress as of this writing, which is why we use XMLHttpRequest
            request.upload.addEventListener("progress", (event) => {
              const uploadingProgress = Math.round(
                (event.loaded / event.total) * 100
              );
              setResultFiles((prevValue) => [
                ...prevValue.map((y) =>
                  y.resultFileId === x.name
                    ? {
                        ...y,
                        uploadingProgress,
                      }
                    : { ...y }
                ),
              ]);
            });

            request.addEventListener("readystatechange", () => {
              if (request.readyState !== 4) {
                return;
              }
              if (request.status === 200) {
                const { resultFileId } = JSON.parse(request.responseText);
                setResultFiles((prevValue) => [
                  ...prevValue.map((y) =>
                    y.resultFileId === x.name
                      ? {
                          ...y,
                          resultFileId,
                          uploading: false,
                        }
                      : { ...y }
                  ),
                ]);
                resolve();
              } else {
                setOpenUploadError(true);
                setResultFiles((prevValue) => [
                  ...prevValue.filter((y) => y.resultFileId !== x.name),
                ]);
                reject();
              }
            });

            const formData = new FormData();
            formData.append("result-file", x, x.name);

            request.send(formData);
          })
      )
    );
  };

  const handleDeleteResultFile = (resultFileId) =>
    setResultFiles((prevValue) => [
      ...prevValue.filter((x) => x.resultFileId !== resultFileId),
    ]);

  const uploadedResultFiles = resultFiles.filter((x) => !x.uploading);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);

    const response = await addResultToPatientMedicalRecord(uploadedResultFiles);

    if (response.ok) {
      const { resultId } = await response.json();
      onAdd({ resultId, files: uploadedResultFiles });
    } else {
      setOpenAddResultError(true);
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={onCancel}
        title={t("results.addResultDialog.title")}
        content={
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Alert component="aside" severity="info">
                {t("results.addResultDialog.alert.text")}
              </Alert>
            </Grid>
            <Grid item>
              <ResultFiles
                files={resultFiles}
                onAddResultFiles={handleAddResultFiles}
                onDeleteResultFile={handleDeleteResultFile}
              />
            </Grid>
          </Grid>
        }
        actions={
          <>
            <Button onClick={onCancel} size="large" variant="outlined">
              {t("results.addResultDialog.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              disabled={
                isSubmitting ||
                resultFiles.length === 0 ||
                resultFiles.some((x) => x.uploading)
              }
              onClick={handleSubmit}
              type="submit"
              size="large"
              variant="contained"
            >
              {t("results.addResultDialog.add")}
            </LoadingButton>
          </>
        }
        TransitionProps={{
          onExit: () => setResultFiles([]),
        }}
      />
      <ErrorAlert
        autoHide
        open={resultFileSizeTooLargeError}
        onClose={() => setResultFilesizeTooLargeError(false)}
        content={t("results.addResultDialog.resultFileSizeTooLargeError")}
      />
      <ErrorAlert
        open={openUploadError}
        onClose={() => setOpenUploadError(false)}
        content={
          <Trans
            i18nKey="patient:results.addResultDialog.uploadError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink color="inherit" variant="body2" />,
            }}
          />
        }
      />
      <ErrorAlert
        open={openAddResultError}
        onClose={() => setOpenAddResultError(false)}
        content={
          <Trans
            i18nKey="patient:results.addResultDialog.addResultError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
    </>
  );
};

AddResultDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default AddResultDialog;
