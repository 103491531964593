import { useState } from "react";
import { PropTypes } from "prop-types";
import CopyToClipboard from "react-copy-to-clipboard";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  Box,
  IconButton,
  InputAdornment,
  Link as MuiLink,
  TextField,
  Tooltip,
} from "@mui/material";

import { Email, Facebook, FileCopy } from "@mui/icons-material";

import { SuccessAlert } from "@vesta/components/atoms";
import { Dialog } from "@vesta/components/molecules";
import { useSession, useUpSm } from "@vesta/lib/react";

const ShareProfileDialog = ({ open, onClose }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("global-modals");
  const [session] = useSession();
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  if (!session) {
    return null;
  }

  const profileFullName = `${session.given_name} ${session.family_name}`;
  const profileUrl = (utmSource) =>
    new URL(
      `/profile/${session.nameid || ""}?` +
        new URLSearchParams({
          utm_source: utmSource,
          utm_content: "share-profile-dialog",
        }),
      process.env.REACT_APP_WEB_BASE_URL
    ).toString();

  const facebookUrl = profileUrl("facebook");
  const emailUrl = profileUrl("email");
  const copyToClipboardUrl = profileUrl("link");

  const emailSubject = `subject=${t("shareProfileDialog.emailSubject", {
    profileFullName,
  })}`;

  const emailBody = `body=${t("shareProfileDialog.emailBody", {
    profileFullName: profileFullName,
    profileUrl: encodeURIComponent(emailUrl),
  })}`;

  const handleCopy = () => {
    setCopiedToClipboard(true);
  };

  const handleOnClose = () => {
    setCopiedToClipboard(false);
    onClose();
  };

  const copyToClipboard = (
    <CopyToClipboard text={copyToClipboardUrl} onCopy={handleCopy}>
      <Tooltip
        arrow
        placement="bottom"
        title={t("shareProfileDialog.copyToClipboardLabel")}
      >
        <IconButton
          color="primary"
          aria-label={t("shareProfileDialog.copyToClipboardLabel")}
          size="large"
        >
          <FileCopy fontSize="large" />
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  );

  return (
    <Dialog
      fullScreen={false}
      maxWidth="md"
      open={open}
      onClose={handleOnClose}
      title={t("shareProfileDialog.title")}
      text={
        <Trans
          i18nKey="global-modals:shareProfileDialog.description"
          components={{
            availabilitiesLink: (
              <MuiLink
                component={Link}
                to="/calendar/availabilities"
                underline="always"
                onClick={handleOnClose}
              />
            ),
          }}
        />
      }
      content={
        <>
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              marginTop: theme.spacing(4),
            })}
          >
            <IconButton
              color="primary"
              edge="start"
              aria-label={t("shareProfileDialog.facebookLabel")}
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                facebookUrl
              )}`}
              target="_blank"
              size="large"
            >
              <Tooltip
                arrow
                placement="bottom"
                title={t("shareProfileDialog.facebookLabel")}
              >
                <Facebook fontSize="large" />
              </Tooltip>
            </IconButton>
            <IconButton
              color="primary"
              aria-label={t("shareProfileDialog.emailLabel")}
              href={`mailto:?${emailSubject}&${emailBody}`}
              target="_blank"
              size="large"
            >
              <Tooltip
                arrow
                placement="bottom"
                title={t("shareProfileDialog.emailLabel")}
              >
                <Email fontSize="large" />
              </Tooltip>
            </IconButton>
            {upSm ? (
              <TextField
                sx={(theme) => ({
                  flex: 1,
                  marginLeft: theme.spacing(2),
                })}
                defaultValue={copyToClipboardUrl}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      {copyToClipboard}
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              copyToClipboard
            )}
          </Box>
          <SuccessAlert
            open={copiedToClipboard}
            onClose={() => setCopiedToClipboard(false)}
            content={t("shareProfileDialog.copiedToClipboard")}
          />
        </>
      }
    />
  );
};

ShareProfileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ShareProfileDialog;
