import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

import { useSpokenLanguageTranslation } from "@vesta/lib/react";
import { spokenLanguageIds } from "@vesta/lib/utils";

const SpokenLanguageIdsSelect = ({
  id,
  className,
  readOnly,
  values,
  onChange,
  error,
  helperText,
  ...rest
}) => {
  const spokenLanguageTranslation = useSpokenLanguageTranslation();
  const { t } = useTranslation("components");

  return (
    <FormControl
      variant="standard"
      className={className}
      fullWidth
      error={error}
      {...rest}
    >
      <InputLabel id="spokenLanguageIdsLabel" shrink>
        {t("molecules.spokenLanguageIdsSelect.label")}
      </InputLabel>
      <Select
        variant="standard"
        id={id}
        labelId="spokenLanguageIdsLabel"
        multiple
        value={values}
        onChange={onChange}
        renderValue={(selectedSpokenLanguageIds) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {selectedSpokenLanguageIds.map((id) => (
              <Chip
                key={id}
                sx={(theme) => ({
                  marginY: theme.spacing(1 / 4),
                  marginRight: theme.spacing(1 / 2),
                })}
                label={spokenLanguageTranslation[id]}
                size="small"
              />
            ))}
          </Box>
        )}
        IconComponent={(props) =>
          readOnly ? null : <ArrowDropDown {...props} />
        }
        inputProps={{
          readOnly,
        }}
        MenuProps={{
          sx: (theme) => ({
            maxHeight: `calc(100% - ${theme.spacing(30)})`,
          }),
        }}
      >
        {spokenLanguageIds.map((id) => (
          <MenuItem
            key={id}
            value={id}
            sx={(theme) => ({
              fontWeight:
                values.indexOf(id) === -1
                  ? theme.typography.fontWeightRegular
                  : theme.typography.fontWeightBold,
            })}
          >
            {spokenLanguageTranslation[id]}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

SpokenLanguageIdsSelect.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default SpokenLanguageIdsSelect;
