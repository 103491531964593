import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { light } from "./palette";

export const getTheme = () => {
  const theme = createTheme({
    palette: light,
    layout: {
      contentWidth: 1236,
    },
    components: {
      MuiBadge: {
        styleOverrides: {
          anchorOriginTopRightRectangular: {
            right: "-8px",
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: "16px 24px",
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: "16px 24px",
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            wordBreak: "break-word",
          },
        },
      },
      MuiStepButton: {
        styleOverrides: {
          vertical: {
            textAlign: "left",
          },
        },
      },
      MuiStepper: {
        styleOverrides: {
          root: {
            paddingTop: "24px",
            paddingBottom: "0",
            paddingLeft: "0",
            paddingRight: "0",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            padding: "6px 32px",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: 600,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: "4px",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "12px",
          },
        },
      },
      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: true,
        },
      },
    },
    typography: {
      h1: {
        fontFamily: "Alegreya Sans SC",
        fontSize: 60,
        fontWeight: "700",
      },
      h2: {
        fontFamily: "Alegreya Sans SC",
        fontSize: 48,
        fontWeight: "700",
      },
      h3: {
        fontFamily: "Alegreya Sans SC",
        fontSize: 36,
        fontWeight: "700",
      },
      h4: {
        fontFamily: "Alegreya Sans SC",
        fontSize: 32,
        fontWeight: "700",
      },
      h5: {
        fontFamily: "Alegreya Sans SC",
        fontSize: 24,
        fontWeight: "700",
      },
      h6: {
        fontFamily: "Alegreya Sans SC",
        fontSize: 18,
        fontWeight: "700",
      },
      subtitle1: {
        fontFamily: "Open Sans",
        fontSize: 24,
        fontWeight: "400",
      },
      subtitle2: {
        fontFamily: "Open Sans",
        fontSize: 20,
        fontWeight: "400",
      },
      body1: {
        fontFamily: "Open Sans",
        fontSize: 18,
        fontWeight: "400",
      },
      body2: {
        fontFamily: "Open Sans",
        fontSize: 16,
        fontWeight: "400",
      },
      button: {
        fontFamily: "Open Sans",
        fontSize: 16,
        fontWeight: "600",
        textTransform: "none",
        letterSpacing: 0.1,
      },
      caption: {
        fontFamily: "Open Sans",
        fontSize: 14,
        fontWeight: "400",
      },
      overline: {
        fontFamily: "Open Sans",
        fontSize: 12,
        fontWeight: "400",
      },
    },
  });

  return responsiveFontSizes(theme);
};

export * as palette from "./palette";
