import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { DesktopDatePicker } from "@vesta/components/atoms";

const BirthdayDatePicker = ({ className, onChange, ...rest }) => {
  const { t } = useTranslation("components");

  return (
    <DesktopDatePicker
      autoComplete="bday"
      className={className}
      disableFuture
      label={t("molecules.birthdayDatePicker.label")}
      onChange={onChange}
      {...rest}
    />
  );
};

BirthdayDatePicker.defaultProps = {
  onChange: () => {},
};

BirthdayDatePicker.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default BirthdayDatePicker;
