import { fr } from "date-fns/locale";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { recurrenceFrequency } from "@vesta/lib/enum";
import { useUpSm } from "@vesta/lib/react";

const AvailabilityRecurrenceFrequency = ({
  id,
  disabled,
  start,
  recurrenceFrequencyId,
  onRecurrenceFrequencyIdChange,
}) => {
  const upSm = useUpSm();
  const { t } = useTranslation("components");

  return (
    start && (
      <FormControl
        sx={(theme) => ({
          width: "100%",
          maxWidth: theme.spacing(40),
        })}
        variant="standard"
        disabled={disabled}
      >
        <InputLabel id={`${id}-label`} shrink>
          {t("molecules.availabilityRecurrenceFrequency.label")}
        </InputLabel>
        <Select
          variant="standard"
          id={id}
          labelId={`${id}-label`}
          displayEmpty
          value={recurrenceFrequencyId}
          onChange={(event) => {
            if (onRecurrenceFrequencyIdChange) {
              onRecurrenceFrequencyIdChange(event.target.value);
            }
          }}
        >
          <MenuItem value="">
            {t("molecules.availabilityRecurrenceFrequency.never")}
          </MenuItem>
          <MenuItem value={recurrenceFrequency.daily}>
            {t("molecules.availabilityRecurrenceFrequency.daily")}
          </MenuItem>
          <MenuItem value={recurrenceFrequency.weekly}>
            {t("molecules.availabilityRecurrenceFrequency.weekly", {
              dayOfWeek: format(start, upSm ? "eeee" : "eee", { locale: fr }),
            })}
          </MenuItem>
        </Select>
      </FormControl>
    )
  );
};

AvailabilityRecurrenceFrequency.defaultProps = {
  id: "availability-recurrence-frequency",
  disabled: false,
  start: new Date(),
  recurrenceFrequencyId: "",
};

AvailabilityRecurrenceFrequency.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  start: PropTypes.instanceOf(Date),
  recurrenceFrequencyId: PropTypes.any,
  onRecurrenceFrequencyIdChange: PropTypes.func,
};

export default AvailabilityRecurrenceFrequency;
