import { addDays, addHours, isBefore, set, startOfDay } from "date-fns";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import { MobileDatePicker, TimeSelect } from "@vesta/components/atoms";

const AvailabilityTime = ({
  modifying,
  start,
  end,
  onStartChange,
  onEndChange,
}) => {
  const { t } = useTranslation("components");

  const handleDateChange = (value) => {
    const year = value.getFullYear();
    const month = value.getMonth();
    const date = value.getDate();

    onStartChange(set(start, { year, month, date }));
    onEndChange(set(end, { year, month, date }));
  };

  const handleStartChange = (value) => {
    onStartChange(value);

    if (isBefore(value, end)) {
      return;
    }

    const newEnd = addHours(value, 1);
    const startOfNextDay = startOfDay(addDays(start, 1));

    onEndChange(newEnd > startOfNextDay ? startOfNextDay : newEnd);
  };

  const handleEndChange = (value) => onEndChange(value);

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <MobileDatePicker
          sx={(theme) => ({ maxWidth: theme.spacing(40) })}
          label={t("molecules.availabilityTime.dateLabel")}
          disabled={modifying}
          value={start}
          onChange={handleDateChange}
        />
      </Grid>
      <Grid container direction="row" alignItems="center" spacing={2} item>
        <Grid item>
          <TimeSelect
            sx={(theme) => ({ minWidth: theme.spacing(10) })}
            id="availability-start"
            label={t("molecules.availabilityTime.startLabel")}
            value={start}
            onChange={handleStartChange}
          />
        </Grid>
        <Grid item>
          <Typography>{t("molecules.availabilityTime.separator")}</Typography>
        </Grid>
        <Grid item>
          <TimeSelect
            sx={(theme) => ({ minWidth: theme.spacing(10) })}
            id="availability-end"
            label={t("molecules.availabilityTime.endLabel")}
            fromValue={start}
            value={end}
            onChange={handleEndChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

AvailabilityTime.propTypes = {
  modifying: PropTypes.bool,
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
  onStartChange: PropTypes.func.isRequired,
  onEndChange: PropTypes.func.isRequired,
};

export default AvailabilityTime;
