import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Divider, MenuItem, MenuList, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import { CreditCardOutlined as CreditCardOutlinedIcon } from "@mui/icons-material";
import { PowerSettingsNewOutlined as PowerSettingsNewOutlinedIcon } from "@mui/icons-material";
import { Share as ShareIcon } from "@mui/icons-material";

import {
  useAppContext,
  useGlobalModalContext,
  useSession,
} from "@vesta/lib/react";

const iconSx = (theme) => ({
  marginRight: theme.spacing(1),
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
});

const StyledDivider = styled((props) => (
  <Divider component="li" variant="middle" tabIndex={null} {...props} />
))(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const AccountMenuItems = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const { t } = useTranslation("components");
  const { openShareProfileDialog } = useGlobalModalContext();
  const [appContext] = useAppContext();
  const [, jwtToken, , signOut] = useSession();

  const openCustomerPortal = async () => {
    const response = await fetch(
      new URL(
        "/queries/get-customer-portal-link?" +
          new URLSearchParams({
            returnUrl: window.location.href,
          }),
        process.env.REACT_APP_API_BASE_URL
      ),
      {
        headers: { Authorization: `Bearer ${jwtToken}` },
      }
    );

    const url = await response.text();
    window.location.href = url;
  };

  return (
    <MenuList
      sx={(theme) => ({ color: theme.palette.text.primary })}
      ref={ref}
      {...props}
    >
      <MenuItem
        disabled={!appContext.subscription?.hasSubscription}
        onClick={openCustomerPortal}
      >
        <CreditCardOutlinedIcon sx={iconSx} />
        <Typography variant="body2">
          {t("molecules.accountMenu.accountMenuItems.billing")}
        </Typography>
      </MenuItem>
      <StyledDivider />
      <MenuItem
        disabled={!appContext.subscription?.hasSubscription}
        onClick={() => openShareProfileDialog()}
      >
        <ShareIcon sx={iconSx} />
        <Typography variant="body2">
          {t("molecules.accountMenu.accountMenuItems.shareProfile")}
        </Typography>
      </MenuItem>
      <StyledDivider />
      <MenuItem
        onClick={() => {
          window.tidioChatApi.display(true);
          window.tidioChatApi.open();
        }}
      >
        <HelpOutlineIcon sx={iconSx} />
        <Typography variant="body2">
          {t("molecules.accountMenu.accountMenuItems.getHelp")}
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          signOut();
          navigate("/sign-in");
        }}
      >
        <PowerSettingsNewOutlinedIcon sx={iconSx} />
        <Typography variant="body2">
          {t("molecules.accountMenu.accountMenuItems.signOut")}
        </Typography>
      </MenuItem>
    </MenuList>
  );
});

AccountMenuItems.displayName = "AccountMenuItems";

export default AccountMenuItems;
