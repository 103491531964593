import {
  addDays,
  isAfter,
  isFriday,
  isMonday,
  isSunday,
  isTuesday,
  isWeekend,
  nextMonday,
  nextSaturday,
  nextThursday,
  roundToNearestMinutes,
  set,
} from "date-fns";
import { useMemo } from "react";

export const useRemindOnOptions = () => {
  const today = roundToNearestMinutes(new Date());

  const memo = useMemo(() => {
    const at6PM = {
      hours: 18,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    };

    const at8AM = {
      hours: 8,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    };

    const laterToday = isAfter(today, set(today, at6PM))
      ? null
      : set(today, at6PM);

    const tomorrow = set(addDays(today, 1), at8AM);

    const laterThisWeek =
      isMonday(today) || isTuesday(today)
        ? set(nextThursday(today), at8AM)
        : null;

    const thisWeekend =
      isFriday(today) || isWeekend(today)
        ? null
        : set(nextSaturday(today), at8AM);

    const nextWeek = isSunday(today) ? null : set(nextMonday(today), at8AM);

    const nextWeekend = isWeekend(today)
      ? set(nextSaturday(today), at8AM)
      : null;

    return {
      laterToday,
      tomorrow,
      laterThisWeek,
      thisWeekend,
      nextWeek,
      nextWeekend,
    };
  }, [today]);

  return memo;
};
