import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useEffect, useState } from "react";
import * as yup from "yup";

import { Checkbox, FormControlLabel, Grid } from "@mui/material";

import {
  PhoneNumberTextField,
  TitledFormPaper,
} from "@vesta/components/molecules";
import { useUpSm } from "@vesta/lib/react";

const ContactInformation = ({ loading, biography, onSave }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("profile");
  const [editing, setEditing] = useState(false);

  const validationSchema = yup.object().shape({
    phoneNumberInE164Format: yup.string().test(
      "is-phone-number-valid",
      <Trans
        i18nKey="profile:biography.contactInformation.phoneNumberInE164Format.phoneNumberValid"
        components={{
          emphasize: <b />,
        }}
      />,
      (value) => isValidPhoneNumber(value ?? "")
    ),
    hidePhoneNumber: yup.bool(),
  });

  const {
    values,
    errors,
    isSubmitting,
    submitCount,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      phoneNumberInE164Format: "",
      hidePhoneNumber: true,
    },
    validationSchema,
    onSubmit: async ({ phoneNumberInE164Format, hidePhoneNumber }) => {
      const ok = await onSave({
        phoneNumberInE164Format,
        hidePhoneNumber,
      });
      if (ok) {
        setEditing(false);
      }
    },
  });

  useEffect(() => {
    if (!editing && biography) {
      setFieldValue(
        "phoneNumberInE164Format",
        biography.phoneNumberInE164Format
      );
      setFieldValue("hidePhoneNumber", biography.hidePhoneNumber);
    }
  }, [editing, biography]);

  const handleCancel = () => {
    resetForm();
    setFieldValue("phoneNumberInE164Format", biography.phoneNumberInE164Format);
    setFieldValue("hidePhoneNumber", biography.hidePhoneNumber);
    setEditing(false);
  };

  const isApproved =
    values.phoneNumberInE164Format ===
    biography?.approvedPhoneNumberInE164Format;

  return (
    <TitledFormPaper
      title={t("biography.contactInformation.title")}
      loading={loading}
      editing={editing}
      saving={isSubmitting}
      saveLabel={
        isApproved
          ? t("biography.contactInformation.saveLabel.alreadyVerified")
          : t("biography.contactInformation.saveLabel.verify")
      }
      onEdit={() => setEditing(true)}
      onCancel={handleCancel}
      onSave={handleSubmit}
    >
      <Grid container spacing={upSm ? 3 : 2}>
        <Grid item xs={12} md={6}>
          <PhoneNumberTextField
            disabled={!editing}
            isApproved={isApproved}
            value={values.phoneNumberInE164Format}
            onChange={(value) =>
              setFieldValue("phoneNumberInE164Format", value)
            }
            error={submitCount > 0 && Boolean(errors.phoneNumberInE164Format)}
            helperText={submitCount > 0 ? errors.phoneNumberInE164Format : null}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            label={t("biography.contactInformation.hidePhoneNumber.label")}
            slotProps={{
              typography: {
                variant: "body2",
              },
            }}
            control={
              <Checkbox
                disabled={!editing}
                checked={!values.hidePhoneNumber}
                onChange={({ target: { checked } }) =>
                  setFieldValue("hidePhoneNumber", !checked)
                }
                size="small"
              />
            }
          />
        </Grid>
      </Grid>
    </TitledFormPaper>
  );
};

ContactInformation.propTypes = {
  loading: PropTypes.bool,
  biography: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};

export default ContactInformation;
