import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import * as yup from "yup";

import { Checkbox, FormControlLabel, Grid } from "@mui/material";

import { TitledFormPaper } from "@vesta/components/molecules";
import { healthInsuranceIssuingAuthority } from "@vesta/lib/enum";
import { useUpSm } from "@vesta/lib/react";

const HealthInsuranceRequirements = ({ loading, service, onSave }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("service");
  const [editing, setEditing] = useState(false);

  const validationSchema = yup.object().shape({
    ramqHealthInsurance: yup.bool(),
  });

  const { values, isSubmitting, handleSubmit, setFieldValue, resetForm } =
    useFormik({
      initialValues: {
        ramqHealthInsurance: false,
      },
      validationSchema,
      onSubmit: async ({ ramqHealthInsurance }) => {
        const ok = await onSave({ ramqHealthInsurance });
        if (ok) {
          setEditing(false);
        }
      },
    });

  useEffect(() => {
    if (!editing && service) {
      setFieldValue(
        "ramqHealthInsurance",
        service.healthInsuranceRequirements.some(
          (x) => x.issuingAuthorityId == healthInsuranceIssuingAuthority.ramq
        )
      );
    }
  }, [editing, service]);

  const handleCancel = () => {
    resetForm();
    setFieldValue(
      "ramqHealthInsurance",
      service.healthInsuranceRequirements.some(
        (x) => x.issuingAuthorityId == healthInsuranceIssuingAuthority.ramq
      )
    );
    setEditing(false);
  };

  return (
    <TitledFormPaper
      title={t("healthInsuranceRequirements.title")}
      loading={loading}
      editing={editing}
      saving={isSubmitting}
      onEdit={() => setEditing(true)}
      onCancel={handleCancel}
      onSave={handleSubmit}
    >
      <Grid container spacing={upSm ? 3 : 2}>
        <Grid item xs={12}>
          <FormControlLabel
            label={t("healthInsuranceRequirements.ramqHealthInsurance.label")}
            control={
              <Checkbox
                disabled={!editing}
                checked={values.ramqHealthInsurance}
                onChange={({ target: { checked } }) =>
                  setFieldValue("ramqHealthInsurance", checked)
                }
              />
            }
          />
        </Grid>
      </Grid>
    </TitledFormPaper>
  );
};

HealthInsuranceRequirements.propTypes = {
  loading: PropTypes.bool,
  service: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};

export default HealthInsuranceRequirements;
