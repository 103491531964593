import { fr } from "date-fns/locale";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { recurrenceFrequency } from "@vesta/lib/enum";

const localizedDayOfWeek = (date) => {
  const dayOfWeek = format(date, "eeee", { locale: fr });
  return dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
};

const AvailabilitySubtitle = ({
  className,
  start,
  end,
  recurrenceFrequencyId,
  recurrenceMode,
  recurrenceEndsAfterMaxNbOccurrences,
  recurrenceEndsOn,
}) => {
  const { t } = useTranslation("components");

  return (
    <span className={className}>
      {t("molecules.availabilitySubtitle.dateTimeRange", {
        dayOfWeek: localizedDayOfWeek(start),
        date: format(start, "PPP", { locale: fr }),
        startTime: format(start, "p", { locale: fr }),
        endTime: format(end, "p", { locale: fr }),
      })}
      {recurrenceFrequencyId && (
        <>
          <br />
          {recurrenceFrequencyId === recurrenceFrequency.daily &&
            t("molecules.availabilitySubtitle.recurrenceFrequency.daily")}
          {recurrenceFrequencyId === recurrenceFrequency.weekly &&
            t("molecules.availabilitySubtitle.recurrenceFrequency.weekly", {
              dayOfWeek: format(start, "eeee", { locale: fr }),
            })}
          {recurrenceMode === 1 &&
            t(
              "molecules.availabilitySubtitle.recurrenceEnd.afterMaxNbOccurrences",
              {
                count: recurrenceEndsAfterMaxNbOccurrences,
              }
            )}
          {recurrenceMode === 2 &&
            t("molecules.availabilitySubtitle.recurrenceEnd.on", {
              date: format(recurrenceEndsOn, "PPP", { locale: fr }),
            })}
        </>
      )}
    </span>
  );
};

AvailabilitySubtitle.propTypes = {
  className: PropTypes.string,
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
  recurrenceFrequencyId: PropTypes.any.isRequired,
  recurrenceMode: PropTypes.number,
  recurrenceEndsAfterMaxNbOccurrences: PropTypes.number,
  recurrenceEndsOn: PropTypes.instanceOf(Date),
};

export default AvailabilitySubtitle;
