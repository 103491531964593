import { useTranslation } from "react-i18next";

import { useSession } from "@vesta/lib/react";

import { AccountMenuAlert } from "../";

const AccountMenuLimitReachedAlert = () => {
  const { t } = useTranslation("components");
  const [, jwtToken] = useSession();

  const openCustomerPortal = async () => {
    const response = await fetch(
      new URL(
        "/queries/get-customer-portal-link?" +
          new URLSearchParams({
            returnUrl: window.location.href,
          }),
        process.env.REACT_APP_API_BASE_URL
      ),
      {
        headers: { Authorization: `Bearer ${jwtToken}` },
      }
    );

    const url = await response.text();
    window.location.href = url;
  };

  return (
    <AccountMenuAlert
      severity={"warning"}
      title={t("molecules.accountMenu.accountMenuLimitReachedAlert.title")}
      description={t(
        "molecules.accountMenu.accountMenuLimitReachedAlert.description"
      )}
      action={{
        label: t("molecules.accountMenu.accountMenuLimitReachedAlert.ctaLabel"),
        func: openCustomerPortal,
      }}
    />
  );
};

export default AccountMenuLimitReachedAlert;
