import { useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Box, Grid, Link, Typography } from "@mui/material";

import {
  useMedicalRecord,
  useScrollToTopOnMount,
  useSession,
} from "@vesta/lib/react";

import {
  AddResultDialog,
  AddResultFloatingButton,
  ResultCard,
} from "./components";

const Results = () => {
  const actionsRef = useRef(null);
  const { t } = useTranslation("patient");
  const [session] = useSession();
  const [medicalRecord, dispatch] = useMedicalRecord();
  const [adding, setAdding] = useState(false);

  useScrollToTopOnMount();

  const loading = !medicalRecord;

  const loadingState = (
    <>
      <Grid item>
        <ResultCard loading />
      </Grid>
      <Grid item>
        <ResultCard loading />
      </Grid>
    </>
  );

  const handleAdd = ({ resultId, files }) => {
    dispatch({
      type: "add-result-to-patient-medical-record",
      payload: {
        resultId,
        files,
        created: new Date(),
        createdBy: {
          userId: session.nameid,
          firstName: session.given_name,
          lastName: session.family_name,
        },
      },
    });

    setAdding(false);

    window.scrollTo({
      top: actionsRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  const emptyState = (
    <Grid item>
      <Typography
        sx={(theme) => ({ marginTop: theme.spacing(4) })}
        gutterBottom
        align="center"
        component="h2"
        variant="h3"
      >
        {t("results.emptyState.title")}
      </Typography>
      <Typography align="center">
        <Trans
          i18nKey="patient:results.emptyState.text"
          values={{
            fullName: medicalRecord?.personalInformation.fullName,
          }}
          components={{
            addResultLink: (
              <Link
                sx={{ cursor: "pointer" }}
                onClick={() => setAdding(true)}
                underline="always"
              />
            ),
          }}
        />
      </Typography>
    </Grid>
  );

  return (
    <>
      <Grid ref={actionsRef} container direction="column" spacing={3}>
        {loading
          ? loadingState
          : medicalRecord.medicalInformation.results.length === 0
          ? emptyState
          : medicalRecord.medicalInformation.results
              .sort((a, b) => b.created - a.created)
              .map((x, index, array) => ({ ...x, order: array.length - index }))
              .map((x, i) => (
                <Grid key={x.resultId} item>
                  <ResultCard
                    resultId={x.resultId}
                    order={x.order}
                    expandInitially={i === 0 || i === 1}
                  />
                </Grid>
              ))}
      </Grid>
      {!loading && (
        <>
          <Box
            sx={(theme) => ({
              height: theme.spacing(7),
              [theme.breakpoints.up("md")]: {
                height: theme.spacing(11),
              },
            })}
          />
          <AddResultFloatingButton
            sx={(theme) => ({
              position: "fixed",
              zIndex: 12,
              right: theme.spacing(2),
              bottom: theme.spacing(9),
              [theme.breakpoints.up("md")]: {
                bottom: theme.spacing(4),
              },
            })}
            onClick={() => setAdding(true)}
          />
          <AddResultDialog
            open={!!adding}
            onCancel={() => setAdding(false)}
            onAdd={handleAdd}
          />
        </>
      )}
    </>
  );
};

export default Results;
