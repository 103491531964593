import { lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";

import { Container } from "@mui/material";

import { Loader } from "@vesta/components/atoms";
import { Header } from "@vesta/components/molecules";
import { useScrollToTopOnMount } from "@vesta/lib/react";

import { Tabs } from "./components";

const Appointments = lazy(() => import("./components/Appointments"));
const Availabilities = lazy(() => import("./components/Availabilities"));

const CalendarView = () => {
  const { t } = useTranslation("calendar");

  useScrollToTopOnMount();

  return (
    <>
      <Helmet>
        <title>{t("title")}</title>
        <meta property="og:title" content={t("title")} />
        <meta property="og:description" content={t("description")} />
      </Helmet>
      <Header title={t("header.title")} />
      <Container
        sx={(theme) => ({
          position: "relative",
          top: theme.spacing(-1),
          [theme.breakpoints.up("md")]: {
            top: theme.spacing(-2),
          },
        })}
        maxWidth="lg"
      >
        <Tabs sx={(theme) => ({ marginBottom: theme.spacing(2) })} />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="appointments" element={<Appointments />} />
            <Route path="availabilities" element={<Availabilities />} />
            <Route
              path="*"
              element={<Navigate to="/calendar/appointments" replace />}
            />
            <Route
              index
              element={<Navigate to="/calendar/appointments" replace />}
            />
          </Routes>
        </Suspense>
      </Container>
    </>
  );
};

export default CalendarView;
