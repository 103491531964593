import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ServiceAgreementPaper } from "@vesta/components/molecules";

const CollectivePrescriptionToTreatGonococcalAndChlamydiaTrachomatisInfectionsAgreement =
  ({ agreement, onReject, onAccept }) => {
    const { t } = useTranslation("service");

    return (
      <ServiceAgreementPaper
        agreement={agreement}
        context={t(
          "collectivePrescriptionToTreatGonococcalAndChlamydiaTrachomatisInfectionsAgreement.context.text"
        )}
        requirements={t(
          "collectivePrescriptionToTreatGonococcalAndChlamydiaTrachomatisInfectionsAgreement.requirement.text"
        )}
        onReject={onReject}
        onAccept={onAccept}
      />
    );
  };

CollectivePrescriptionToTreatGonococcalAndChlamydiaTrachomatisInfectionsAgreement.propTypes =
  {
    agreement: PropTypes.object.isRequired,
    onReject: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
  };

export default CollectivePrescriptionToTreatGonococcalAndChlamydiaTrachomatisInfectionsAgreement;
