import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";

import { Loader } from "@vesta/components/atoms";
import { App } from "@vesta/components/organisms";

import "@fontsource/alegreya-sans-sc/700.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/600.css";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import "@vesta/lib/i18n";
import "aos/dist/aos.css";
import "leaflet/dist/leaflet.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-image-crop/dist/ReactCrop.css";
import "react-lazy-load-image-component/src/effects/blur.css";

const root = createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={<Loader />}>
    <App />
  </Suspense>
);
