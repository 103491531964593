import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMatch, useParams } from "react-router-dom";

import { Box, List, Typography } from "@mui/material";
import { Assessment, Assignment, DateRange } from "@mui/icons-material";

import { SubNavigationListItem } from "@vesta/components/atoms";
import {
  BottomNavigation,
  MainSidebar,
  SecondarySidebar,
} from "@vesta/components/organisms";
import { MainLayout } from "@vesta/layouts";
import { useUpMd } from "@vesta/lib/react";

export const MainLayoutWithPatientNavigation = ({ children }) => {
  const upMd = useUpMd();
  const { t } = useTranslation("components");
  const { patientId, someoneElseId } = useParams();
  const [primarySidebarOpen, setPrimarySidebarOpen] = useState(false);
  const [secondarySidebarOpen, setSecondarySidebarOpen] = useState(false);

  const matchesNotes = useMatch(
    someoneElseId
      ? "/patients/:patientId/s/:someoneElseId/notes"
      : "/patients/:patientId/notes"
  );

  const matchesResults = useMatch(
    someoneElseId
      ? "/patients/:patientId/s/:someoneElseId/results"
      : "/patients/:patientId/results"
  );

  const matchesAppointments = useMatch(
    someoneElseId
      ? "/patients/:patientId/s/:someoneElseId/appointments"
      : "/patients/:patientId/appointments"
  );

  const primarySidebar = (
    <MainSidebar
      isOpen={primarySidebarOpen}
      onOpen={() => setPrimarySidebarOpen(true)}
      onClose={() => setPrimarySidebarOpen(false)}
      subLayout={
        <Box>
          {!upMd && (
            <Box
              sx={(theme) => ({
                margin: theme.spacing(2),
                marginBottom: theme.spacing(0),
              })}
            >
              <Typography component="h1" variant="h3">
                {t(
                  "organisms.app.appRoutes.mainLayoutWithPatientNavigation.subLayoutHeaderTitle"
                )}
              </Typography>
            </Box>
          )}
          <List
            sx={(theme) => ({
              [theme.breakpoints.up("md")]: {
                paddingTop: 0,
              },
            })}
          >
            <SubNavigationListItem
              to={
                someoneElseId
                  ? `/patients/${patientId}/s/${someoneElseId}/notes`
                  : `/patients/${patientId}/notes`
              }
              label={t(
                "organisms.app.appRoutes.mainLayoutWithPatientNavigation.notesLabel"
              )}
              icon={<Assignment />}
              selected={!!matchesNotes}
              onClick={() => setPrimarySidebarOpen(false)}
            />
            <SubNavigationListItem
              to={
                someoneElseId
                  ? `/patients/${patientId}/s/${someoneElseId}/results`
                  : `/patients/${patientId}/results`
              }
              label={t(
                "organisms.app.appRoutes.mainLayoutWithPatientNavigation.resultsLabel"
              )}
              icon={<Assessment />}
              selected={!!matchesResults}
              onClick={() => setPrimarySidebarOpen(false)}
            />
            <SubNavigationListItem
              to={
                someoneElseId
                  ? `/patients/${patientId}/s/${someoneElseId}/appointments`
                  : `/patients/${patientId}/appointments`
              }
              label={t(
                "organisms.app.appRoutes.mainLayoutWithPatientNavigation.appointmentsLabel"
              )}
              icon={<DateRange />}
              selected={!!matchesAppointments}
              onClick={() => setPrimarySidebarOpen(false)}
            />
          </List>
        </Box>
      }
    />
  );

  const secondarySidebar = (
    <SecondarySidebar
      isOpen={secondarySidebarOpen}
      onOpen={() => setSecondarySidebarOpen(true)}
      onClose={() => setSecondarySidebarOpen(false)}
    />
  );

  return (
    <MainLayout
      sidebar={primarySidebar}
      onOpenSidebar={() => setPrimarySidebarOpen(true)}
      secondarySidebar={secondarySidebar}
      onOpenSecondarySidebar={() => setSecondarySidebarOpen(true)}
      renderBottomNavigation={({ sx }) => (
        <BottomNavigation
          sx={sx}
          value={
            matchesNotes
              ? "notes"
              : matchesResults
              ? "results"
              : matchesAppointments
              ? "appointments"
              : ""
          }
          actions={[
            {
              label: (
                <Typography variant="caption">
                  {t(
                    "organisms.app.appRoutes.mainLayoutWithPatientNavigation.notesLabel"
                  )}
                </Typography>
              ),
              value: "notes",
              to: someoneElseId
                ? `/patients/${patientId}/s/${someoneElseId}/notes`
                : `/patients/${patientId}/notes`,
              icon: <Assignment />,
            },
            {
              label: (
                <Typography variant="caption">
                  {t(
                    "organisms.app.appRoutes.mainLayoutWithPatientNavigation.resultsLabel"
                  )}
                </Typography>
              ),
              value: "results",
              to: someoneElseId
                ? `/patients/${patientId}/s/${someoneElseId}/results`
                : `/patients/${patientId}/results`,
              icon: <Assessment />,
            },
            {
              label: (
                <Typography variant="caption">
                  {t(
                    "organisms.app.appRoutes.mainLayoutWithPatientNavigation.appointmentsLabel"
                  )}
                </Typography>
              ),
              value: "appointments",
              to: someoneElseId
                ? `/patients/${patientId}/s/${someoneElseId}/appointments`
                : `/patients/${patientId}/appointments`,
              icon: <DateRange />,
            },
          ]}
        />
      )}
    >
      {children}
    </MainLayout>
  );
};

MainLayoutWithPatientNavigation.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayoutWithPatientNavigation;
