import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";

import { Typography } from "@mui/material";
import {
  AccountBox,
  CalendarToday,
  Home,
  MedicalInformation,
} from "@mui/icons-material";

import {
  BottomNavigation,
  MainSidebar,
  SecondarySidebar,
} from "@vesta/components/organisms";
import { MainLayout } from "@vesta/layouts";

const MainLayoutWithRootNavigation = ({ children }) => {
  const { t } = useTranslation("components");
  const [primarySidebarOpen, setPrimarySidebarOpen] = useState(false);
  const [secondarySidebarOpen, setSecondarySidebarOpen] = useState(false);

  const matchesCalendar = useMatch("/calendar/*");
  const matchesPatients = useMatch("/patients/*");
  const matchesProfile = useMatch("/profile/*");

  const primarySidebar = (
    <MainSidebar
      isOpen={primarySidebarOpen}
      onOpen={() => setPrimarySidebarOpen(true)}
      onClose={() => setPrimarySidebarOpen(false)}
    />
  );

  const secondarySidebar = (
    <SecondarySidebar
      isOpen={secondarySidebarOpen}
      onOpen={() => setSecondarySidebarOpen(true)}
      onClose={() => setSecondarySidebarOpen(false)}
    />
  );

  return (
    <MainLayout
      sidebar={primarySidebar}
      onOpenSidebar={() => setPrimarySidebarOpen(true)}
      secondarySidebar={secondarySidebar}
      onOpenSecondarySidebar={() => setSecondarySidebarOpen(true)}
      renderBottomNavigation={({ sx }) => (
        <BottomNavigation
          sx={sx}
          value={
            matchesProfile
              ? "profile"
              : matchesPatients
              ? "patients"
              : matchesCalendar
              ? "calendar"
              : "home"
          }
          actions={[
            {
              label: (
                <Typography variant="caption">
                  {t(
                    "organisms.app.appRoutes.mainLayoutWithRootNavigation.homeLabel"
                  )}
                </Typography>
              ),
              value: "home",
              to: "/",
              icon: <Home />,
            },
            {
              label: (
                <Typography variant="caption">
                  {t(
                    "organisms.app.appRoutes.mainLayoutWithRootNavigation.calendarLabel"
                  )}
                </Typography>
              ),
              value: "calendar",
              to: "/calendar/appointments",
              icon: <CalendarToday />,
            },
            {
              label: (
                <Typography variant="caption">
                  {t(
                    "organisms.app.appRoutes.mainLayoutWithRootNavigation.patientsLabel"
                  )}
                </Typography>
              ),
              value: "patients",
              to: "/patients",
              icon: <MedicalInformation />,
            },
            {
              label: (
                <Typography variant="caption">
                  {t(
                    "organisms.app.appRoutes.mainLayoutWithRootNavigation.profileLabel"
                  )}
                </Typography>
              ),
              value: "profile",
              to: "/profile/biography",
              icon: <AccountBox />,
            },
          ]}
        />
      )}
    >
      {children}
    </MainLayout>
  );
};

MainLayoutWithRootNavigation.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayoutWithRootNavigation;
