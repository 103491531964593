import { createContext, useContext } from "react";

export const AppContext = createContext();
AppContext.displayName = "AppContext";

export const useAppContext = () => useContext(AppContext);

export const AppointmentContext = createContext();
AppointmentContext.displayName = "AppointmentContext";

export const useAppointment = () => useContext(AppointmentContext);

export const MedicalRecordContext = createContext();
AppointmentContext.displayName = "MedicalRecordContext";

export const useMedicalRecord = () => useContext(MedicalRecordContext);

export const GlobalModalContext = createContext();
GlobalModalContext.displayName = "GlobalModalContext";

export const useGlobalModalContext = () => useContext(GlobalModalContext);

export const OnboardingSessionV1Context = createContext();
OnboardingSessionV1Context.displayName = "OnboardingSessionV1Context";

export const useOnboardingSessionV1 = () =>
  useContext(OnboardingSessionV1Context);
