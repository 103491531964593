import { addDays, addWeeks } from "date-fns";
import { useCallback, useEffect } from "react";
import { Views } from "react-big-calendar";
import { useSwipeable } from "react-swipeable";

import { useLocalStorage, useUpSm } from "@vesta/lib/react";

export const useSwipeableHandlers = (date, onNavigate, view) => {
  const handleSwiped = (increment) =>
    useCallback(() => {
      switch (view) {
        case Views.DAY:
          onNavigate(addDays(date, increment));
          break;
        case Views.WEEK:
          onNavigate(addWeeks(date, increment));
          break;
      }
    }, [increment, date, onNavigate, view]);

  const handlers = useSwipeable({
    onSwipedLeft: handleSwiped(1),
    onSwipedRight: handleSwiped(-1),
    delta: 30,
  });

  return handlers;
};

export const useView = () => {
  const upSm = useUpSm();
  const [view, setView] = useLocalStorage("vesta-view", Views.WEEK);

  useEffect(() => {
    if (upSm) {
      return;
    }

    setView(Views.DAY);
  }, [upSm]);

  return [view, setView];
};
