import { fr } from "date-fns/locale";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { useUpMd } from "@vesta/lib/react";

const StyledSortLabelSpan = styled("span")({
  border: 0,
  clip: "rect(0 0 0 0)",
  height: 1,
  margin: -1,
  overflow: "hidden",
  padding: 0,
  position: "absolute",
  top: 20,
  width: 1,
});

const PatientsTable = ({
  loading,
  count,
  data,
  sort,
  onSortChange,
  pageSize,
  page,
  onPageChange,
}) => {
  const upMd = useUpMd();
  const { t } = useTranslation("patients");

  if (loading) {
    return <Skeleton variant="rectangular" height={64} />;
  }

  const sortDirection = sort.isDescending ? "desc" : "asc";

  const handleSortByName = () =>
    onSortChange({
      sortByName: true,
      sortByBirthday: false,
      isDescending: sort.sortByName ? !sort.isDescending : false,
    });

  const handleSortByBirthday = () =>
    onSortChange({
      sortByName: false,
      sortByBirthday: true,
      isDescending: sort.sortByBirthday ? !sort.isDescending : false,
    });

  const sortLabel = (
    <StyledSortLabelSpan>
      {sort.isDescending
        ? t("patientsTable.head.sortOrder.descending")
        : t("patientsTable.head.sortOrder.ascending")}
    </StyledSortLabelSpan>
  );

  return (
    <>
      <TableContainer
        sx={(theme) => ({ marginBottom: theme.spacing(1) })}
        component={Paper}
      >
        <Table aria-label={t("patientsTable.label")} component="div">
          <TableHead component="div">
            <TableRow component="div">
              {upMd && <TableCell component="div" />}
              <TableCell
                component="div"
                sortDirection={sort.sortByName ? sortDirection : false}
              >
                <TableSortLabel
                  active={sort.sortByName}
                  direction={sortDirection}
                  onClick={handleSortByName}
                >
                  {t("patientsTable.head.name")}
                  {sort.sortByName && sortLabel}
                </TableSortLabel>
              </TableCell>
              <TableCell
                component="div"
                sortDirection={sort.sortByBirthday ? sortDirection : false}
              >
                <TableSortLabel
                  active={sort.sortByBirthday}
                  direction={sortDirection}
                  onClick={handleSortByBirthday}
                >
                  {t("patientsTable.head.birthday")}
                  {sort.sortByBirthday && sortLabel}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody component="div">
            {data.map((x) => {
              const fullName = `${x.firstName} ${x.lastName}`;

              return (
                <TableRow
                  key={`${x.patientId}_${x.someoneElseId}`}
                  sx={{ textDecoration: "none" }}
                  hover
                  component={Link}
                  to={
                    x.someoneElseId
                      ? `/patients/${x.patientId}/s/${x.someoneElseId}/notes`
                      : `/patients/${x.patientId}/notes`
                  }
                >
                  {upMd && (
                    <TableCell component="div">
                      <Avatar
                        sx={(theme) => ({
                          backgroundColor: theme.palette.secondary.dark,
                          color: theme.palette.getContrastText(
                            theme.palette.secondary.dark
                          ),
                          height: theme.spacing(6),
                          width: theme.spacing(6),
                          [theme.breakpoints.up("sm")]: {
                            height: theme.spacing(8),
                            width: theme.spacing(8),
                          },
                        })}
                        alt={fullName}
                      >
                        {x.firstName.charAt(0).toUpperCase() +
                          x.lastName.charAt(0).toUpperCase()}
                      </Avatar>
                    </TableCell>
                  )}
                  <TableCell component="div">
                    {t(
                      x.isDemoPatient
                        ? "patientsTable.demoPatientFullName"
                        : "patientsTable.patientFullName",
                      { fullName }
                    )}
                  </TableCell>
                  <TableCell component="div">
                    {format(new Date(x.birthday), "PPP", { locale: fr })}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {count > 0 && (
        <TablePagination
          component="div"
          getItemAriaLabel={(type) =>
            t(`patientsTable.pagination.${type}Label`)
          }
          labelDisplayedRows={() =>
            t("patientsTable.pagination.displayLabel", {
              page: page + 1,
              total: Math.ceil(count / pageSize),
            })
          }
          count={count}
          rowsPerPageOptions={[pageSize]}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={(_, newPage) => onPageChange(newPage)}
        />
      )}
    </>
  );
};

PatientsTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  count: PropTypes.number,
  data: PropTypes.array,
  sort: PropTypes.shape({
    sortByName: PropTypes.bool.isRequired,
    sortByBirthday: PropTypes.bool.isRequired,
    isDescending: PropTypes.bool.isRequired,
  }).isRequired,
  onSortChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default PatientsTable;
