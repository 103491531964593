import { useParams } from "react-router-dom";
import { useCallback, useEffect, useReducer } from "react";

import { agreementStatus } from "@vesta/lib/enum";
import {
  useAuthorizedFetchJson,
  useInterval,
  usePageVisibility,
} from "@vesta/lib/react";

const useGetService = (jwtToken, searchParams) => {
  const isVisible = usePageVisibility();
  const { serviceId } = useParams();
  const [fetchJson, json] = useAuthorizedFetchJson(jwtToken);

  const getService = useCallback(
    () =>
      fetchJson(
        new URL(
          `/queries/get-service-by-service-id/${serviceId}`,
          process.env.REACT_APP_API_BASE_URL
        )
      ),
    [serviceId]
  );

  useEffect(() => {
    getService();
  }, [getService]);

  const added = searchParams.has("added");

  const refreshService = useCallback(async () => {
    if (json) {
      return;
    }

    if (added) {
      await getService();
    }
  }, [json, added, getService]);

  useInterval(refreshService, isVisible ? 2000 : null);

  return json;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "init": {
      const { agreements } = action.payload;

      return {
        ...action.payload,
        agreements: agreements.map((x) => ({
          ...x,
          acceptedOn: x.acceptedOn ? new Date(x.acceptedOn) : null,
        })),
      };
    }
    case "modify-service-general-information": {
      const {
        nameFr,
        nameEn,
        descriptionFr,
        descriptionEn,
        price,
        maximumPrice,
      } = action.payload;

      return {
        ...state,
        nameFr,
        nameEn,
        descriptionFr,
        descriptionEn,
        price,
        maximumPrice,
      };
    }
    case "modify-service-durations": {
      const {
        appointmentDurationInMinutes,
        breakDurationAfterEachAppointmentInMinutes,
        minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutes,
      } = action.payload;

      return {
        ...state,
        appointmentDurationInMinutes,
        breakDurationAfterEachAppointmentInMinutes,
        minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutes,
      };
    }
    case "modify-health-insurance-requirement": {
      const { issuingAuthorityId, isRequiredToProvideService } = action.payload;

      return {
        ...state,
        healthInsuranceRequirements: [
          ...state.healthInsuranceRequirements.filter(
            (x) => x.issuingAuthorityId !== issuingAuthorityId
          ),
          {
            issuingAuthorityId,
            isRequiredToProvideService,
          },
        ],
      };
    }
    case "remove-health-insurance-requirement": {
      const { issuingAuthorityId } = action.payload;

      return {
        ...state,
        healthInsuranceRequirements: [
          ...state.healthInsuranceRequirements.filter(
            (x) => x.issuingAuthorityId !== issuingAuthorityId
          ),
        ],
      };
    }
    case "accept-service-agreement": {
      const { agreementId } = action.payload;

      return {
        ...state,
        agreements: state.agreements.map((x) =>
          x.agreementId === agreementId
            ? {
                ...x,
                statusId: agreementStatus.accepted,
                acceptedOn: new Date(),
              }
            : { ...x }
        ),
      };
    }
    default:
      throw new Error(`The action type ${action.type} cannot be handled`);
  }
};

export const useService = (jwtToken, searchParams) => {
  const service = useGetService(jwtToken, searchParams);

  const [state, dispatch] = useReducer(reducer);

  useEffect(() => {
    if (service) {
      dispatch({ type: "init", payload: service });
    }
  }, [service]);

  return [state, dispatch];
};
