import React, { useState, useMemo } from "react";
import { PropTypes } from "prop-types";

import { GlobalModalContext } from "@vesta/lib/react";

import { ChoosePlanDialog, ShareProfileDialog } from "./components";

const dialogNames = {
  ChooseSubcription: "choose-subcription",
  ShareProfile: "share-profile",
};

const GlobalModalContextProvider = ({ children }) => {
  const [openedDialogName, setOpenedDialogName] = useState();

  const value = useMemo(
    () => ({
      openChooseSubscriptionDialog: () =>
        setOpenedDialogName(dialogNames.ChooseSubcription),
      openShareProfileDialog: () =>
        setOpenedDialogName(dialogNames.ShareProfile),
    }),
    []
  );

  const handleClose = () => setOpenedDialogName(null);

  return (
    <>
      <GlobalModalContext.Provider value={value}>
        {children}
      </GlobalModalContext.Provider>
      <ChoosePlanDialog
        open={openedDialogName === dialogNames.ChooseSubcription}
        onClose={handleClose}
      />
      <ShareProfileDialog
        open={openedDialogName === dialogNames.ShareProfile}
        onClose={handleClose}
      />
    </>
  );
};

GlobalModalContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default GlobalModalContextProvider;
