import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Box, FormHelperText, Grid, InputLabel, Link } from "@mui/material";
import { styled } from "@mui/material/styles";

import { onboardingV1ViewStep6PhotoIdentification } from "@vesta/assets/images";
import {
  AddFileButton,
  DelayedCircularProgress,
} from "@vesta/components/atoms";

const a4AspectRatio = 3 / 2;

const sharedSx = (theme) => ({
  borderRadius: theme.spacing(1 / 2),
  height: theme.spacing(12 * a4AspectRatio),
  [theme.breakpoints.up("sm")]: {
    height: theme.spacing(16 * a4AspectRatio),
  },
  [theme.breakpoints.up("md")]: {
    height: theme.spacing(20 * a4AspectRatio),
  },
});

const StyledLoaderBox = styled(Box)(({ theme }) => ({
  ...sharedSx(theme),
  display: "flex",
}));

const StyledPhotoIdentificationImg = styled(LazyLoadImage)(({ theme }) =>
  sharedSx(theme)
);

const StyledPlaceholderImg = styled("img")(({ theme }) => ({
  ...sharedSx(theme),
  marginRight: theme.spacing(1),
}));

const PhotoIdentification = ({
  uploading,
  label,
  submitCount,
  values,
  errors,
  onAddFileChange,
  onRemoveClick,
}) => {
  const { t } = useTranslation("components");

  const hasError = submitCount > 0 && Boolean(errors.photoIdentificationId);

  const loader = (
    <StyledLoaderBox>
      <DelayedCircularProgress
        sx={(theme) => ({
          margin: "auto",
          marginLeft: theme.spacing(10),
        })}
      />
    </StyledLoaderBox>
  );

  const src = new URL(
    `/download-photo-identification/${values.photoIdentificationId}`,
    process.env.REACT_APP_API_BASE_URL
  ).toString();

  const placeholderSrc = new URL(
    `/download-blurred-photo-identification/${values.photoIdentificationId}`,
    process.env.REACT_APP_API_BASE_URL
  ).toString();

  const image = (
    <Link href={src} target="_blank">
      <StyledPhotoIdentificationImg
        alt={t("organisms.photoIdentification.imageAlt")}
        effect="blur"
        src={src}
        placeholderSrc={placeholderSrc}
      />
    </Link>
  );

  const addFile = (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{ display: { xs: "none", sm: "block", md: "none", lg: "block" } }}
      >
        <StyledPlaceholderImg
          src={onboardingV1ViewStep6PhotoIdentification}
          alt={t("organisms.photoIdentification.imageAlt")}
        />
      </Box>
      <AddFileButton
        sx={[
          sharedSx,
          (theme) => ({
            width: theme.spacing(12),
            [theme.breakpoints.up("sm")]: {
              width: theme.spacing(16),
            },
            [theme.breakpoints.up("md")]: {
              width: theme.spacing(20),
            },
          }),
        ]}
        accept="image/*"
        onChange={onAddFileChange}
      />
    </Box>
  );

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <InputLabel error={hasError} shrink>
          {label ?? t("organisms.photoIdentification.label")}
        </InputLabel>
      </Grid>
      <Grid item>
        {uploading ? loader : values.photoIdentificationId ? image : addFile}
      </Grid>
      <Grid item>
        {hasError ? (
          <FormHelperText error={hasError}>
            {errors.photoIdentificationId}
          </FormHelperText>
        ) : values.photoIdentificationId ? (
          <Link
            component="button"
            underline="always"
            variant="caption"
            onClick={onRemoveClick}
          >
            {t("organisms.photoIdentification.remove")}
          </Link>
        ) : (
          <FormHelperText>
            {t("organisms.photoIdentification.examples")}
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  );
};

PhotoIdentification.propTypes = {
  uploading: PropTypes.bool,
  label: PropTypes.string,
  submitCount: PropTypes.number.isRequired,
  values: PropTypes.shape({
    photoIdentificationId: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    photoIdentificationId: PropTypes.string,
  }).isRequired,
  onAddFileChange: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
};

export default PhotoIdentification;
