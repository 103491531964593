import { useEffect } from "react";

import { Avatar, Badge } from "@mui/material";
import { alpha } from "@mui/material/styles";

import {
  useAuthorizedFetchJson,
  useSession,
  useAppContext,
} from "@vesta/lib/react";

const useGetProfilePictureId = () => {
  const [, jwtToken] = useSession();
  const [fetchJson, json] = useAuthorizedFetchJson(jwtToken);

  useEffect(() => {
    fetchJson(
      new URL(
        "/queries/get-profile-picture-id",
        process.env.REACT_APP_API_BASE_URL
      )
    );
  }, []);

  return json;
};

const dot = (color) => ({
  height: 12,
  width: 12,
  borderRadius: 6,
  backgroundColor: color,
  animation: `pulse 4s infinite ease-out`,
});

const keyframes = (color) => ({
  "0%": {},
  "5%, 11%": {
    boxShadow: `0 0 0 0 ${alpha(color, 0.33)}`,
  },
  "6%, 12%": {
    boxShadow: `0 0 0 10px ${alpha(color, 0.33)}`,
  },
  "8%, 14%": {
    boxShadow: `0 0 0 0 ${alpha(color, 0.33)}`,
  },
  "100%": {},
});

const AccountAvatar = () => {
  const profilePictureId = useGetProfilePictureId();
  const [appContext] = useAppContext();

  const { subscription } = appContext;

  const src = profilePictureId
    ? new URL(
        `/download-profile-picture/xs/${profilePictureId}`,
        process.env.REACT_APP_API_BASE_URL
      ).toString()
    : null;

  return (
    <Badge
      sx={(theme) => {
        if (!subscription) {
          return {};
        }

        const hasError =
          subscription.actionRequired ||
          (subscription.isDelinquent && !subscription.hasSubscription);

        if (hasError) {
          return {
            "& .MuiBadge-dot": dot(theme.palette.error.main),
            "@keyframes pulse": keyframes(theme.palette.error.main),
          };
        }

        if (!subscription.hasSubscription) {
          return {
            "& .MuiBadge-dot": dot(theme.palette.info.main),
            "@keyframes pulse": keyframes(theme.palette.info.main),
          };
        }

        if (subscription.hasReachedLimit) {
          return {
            "& .MuiBadge-dot": dot(theme.palette.warning.light),
            "@keyframes pulse": keyframes(theme.palette.warning.main),
          };
        }

        return {};
      }}
      overlap="circular"
      variant="dot"
    >
      <Avatar
        sx={(theme) => ({
          height: theme.spacing(4),
          width: theme.spacing(4),
          [theme.breakpoints.up("sm")]: {
            height: theme.spacing(5),
            width: theme.spacing(5),
          },
        })}
        src={src}
      />
    </Badge>
  );
};

export default AccountAvatar;
