import { addWeeks, startOfWeek } from "date-fns";
import { useCallback, useEffect, useState } from "react";

import { useInterval, usePageVisibility, useSession } from "@vesta/lib/react";

const weekStartsOn = 1; // Monday

export const useEvents = (date, dirty) => {
  const isVisible = usePageVisibility();
  const [, jwtToken] = useSession();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const getEvents = useCallback(async () => {
    const start = startOfWeek(new Date(date), { weekStartsOn });
    const end = startOfWeek(addWeeks(new Date(date), 1), { weekStartsOn });

    const url = new URL(
      `/queries/get-calendar-availabilities-between-two-dates?` +
        new URLSearchParams({
          start: start.toISOString(),
          end: end.toISOString(),
        }),
      process.env.REACT_APP_API_BASE_URL
    );

    setLoading(true);

    const response = await fetch(url, {
      headers: { Authorization: `Bearer ${jwtToken}` },
    });

    setLoading(false);

    if (!response.ok) {
      throw new Error("An error occurred while getting availabilities");
    }

    const events = (await response.json())
      .filter((x) => !x.isDeleted)
      .map((x) => ({
        availabilityId: x.availabilityId,
        occurrenceId: x.occurrenceId,
        start: new Date(x.start),
        end: new Date(x.end),
        isRecurring: x.isRecurring,
        recurrenceFrequencyId: x.recurrenceFrequencyId,
        recurrenceEndsAfterMaxNbOccurrences:
          x.recurrenceEndsAfterMaxNbOccurrences,
        recurrenceEndsOn: x.recurrenceEndsOn && new Date(x.recurrenceEndsOn),
      }));

    setEvents(events);
  }, [date, jwtToken]);

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  useInterval(() => getEvents(), dirty && isVisible ? 2000 : null);

  return [events, loading];
};
