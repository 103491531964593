import PropTypes from "prop-types";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input/input";

import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { Call, CheckCircle } from "@mui/icons-material";

import { useUpMd } from "@vesta/lib/react";

const PhoneNumberTextFieldRef = forwardRef((props, ref) => (
  <TextField variant="standard" fullWidth {...props} inputRef={ref} />
));

PhoneNumberTextFieldRef.displayName = "PhoneNumberTextFieldRef";

const PhoneNumberTextField = ({
  className,
  readOnly,
  value,
  onChange,
  isApproved,
  error,
  helperText,
  ...rest
}) => {
  const upMd = useUpMd();
  const { t } = useTranslation("components");

  const startAdornment = readOnly ? (
    <InputAdornment position="start">
      <IconButton
        color="primary"
        component="a"
        edge="start"
        href={`tel:${value}`}
        aria-label={t("molecules.phoneNumberTextField.call")}
        size="small"
      >
        <Call />
      </IconButton>
    </InputAdornment>
  ) : null;

  const endAdornment =
    !readOnly && isApproved ? (
      <InputAdornment position="end">
        <Tooltip
          arrow
          enterTouchDelay={0}
          leaveTouchDelay={30000}
          placement="bottom"
          title={t("molecules.phoneNumberTextField.isApproved")}
        >
          <CheckCircle
            sx={(theme) => ({ color: theme.palette.success.main })}
          />
        </Tooltip>
      </InputAdornment>
    ) : null;

  return (
    <PhoneInput
      autoComplete="tel"
      className={className}
      inputComponent={PhoneNumberTextFieldRef}
      country="CA"
      international={false}
      withCountryCallingCode={false}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      label={
        upMd
          ? t("molecules.phoneNumberTextField.label")
          : t("molecules.phoneNumberTextField.shortLabel")
      }
      placeholder={t("molecules.phoneNumberTextField.placeholder")}
      inputProps={{
        readOnly,
        "aria-label": t("molecules.phoneNumberTextField.label"),
      }}
      InputProps={{
        startAdornment,
        endAdornment,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      {...rest}
    />
  );
};

PhoneNumberTextField.defaultProps = {
  onChange: () => {},
};

PhoneNumberTextField.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  isApproved: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default PhoneNumberTextField;
