import { BrowserRouter } from "react-router-dom";

import WithLayout, { MinimalLayout } from "@vesta/layouts";
import { useHotjar, useTidio } from "@vesta/lib/react";
import { MaintenanceView } from "@vesta/views";

import { AppRoutes, LogErrorBoundary } from "./components";

const maintenance = process.env.REACT_APP_MAINTENANCE === "true";

const App = () => {
  useHotjar();
  useTidio();

  if (maintenance) {
    return (
      <WithLayout
        renderLayout={({ view }) => <MinimalLayout>{view}</MinimalLayout>}
        renderView={() => <MaintenanceView />}
      />
    );
  }

  return (
    <LogErrorBoundary>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </LogErrorBoundary>
  );
};

export default App;
