import { fr } from "date-fns/locale";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ExpandMore } from "@mui/icons-material";

import {
  ContactUsLink,
  ErrorAlert,
  LoadingButton,
  SuccessAlert,
} from "@vesta/components/atoms";
import { NursingDocumentationStandardAlert } from "@vesta/components/molecules";
import { noteAttachmentType } from "@vesta/lib/enum";
import { useMedicalRecord, useSession, useUpSm } from "@vesta/lib/react";
import { defaultRawContent } from "@vesta/lib/slate";

import { AppointmentAlert, NoteEditor } from "./components";
import { useNotes } from "./hooks";

const maxFileSize = 16777216; // 16 MB

const StyledHeaderBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  minHeight: theme.spacing(12),
}));

const StyledGrowBox = styled(Box)({
  flexGrow: 1,
});

const StyledChip = styled((props) => <Chip size="small" {...props} />)(
  ({ theme }) => ({
    "&:not(:last-of-type)": {
      marginRight: theme.spacing(1 / 2),
    },
  })
);

const StyledExpandIconButton = styled((props) => (
  <IconButton edge="end" size="large" {...props} />
))(({ theme }) => ({
  transform: "rotate(0deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const NoteCard = ({ loading, noteId, order, expandInitially }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("patient");
  const [raised, setRaised] = useState(false);
  const [expanded, setExpanded] = useState(expandInitially);
  const [session, jwtToken] = useSession();
  const [medicalRecord, dispatch] = useMedicalRecord();
  const [primaryNote, primaryNoteAndAddenda] = useNotes(noteId, medicalRecord);
  const [noteAttachmentDeleted, setNoteAttachmentDeleted] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [noteAttachmentSizeTooLargeAlert, setNoteAttachmentSizeTooLargeAlert] =
    useState(false);
  const [uploadNoteAttachmentFailed, setUploadNoteAttachmentFailed] =
    useState(false);
  const [
    addNoteAttachmentToPatientMedicalRecordFailed,
    setAddNoteAttachmentToPatientMedicalRecordFailed,
  ] = useState(false);
  const [
    deleteNoteAttachmentFromPatientMedicalRecordFailed,
    setDeleteNoteAttachmentFromPatientMedicalRecordFailed,
  ] = useState(false);
  const [
    completeNoteInPatientMedicalRecordFailed,
    setCompleteNoteInPatientMedicalRecordFailed,
  ] = useState(false);

  if (loading) {
    return (
      <Card
        sx={(theme) => ({
          display: "flex",
          padding: theme.spacing(2),
        })}
        component="section"
      >
        <StyledHeaderBox>
          <Typography variant="button">
            <Skeleton width="70%" />
          </Typography>
          <Typography variant="caption">
            <Skeleton width="40%" />
          </Typography>
          <StyledGrowBox />
          <Typography variant="caption">
            <Skeleton width="50%" />
          </Typography>
        </StyledHeaderBox>
        <StyledExpandIconButton disabled>
          <ExpandMore />
        </StyledExpandIconButton>
      </Card>
    );
  }

  const appointment = medicalRecord.medicalInformation.appointments.find(
    (x) => x.appointmentId === primaryNote.appointmentId
  );

  const handleRawContentChange = (noteId) => async (rawContent) => {
    dispatch({
      type: "adding-note-version-to-patient-medical-record",
      payload: {
        noteId,
      },
    });

    const url = new URL(
      "/commands/add-note-version-to-patient-medical-record",
      process.env.REACT_APP_API_BASE_URL
    );

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        patientId: medicalRecord.patientId,
        someoneElseId: medicalRecord.someoneElseId,
        noteId,
        rawContent: JSON.stringify(rawContent),
      }),
    });

    if (response.ok) {
      const { noteVersionId } = await response.json();

      dispatch({
        type: "add-note-version-to-patient-medical-record",
        payload: {
          noteId,
          noteVersionId,
          rawContent,
          modified: new Date(),
        },
      });
    }
  };

  const handleNoteAttachmentChange = (noteId) => async (event) => {
    const files = [...event.target.files];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > maxFileSize) {
        setNoteAttachmentSizeTooLargeAlert(true);
        continue;
      }

      dispatch({
        type: "uploading-note-attachment-to-patient-medical-record",
        payload: {
          noteId,
          noteAttachment: {
            noteAttachmentId: file.name,
            name: file.name,
            typeId: noteAttachmentType.pdf,
            created: new Date(),
          },
        },
      });

      const uploadNoteAttachment = () =>
        new Promise((resolve, reject) => {
          const url = new URL(
            "upload-note-attachment",
            process.env.REACT_APP_API_BASE_URL
          );

          const request = new XMLHttpRequest();
          request.open("POST", url);
          request.setRequestHeader("Authorization", `Bearer ${jwtToken}`);

          // The fetch API does not support progress as of this writing, which is why we use XMLHttpRequest
          request.upload.addEventListener("progress", (event) => {
            const progress = Math.round((event.loaded / event.total) * 100);
            dispatch({
              type: "continue-uploading-note-attachment-to-patient-medical-record",
              payload: {
                noteId,
                noteAttachment: {
                  noteAttachmentId: file.name,
                  uploadingProgress: progress,
                },
              },
            });
          });

          request.addEventListener("readystatechange", () => {
            if (request.readyState !== 4) {
              return;
            }
            if (request.status === 200) {
              const { noteAttachmentId } = JSON.parse(request.responseText);
              resolve(noteAttachmentId);
            } else {
              reject();
            }
          });

          const formData = new FormData();
          formData.append("note-attachment", file, file.name);

          request.send(formData);
        });

      let noteAttachmentId;
      try {
        noteAttachmentId = await uploadNoteAttachment();
      } catch (e) {
        setUploadNoteAttachmentFailed(true);
        dispatch({
          type: "cancel-uploading-note-attachment-to-patient-medical-record",
          payload: {
            noteId,
            noteAttachment: {
              noteAttachmentId: file.name,
            },
          },
        });
        continue;
      }

      const addNoteAttachmentToPatientMedicalRecordResponse = await fetch(
        new URL(
          "commands/add-note-attachment-to-patient-medical-record",
          process.env.REACT_APP_API_BASE_URL
        ),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({
            patientId: medicalRecord.patientId,
            someoneElseId: medicalRecord.someoneElseId,
            noteId,
            noteAttachmentId,
            noteAttachmentName: file.name,
            noteAttachmentTypeId: noteAttachmentType.pdf,
          }),
        }
      );

      if (!addNoteAttachmentToPatientMedicalRecordResponse.ok) {
        setAddNoteAttachmentToPatientMedicalRecordFailed(true);
        continue;
      }

      dispatch({
        type: "add-note-attachment-to-patient-medical-record",
        payload: {
          noteId,
          modified: new Date(),
          noteAttachment: {
            noteAttachmentId: file.name,
            newNoteAttachmentId: noteAttachmentId,
          },
        },
      });
    }
  };

  const handleDeleteNoteAttachment = (noteId) => async (noteAttachmentId) => {
    const url = new URL(
      "commands/delete-note-attachment-from-patient-medical-record",
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        patientId: medicalRecord.patientId,
        someoneElseId: medicalRecord.someoneElseId,
        noteId,
        noteAttachmentId,
      }),
    });

    if (ok) {
      setNoteAttachmentDeleted(true);
      dispatch({
        type: "delete-note-attachment-from-patient-medical-record",
        payload: {
          noteId,
          modified: new Date(),
          noteAttachment: {
            noteAttachmentId,
            deleted: new Date(),
          },
        },
      });
    } else {
      setDeleteNoteAttachmentFromPatientMedicalRecordFailed(true);
    }
  };

  const handleCompleteNote = (noteId) => async () => {
    dispatch({
      type: "completing-note-in-patient-medical-record",
      payload: {
        noteId,
      },
    });

    const url = new URL(
      "commands/complete-note-in-patient-medical-record",
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        patientId: medicalRecord.patientId,
        someoneElseId: medicalRecord.someoneElseId,
        noteId,
      }),
    });

    if (ok) {
      setCompleted(true);
      dispatch({
        type: "complete-note-in-patient-medical-record",
        payload: {
          noteId,
          modified: new Date(),
          completed: new Date(),
          completedBy: {
            userId: session.nameid,
            firstName: session.given_name,
            lastName: session.family_name,
          },
        },
      });
    } else {
      setCompleteNoteInPatientMedicalRecordFailed(true);
    }
  };

  const handleAddNoteAddendum = (addendumTo) => async () => {
    dispatch({ type: "adding-note-to-patient-medical-record" });

    const url = new URL(
      "/commands/add-note-to-patient-medical-record",
      process.env.REACT_APP_API_BASE_URL
    );

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        patientId: medicalRecord.patientId,
        someoneElseId: medicalRecord.someoneElseId,
        addendumTo,
        appointmentId: appointment?.appointmentId,
        rawContent: JSON.stringify(defaultRawContent),
      }),
    });

    if (response.ok) {
      const { noteId, noteVersionId } = await response.json();

      dispatch({
        type: "add-note-to-patient-medical-record",
        payload: {
          noteId,
          noteVersionId,
          addendumTo,
          appointmentId: appointment?.appointmentId,
          rawContent: defaultRawContent,
          attachments: [],
          created: new Date(),
          modified: new Date(),
        },
      });
    }
  };

  const noteToComplete = primaryNoteAndAddenda.find((x) => !x.completed);

  const addendaToComplete = primaryNoteAndAddenda
    .filter((x) => x.addendumTo)
    .filter((x) => !x.completed);

  const completedNotes = primaryNoteAndAddenda
    .filter((x) => x.completed)
    .sort((a, b) => b.completed - a.completed);

  const containAttachments =
    primaryNoteAndAddenda
      .flatMap((x) => x.attachments)
      .filter((x) => !x.deleted).length > 0;

  return (
    <>
      <Card
        component="section"
        raised={raised}
        onMouseOver={() => setRaised(true)}
        onMouseOut={() => setRaised(false)}
      >
        <CardActionArea
          sx={(theme) => ({
            display: "flex",
            padding: theme.spacing(2),
          })}
          component="div"
          onClick={() => setExpanded((prevValue) => !prevValue)}
        >
          <StyledHeaderBox>
            <Typography component="h2" variant="button">
              {t("notes.noteCard.title", {
                order,
              })}
            </Typography>
            <Typography color="textSecondary" variant="caption">
              {appointment
                ? appointment.services.length > 0
                  ? t("notes.noteCard.subtitle", {
                      count: appointment.services.length,
                      firstServiceName: appointment.services[0].nameFr,
                      delta: appointment.services.length - 1,
                      date: format(appointment.start, "PPP", { locale: fr }),
                      interpolation: { escapeValue: false },
                    })
                  : t("notes.noteCard.subtitle", {
                      count: 0,
                      date: format(appointment.start, "PPP", { locale: fr }),
                    })
                : format(primaryNote.created, "PPP", { locale: fr })}
            </Typography>
            <StyledGrowBox />
            <div>
              {primaryNoteAndAddenda.every((x) => x.completed) ? (
                <StyledChip
                  sx={(theme) => ({
                    backgroundColor: theme.palette.success.dark,
                    color: theme.palette.getContrastText(
                      theme.palette.success.dark
                    ),
                  })}
                  label={t("notes.noteCard.completed")}
                />
              ) : (
                <StyledChip
                  sx={(theme) => ({
                    backgroundColor: theme.palette.error.dark,
                    color: theme.palette.getContrastText(
                      theme.palette.error.dark
                    ),
                  })}
                  label={t("notes.noteCard.toComplete")}
                />
              )}
              {primaryNoteAndAddenda.length > 1 && (
                <StyledChip
                  sx={(theme) => ({
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.getContrastText(
                      theme.palette.secondary.dark
                    ),
                  })}
                  label={t("notes.noteCard.addenda")}
                />
              )}
              {containAttachments && (
                <StyledChip
                  sx={(theme) => ({
                    backgroundColor: theme.palette.info.dark,
                    color: theme.palette.getContrastText(
                      theme.palette.info.dark
                    ),
                  })}
                  label={t("notes.noteCard.attachment")}
                />
              )}
            </div>
          </StyledHeaderBox>
          <StyledExpandIconButton
            sx={
              expanded && {
                transform: "rotate(180deg)",
              }
            }
            aria-expanded={expanded}
            aria-label={
              expanded
                ? t("notes.noteCard.showLess")
                : t("notes.noteCard.showMore")
            }
          >
            <ExpandMore />
          </StyledExpandIconButton>
        </CardActionArea>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent
            sx={(theme) => ({
              padding: theme.spacing(2),
              [theme.breakpoints.up("sm")]: {
                padding: theme.spacing(3),
              },
            })}
          >
            <Grid container direction="column" spacing={4}>
              {appointment && (
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  item
                >
                  <AppointmentAlert
                    sx={(theme) => ({
                      width: "100%",
                      maxWidth: theme.spacing(100),
                    })}
                    appointmentId={appointment.appointmentId}
                  />
                </Grid>
              )}
              {primaryNoteAndAddenda.some((x) => !x.completed) && (
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  item
                >
                  <NursingDocumentationStandardAlert
                    sx={(theme) => ({
                      width: "100%",
                      maxWidth: theme.spacing(100),
                    })}
                  />
                </Grid>
              )}
              {primaryNoteAndAddenda.map((x, i, { length }) => (
                <Grid
                  key={x.noteId}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  item
                >
                  <Box
                    sx={(theme) => ({
                      width: "100%",
                      maxWidth: theme.spacing(100),
                      [theme.breakpoints.up("sm")]: {
                        backgroundColor: theme.palette.background.default,
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: theme.palette.divider,
                        padding: theme.spacing(2),
                      },
                      [theme.breakpoints.up("lg")]: {
                        padding: theme.spacing(4),
                      },
                    })}
                  >
                    <NoteEditor
                      noteId={x.noteId}
                      onRawContentChange={handleRawContentChange(x.noteId)}
                      onNoteAttachmentChange={handleNoteAttachmentChange(
                        x.noteId
                      )}
                      onDeleteNoteAttachment={handleDeleteNoteAttachment(
                        x.noteId
                      )}
                    />
                  </Box>
                  {i + 1 !== length && length > 1 && !upSm && (
                    <Divider
                      sx={(theme) => ({
                        marginTop: theme.spacing(4),
                        width: "100%",
                      })}
                      light
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </CardContent>
          <CardActions
            sx={(theme) => ({
              [theme.breakpoints.up("sm")]: {
                margin: theme.spacing(1),
              },
            })}
          >
            {noteToComplete && (
              <LoadingButton
                loading={
                  noteToComplete.addingVersion || noteToComplete.completing
                }
                disabled={
                  noteToComplete.addingVersion || noteToComplete.completing
                }
                onClick={handleCompleteNote(noteToComplete.noteId)}
              >
                {noteToComplete.addingVersion
                  ? t("notes.noteCard.actions.saving")
                  : noteToComplete.addendumTo
                  ? t("notes.noteCard.actions.completeNoteAddendum")
                  : t("notes.noteCard.actions.completeNote")}
              </LoadingButton>
            )}
            {addendaToComplete.length === 0 && completedNotes.length > 0 && (
              <LoadingButton
                loading={medicalRecord.medicalInformation.addingNote}
                disabled={medicalRecord.medicalInformation.addingNote}
                onClick={handleAddNoteAddendum(completedNotes[0].noteId)}
              >
                {t("notes.noteCard.actions.addNoteAddendum")}
              </LoadingButton>
            )}
          </CardActions>
        </Collapse>
      </Card>
      <SuccessAlert
        open={noteAttachmentDeleted}
        onClose={() => setNoteAttachmentDeleted(false)}
        content={t("notes.noteCard.noteAttachmentDeletedAlert")}
      />
      <SuccessAlert
        open={completed}
        onClose={() => setCompleted(false)}
        content={t("notes.noteCard.completedAlert")}
      />
      <ErrorAlert
        autoHide
        open={noteAttachmentSizeTooLargeAlert}
        onClose={() => setNoteAttachmentSizeTooLargeAlert(false)}
        content={t("notes.noteCard.noteAttachmentSizeTooLargeAlert")}
      />
      <ErrorAlert
        open={uploadNoteAttachmentFailed}
        onClose={() => setUploadNoteAttachmentFailed(false)}
        content={t("notes.noteCard.uploadNoteAttachmentFailedAlert")}
      />
      <ErrorAlert
        open={addNoteAttachmentToPatientMedicalRecordFailed}
        onClose={() => setAddNoteAttachmentToPatientMedicalRecordFailed(false)}
        content={
          <Trans
            i18nKey="patient:notes.noteCard.addNoteAttachmentToPatientMedicalRecordFailedAlert"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
      <ErrorAlert
        open={deleteNoteAttachmentFromPatientMedicalRecordFailed}
        onClose={() =>
          setDeleteNoteAttachmentFromPatientMedicalRecordFailed(false)
        }
        content={
          <Trans
            i18nKey="patient:notes.noteCard.deleteNoteAttachmentFromPatientMedicalRecordFailedAlert"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
      <ErrorAlert
        open={completeNoteInPatientMedicalRecordFailed}
        onClose={() => setCompleteNoteInPatientMedicalRecordFailed(false)}
        content={
          <Trans
            i18nKey="patient:notes.noteCard.completeNoteInPatientMedicalRecordFailedAlert"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
    </>
  );
};

NoteCard.propTypes = {
  loading: PropTypes.bool,
  noteId: PropTypes.string,
  order: PropTypes.number,
  expandInitially: PropTypes.bool,
};

export default NoteCard;
