import { fr } from "date-fns/locale";
import PropTypes from "prop-types";

import {
  frFR,
  LocalizationProvider,
  MobileDatePicker as MuiMobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const MobileDatePicker = ({
  sx,
  autoComplete,
  fullWidth,
  error,
  helperText,
  ...rest
}) => (
  <LocalizationProvider
    dateAdapter={AdapterDateFns}
    adapterLocale={fr}
    localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
  >
    <MuiMobileDatePicker
      format="PPP"
      closeOnSelect
      views={["day"]}
      slots={{ toolbar: null }}
      slotProps={{
        actionBar: {
          actions: ["cancel"],
        },
        textField: {
          sx,
          variant: "standard",
          fullWidth,
          error,
          helperText,
          InputLabelProps: {
            shrink: true,
          },
          inputProps: {
            autoComplete,
          },
        },
      }}
      {...rest}
    />
  </LocalizationProvider>
);

MobileDatePicker.defaultProps = {
  fullWidth: true,
};

MobileDatePicker.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  autoComplete: PropTypes.string,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default MobileDatePicker;
