import { fr } from "date-fns/locale";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import { Add, Person } from "@mui/icons-material";

const AutocompleteOption = ({ option }) => {
  const { t } = useTranslation("components");

  if (option.isNewPatient) {
    return (
      <>
        <Add
          sx={(theme) => ({
            color: theme.palette.primary.main,
            marginRight: theme.spacing(2),
          })}
        />
        <Typography color="primary" component="span">
          {option.label}
        </Typography>
      </>
    );
  }

  const fullName = `${option.firstName} ${option.lastName}`;

  return (
    <>
      <Person
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(2),
        })}
      />
      <Typography>
        {t(
          option.isDemoPatient
            ? "atoms.patientsAutocomplete.autocompleteOption.demoPatientFullName"
            : "atoms.patientsAutocomplete.autocompleteOption.patientFullName",
          { fullName }
        )}
        {option.birthday && (
          <Typography
            component="span"
            display="block"
            variant="body2"
            color="textSecondary"
          >
            {format(option.birthday, "PPP", {
              locale: fr,
            })}
          </Typography>
        )}
      </Typography>
    </>
  );
};

AutocompleteOption.propTypes = {
  option: PropTypes.object.isRequired,
};

export default AutocompleteOption;
