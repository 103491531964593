import PropTypes from "prop-types";

import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

const GroupedForms = ({
  sx,
  readOnly,
  disabled,
  label,
  value,
  onChange,
  groups,
}) => (
  <FormControl
    variant="standard"
    sx={sx}
    component="fieldset"
    disabled={disabled}
  >
    {label && (
      <Box sx={{ pb: 3 }}>
        <InputLabel component="legend" shrink>
          {label}
        </InputLabel>
      </Box>
    )}
    <RadioGroup
      value={value}
      onChange={(event) => onChange(event.target.value)}
    >
      {groups.map((x, i) => (
        <Box
          key={i}
          sx={(theme) => ({
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            "&:not(:last-of-type)": {
              marginBottom: theme.spacing(1),
            },
            ...(x.value === value
              ? {
                  paddingTop: theme.spacing(1),
                  paddingBottom: theme.spacing(3),
                  borderStyle: "dashed",
                  borderWidth: 1,
                  borderColor: theme.palette.text.secondary,
                  borderRadius: 4,
                }
              : {}),
          })}
        >
          <Grid container direction="column">
            <FormControlLabel
              sx={{ mb: 1 }}
              disabled={readOnly || disabled}
              value={x.value}
              control={<Radio />}
              label={x.label}
            />
            {x.value === value && x.form}
          </Grid>
        </Box>
      ))}
    </RadioGroup>
  </FormControl>
);

GroupedForms.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      form: PropTypes.node.isRequired,
    })
  ).isRequired,
};

export default GroupedForms;
