import { debounce } from "lodash";
import { useCallback, useEffect } from "react";

import { useAuthorizedFetchJson, useSession } from "@vesta/lib/react";

export const useSearchableMedicalRecords = (term, sort, page, pageSize) => {
  const [, jwtToken] = useSession();
  const [fetchJson, json] = useAuthorizedFetchJson(jwtToken);

  const getSearchableMedicalRecords = useCallback(
    debounce(async (term) => {
      const trimmedTerm = term.trim();
      const { sortByName, sortByBirthday, isDescending } = sort;

      await fetchJson(
        new URL(
          (trimmedTerm
            ? `/queries/get-searchable-medical-records-by-search-term/${term}?`
            : "/queries/get-all-searchable-medical-records?") +
            new URLSearchParams({
              ...(sortByName && { sortByName }),
              ...(sortByBirthday && { sortByBirthday }),
              ...(isDescending && { isDescending }),
              page,
              pageSize,
            }),
          process.env.REACT_APP_API_BASE_URL
        )
      );
    }, 200),
    [sort, page, pageSize]
  );

  useEffect(() => {
    getSearchableMedicalRecords(term);
  }, [term, getSearchableMedicalRecords]);

  return json;
};
