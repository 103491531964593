import { fr } from "date-fns/locale";
import { differenceInMinutes, format } from "date-fns";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Box, Tooltip, Typography } from "@mui/material";
import { Repeat } from "@mui/icons-material";

import { recurrenceFrequency } from "@vesta/lib/enum";

const rootSx = {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
};

const timeSx = (theme) => ({
  fontSize: 12,
  color: theme.palette.primary.contrastText,
});

const recurringIconSx = {
  position: "absolute",
  right: 4,
  bottom: 4,
};

const Event = ({ event }) => {
  const { t } = useTranslation("calendar");

  const {
    start,
    end,
    recurrenceFrequencyId,
    recurrenceEndsAfterMaxNbOccurrences,
    recurrenceEndsOn,
    isRecurring,
  } = event;

  const getRecurringEventTitle = () => {
    let title = "";

    if (recurrenceFrequencyId === recurrenceFrequency.daily) {
      title = title.concat(
        t("availabilities.event.recurrenceFrequencyTooltip.daily")
      );
    }

    if (recurrenceFrequencyId === recurrenceFrequency.weekly) {
      title = title.concat(
        t("availabilities.event.recurrenceFrequencyTooltip.weekly", {
          dayOfWeek: format(event.start ?? start, "eeee", { locale: fr }),
        })
      );
    }

    if (recurrenceEndsAfterMaxNbOccurrences) {
      title = title.concat(
        t("availabilities.event.recurrenceEndTooltip.afterMaxNbOccurrences", {
          count: recurrenceEndsAfterMaxNbOccurrences,
        })
      );
    }

    if (recurrenceEndsOn) {
      title = title.concat(
        t("availabilities.event.recurrenceEndTooltip.on", {
          date: format(recurrenceEndsOn, "PPP", { locale: fr }),
        })
      );
    }

    return title;
  };

  const time = t("availabilities.event.time", {
    start: format(event.start ?? start, "p", { locale: fr }),
    end: format(event.end ?? end, "p", { locale: fr }),
  });

  const availabilityDurationInMinutes = differenceInMinutes(
    new Date(end),
    new Date(start)
  );

  if (availabilityDurationInMinutes > 45) {
    return (
      <Box sx={rootSx}>
        <Typography sx={timeSx} variant="caption">
          {time}
        </Typography>
        {isRecurring && (
          <Tooltip arrow placement="bottom" title={getRecurringEventTitle()}>
            <Repeat sx={recurringIconSx} fontSize="small" />
          </Tooltip>
        )}
      </Box>
    );
  }

  if (availabilityDurationInMinutes >= 15) {
    return (
      <Box sx={[rootSx, { transform: "translateY(-3px)" }]}>
        <Typography sx={timeSx} variant="caption">
          {time}
        </Typography>
        {isRecurring && (
          <Tooltip arrow placement="bottom" title={getRecurringEventTitle()}>
            <Repeat sx={[recurringIconSx, { fontSize: 14, bottom: 1 }]} />
          </Tooltip>
        )}
      </Box>
    );
  }

  return (
    <Box sx={rootSx}>
      <Typography sx={timeSx}>{time}</Typography>
    </Box>
  );
};

Event.propTypes = {
  event: PropTypes.object.isRequired,
};

export default Event;
