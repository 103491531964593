import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";

import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const PlanRadioGroup = ({ selectedPlan, onChange }) => {
  const { t } = useTranslation("global-modals");

  const plans = t("choosePlanDialog.step1.plans", { returnObjects: true });

  return (
    <FormControl fullWidth variant="standard" component="fieldset">
      <Box sx={{ pb: 2 }}>
        <InputLabel component="legend" shrink>
          {t("choosePlanDialog.step1.plansTitle")}
        </InputLabel>
      </Box>
      <RadioGroup name="plan" value={selectedPlan} onChange={onChange}>
        {plans.map((plan) => {
          return (
            <FormControlLabel
              key={plan.id}
              value={plan.id}
              control={<Radio />}
              label={plan.name}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

PlanRadioGroup.propTypes = {
  selectedPlan: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PlanRadioGroup;
