import PropTypes from "prop-types";
import { useMemo } from "react";
import { Node } from "slate";

import { Box } from "@mui/material";

import {
  createAlignPlugin,
  createBlockquotePlugin,
  createBoldPlugin,
  createExitBreakPlugin,
  createHeadingPlugin,
  createImagePlugin,
  createIndentPlugin,
  createItalicPlugin,
  createParagraphPlugin,
  createPlateUI,
  createPlugins,
  createResetNodePlugin,
  createSelectOnBackspacePlugin,
  createSoftBreakPlugin,
  createUnderlinePlugin,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_IMAGE,
  ELEMENT_PARAGRAPH,
  isBlockAboveEmpty,
  isSelectionAtBlockStart,
  KEYS_HEADING,
  Plate,
  PlateProvider,
} from "@udecode/plate";

const RichTextEditor = ({
  placeholder,
  readOnly,
  toolbar,
  value,
  onChange,
}) => {
  const plugins = useMemo(
    () =>
      createPlugins(
        [
          createAlignPlugin({
            inject: {
              props: {
                validTypes: [ELEMENT_PARAGRAPH, ELEMENT_H1, ELEMENT_H2],
              },
            },
          }),
          createBlockquotePlugin(),
          createBoldPlugin(),
          createHeadingPlugin(),
          createImagePlugin({
            props: {
              caption: {
                disabled: true,
              },
            },
          }),
          createSelectOnBackspacePlugin({
            options: {
              query: {
                allow: [ELEMENT_IMAGE],
              },
            },
          }),
          createIndentPlugin({
            inject: {
              props: {
                validTypes: [
                  ELEMENT_PARAGRAPH,
                  ELEMENT_H1,
                  ELEMENT_H2,
                  ELEMENT_BLOCKQUOTE,
                ],
              },
            },
          }),
          createItalicPlugin(),
          createParagraphPlugin(),
          createUnderlinePlugin(),
          createResetNodePlugin({
            options: {
              rules: [
                {
                  types: [ELEMENT_BLOCKQUOTE],
                  defaultType: ELEMENT_PARAGRAPH,
                  hotkey: "enter",
                  predicate: isBlockAboveEmpty,
                },
                {
                  types: [ELEMENT_BLOCKQUOTE],
                  defaultType: ELEMENT_PARAGRAPH,
                  hotkey: "backspace",
                  predicate: isSelectionAtBlockStart,
                },
              ],
            },
          }),
          createSoftBreakPlugin({
            options: {
              rules: [
                { hotkey: "shift+enter" },
                {
                  hotkey: "enter",
                  query: {
                    allow: [ELEMENT_BLOCKQUOTE],
                  },
                },
              ],
            },
          }),
          createExitBreakPlugin({
            options: {
              rules: [
                {
                  hotkey: "mod+enter",
                },
                {
                  hotkey: "mod+shift+enter",
                  before: true,
                },
                {
                  hotkey: "enter",
                  query: {
                    start: true,
                    end: true,
                    allow: KEYS_HEADING,
                  },
                },
              ],
            },
          }),
        ],
        {
          components: createPlateUI(),
        }
      ),
    []
  );

  return (
    <Box sx={{ wordBreak: "break-all" }}>
      <PlateProvider
        plugins={plugins}
        initialValue={value}
        onChange={(rawContent) => {
          const serializedRawContent = rawContent
            .map((x) => Node.string(x))
            .join("\n");

          onChange({
            rawContent,
            serializedRawContent,
          });
        }}
      >
        {toolbar}
        <Plate
          editableProps={{
            placeholder,
            readOnly,
          }}
        />
      </PlateProvider>
    </Box>
  );
};

RichTextEditor.propTypes = {
  placeholder: PropTypes.node,
  readOnly: PropTypes.bool,
  toolbar: PropTypes.node,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RichTextEditor;
