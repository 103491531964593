import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  Popper,
} from "@mui/material";

import {
  AccountAvatar,
  AccountMenuItemAlert,
  AccountMenuItems,
  AccountMenuHeader,
} from "@vesta/components/molecules";

const AccountMenu = () => {
  const anchorRef = useRef(null);
  const { t } = useTranslation("components");
  const [open, setOpen] = useState(false);

  const handleClickAway = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current.focus(); // return focus to the button when we transitioned from !open -> open
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <IconButton
        ref={anchorRef}
        edge="end"
        aria-label={t("molecules.accountMenu.triggerAriaLabel")}
        aria-haspopup="true"
        aria-controls={open ? "account-menu-items" : undefined}
        onClick={() => setOpen((prevValue) => !prevValue)}
        size="large"
      >
        <AccountAvatar />
      </IconButton>
      <Popper
        sx={(theme) => ({ zIndex: theme.zIndex.appBar + 1 })}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} sx={{ transformOrigin: "center top" }}>
            <Box>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box
                  sx={(theme) => ({
                    background: theme.palette.background.paper,
                    borderRadius: theme.spacing(1),
                    boxShadow: theme.shadows[2],
                    maxWidth: theme.spacing(30),
                    [theme.breakpoints.up("sm")]: {
                      maxWidth: theme.spacing(50),
                    },
                  })}
                >
                  <AccountMenuHeader />
                  <AccountMenuItemAlert />
                  <AccountMenuItems
                    id="account-menu-items"
                    autoFocus={open}
                    onKeyDown={handleKeyDown}
                    onClick={() => setOpen(false)}
                  />
                </Box>
              </ClickAwayListener>
            </Box>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default AccountMenu;
