import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";

import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import { plans } from "@vesta/lib/enum";

const CycleRadioGroup = ({ selectedCycle, selectedPlan, onChange }) => {
  const { t } = useTranslation("global-modals");

  const cycles = t("choosePlanDialog.step1.cycles", { returnObjects: true });

  const renderAnnualDiscount = () => {
    const currentSubscriptionPlan = plans.find(
      (plan) => plan.name === selectedPlan && plan.cycle === "annually"
    );

    if (
      currentSubscriptionPlan &&
      currentSubscriptionPlan.annualDiscount !== null
    ) {
      return (
        <Typography variant="caption">
          {t("choosePlanDialog.step1.cycleAnnualDiscountLabel", {
            annualDiscount: currentSubscriptionPlan.annualDiscount,
          })}
        </Typography>
      );
    }

    return null;
  };

  const getFormattedLabel = (cycle) => {
    return (
      <Typography>
        {cycle.name}
        {cycle.id === "annually" && renderAnnualDiscount(cycle)}
      </Typography>
    );
  };

  return (
    <FormControl fullWidth variant="standard" component="fieldset">
      <Box sx={{ pb: 2 }}>
        <InputLabel component="legend" shrink>
          {t("choosePlanDialog.step1.cyclesTitle")}
        </InputLabel>
      </Box>
      <RadioGroup name="cycle" value={selectedCycle} onChange={onChange}>
        {cycles.map((cycle) => {
          return (
            <FormControlLabel
              key={cycle.id}
              value={cycle.id}
              control={<Radio />}
              label={getFormattedLabel(cycle)}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

CycleRadioGroup.propTypes = {
  selectedCycle: PropTypes.string.isRequired,
  selectedPlan: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CycleRadioGroup;
