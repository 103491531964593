import { fr } from "date-fns/locale";
import {
  endOfWeek,
  format,
  isSameMonth,
  isSameYear,
  startOfWeek,
} from "date-fns";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { Views } from "react-big-calendar";

import { Button } from "@mui/material";

import { DelayedCircularProgress } from "@vesta/components/atoms";
import { useUpSm } from "@vesta/lib/react";

const weekStartsOn = 1; // Monday

const useTitle = (date, view) => {
  const upSm = useUpSm();

  const memoizedTitle = useMemo(() => {
    let title = "";

    if (view === Views.DAY) {
      const formattedDate = format(date, "PP", { locale: fr });
      title = upSm
        ? formattedDate
        : formattedDate.substring(0, formattedDate.length - 5);
    }

    if (view === Views.WEEK) {
      const start = startOfWeek(date, { weekStartsOn });
      const end = endOfWeek(date, { weekStartsOn });

      if (isSameMonth(start, end)) {
        title = format(start, "MMMM yyyy", { locale: fr });
      } else if (isSameYear(start, end)) {
        const formattedStart = format(start, "MMM", { locale: fr });
        const formattedEnd = format(end, "MMM yyyy", { locale: fr });
        title = `${formattedStart} – ${formattedEnd}`;
      } else {
        const formattedStart = format(start, "MMM yyyy", { locale: fr });
        const formattedEnd = format(end, "MMM yyyy", { locale: fr });
        title = `${formattedStart} – ${formattedEnd}`;
      }
    }

    return title;
  }, [date, view, upSm]);

  return memoizedTitle;
};

const TitleButton = ({ loading, date, view, onClick }) => {
  const title = useTitle(date, view);

  return (
    <Button
      sx={{ textTransform: "capitalize" }}
      onClick={onClick}
      startIcon={
        loading && (
          <DelayedCircularProgress
            sx={(theme) => ({ marginRight: theme.spacing(1) })}
            size={20}
          />
        )
      }
    >
      {title}
    </Button>
  );
};

TitleButton.propTypes = {
  loading: PropTypes.bool,
  date: PropTypes.instanceOf(Date).isRequired,
  view: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TitleButton;
