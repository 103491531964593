import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Fab } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Delete } from "@mui/icons-material";

import { useUpSm } from "@vesta/lib/react";

const StyledSpan = styled("span")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const DeleteServiceFloatingButton = ({ sx, onClick }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("service");

  return (
    <Fab
      sx={[
        (theme) => ({
          backgroundColor: theme.palette.error.main,
          "&:hover": {
            backgroundColor: theme.palette.error.dark,
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      color="primary"
      size={upSm ? "large" : "medium"}
      variant="extended"
      onClick={onClick}
    >
      <Delete />
      <StyledSpan>{t("deleteServiceFloatingButton.deleteLabel")}</StyledSpan>
    </Fab>
  );
};

DeleteServiceFloatingButton.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  onClick: PropTypes.func.isRequired,
};

export default DeleteServiceFloatingButton;
