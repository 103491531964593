import PropTypes from "prop-types";

import { CircularProgress } from "@mui/material";

import { useDelayedRender } from "@vesta/lib/react";

const DelayedCircularProgress = ({ sx, delay, ...rest }) => {
  const delayedRender = useDelayedRender(delay);

  return delayedRender((delayed) => (
    <CircularProgress
      sx={[
        { visibility: delayed ? "hidden" : null },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    />
  ));
};

DelayedCircularProgress.defaultProps = {
  delay: 300,
};

DelayedCircularProgress.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  delay: PropTypes.number,
};

export default DelayedCircularProgress;
