import { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Navigate,
  Route,
  Routes,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { Container, Grid } from "@mui/material";

import { BackButton, Loader, SuccessAlert } from "@vesta/components/atoms";
import { AppointmentContext, useUpSm } from "@vesta/lib/react";

import {
  AppointmentHeader,
  CancelledAlert,
  DemoPatientAlert,
  RelationshipAlert,
} from "./components";
import { useAppointmentReducer } from "./hooks";

const Directions = lazy(() => import("./components/Directions"));
const Note = lazy(() => import("./components/Note"));
const Preparation = lazy(() => import("./components/Preparation"));

const AppointmentView = () => {
  const upSm = useUpSm();
  const { t } = useTranslation("appointment");
  const { appointmentId } = useParams();
  const [searchParams] = useSearchParams();
  const [created, setCreated] = useState(searchParams.has("created"));
  const [appointment, dispatch] = useAppointmentReducer(
    appointmentId,
    searchParams
  );

  const value = useMemo(() => [appointment, dispatch], [appointment, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [appointmentId]);

  return (
    <AppointmentContext.Provider value={value}>
      <AppointmentHeader />
      <Container
        sx={(theme) => ({
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
          [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
          },
        })}
        maxWidth={false}
      >
        <Grid container direction="column" spacing={upSm ? 3 : 2}>
          <Grid item>
            <BackButton to="/calendar/appointments" label={t("backLabel")} />
          </Grid>
          {appointment?.cancelled && (
            <Grid item>
              <CancelledAlert />
            </Grid>
          )}
          {appointment?.isDemoPatient && (
            <Grid item>
              <DemoPatientAlert />
            </Grid>
          )}
          {appointment?.isAppointmentForSomeoneElse && (
            <Grid item>
              <RelationshipAlert />
            </Grid>
          )}
          <Grid item>
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/preparation" element={<Preparation />} />
                <Route path="/directions" element={<Directions />} />
                <Route path="/note" element={<Note />} />
                <Route
                  path="*"
                  element={
                    <Navigate
                      to={`/calendar/appointments/${appointmentId}/preparation`}
                      replace
                    />
                  }
                />
              </Routes>
            </Suspense>
          </Grid>
        </Grid>
      </Container>
      <SuccessAlert
        open={created}
        onClose={() => setCreated(false)}
        content={t("createdSuccessAlert")}
      />
    </AppointmentContext.Provider>
  );
};

export default AppointmentView;
