import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState } from "react";

import { Alert, AlertTitle, Box } from "@mui/material";
import { EmojiObjectsOutlined } from "@mui/icons-material";

import { ContactUsLink, ErrorAlert } from "@vesta/components/atoms";
import {
  BodyWithTips,
  MinimalNavigationHeader,
} from "@vesta/components/organisms";
import {
  useAppContext,
  useScrollToTopOnMount,
  useSession,
} from "@vesta/lib/react";

import { Step1, Step2 } from "./components";

const AddTerritoryView = () => {
  const navigate = useNavigate();
  const matchesStep1 = useMatch("/profile/add-territory/1");
  const matchesStep2 = useMatch("/profile/add-territory/2");
  const { t } = useTranslation("add-territory");
  const [, jwtToken] = useSession();
  const [, dispatch] = useAppContext();
  const [points, setPoints] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [detectError, setDetectError] = useState(false);
  const [createTerritoryError, setCreateTerritoryError] = useState(false);
  const [intersectError, setIntersectError] = useState(false);

  useScrollToTopOnMount();

  useEffect(() => {
    if (points) {
      return;
    }
    if (matchesStep2) {
      navigate("/profile/add-territory/1", { replace: true });
    }
  }, [matchesStep2, points]);

  const handleSubmit = async ({ name }) => {
    setSubmitting(true);

    const url = new URL(
      "/commands/create-territory-as-polygon",
      process.env.REACT_APP_API_BASE_URL
    );

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        name,
        points,
      }),
    });

    if (response.ok) {
      const { territoryId } = await response.json();
      dispatch({ type: "create-territory-as-polygon" });
      navigate(`/profile/territories/${territoryId}?added`);
    } else {
      setSubmitting(false);
      setCreateTerritoryError(true);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column",
        height: "100vh",
      }}
    >
      <Helmet>
        <title>{t("title")}</title>
        <meta property="og:title" content={t("title")} />
        <meta property="og:description" content={t("description")} />
      </Helmet>
      <MinimalNavigationHeader
        backTo="/profile/territories"
        backLabel={t("backLabel")}
      />
      <BodyWithTips
        progressValue={
          matchesStep1 ? 0 : matchesStep2 ? 50 : submitting ? 100 : null
        }
        tips={[
          <Alert
            key="tip"
            component="aside"
            icon={<EmojiObjectsOutlined fontSize="inherit" />}
            severity="info"
          >
            <AlertTitle>{t("tip.title")}</AlertTitle>
            {t("tip.text")}
          </Alert>,
        ]}
      >
        <Routes>
          <Route
            path="1"
            element={
              <Step1
                points={points}
                onDetect={setPoints}
                onDetectError={() => setDetectError(true)}
                onCreate={setPoints}
                onEdit={setPoints}
                onIntersect={() => setIntersectError(true)}
                onNext={() => navigate("/profile/add-territory/2")}
              />
            }
          />
          <Route
            path="2"
            element={
              <Step2
                onBack={() => navigate("/profile/add-territory/1")}
                onSubmit={handleSubmit}
              />
            }
          />
          <Route
            path="*"
            element={<Navigate to="/profile/add-territory/1" replace />}
          />
          <Route
            index
            element={<Navigate to="/profile/add-territory/1" replace />}
          />
        </Routes>
      </BodyWithTips>
      <ErrorAlert
        open={detectError}
        onClose={() => setDetectError(false)}
        content={t("alerts.detectError")}
      />
      <ErrorAlert
        open={createTerritoryError}
        onClose={() => setCreateTerritoryError(false)}
        content={
          <Trans
            i18nKey="add-territory:alerts.createTerritoryError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
      <ErrorAlert
        autoHide
        open={intersectError}
        onClose={() => setIntersectError(false)}
        content={t("alerts.intersectError")}
      />
    </Box>
  );
};

export default AddTerritoryView;
