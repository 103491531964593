import PropTypes from "prop-types";

import { Box, Container, Skeleton, Typography } from "@mui/material";

const SecondaryHeader = ({ sx, loading, title }) => (
  <Box
    sx={[
      (theme) => ({
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
        minHeight: theme.spacing(16),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
          minHeight: theme.spacing(18),
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
        },
      }),
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    <Container maxWidth="xl">
      <Typography
        sx={(theme) => ({
          color: theme.palette.getContrastText(theme.palette.primary.main),
        })}
        variant="h1"
      >
        {loading ? <Skeleton width="60%" /> : title}
      </Typography>
    </Container>
  </Box>
);

SecondaryHeader.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  loading: PropTypes.bool,
  title: PropTypes.node,
};

export default SecondaryHeader;
