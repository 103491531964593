import { useCallback, useEffect, useReducer } from "react";

import {
  useAuthorizedFetchJson,
  useInterval,
  usePageVisibility,
  useSession,
} from "@vesta/lib/react";

const useGetAppointmentDetails = (appointmentId, searchParams) => {
  const isVisible = usePageVisibility();
  const [, jwtToken] = useSession();
  const [fetchJson, json] = useAuthorizedFetchJson(jwtToken);

  const getAppointmentDetails = useCallback(
    () =>
      fetchJson(
        new URL(
          `queries/get-appointment-details-by-appointment-id/${appointmentId}`,
          process.env.REACT_APP_API_BASE_URL
        )
      ),
    [appointmentId]
  );

  useEffect(() => {
    getAppointmentDetails();
  }, [getAppointmentDetails]);

  const created = searchParams.has("created");

  const refreshAppointmentDetails = useCallback(async () => {
    if (json) {
      return;
    }

    if (created) {
      await getAppointmentDetails();
    }
  }, [json, created, getAppointmentDetails]);

  useInterval(refreshAppointmentDetails, isVisible ? 2000 : null);

  return json;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "init": {
      const {
        patientBirthday,
        patientRamqHealthInsuranceExpiration,
        someoneElse,
        notes,
        start,
        end,
        submitted,
        cancelled,
      } = action.payload;

      return {
        ...action.payload,
        patientBirthday: patientBirthday ? new Date(patientBirthday) : null,
        patientRamqHealthInsuranceExpiration:
          patientRamqHealthInsuranceExpiration
            ? new Date(patientRamqHealthInsuranceExpiration)
            : null,
        someoneElse: someoneElse
          ? {
              ...someoneElse,
              birthday: new Date(someoneElse.birthday),
              ramqHealthInsuranceExpiration:
                someoneElse.ramqHealthInsuranceExpiration
                  ? new Date(someoneElse.ramqHealthInsuranceExpiration)
                  : null,
            }
          : null,
        notes: notes.map((x) => ({
          ...x,
          rawContent: JSON.parse(x.rawContent),
          attachments: x.attachments.map((y) => ({
            ...y,
            created: new Date(y.created),
            deleted: y.deleted ? new Date(y.deleted) : null,
          })),
          created: new Date(x.created),
          modified: new Date(x.modified),
          completed: x.completed ? new Date(x.completed) : null,
        })),
        start: new Date(start),
        end: new Date(end),
        submitted: new Date(submitted),
        cancelled: cancelled ? new Date(cancelled) : null,
        canCancel: !cancelled && new Date(start) >= Date.now(),
      };
    }
    case "adding-note-to-patient-medical-record": {
      return {
        ...state,
        addingNote: true,
      };
    }
    case "add-note-to-patient-medical-record": {
      const {
        noteId,
        noteVersionId,
        addendumTo,
        appointmentId,
        rawContent,
        attachments,
        created,
        modified,
      } = action.payload;

      return {
        ...state,
        addingNote: false,
        notes: [
          ...state.notes,
          {
            noteId,
            noteVersionId,
            addendumTo,
            appointmentId,
            rawContent,
            attachments,
            created,
            modified,
          },
        ],
      };
    }
    case "cancelling-appointment": {
      return {
        ...state,
        cancelling: true,
      };
    }
    case "cancel-appointment": {
      const { cancelled } = action.payload;

      return {
        ...state,
        cancelling: false,
        canCancel: false,
        cancelled,
      };
    }
    case "change-note-raw-content": {
      const { noteId, rawContent } = action.payload;

      return {
        ...state,
        notes: [
          ...state.notes.filter((x) => x.noteId !== noteId),
          {
            ...state.notes.find((x) => x.noteId === noteId),
            rawContent,
          },
        ],
      };
    }
    case "adding-note-version-to-patient-medical-record": {
      const { noteId } = action.payload;

      return {
        ...state,
        notes: [
          ...state.notes.filter((x) => x.noteId !== noteId),
          {
            ...state.notes.find((x) => x.noteId === noteId),
            addingVersion: true,
          },
        ],
      };
    }
    case "add-note-version-to-patient-medical-record": {
      const { noteId, noteVersionId, rawContent, modified } = action.payload;

      const prevNote = state.notes.find((x) => x.noteId === noteId);
      const noteVersionChanged = prevNote.noteVersionId !== noteVersionId;

      return {
        ...state,
        notes: [
          ...state.notes.filter((x) => x.noteId !== noteId),
          {
            ...prevNote,
            addingVersion: false,
            noteVersionId,
            rawContent,
            modified: noteVersionChanged ? modified : prevNote.modified,
          },
        ],
      };
    }
    case "uploading-note-attachment-to-patient-medical-record": {
      const {
        noteId,
        noteAttachment: { noteAttachmentId, name, typeId, created },
      } = action.payload;

      const note = state.notes.find((x) => x.noteId === noteId);

      return {
        ...state,
        notes: [
          ...state.notes.filter((x) => x.noteId !== noteId),
          {
            ...note,
            attachments: [
              ...note.attachments,
              {
                noteAttachmentId,
                name,
                typeId,
                created,
                uploading: true,
                uploadingProgress: 0,
              },
            ],
          },
        ],
      };
    }
    case "continue-uploading-note-attachment-to-patient-medical-record": {
      const {
        noteId,
        noteAttachment: { noteAttachmentId, uploadingProgress },
      } = action.payload;

      const note = state.notes.find((x) => x.noteId === noteId);

      return {
        ...state,
        notes: [
          ...state.notes.filter((x) => x.noteId !== noteId),
          {
            ...note,
            attachments: [
              ...note.attachments.filter(
                (x) => x.noteAttachmentId !== noteAttachmentId || !x.uploading
              ),
              {
                ...note.attachments.find(
                  (x) => x.noteAttachmentId === noteAttachmentId && x.uploading
                ),
                uploadingProgress,
              },
            ],
          },
        ],
      };
    }
    case "cancel-uploading-note-attachment-to-patient-medical-record": {
      const {
        noteId,
        noteAttachment: { noteAttachmentId },
      } = action.payload;

      const note = state.notes.find((x) => x.noteId === noteId);

      return {
        ...state,
        notes: [
          ...state.notes.filter((x) => x.noteId !== noteId),
          {
            ...note,
            attachments: [
              ...note.attachments.filter(
                (x) => x.noteAttachmentId !== noteAttachmentId || !x.uploading
              ),
            ],
          },
        ],
      };
    }
    case "add-note-attachment-to-patient-medical-record": {
      const {
        noteId,
        noteAttachment: { noteAttachmentId, newNoteAttachmentId },
        modified,
      } = action.payload;

      const note = state.notes.find((x) => x.noteId === noteId);

      return {
        ...state,
        notes: [
          ...state.notes.filter((x) => x.noteId !== noteId),
          {
            ...note,
            attachments: [
              ...note.attachments.filter(
                (x) => x.noteAttachmentId !== noteAttachmentId || !x.uploading
              ),
              {
                ...note.attachments.find(
                  (x) => x.noteAttachmentId === noteAttachmentId && x.uploading
                ),
                noteAttachmentId: newNoteAttachmentId,
                uploading: false,
              },
            ],
            modified,
          },
        ],
      };
    }
    case "delete-note-attachment-from-patient-medical-record": {
      const {
        noteId,
        noteAttachment: { noteAttachmentId, deleted },
        modified,
      } = action.payload;

      const note = state.notes.find((x) => x.noteId === noteId);

      return {
        ...state,
        notes: [
          ...state.notes.filter((x) => x.noteId !== noteId),
          {
            ...note,
            attachments: [
              ...note.attachments.filter(
                (x) => x.noteAttachmentId !== noteAttachmentId
              ),
              {
                ...note.attachments.find(
                  (x) => x.noteAttachmentId !== noteAttachmentId
                ),
                deleted,
              },
            ],
            modified,
          },
        ],
      };
    }
    case "completing-note-in-patient-medical-record": {
      const { noteId } = action.payload;

      return {
        ...state,
        notes: [
          ...state.notes.filter((x) => x.noteId !== noteId),
          {
            ...state.notes.find((x) => x.noteId === noteId),
            completing: true,
          },
        ],
      };
    }
    case "complete-note-in-patient-medical-record": {
      const { noteId, modified, completed, completedBy } = action.payload;

      return {
        ...state,
        notes: [
          ...state.notes.filter((x) => x.noteId !== noteId),
          {
            ...state.notes.find((x) => x.noteId === noteId),
            completing: false,
            modified,
            completed,
            completedBy,
          },
        ],
      };
    }
    default:
      throw new Error(`The action type ${action.type} cannot be handled`);
  }
};

export const useAppointmentReducer = (appointmentId, searchParams) => {
  const appointmentDetails = useGetAppointmentDetails(
    appointmentId,
    searchParams
  );
  const [state, dispatch] = useReducer(reducer);

  useEffect(() => {
    if (appointmentDetails) {
      dispatch({ type: "init", payload: appointmentDetails });
    }
  }, [appointmentDetails]);

  return [state, dispatch];
};
