import { formatISO } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  useAuthorizedFetchJson,
  useInterval,
  usePageVisibility,
  useSession,
} from "@vesta/lib/react";

export const useAppointmentsDailySummary = () => {
  const isVisible = usePageVisibility();
  const [searchParams] = useSearchParams();
  const [, jwtToken] = useSession();
  const [fetchJson, json] = useAuthorizedFetchJson(jwtToken);
  const [appointmentsDailySummary, setAppointmentsDailySummary] = useState();

  const getAppointmentsDailySummary = useCallback(async () => {
    const today = formatISO(new Date(), { representation: "date" });
    await fetchJson(
      new URL(
        `/queries/get-appointments-daily-summary-by-date/${today}`,
        process.env.REACT_APP_API_BASE_URL
      )
    );
  }, []);

  useEffect(() => {
    getAppointmentsDailySummary();
  }, []);

  const finished = searchParams.get("finished");

  const refreshAppointmentsDailySummary = useCallback(async () => {
    if (finished === "v1") {
      await getAppointmentsDailySummary();
    }
  }, [finished, getAppointmentsDailySummary]);

  useInterval(refreshAppointmentsDailySummary, isVisible ? 2000 : null);

  useEffect(() => {
    if (json) {
      const withDates = (appointment) => ({
        ...appointment,
        submitted: new Date(appointment.submitted),
        start: new Date(appointment.start),
        cancelled: appointment.cancelled
          ? new Date(appointment.cancelled)
          : null,
      });

      const submittedYesterday =
        json?.submittedYesterday
          .map(withDates)
          .sort((a, b) => a.start - b.start) ?? [];

      const cancelledYesterday =
        json?.cancelledYesterday
          .map(withDates)
          .sort((a, b) => a.start - b.start) ?? [];

      const startingToday =
        json?.startingToday
          .map(withDates)
          .filter((x) => !x.cancelled)
          .sort((a, b) => a.start - b.start) ?? [];

      const startingTomorrow =
        json?.startingTomorrow
          .map(withDates)
          .filter((x) => !x.cancelled)
          .sort((a, b) => a.start - b.start) ?? [];

      setAppointmentsDailySummary({
        submittedYesterday,
        cancelledYesterday,
        startingToday,
        startingTomorrow,
      });
    }
  }, [json]);

  return [appointmentsDailySummary, setAppointmentsDailySummary];
};
