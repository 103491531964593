import { useCallback, useEffect, useReducer } from "react";

import { useAuthorizedFetchJson, useSession } from "@vesta/lib/react";

const useGetMedicalRecord = (patientId, someoneElseId) => {
  const [, jwtToken] = useSession();
  const [fetchJson, json] = useAuthorizedFetchJson(jwtToken);

  const getMedicalRecordByPatientId = useCallback(
    () =>
      fetchJson(
        new URL(
          someoneElseId
            ? `queries/get-medical-record-by-patient-id/${patientId}?` +
              new URLSearchParams({
                someoneElseId,
              })
            : `queries/get-medical-record-by-patient-id/${patientId}`,
          process.env.REACT_APP_API_BASE_URL
        )
      ),
    [patientId, someoneElseId]
  );

  useEffect(() => {
    getMedicalRecordByPatientId();
  }, [getMedicalRecordByPatientId]);

  return json;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "init": {
      const { personalInformation, medicalInformation } = action.payload;

      return {
        ...action.payload,
        personalInformation: {
          ...personalInformation,
          birthday: new Date(personalInformation.birthday),
          fullName: `${personalInformation.firstName} ${personalInformation.lastName}`,
          phoneNumbers: personalInformation.phoneNumbers.map((x) => ({
            ...x,
            created: new Date(x.created),
          })),
        },
        medicalInformation: {
          ...medicalInformation,
          appointments: medicalInformation.appointments.map((x) => ({
            ...x,
            start: new Date(x.start),
            end: new Date(x.end),
            cancelled: x.cancelled ? new Date(x.cancelled) : null,
          })),
          notes: medicalInformation.notes.map((x) => ({
            ...x,
            rawContent: JSON.parse(x.rawContent),
            attachments: x.attachments.map((y) => ({
              ...y,
              created: new Date(y.created),
              deleted: y.deleted ? new Date(y.deleted) : null,
            })),
            created: new Date(x.created),
            modified: new Date(x.modified),
            completed: x.completed ? new Date(x.completed) : null,
          })),
          results: medicalInformation.results.map((x) => ({
            ...x,
            files: x.files.map((y) => ({
              ...y,
              created: new Date(y.created),
            })),
            created: new Date(x.created),
          })),
        },
      };
    }
    case "adding-note-to-patient-medical-record": {
      return {
        ...state,
        medicalInformation: {
          ...state.medicalInformation,
          addingNote: true,
        },
      };
    }
    case "add-note-to-patient-medical-record": {
      const {
        noteId,
        noteVersionId,
        addendumTo,
        appointmentId,
        rawContent,
        attachments,
        created,
        modified,
      } = action.payload;

      return {
        ...state,
        medicalInformation: {
          ...state.medicalInformation,
          addingNote: false,
          notes: [
            ...state.medicalInformation.notes,
            {
              noteId,
              noteVersionId,
              addendumTo,
              appointmentId,
              rawContent,
              attachments,
              created,
              modified,
            },
          ],
        },
      };
    }
    case "change-note-raw-content": {
      const { noteId, rawContent } = action.payload;

      return {
        ...state,
        medicalInformation: {
          ...state.medicalInformation,
          notes: [
            ...state.medicalInformation.notes.filter(
              (x) => x.noteId !== noteId
            ),
            {
              ...state.medicalInformation.notes.find(
                (x) => x.noteId === noteId
              ),
              rawContent,
            },
          ],
        },
      };
    }
    case "adding-note-version-to-patient-medical-record": {
      const { noteId } = action.payload;

      return {
        ...state,
        medicalInformation: {
          ...state.medicalInformation,
          notes: [
            ...state.medicalInformation.notes.filter(
              (x) => x.noteId !== noteId
            ),
            {
              ...state.medicalInformation.notes.find(
                (x) => x.noteId === noteId
              ),
              addingVersion: true,
            },
          ],
        },
      };
    }
    case "add-note-version-to-patient-medical-record": {
      const { noteId, noteVersionId, rawContent, modified } = action.payload;

      const prevNote = state.medicalInformation.notes.find(
        (x) => x.noteId === noteId
      );

      const noteVersionChanged = prevNote.noteVersionId !== noteVersionId;

      return {
        ...state,
        medicalInformation: {
          ...state.medicalInformation,
          notes: [
            ...state.medicalInformation.notes.filter(
              (x) => x.noteId !== noteId
            ),
            {
              ...prevNote,
              addingVersion: false,
              noteVersionId,
              rawContent,
              modified: noteVersionChanged ? modified : prevNote.modified,
            },
          ],
        },
      };
    }
    case "uploading-note-attachment-to-patient-medical-record": {
      const {
        noteId,
        noteAttachment: { noteAttachmentId, name, typeId, created },
      } = action.payload;

      const note = state.medicalInformation.notes.find(
        (x) => x.noteId === noteId
      );

      return {
        ...state,
        medicalInformation: {
          ...state.medicalInformation,
          notes: [
            ...state.medicalInformation.notes.filter(
              (x) => x.noteId !== noteId
            ),
            {
              ...note,
              attachments: [
                ...note.attachments,
                {
                  noteAttachmentId,
                  name,
                  typeId,
                  created,
                  uploading: true,
                  uploadingProgress: 0,
                },
              ],
            },
          ],
        },
      };
    }
    case "continue-uploading-note-attachment-to-patient-medical-record": {
      const {
        noteId,
        noteAttachment: { noteAttachmentId, uploadingProgress },
      } = action.payload;

      const note = state.medicalInformation.notes.find(
        (x) => x.noteId === noteId
      );

      return {
        ...state,
        medicalInformation: {
          ...state.medicalInformation,
          notes: [
            ...state.medicalInformation.notes.filter(
              (x) => x.noteId !== noteId
            ),
            {
              ...note,
              attachments: [
                ...note.attachments.filter(
                  (x) => x.noteAttachmentId !== noteAttachmentId || !x.uploading
                ),
                {
                  ...note.attachments.find(
                    (x) =>
                      x.noteAttachmentId === noteAttachmentId && x.uploading
                  ),
                  uploadingProgress,
                },
              ],
            },
          ],
        },
      };
    }
    case "cancel-uploading-note-attachment-to-patient-medical-record": {
      const {
        noteId,
        noteAttachment: { noteAttachmentId },
      } = action.payload;

      const note = state.medicalInformation.notes.find(
        (x) => x.noteId === noteId
      );

      return {
        ...state,
        medicalInformation: {
          ...state.medicalInformation,
          notes: [
            ...state.medicalInformation.notes.filter(
              (x) => x.noteId !== noteId
            ),
            {
              ...note,
              attachments: [
                ...note.attachments.filter(
                  (x) => x.noteAttachmentId !== noteAttachmentId || !x.uploading
                ),
              ],
            },
          ],
        },
      };
    }
    case "add-note-attachment-to-patient-medical-record": {
      const {
        noteId,
        noteAttachment: { noteAttachmentId, newNoteAttachmentId },
        modified,
      } = action.payload;

      const note = state.medicalInformation.notes.find(
        (x) => x.noteId === noteId
      );

      return {
        ...state,
        medicalInformation: {
          ...state.medicalInformation,
          notes: [
            ...state.medicalInformation.notes.filter(
              (x) => x.noteId !== noteId
            ),
            {
              ...note,
              attachments: [
                ...note.attachments.filter(
                  (x) => x.noteAttachmentId !== noteAttachmentId || !x.uploading
                ),
                {
                  ...note.attachments.find(
                    (x) =>
                      x.noteAttachmentId === noteAttachmentId && x.uploading
                  ),
                  noteAttachmentId: newNoteAttachmentId,
                  uploading: false,
                },
              ],
              modified,
            },
          ],
        },
      };
    }
    case "delete-note-attachment-from-patient-medical-record": {
      const {
        noteId,
        noteAttachment: { noteAttachmentId, deleted },
        modified,
      } = action.payload;

      const note = state.medicalInformation.notes.find(
        (x) => x.noteId === noteId
      );

      return {
        ...state,
        medicalInformation: {
          ...state.medicalInformation,
          notes: [
            ...state.medicalInformation.notes.filter(
              (x) => x.noteId !== noteId
            ),
            {
              ...note,
              attachments: [
                ...note.attachments.filter(
                  (x) => x.noteAttachmentId !== noteAttachmentId
                ),
                {
                  ...note.attachments.find(
                    (x) => x.noteAttachmentId !== noteAttachmentId
                  ),
                  deleted,
                },
              ],
              modified,
            },
          ],
        },
      };
    }
    case "completing-note-in-patient-medical-record": {
      const { noteId } = action.payload;

      return {
        ...state,
        medicalInformation: {
          ...state.medicalInformation,
          notes: [
            ...state.medicalInformation.notes.filter(
              (x) => x.noteId !== noteId
            ),
            {
              ...state.medicalInformation.notes.find(
                (x) => x.noteId === noteId
              ),
              completing: true,
            },
          ],
        },
      };
    }
    case "complete-note-in-patient-medical-record": {
      const { noteId, modified, completed, completedBy } = action.payload;

      return {
        ...state,
        medicalInformation: {
          ...state.medicalInformation,
          notes: [
            ...state.medicalInformation.notes.filter(
              (x) => x.noteId !== noteId
            ),
            {
              ...state.medicalInformation.notes.find(
                (x) => x.noteId === noteId
              ),
              completing: false,
              modified,
              completed,
              completedBy,
            },
          ],
        },
      };
    }
    case "add-result-to-patient-medical-record": {
      const { resultId, files, created, createdBy } = action.payload;

      return {
        ...state,
        medicalInformation: {
          ...state.medicalInformation,
          results: [
            ...state.medicalInformation.results,
            {
              resultId,
              files,
              created,
              createdBy,
            },
          ],
        },
      };
    }
    default:
      throw new Error(`The action type ${action.type} cannot be handled`);
  }
};

export const useMedicalRecordReducer = (patientId, someoneElseId) => {
  const medicalRecord = useGetMedicalRecord(patientId, someoneElseId);
  const [state, dispatch] = useReducer(reducer);

  useEffect(() => {
    if (medicalRecord) {
      dispatch({ type: "init", payload: medicalRecord });
    }
  }, [medicalRecord]);

  return [state, dispatch];
};
