import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { TextField } from "@mui/material";

import { RamqHealthInsuranceNumberMask } from "./components";

const RamqHealthInsuranceNumberTextField = ({ readOnly, ...rest }) => {
  const { t } = useTranslation("components");

  return (
    <TextField
      variant="standard"
      fullWidth
      label={t("molecules.ramqHealthInsuranceNumberTextField.label")}
      placeholder={
        readOnly
          ? null
          : t("molecules.ramqHealthInsuranceNumberTextField.placeholder")
      }
      InputProps={{
        readOnly,
        inputComponent: RamqHealthInsuranceNumberMask,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      {...rest}
    />
  );
};

RamqHealthInsuranceNumberTextField.propTypes = {
  readOnly: PropTypes.bool,
};

export default RamqHealthInsuranceNumberTextField;
