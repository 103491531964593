import { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Container } from "@mui/material";

import { Header, SearchBar } from "@vesta/components/molecules";

import { PatientsTable, Summary } from "./components";
import { useSearchableMedicalRecords } from "./hooks";

const pageSize = 20;

const PatientsView = () => {
  const { t } = useTranslation("patients");
  const [searchParams, setSearchParams] = useSearchParams();
  const [sort, setSort] = useState({
    sortByName: true,
    sortByBirthday: false,
    isDescending: false,
  });

  const page = searchParams.has("page")
    ? parseInt(searchParams.get("page")) - 1
    : 0;

  const term = searchParams.get("term") ?? "";

  const searchableMedicalRecords = useSearchableMedicalRecords(
    term,
    sort,
    page,
    pageSize
  );

  return (
    <>
      <Helmet>
        <title>{t("title")}</title>
        <meta property="og:title" content={t("title")} />
        <meta property="og:description" content={t("description")} />
      </Helmet>
      <Header
        sx={(theme) => ({ marginBottom: theme.spacing(5) })}
        title={t("header.title")}
      />
      <Container
        sx={(theme) => ({ marginBottom: theme.spacing(1) })}
        maxWidth="lg"
      >
        <SearchBar
          sx={(theme) => ({ marginBottom: theme.spacing(3) })}
          placeholder={t("searchBarPlaceholder")}
          value={term}
          onChange={(term) =>
            setSearchParams({ page: 1, term }, { replace: true })
          }
        />
        <Summary
          sx={(theme) => ({ marginBottom: theme.spacing(2) })}
          loading={!searchableMedicalRecords}
          count={searchableMedicalRecords?.count}
        />
        <PatientsTable
          loading={!searchableMedicalRecords}
          count={searchableMedicalRecords?.count}
          data={searchableMedicalRecords?.data}
          sort={sort}
          onSortChange={setSort}
          pageSize={pageSize}
          page={page}
          onPageChange={(page) => setSearchParams({ page: page + 1, term })}
        />
      </Container>
    </>
  );
};

export default PatientsView;
