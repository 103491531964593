import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import {
  Avatar,
  Badge,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

import {
  ContactUsLink,
  ErrorAlert,
  LoadingButton,
} from "@vesta/components/atoms";
import { CropProfilePictureDialog } from "@vesta/components/organisms";
import {
  useOnboardingSessionV1,
  useScrollToTopOnMount,
  useSession,
  useUpLg,
  useUpSm,
} from "@vesta/lib/react";

const avatarSx = (theme) => ({
  margin: theme.spacing(4),
  height: theme.spacing(24),
  width: theme.spacing(24),
  [theme.breakpoints.up("sm")]: {
    height: theme.spacing(32),
    width: theme.spacing(32),
  },
  [theme.breakpoints.up("md")]: {
    height: theme.spacing(40),
    width: theme.spacing(40),
  },
});

const maxFileSize = 16777216; // 16 MB

const Step5 = ({ onPrevious, onSkip, onNext }) => {
  const upSm = useUpSm();
  const upLg = useUpLg();
  const { t } = useTranslation("onboarding-v1");
  const [, jwtToken] = useSession();
  const [onboardingSession, dispatch] = useOnboardingSessionV1();
  const [imgSrc, setImgSrc] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [savedProfilePictureBlob, setSavedProfilePictureBlob] = useState();
  const [savedProfilePictureUrl, setSavedProfilePictureUrl] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [profilePictureSizeTooLargeError, setProfilePictureSizeTooLargeError] =
    useState(false);
  const [modifyProfilePictureError, setModifyProfilePictureError] =
    useState(false);

  useScrollToTopOnMount();

  useEffect(() => {
    if (savedProfilePictureBlob) {
      const url = URL.createObjectURL(savedProfilePictureBlob);
      setSavedProfilePictureUrl(url);

      return () => URL.revokeObjectURL(url);
    }
  }, [savedProfilePictureBlob]);

  const handleNextClick = async () => {
    setSubmitting(true);

    const profilePictureId =
      onboardingSession.uploadedProfilePictureId ??
      onboardingSession.profilePictureId;

    const url = new URL(
      `/commands/modify-profile-picture/${profilePictureId}`,
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    if (ok) {
      dispatch({
        type: "modify-profile-picture",
        payload: {
          profilePictureId,
        },
      });
      await onNext();
    } else {
      setSubmitting(false);
      setModifyProfilePictureError(true);
    }
  };

  const handleFileChange = (event) => {
    const reader = new FileReader();
    reader.addEventListener("load", (loadEvent) => {
      setImgSrc(loadEvent.target.result);
      setOpenDialog(true);
    });
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleSave = async ({ blob }) => {
    if (blob.size > maxFileSize) {
      setProfilePictureSizeTooLargeError(true);
      return;
    }

    const url = new URL(
      "upload-profile-picture",
      process.env.REACT_APP_API_BASE_URL
    );

    const formData = new FormData();
    formData.append("profile-picture", blob);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      body: formData,
    });

    if (response.ok) {
      const { profilePictureId } = await response.json();
      dispatch({
        type: "upload-profile-picture",
        payload: {
          profilePictureId,
        },
      });
      setSavedProfilePictureBlob(blob);
      setOpenDialog(false);
    } else {
      setModifyProfilePictureError(true);
    }
  };

  const canSkip = !onboardingSession.profilePictureId;

  return (
    <>
      <Grid container direction="column" spacing={4} data-aos="fade-up">
        <Grid item>
          <Typography gutterBottom variant="h1">
            {t("onboardingSteps.step5.title")}
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
          item
        >
          {savedProfilePictureUrl ? (
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              badgeContent={
                <IconButton
                  aria-label={t("onboardingSteps.step5.actions.remove")}
                  edge="end"
                  onClick={() => setSavedProfilePictureUrl(null)}
                  size="large"
                >
                  <Delete fontSize={upSm ? "large" : "medium"} />
                </IconButton>
              }
            >
              <Avatar sx={avatarSx} src={savedProfilePictureUrl} />
            </Badge>
          ) : onboardingSession.profilePictureId ? (
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              badgeContent={
                <IconButton
                  aria-label={t("onboardingSteps.step5.actions.remove")}
                  edge="end"
                  onClick={() =>
                    dispatch({
                      type: "remove-profile-picture",
                    })
                  }
                  size="large"
                >
                  <Delete fontSize={upSm ? "large" : "medium"} />
                </IconButton>
              }
            >
              <Avatar
                sx={avatarSx}
                src={new URL(
                  `/download-profile-picture/md/${onboardingSession.profilePictureId}`,
                  process.env.REACT_APP_API_BASE_URL
                ).toString()}
              />
            </Badge>
          ) : (
            <Avatar sx={avatarSx} />
          )}
        </Grid>
        <Grid container justifyContent="space-between" item>
          <Grid item>
            <Button onClick={onPrevious} variant="outlined">
              {t("onboardingSteps.step5.actions.previous")}
            </Button>
          </Grid>
          <Grid item>
            {upLg && canSkip && (
              <Button
                sx={(theme) => ({ marginRight: theme.spacing(2) })}
                onClick={onSkip}
              >
                {t("onboardingSteps.step5.actions.skip")}
              </Button>
            )}
            {savedProfilePictureUrl || onboardingSession.profilePictureId ? (
              <LoadingButton
                disabled={submitting}
                loading={submitting}
                onClick={handleNextClick}
                variant="contained"
              >
                {t("onboardingSteps.step5.actions.next")}
              </LoadingButton>
            ) : (
              <>
                <input
                  id="add-profile-picture"
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
                <label htmlFor="add-profile-picture">
                  <Button component="span" color="primary" variant="contained">
                    {t("onboardingSteps.step5.actions.add")}
                  </Button>
                </label>
              </>
            )}
          </Grid>
        </Grid>
        {!upLg && canSkip && (
          <Grid container justifyContent="flex-end" item>
            <Grid item>
              <Button onClick={onSkip}>
                {t("onboardingSteps.step5.actions.skip")}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      <CropProfilePictureDialog
        open={openDialog}
        imgSrc={imgSrc}
        onSave={handleSave}
        onCancel={() => setOpenDialog(false)}
      />
      <ErrorAlert
        autoHide
        open={profilePictureSizeTooLargeError}
        onClose={() => setProfilePictureSizeTooLargeError(false)}
        content={t(
          "onboardingSteps.step5.alerts.profilePictureSizeTooLargeError"
        )}
      />
      <ErrorAlert
        open={modifyProfilePictureError}
        onClose={() => setModifyProfilePictureError(false)}
        content={
          <Trans
            i18nKey="onboarding-v1:onboardingSteps.step5.alerts.modifyProfilePictureError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
    </>
  );
};

Step5.propTypes = {
  onPrevious: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default Step5;
