import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Alert, Link as MuiLink, Typography } from "@mui/material";

const CannotCreateAppointmentAlert = () => {
  const { t } = useTranslation("calendar");

  return (
    <Alert
      sx={(theme) => ({ backgroundColor: theme.palette.background.paper })}
      component="aside"
      severity="warning"
      variant="outlined"
    >
      <Typography gutterBottom variant="body2">
        {t(
          "appointments.createAppointmentDialog.cannotCreateAppointmentAlert.text"
        )}
      </Typography>
      <MuiLink
        component={Link}
        to="/profile/services"
        color="primary"
        underline="always"
      >
        {t(
          "appointments.createAppointmentDialog.cannotCreateAppointmentAlert.ctaLabel"
        )}
      </MuiLink>
    </Alert>
  );
};

export default CannotCreateAppointmentAlert;
