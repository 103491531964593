import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useCallback } from "react";

import { TextField } from "@mui/material";

const noPrice = "";

const FormattedServicePriceTextField = ({
  className,
  value,
  readOnly,
  InputProps,
  InputLabelProps,
  ...rest
}) => {
  const { t } = useTranslation("components");

  const cadCurrency = new Intl.NumberFormat("fr", {
    style: "currency",
    currency: "CAD",
    currencyDisplay: "narrowSymbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formatPriceRange = useCallback(
    () =>
      t("molecules.formattedServicePriceTextField.priceRange", {
        minimumPrice: cadCurrency.format(value.price),
        maximumPrice: cadCurrency.format(value.maximumPrice),
      }),
    [value]
  );

  const formatMinimumPrice = useCallback(
    () =>
      t("molecules.formattedServicePriceTextField.minimumPrice", {
        price: cadCurrency.format(value.price),
      }),
    [value]
  );

  const formatPrice = useCallback(
    () => cadCurrency.format(value.price),
    [value]
  );

  return (
    <TextField
      variant="standard"
      className={className}
      fullWidth
      label={t("molecules.formattedServicePriceTextField.label")}
      InputProps={{
        ...InputProps,
        readOnly,
      }}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
      }}
      value={
        value
          ? value.maximumPrice
            ? formatPriceRange()
            : value.isMinimumPrice
            ? formatMinimumPrice()
            : formatPrice()
          : noPrice
      }
      {...rest}
    />
  );
};

FormattedServicePriceTextField.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.shape({
    price: PropTypes.number.isRequired,
    maximumPrice: PropTypes.number,
    isMinimumPrice: PropTypes.bool,
  }),
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
};

export default FormattedServicePriceTextField;
