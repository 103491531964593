import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button } from "@mui/material";

import { LoadingButton } from "@vesta/components/atoms";
import { CropImage, Dialog } from "@vesta/components/molecules";
import { useToBlob, useUpSm } from "@vesta/lib/react";

const maxSize = 800;

const CropProfilePictureDialog = ({ open, imgSrc, onSave, onCancel }) => {
  const upSm = useUpSm();
  const imgRef = useRef(null);
  const { t } = useTranslation("components");
  const [completedCrop, setCompletedCrop] = useState();
  const [saving, setSaving] = useState(false);

  const toBlob = useToBlob(imgRef.current, completedCrop, maxSize);

  const handleSaveClick = async () => {
    setSaving(true);

    const blob = await toBlob();
    await onSave({ blob });

    setSaving(false);
  };

  return (
    <Dialog
      fullScreen={false}
      maxWidth="md"
      open={open}
      onClose={onCancel}
      title={t("organisms.cropProfilePictureDialog.title")}
      content={
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {imgSrc && (
            <CropImage
              imgRef={imgRef}
              imgSrc={imgSrc}
              onComplete={setCompletedCrop}
            />
          )}
        </Box>
      }
      actions={
        <>
          <Button
            disabled={saving}
            onClick={onCancel}
            size="large"
            variant="outlined"
          >
            {t("organisms.cropProfilePictureDialog.cancelLabel")}
          </Button>
          <LoadingButton
            loading={saving}
            disabled={saving}
            onClick={handleSaveClick}
            size="large"
            variant="contained"
          >
            {upSm
              ? t("organisms.cropProfilePictureDialog.saveUpSmLabel")
              : t("organisms.cropProfilePictureDialog.saveLabel")}
          </LoadingButton>
        </>
      }
    />
  );
};

CropProfilePictureDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  imgSrc: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CropProfilePictureDialog;
