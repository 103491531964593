import PropTypes from "prop-types";

import { Grid, LinearProgress } from "@mui/material";

import { useUpMd } from "@vesta/lib/react";

const BodyWithTips = ({ children, progressValue, image, tips }) => {
  const upMd = useUpMd();

  return (
    <Grid
      sx={(theme) => ({
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
      })}
      container
    >
      <Grid item xs={12}>
        <LinearProgress
          sx={(theme) =>
            progressValue === 100
              ? {
                  backgroundColor: theme.palette.success.light,
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: theme.palette.success.main,
                  },
                }
              : {}
          }
          color="secondary"
          value={progressValue}
          variant="determinate"
        />
      </Grid>
      <Grid
        sx={(theme) => ({
          height: "99%",
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          paddingTop: theme.spacing(4),
          paddingBottom: theme.spacing(4),
          [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(4),
          },
          [theme.breakpoints.up("md")]: {
            padding: theme.spacing(6),
          },
          [theme.breakpoints.up("lg")]: {
            padding: theme.spacing(8),
          },
        })}
        item
        xs={12}
        md={7}
      >
        {children}
      </Grid>
      {upMd &&
        (image ? (
          <Grid sx={{ position: "relative" }} item xs={12} md={5}>
            {image}
          </Grid>
        ) : (
          <Grid
            sx={(theme) => ({
              backgroundColor: theme.palette.background.default,
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              paddingTop: theme.spacing(4),
              paddingBottom: theme.spacing(4),
              [theme.breakpoints.up("sm")]: {
                padding: theme.spacing(4),
              },
              [theme.breakpoints.up("md")]: {
                padding: theme.spacing(6),
              },
              [theme.breakpoints.up("lg")]: {
                padding: theme.spacing(8),
              },
            })}
            item
            xs={12}
            md={5}
          >
            <Grid container direction="column" spacing={2}>
              {tips.map((x, i) => (
                <Grid key={i} item>
                  {x}
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};

BodyWithTips.defaultProps = {
  tips: [],
};

BodyWithTips.propTypes = {
  children: PropTypes.node.isRequired,
  progressValue: PropTypes.number,
  image: PropTypes.node,
  tips: PropTypes.arrayOf(PropTypes.node),
};

export default BodyWithTips;
