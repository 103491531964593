import { Trans, useTranslation } from "react-i18next";

import { Alert, AlertTitle, Link as MuiLink } from "@mui/material";

import { useSession } from "@vesta/lib/react";

const PublishProfileAlert = () => {
  const { t } = useTranslation("home");
  const [session] = useSession();

  return (
    <>
      <Alert
        sx={(theme) => ({ backgroundColor: theme.palette.background.paper })}
        component="aside"
        severity="success"
        variant="outlined"
      >
        <AlertTitle sx={(theme) => ({ marginBottom: theme.spacing(2) })}>
          {t("publishProfileAlert.title", { firstName: session.given_name })}
        </AlertTitle>
        <p>
          <Trans
            i18nKey="home:publishProfileAlert.text"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
            }}
          />
        </p>
        <MuiLink
          href={process.env.REACT_APP_BOOK_SUCCESS_SESSION_LINK}
          target="_blank"
          underline="always"
        >
          {t("publishProfileAlert.ctaLabel")}
        </MuiLink>
      </Alert>
    </>
  );
};

export default PublishProfileAlert;
