import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import * as yup from "yup";

import { Grid } from "@mui/material";

import {
  BiographyTextField,
  OccupationTextField,
  TitledFormPaper,
} from "@vesta/components/molecules";
import { useUpSm } from "@vesta/lib/react";

const AboutMe = ({ loading, biography, onSave }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("profile");
  const [editing, setEditing] = useState(false);

  const validationSchema = yup.object().shape({
    occupationFr: yup.string().nullable(),
    occupationEn: yup.string().nullable(),
    biographyFr: yup.string().nullable(),
    biographyEn: yup.string().nullable(),
  });

  const {
    values,
    errors,
    isSubmitting,
    submitCount,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      occupationFr: "",
      occupationEn: "",
      biographyFr: "",
      biographyEn: "",
    },
    validationSchema,
    onSubmit: async ({
      occupationFr,
      occupationEn,
      biographyFr,
      biographyEn,
    }) => {
      const ok = await onSave({
        occupationFr,
        occupationEn,
        biographyFr,
        biographyEn,
      });
      if (ok) {
        setEditing(false);
      }
    },
  });

  useEffect(() => {
    if (!editing && biography) {
      setFieldValue("occupationFr", biography.occupationFr);
      setFieldValue("occupationEn", biography.occupationEn);
      setFieldValue("biographyFr", biography.biographyFr);
      setFieldValue("biographyEn", biography.biographyEn);
    }
  }, [editing, biography]);

  const handleCancel = () => {
    resetForm();
    setFieldValue("occupationFr", biography.occupationFr);
    setFieldValue("occupationEn", biography.occupationEn);
    setFieldValue("biographyFr", biography.biographyFr);
    setFieldValue("biographyEn", biography.biographyEn);
    setEditing(false);
  };

  return (
    <TitledFormPaper
      title={t("biography.aboutMe.title")}
      loading={loading}
      editing={editing}
      saving={isSubmitting}
      onEdit={() => setEditing(true)}
      onCancel={handleCancel}
      onSave={handleSubmit}
    >
      <Grid container spacing={upSm ? 3 : 2}>
        <Grid item xs={12} md={6}>
          <OccupationTextField
            id="occupationFr"
            readOnly={!editing}
            lang="fr"
            value={values.occupationFr}
            onChange={handleChange}
            error={submitCount > 0 && Boolean(errors.occupationFr)}
            helperText={submitCount > 0 && errors.occupationFr}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <OccupationTextField
            id="occupationEn"
            readOnly={!editing}
            lang="en"
            value={values.occupationEn}
            onChange={handleChange}
            error={submitCount > 0 && Boolean(errors.occupationEn)}
            helperText={submitCount > 0 && errors.occupationEn}
          />
        </Grid>
        <Grid item xs={12}>
          <BiographyTextField
            id="biographyFr"
            readOnly={!editing}
            lang="fr"
            value={values.biographyFr}
            onChange={handleChange}
            error={submitCount > 0 && Boolean(errors.biographyFr)}
            helperText={submitCount > 0 && errors.biographyFr}
          />
        </Grid>
        <Grid item xs={12}>
          <BiographyTextField
            id="biographyEn"
            readOnly={!editing}
            lang="en"
            value={values.biographyEn}
            onChange={handleChange}
            error={submitCount > 0 && Boolean(errors.biographyEn)}
            helperText={submitCount > 0 && errors.biographyEn}
          />
        </Grid>
      </Grid>
    </TitledFormPaper>
  );
};

AboutMe.propTypes = {
  loading: PropTypes.bool,
  biography: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};

export default AboutMe;
