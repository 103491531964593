import { PropTypes } from "prop-types";
import { useState } from "react";
import { Trans } from "react-i18next";

import { ContactUsLink, ErrorAlert } from "@vesta/components/atoms";
import { plans, recommendedPlan } from "@vesta/lib/enum";
import { useSession } from "@vesta/lib/react";

import { Step1, Step2 } from "./components";

const ChoosePlanDialog = ({ open, onClose }) => {
  const [step, setStep] = useState(1);
  const [plan, setPlan] = useState(recommendedPlan);
  const [cycle, setCycle] = useState("");
  const [loading, setLoading] = useState(false);
  const [, jwtToken] = useSession();
  const [createSubscriptionError, setCreateSubscriptionError] = useState(false);

  const handlePlanChange = (e) => {
    e.preventDefault();
    setPlan(e.target.value);
  };

  const handleCycleChange = (e) => {
    e.preventDefault();
    setCycle(e.target.value);
  };

  const handleStep2Next = async () => {
    setLoading(true);

    const url = new URL(
      "/commands/create-subscription",
      process.env.REACT_APP_API_BASE_URL
    );

    const subscriptionPlan = plans.find(
      (x) => x.name === plan && x.cycle === cycle
    );

    const response = await fetch(url, {
      method: "POST",
      headers: { Authorization: `Bearer ${jwtToken}` },
      body: JSON.stringify({
        subscriptionPlanId: subscriptionPlan.id,
        returnUrl: window.location.href,
      }),
    });

    if (response.ok) {
      const portalUrl = await response.text();
      window.location.href = portalUrl;
    } else {
      setLoading(false);
      setCreateSubscriptionError(true);
    }
  };

  const steps = [
    <Step1
      key="1"
      plan={plan}
      onPlanChange={handlePlanChange}
      cycle={cycle}
      onCycleChange={handleCycleChange}
      open={open}
      onClose={onClose}
      onNext={() => setStep(2)}
    />,
    <Step2
      key="2"
      loading={loading}
      open={open}
      onClose={onClose}
      onPrevious={() => setStep(1)}
      onNext={handleStep2Next}
    />,
  ];

  return (
    <>
      {steps[step - 1]}
      <ErrorAlert
        open={createSubscriptionError}
        onClose={() => setCreateSubscriptionError(false)}
        content={
          <Trans
            i18nKey="global-modals:choosePlanDialog.alerts.createSubscriptionError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
    </>
  );
};

ChoosePlanDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ChoosePlanDialog;
