import { useAppContext } from "@vesta/lib/react";

import {
  AccountMenuLimitReachedAlert,
  AccountMenuPaymentRequiredAlert,
  AccountMenuSubscriptionCancelledAlert,
  AccountMenuSubscriptionRequiredAlert,
} from "./components";

const AccountMenuItemAlert = () => {
  const [appContext] = useAppContext();

  if (!appContext.subscription) {
    return null;
  }

  if (!appContext.subscription.hasSubscription) {
    if (appContext.subscription.isDelinquent) {
      return <AccountMenuSubscriptionCancelledAlert />;
    }
    return <AccountMenuSubscriptionRequiredAlert />;
  }

  if (appContext.subscription.actionRequired) {
    return <AccountMenuPaymentRequiredAlert />;
  }

  if (appContext.subscription.hasReachedLimit) {
    return <AccountMenuLimitReachedAlert />;
  }

  return null;
};

export default AccountMenuItemAlert;
