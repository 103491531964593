import { useTranslation } from "react-i18next";

import { Alert } from "@mui/material";

const DrivingTimeBetweenAppointmentsAlert = () => {
  const { t } = useTranslation("components");

  return (
    <Alert sx={{ whiteSpace: "pre-line" }} component="aside" severity="info">
      {t("molecules.drivingTimeBetweenAppointmentsAlert.text")}
    </Alert>
  );
};

export default DrivingTimeBetweenAppointmentsAlert;
