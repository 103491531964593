import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import {
  EditablePolygonMap,
  PolygonMap,
  TitledFormPaper,
} from "@vesta/components/molecules";
import { ErrorAlert } from "@vesta/components/atoms";
import { Box } from "@mui/material";

const Geography = ({ loading, territory, onSave }) => {
  const { t } = useTranslation("territory");
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editingPoints, setEditingPoints] = useState();
  const [intersectError, setIntersectError] = useState(false);

  const handleEdit = () => {
    setEditingPoints(territory.polygonPoints);
    setEditing(true);
  };

  const handleCancel = () => {
    setEditingPoints(territory.polygonPoints);
    setEditing(false);
  };

  const handleSave = async () => {
    setSaving(true);
    const ok = await onSave({ points: editingPoints });
    if (ok) {
      setEditing(false);
    }
    setSaving(false);
  };

  return (
    <TitledFormPaper
      title={t("geography.title")}
      loading={loading}
      editing={editing}
      saving={saving}
      onEdit={handleEdit}
      onCancel={handleCancel}
      onSave={handleSave}
    >
      {territory && (
        <Box sx={{ height: "64vh" }}>
          {editing ? (
            <EditablePolygonMap
              id={territory.id}
              points={editingPoints ?? territory.polygonPoints}
              onEdit={setEditingPoints}
              onIntersect={() => setIntersectError(true)}
            />
          ) : (
            <PolygonMap
              id={territory.id}
              points={editingPoints ?? territory.polygonPoints}
            />
          )}
        </Box>
      )}
      <ErrorAlert
        autoHide
        open={intersectError}
        onClose={() => setIntersectError(false)}
        content={t("geography.alerts.intersectError")}
      />
    </TitledFormPaper>
  );
};

Geography.propTypes = {
  loading: PropTypes.bool,
  territory: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};

export default Geography;
