import { lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";

import { Loader } from "@vesta/components/atoms";

const SignUp = lazy(() => import("./components/SignUp"));
const VerifyToken = lazy(() => import("./components/VerifyToken"));

const SignUpView = () => {
  const { t } = useTranslation("sign-up");

  return (
    <>
      <Helmet>
        <title>{t("title")}</title>
        <meta property="og:title" content={t("title")} />
        <meta property="og:description" content={t("description")} />
      </Helmet>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path=":email/verify-token" element={<VerifyToken />} />
          <Route path="*" element={<Navigate to="/sign-up" replace />} />
          <Route index element={<SignUp />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default SignUpView;
