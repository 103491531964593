import { setHours, startOfTomorrow } from "date-fns";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { Button, Grid } from "@mui/material";

import {
  LoadingButton,
  MobileDatePicker,
  TimeSelect,
} from "@vesta/components/atoms";
import { Dialog } from "@vesta/components/molecules";

const ReminderDateAndTimePicker = ({ open, onCancel, onPick }) => {
  const { t } = useTranslation("home");

  const validationSchema = yup.object().shape({
    remindOn: yup.date().nullable().required(),
  });

  const { values, isSubmitting, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        remindOn: setHours(startOfTomorrow(), 8),
      },
      validationSchema,
      onSubmit: async ({ remindOn }) => {
        await onPick(remindOn);
        resetForm();
      },
    });

  const handleCancel = () => {
    onCancel();
    resetForm();
  };

  return (
    <Dialog
      fullScreen={false}
      maxWidth="xs"
      open={open}
      onClose={handleCancel}
      title={t("reminders.reminderDateAndTimePicker.title")}
      content={
        <Grid container spacing={3}>
          <Grid item>
            <MobileDatePicker
              label={t("reminders.reminderDateAndTimePicker.remindOnDateLabel")}
              value={values.remindOn}
              onChange={(value) => setFieldValue("remindOn", value)}
              views={["day"]}
            />
          </Grid>
          <Grid item>
            <TimeSelect
              sx={(theme) => ({ minWidth: theme.spacing(10) })}
              id="remind-on"
              label={t("reminders.reminderDateAndTimePicker.remindOnTimeLabel")}
              value={values.remindOn}
              onChange={(value) => setFieldValue("remindOn", value)}
            />
          </Grid>
        </Grid>
      }
      actions={
        <>
          <Button onClick={handleCancel} size="large" variant="outlined">
            {t("reminders.reminderDateAndTimePicker.cancel")}
          </Button>
          <LoadingButton
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={handleSubmit}
            type="submit"
            size="large"
            variant="contained"
          >
            {t("reminders.reminderDateAndTimePicker.pick")}
          </LoadingButton>
        </>
      }
    />
  );
};

ReminderDateAndTimePicker.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onPick: PropTypes.func.isRequired,
};

export default ReminderDateAndTimePicker;
