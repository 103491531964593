import PropTypes from "prop-types";

import { Box, Grid, Skeleton } from "@mui/material";

import { Logo, LogoVariant } from "@vesta/components/atoms";
import { useUpMd } from "@vesta/lib/react";

const HeroShaped = ({ loading, content, image }) => {
  const upMd = useUpMd();

  return (
    <Grid
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        minHeight: "100vh",
      })}
      container
      direction="row-reverse"
    >
      <Grid item xs={12} md={6} lg={7}>
        <Box
          sx={(theme) => ({
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            [theme.breakpoints.up("sm")]: {
              padding: theme.spacing(4),
            },
            [theme.breakpoints.up("md")]: {
              padding: theme.spacing(6),
            },
            [theme.breakpoints.up("lg")]: {
              padding: theme.spacing(8),
            },
          })}
          component="section"
        >
          <Logo
            sx={(theme) => ({
              height: theme.spacing(11),
              marginBottom: theme.spacing(7),
            })}
            variant={LogoVariant.LogoTop}
          />
          {loading ? (
            <>
              <Skeleton width="70%" />
              <Skeleton width="40%" />
            </>
          ) : (
            content
          )}
        </Box>
      </Grid>
      {upMd && (
        <Grid sx={{ position: "relative" }} item xs={12} md={6} lg={5}>
          {image}
        </Grid>
      )}
    </Grid>
  );
};

HeroShaped.propTypes = {
  loading: PropTypes.bool,
  content: PropTypes.node.isRequired,
  image: PropTypes.node.isRequired,
};

export default HeroShaped;
