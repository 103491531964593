import { fr } from "date-fns/locale";
import { addDays, format } from "date-fns";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { FormControl, MenuItem, Select } from "@mui/material";

import { MobileDatePicker } from "@vesta/components/atoms";
import { GroupedForms } from "@vesta/components/molecules";

const recurrenceEndsAfterMaxNbOccurrencesAvailableValues = [
  5, 10, 15, 20, 25, 30,
];

const AvailabilityRecurrenceEnd = ({
  disabled,
  start,
  recurrenceMode,
  onRecurrenceModeChange,
  recurrenceEndsAfterMaxNbOccurrences,
  onRecurrenceEndsAfterMaxNbOccurrencesChange,
  recurrenceEndsOn,
  onRecurrenceEndsOnChange,
}) => {
  const { t } = useTranslation("components");

  return (
    <GroupedForms
      disabled={disabled}
      label={t("molecules.availabilityRecurrenceEnd.label")}
      value={recurrenceMode}
      onChange={(value) => onRecurrenceModeChange(parseInt(value))}
      groups={[
        {
          value: 1,
          label:
            recurrenceMode === 1
              ? t(
                  "molecules.availabilityRecurrenceEnd.activeAfterMaxNbOccurrencesLabel",
                  { count: recurrenceEndsAfterMaxNbOccurrences }
                )
              : t(
                  "molecules.availabilityRecurrenceEnd.afterMaxNbOccurrencesLabel"
                ),
          form: (
            <FormControl variant="standard" disabled={disabled}>
              <Select
                variant="standard"
                sx={(theme) => ({
                  marginLeft: theme.spacing(4),
                  maxWidth: theme.spacing(28),
                })}
                value={recurrenceEndsAfterMaxNbOccurrences}
                inputProps={{
                  "aria-label": t(
                    "molecules.availabilityRecurrenceEnd.afterMaxNbOccurrencesInputLabel"
                  ),
                }}
                onChange={(event) =>
                  onRecurrenceEndsAfterMaxNbOccurrencesChange(
                    event.target.value
                  )
                }
              >
                {recurrenceEndsAfterMaxNbOccurrencesAvailableValues.map(
                  (availableValue, i) => (
                    <MenuItem
                      key={`max-nb-occurrences-${i}`}
                      value={availableValue}
                    >
                      {t(
                        "molecules.availabilityRecurrenceEnd.afterMaxNbOccurrencesInputValue",
                        { value: availableValue }
                      )}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          ),
        },
        {
          value: 2,
          label:
            recurrenceMode === 2
              ? t("molecules.availabilityRecurrenceEnd.activeOnLabel", {
                  date: format(recurrenceEndsOn, "PPP", { locale: fr }),
                })
              : t("molecules.availabilityRecurrenceEnd.onLabel"),
          form: (
            <MobileDatePicker
              sx={(theme) => ({
                marginLeft: theme.spacing(4),
                maxWidth: theme.spacing(28),
              })}
              fullWidth={false}
              disabled={disabled}
              minDate={!disabled && addDays(start, 1)}
              value={recurrenceEndsOn}
              onChange={(date) => onRecurrenceEndsOnChange(date)}
            />
          ),
        },
      ]}
    />
  );
};

AvailabilityRecurrenceEnd.defaultProps = {
  disabled: false,
  recurrenceEndsAfterMaxNbOccurrences: 5,
  recurrenceEndsOn: new Date(),
};

AvailabilityRecurrenceEnd.propTypes = {
  disabled: PropTypes.bool,
  start: PropTypes.instanceOf(Date).isRequired,
  recurrenceMode: PropTypes.number.isRequired,
  onRecurrenceModeChange: PropTypes.func,
  recurrenceEndsAfterMaxNbOccurrences: PropTypes.number,
  onRecurrenceEndsAfterMaxNbOccurrencesChange: PropTypes.func,
  recurrenceEndsOn: PropTypes.instanceOf(Date),
  onRecurrenceEndsOnChange: PropTypes.func,
};

export default AvailabilityRecurrenceEnd;
