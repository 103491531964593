import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Grid, IconButton } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Add, Edit } from "@mui/icons-material";

import { TitledPaper } from "@vesta/components/molecules";
import { useUpMd, useUpSm } from "@vesta/lib/react";

import { ModifyCoverPictureDialog, WhatAlert } from "./components";

const StyledImg = styled("img")(({ theme }) => ({
  objectFit: "cover",
  objectPosition: "center",
  width: "100%",
  height: theme.spacing(14),
  [theme.breakpoints.up("sm")]: {
    height: theme.spacing(28),
  },
}));

const CoverPicture = ({
  loading,
  biography,
  onSelectUnsplashPhoto,
  onSelectFile,
}) => {
  const upSm = useUpSm();
  const upMd = useUpMd();
  const theme = useTheme();
  const { t } = useTranslation("profile");
  const [openDialog, setOpenDialog] = useState(false);

  const actions = (
    <>
      <Button
        sx={{ display: { xs: "none", sm: "block" } }}
        disabled={loading}
        onClick={() => setOpenDialog(true)}
        variant="contained"
      >
        {biography?.coverPictureId
          ? t("biography.coverPicture.actions.edit")
          : t("biography.coverPicture.actions.add")}
      </Button>
      <IconButton
        sx={{ display: { xs: "block", sm: "none" } }}
        disabled={loading}
        aria-label={
          biography?.coverPictureId
            ? t("biography.coverPicture.actions.edit")
            : t("biography.coverPicture.actions.add")
        }
        color="primary"
        size="small"
        onClick={() => setOpenDialog(true)}
        variant="contained"
      >
        {biography?.coverPictureId ? <Edit /> : <Add />}
      </IconButton>
    </>
  );

  let coverPicture = null;

  if (biography?.coverPictureUnsplashPhotoRawUrl) {
    const width = upMd
      ? theme.breakpoints.values.lg
      : upSm
      ? theme.breakpoints.values.md
      : theme.breakpoints.values.sm;

    const src = new URL(biography.coverPictureUnsplashPhotoRawUrl);
    src.searchParams.set("w", width);
    src.searchParams.set("fit", "crop");
    src.searchParams.set("auto", "format");

    coverPicture = <StyledImg src={src} loading="lazy" />;
  } else if (biography?.coverPictureId) {
    coverPicture = (
      <StyledImg
        src={new URL(
          `/download-cover-picture/${biography.coverPictureId}`,
          process.env.REACT_APP_API_BASE_URL
        ).toString()}
        loading="lazy"
      />
    );
  }

  return (
    <>
      <TitledPaper
        title={t("biography.coverPicture.title")}
        loading={loading}
        actions={actions}
      >
        <Grid container direction="column" spacing={upSm ? 3 : 2}>
          <Grid item>
            <WhatAlert />
          </Grid>
          {coverPicture && <Grid item>{coverPicture}</Grid>}
        </Grid>
      </TitledPaper>
      <ModifyCoverPictureDialog
        open={openDialog}
        onCancel={() => setOpenDialog(false)}
        onSelectUnsplashPhoto={async (photo) => {
          await onSelectUnsplashPhoto(photo);
          setOpenDialog(false);
        }}
        onSelectFile={async ({ file }) => {
          await onSelectFile({ file });
          setOpenDialog(false);
        }}
      />
    </>
  );
};

CoverPicture.propTypes = {
  loading: PropTypes.bool,
  biography: PropTypes.object,
  onSelectUnsplashPhoto: PropTypes.func.isRequired,
  onSelectFile: PropTypes.func.isRequired,
};

export default CoverPicture;
