import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Box, Skeleton, Typography } from "@mui/material";

const Summary = ({ sx, loading, count }) => {
  const { t } = useTranslation("patients");

  return (
    <Box sx={sx}>
      <Typography color="textSecondary" variant="caption">
        {loading ? <Skeleton width="30%" /> : t("summary.count", { count })}
      </Typography>
    </Box>
  );
};

Summary.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  count: PropTypes.number,
  loading: PropTypes.bool.isRequired,
};

export default Summary;
