import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import { AddFileButton } from "@vesta/components/atoms";
import { resultFileType } from "@vesta/lib/enum";

import { ImageFile, PdfFile } from "./components";

const ResultFiles = ({
  files,
  created,
  onAddResultFiles,
  onDeleteResultFile,
}) => (
  <Grid container spacing={3}>
    {files
      .sort((a, b) => a.created - b.created)
      .map((x) => {
        switch (x.typeId) {
          case resultFileType.image:
            return (
              <Grid key={x.resultFileId} item xs={12} sm={6} lg={4} xl={3}>
                <ImageFile
                  key={x.resultFileId}
                  resultFile={x}
                  canDelete={!created}
                  onDelete={() => onDeleteResultFile(x.resultFileId)}
                />
              </Grid>
            );
          case resultFileType.pdf:
            return (
              <Grid key={x.resultFileId} item xs={12} sm={6} lg={4} xl={3}>
                <PdfFile
                  key={x.resultFileId}
                  resultFile={x}
                  canDelete={!created}
                  onDelete={() => onDeleteResultFile(x.resultFileId)}
                />
              </Grid>
            );
          default:
            console.error("Unknown result file type", x.typeId);
            return null;
        }
      })}
    {!created && (
      <Grid item xs={12} sm={6} lg={4} xl={3}>
        <AddFileButton
          sx={(theme) => ({
            width: "100%",
            height: theme.spacing(12),
            [theme.breakpoints.up("sm")]: {
              height: theme.spacing(16),
            },
            [theme.breakpoints.up("md")]: {
              height: theme.spacing(20),
            },
          })}
          accept="image/*,application/pdf"
          multiple
          onChange={onAddResultFiles}
        />
      </Grid>
    )}
  </Grid>
);

ResultFiles.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      resultFileId: PropTypes.string.isRequired,
      typeId: PropTypes.number.isRequired,
      created: PropTypes.instanceOf(Date).isRequired,
    })
  ).isRequired,
  created: PropTypes.instanceOf(Date),
  onAddResultFiles: PropTypes.func,
  onDeleteResultFile: PropTypes.func,
};

export default ResultFiles;
