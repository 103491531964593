import { useEffect, useState } from "react";

import { useAuthorizedFetchJson, useSession } from "@vesta/lib/react";

export const useGetAvailability = (open, availabilityId, occurrenceId) => {
  const [, jwtToken] = useSession();
  const [fetchJson, json, loading] = useAuthorizedFetchJson(jwtToken);

  useEffect(() => {
    if (open) {
      fetchJson(
        new URL(
          `/queries/get-availability-by-availability-and-occurrence-id/${availabilityId}/${occurrenceId}`,
          process.env.REACT_APP_API_BASE_URL
        )
      );
    }
  }, [open, availabilityId, occurrenceId]);

  return [json, loading];
};

export const useTerritories = () => {
  const [territories, setTerritories] = useState();

  const error = territories?.filter((x) => x.include).length === 0 ?? false;

  const handleChange = ({ id, include }) =>
    setTerritories(
      territories.map((x) => (x.id === id ? { ...x, include } : { ...x }))
    );

  return [territories, setTerritories, error, handleChange];
};

export const useServices = () => {
  const [services, setServices] = useState();

  const error = services?.filter((x) => x.include).length === 0 ?? false;

  const handleChange = ({ id, include }) => {
    setServices(
      services.map((x) => (x.id === id ? { ...x, include } : { ...x }))
    );
  };

  return [services, setServices, error, handleChange];
};
