import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import { Attachment } from "./components";

const NoteAttachments = ({ note, onDeleteNoteAttachment }) => (
  <Grid container spacing={3}>
    {note.attachments
      .filter((x) => !x.deleted)
      .sort((a, b) => a.created - b.created)
      .map((x) => (
        <Grid key={x.noteAttachmentId} item xs={12} sm={6} md={4}>
          <Attachment
            key={x.noteAttachmentId}
            noteAttachment={x}
            canDelete={!note.completed}
            onDelete={() => onDeleteNoteAttachment(x.noteAttachmentId)}
          />
        </Grid>
      ))}
  </Grid>
);

NoteAttachments.propTypes = {
  note: PropTypes.shape({
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        noteAttachmentId: PropTypes.string.isRequired,
        created: PropTypes.instanceOf(Date).isRequired,
        deleted: PropTypes.instanceOf(Date),
      })
    ).isRequired,
    completed: PropTypes.instanceOf(Date),
  }),
  onDeleteNoteAttachment: PropTypes.func.isRequired,
};

export default NoteAttachments;
