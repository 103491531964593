import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ClickAwayListener,
  Grow,
  IconButton,
  ListSubheader,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";

const SelectOtherOriginButton = ({ menuItems }) => {
  const anchorRef = useRef(null);
  const { t } = useTranslation("appointment");
  const [open, setOpen] = useState(false);

  const handleClickAway = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current.focus(); // return focus to the button when we transitioned from !open -> open
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-label={t("directions.selectOtherOriginButton.label")}
        aria-haspopup="true"
        aria-controls={open ? "select-other-origin-menu" : undefined}
        onClick={() => setOpen((prevValue) => !prevValue)}
      >
        <MoreVert />
      </IconButton>
      <Popper
        sx={(theme) => ({ zIndex: theme.zIndex.appBar + 1 })}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} sx={{ transformOrigin: "center top" }}>
            <Paper elevation={4}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <MenuList
                  id="select-other-origin-menu"
                  dense
                  autoFocus={open}
                  onKeyDown={handleKeyDown}
                  onClick={() => setOpen(false)}
                >
                  <ListSubheader disableSticky>
                    {t("directions.selectOtherOriginButton.label")}
                  </ListSubheader>
                  {menuItems}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

SelectOtherOriginButton.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default SelectOtherOriginButton;
