import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import { Alert, Link, Typography } from "@mui/material";

const NursingDocumentationStandardAlert = ({ sx }) => {
  const { t } = useTranslation("components");

  return (
    <Alert
      sx={[
        (theme) => ({ backgroundColor: theme.palette.background.paper }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      component="aside"
      severity="info"
      variant="outlined"
    >
      <Typography gutterBottom variant="body2">
        <Trans
          i18nKey="components:molecules.nursingDocumentationStandardAlert.text"
          components={{
            emphasize: <b />,
          }}
        />
      </Typography>
      <Link
        color="primary"
        underline="always"
        href="https://www.oiiq.org/norme-exercice-documentation"
        target="_blank"
      >
        {t("molecules.nursingDocumentationStandardAlert.ctaLabel")}
      </Link>
    </Alert>
  );
};

NursingDocumentationStandardAlert.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
};

export default NursingDocumentationStandardAlert;
