import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { Autocomplete } from "@mui/material";

import { AutocompleteOption } from "./components";
import { useGetPlaceAutocomplete } from "./hooks";

const PlacesAutocomplete = ({
  className,
  sessionToken,
  value,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation("components");
  const [input, setInput] = useState("");
  const [options, setOptions] = useState([]);
  const [placeAutocomplete, loading] = useGetPlaceAutocomplete(
    input,
    sessionToken
  );

  useEffect(() => {
    let newOptions = value ? [value] : [];
    if (input) {
      newOptions = [
        ...newOptions,
        ...(placeAutocomplete ? [...placeAutocomplete.predictions] : []),
      ];
    }
    setOptions(newOptions);
  }, [input, value, placeAutocomplete]);

  return (
    <Autocomplete
      className={className}
      includeInputInList
      filterSelectedOptions
      disableClearable
      forcePopupIcon={false}
      loading={loading}
      loadingText={t("atoms.placesAutocomplete.loadingText")}
      noOptionsText={t("atoms.placesAutocomplete.noOptionsText")}
      value={value}
      options={options}
      getOptionLabel={(option) => option.description}
      onChange={(e, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        onChange(e, newValue);
      }}
      onInputChange={(_, newInput) => setInput(newInput.trim())}
      isOptionEqualToValue={(option, value) =>
        option.place_id === value.place_id
      }
      renderOption={(props, option) => (
        <li {...props}>
          <AutocompleteOption option={option} />
        </li>
      )}
      filterOptions={(options) =>
        options.filter(({ terms }) => {
          const { value: province } = terms[terms.length - 2];
          const { value: country } = terms[terms.length - 1];

          return province === "QC" && country === "Canada";
        })
      }
      {...rest}
    />
  );
};

PlacesAutocomplete.propTypes = {
  className: PropTypes.string,
  sessionToken: PropTypes.string.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default PlacesAutocomplete;
