import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Skeleton,
} from "@mui/material";
import { ArrowForward, DateRange, MyLocation } from "@mui/icons-material";

import { ErrorAlert } from "@vesta/components/atoms";
import { DirectionsMap, StreetView } from "@vesta/components/molecules";
import { origin } from "@vesta/lib/enum";
import {
  useAppointment,
  useScrollToTopOnMount,
  useUpSm,
} from "@vesta/lib/react";

import {
  DestinationCard,
  DurationInTrafficAlert,
  EmptyDirections,
  OriginCard,
  PreviousAppointmentSubheader,
  SelectOtherOriginButton,
} from "./components";
import {
  useDirections,
  useGeocode,
  usePanorama,
  usePreviousAppointment,
} from "./hooks";

const sharedSx = (theme) => ({
  [theme.breakpoints.up("sm")]: {
    marginX: theme.spacing(6),
  },
  [theme.breakpoints.up("md")]: {
    marginX: theme.spacing(8),
  },
  [theme.breakpoints.up("lg")]: {
    marginX: theme.spacing(12),
  },
});

const emptyDirectionsSx = [sharedSx];

const durationInTrafficAlertSx = [sharedSx];

const directionsMapSx = [
  (theme) => ({
    height: theme.spacing(48),
    [theme.breakpoints.up("sm")]: {
      height: theme.spacing(56),
    },
  }),
  sharedSx,
];

const streetViewSx = [
  (theme) => ({
    height: theme.spacing(48),
    [theme.breakpoints.up("sm")]: {
      height: theme.spacing(56),
    },
  }),
  sharedSx,
];

const Directions = () => {
  const upSm = useUpSm();
  const { t } = useTranslation("appointment");
  const [selectedOrigin, setSelectedOrigin] = useState(
    origin.previousAppointment
  );
  const [appointment] = useAppointment();
  const [previousAppointment, noPreviousAppointment] =
    usePreviousAppointment(appointment);
  const [geoLocationPosition, setGeoLocationPosition] = useState();
  const [geoLocationPositionErrorCode, setGeoLocationPositionErrorCode] =
    useState();
  const [geoLocationPositionError, setGeoLocationPositionError] =
    useState(false);
  const [directions, loadingDirections, directionsNotOk, setDirectionsNotOk] =
    useDirections(
      selectedOrigin,
      geoLocationPosition,
      previousAppointment,
      appointment
    );
  const [geocode, loadingGeocode, geocodeNotOk, setGeocodeNotOk] =
    useGeocode(appointment);
  const [panorama, loadingPanorama, panoramaNotOk, setPanoramaNotOk] =
    usePanorama(geocode);

  useScrollToTopOnMount();

  useEffect(() => {
    if (noPreviousAppointment) {
      const selectedOrigin = geoLocationPositionErrorCode
        ? origin.none
        : origin.currentPosition;

      setSelectedOrigin(selectedOrigin);
    }
  }, [noPreviousAppointment, geoLocationPositionErrorCode]);

  useEffect(() => {
    if (selectedOrigin !== origin.currentPosition) {
      return;
    }

    if (geoLocationPosition) {
      return;
    }

    if (!navigator.geolocation) {
      return;
    }

    const handleSuccess = (position) => setGeoLocationPosition(position);
    const handleError = (error) => {
      setGeoLocationPositionErrorCode(error.code);
      setGeoLocationPositionError(true);

      const selectedOrigin = previousAppointment
        ? origin.previousAppointment
        : origin.none;

      setSelectedOrigin(selectedOrigin);
    };

    navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
  }, [selectedOrigin, geoLocationPosition, previousAppointment]);

  const originCardSubheader = appointment ? (
    selectedOrigin === origin.none ? (
      t("directions.noOrigin")
    ) : selectedOrigin === origin.previousAppointment ? (
      <PreviousAppointmentSubheader appointment={previousAppointment} />
    ) : selectedOrigin === origin.currentPosition ? (
      t("directions.currentPosition")
    ) : null
  ) : (
    <Skeleton />
  );

  const selectOtherOriginAction = (
    <SelectOtherOriginButton
      menuItems={[
        <MenuItem
          key="previous-appointment"
          disabled={noPreviousAppointment}
          onClick={() => setSelectedOrigin(origin.previousAppointment)}
        >
          <ListItemIcon>
            <DateRange fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("directions.previousAppointment")}</ListItemText>
        </MenuItem>,
        <MenuItem
          key="current-position"
          disabled={!!geoLocationPositionErrorCode}
          onClick={() => setSelectedOrigin(origin.currentPosition)}
        >
          <ListItemIcon>
            <MyLocation fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("directions.currentPosition")}</ListItemText>
        </MenuItem>,
      ]}
    />
  );

  const hasDirections = directions && !loadingDirections;
  const hasGeocode = geocode && !loadingGeocode;
  const hasPanorama = panorama && !loadingPanorama;

  return (
    <>
      <Grid container direction="column" spacing={4}>
        {upSm ? (
          <Grid sx={{ display: "flex", alignItems: "center" }} item>
            <OriginCard
              sx={{ flex: "1 1 0" }}
              subheader={originCardSubheader}
              action={selectOtherOriginAction}
            />
            <ArrowForward
              sx={(theme) => ({ marginX: theme.spacing(2) })}
              fontSize="large"
            />
            <DestinationCard sx={{ flex: "1 1 0" }} appointment={appointment} />
          </Grid>
        ) : (
          <Grid sx={{ display: "flex", flexDirection: "column" }} item>
            <OriginCard
              sx={(theme) => ({ marginBottom: theme.spacing(1) })}
              subheader={originCardSubheader}
              action={selectOtherOriginAction}
            />
            <DestinationCard appointment={appointment} />
          </Grid>
        )}
        {selectedOrigin === origin.none ? (
          <Grid item>
            <EmptyDirections sx={emptyDirectionsSx} />
          </Grid>
        ) : appointment && hasDirections ? (
          <>
            <Grid item>
              <DurationInTrafficAlert
                sx={durationInTrafficAlertSx}
                directions={directions}
              />
            </Grid>
            <Grid item>
              <DirectionsMap sx={directionsMapSx} directions={directions} />
            </Grid>
          </>
        ) : (
          <>
            <Grid item>
              <DurationInTrafficAlert sx={durationInTrafficAlertSx} loading />
            </Grid>
            <Grid item>
              <Skeleton sx={directionsMapSx} variant="rectangular" />
            </Grid>
          </>
        )}
        <Grid item>
          {appointment && hasGeocode && hasPanorama ? (
            <StreetView
              sx={streetViewSx}
              geocode={geocode}
              panorama={panorama}
            />
          ) : (
            <Skeleton sx={streetViewSx} variant="rectangular" />
          )}
        </Grid>
      </Grid>
      <ErrorAlert
        open={geoLocationPositionError}
        onClose={() => setGeoLocationPositionError(false)}
        content={t("directions.geoLocationPositionError")}
      />
      <ErrorAlert
        open={directionsNotOk}
        onClose={() => setDirectionsNotOk(false)}
        content={t("directions.directionsNotOk")}
      />
      <ErrorAlert
        open={geocodeNotOk}
        onClose={() => setGeocodeNotOk(false)}
        content={t("directions.geocodeNotOk")}
      />
      <ErrorAlert
        open={panoramaNotOk}
        onClose={() => setPanoramaNotOk(false)}
        content={t("directions.panoramaNotOk")}
      />
    </>
  );
};

export default Directions;
