import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";

import { Box, Grid, Link as MuiLink, Typography } from "@mui/material";

import { EmptyBagIllustration, SuccessAlert } from "@vesta/components/atoms";
import {
  useAuthorizedFetchJson,
  useScrollToTopOnMount,
  useSession,
} from "@vesta/lib/react";

import { AddServiceFloatingButton, ServiceCard } from "./components";

const useProfileServices = (searchParams) => {
  const [, jwtToken] = useSession();
  const [fetchJson, json, loading] = useAuthorizedFetchJson(jwtToken);

  useScrollToTopOnMount();

  useEffect(() => {
    fetchJson(
      new URL(
        "/queries/get-profile-services",
        process.env.REACT_APP_API_BASE_URL
      )
    );
  }, []);

  return [
    json?.services
      .filter((x) => x.id !== searchParams.get("deleted"))
      .filter((x) => !x.isDeleted),
    loading,
  ];
};

const Services = () => {
  const { t } = useTranslation("profile");
  const [searchParams] = useSearchParams();
  const [deleted, setDeleted] = useState(searchParams.has("deleted"));
  const [services, loading] = useProfileServices(searchParams);

  if (!loading && !services) {
    return null;
  }

  const term = searchParams.get("term") ?? "";

  const loadingState = (
    <>
      <Grid item xs={12} md={6}>
        <ServiceCard loading />
      </Grid>
      <Grid item xs={12} md={6}>
        <ServiceCard loading />
      </Grid>
    </>
  );

  const emptyState = (
    <>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        item
        xs={12}
      >
        <EmptyBagIllustration
          sx={(theme) => ({
            marginTop: theme.spacing(2),
            width: 240,
            [theme.breakpoints.up("sm")]: {
              width: 345,
            },
          })}
        />
        <Typography
          sx={(theme) => ({ marginTop: theme.spacing(4) })}
          gutterBottom
          align="center"
          component="h2"
          variant="h3"
        >
          {t("services.emptyState.title")}
        </Typography>
        <Typography
          sx={(theme) => ({ maxWidth: theme.spacing(80) })}
          align="center"
        >
          <Trans
            i18nKey="profile:services.emptyState.text"
            components={{
              addServiceLink: (
                <MuiLink
                  component={Link}
                  to="/profile/add-service"
                  underline="always"
                />
              ),
            }}
          />
        </Typography>
      </Grid>
    </>
  );

  const filteredServices = services?.filter((x) =>
    x.nameFr.toLowerCase().includes(term.toLowerCase())
  );

  return (
    <>
      <Grid container component="section" spacing={2}>
        {loading
          ? loadingState
          : services.length === 0
          ? emptyState
          : filteredServices
              .sort((a, b) => a.nameFr.localeCompare(b.nameFr, "fr"))
              .map((service) => (
                <Grid key={service.id} item xs={12} md={6}>
                  <ServiceCard service={service} />
                </Grid>
              ))}
      </Grid>
      <Box
        sx={(theme) => ({
          height: theme.spacing(7),
          [theme.breakpoints.up("md")]: {
            height: theme.spacing(11),
          },
        })}
      />
      <AddServiceFloatingButton
        sx={(theme) => ({
          position: "fixed",
          zIndex: 4,
          right: theme.spacing(2),
          bottom: theme.spacing(9),
          [theme.breakpoints.up("md")]: {
            bottom: theme.spacing(4),
          },
        })}
        component={Link}
        to="/profile/add-service"
      />
      <SuccessAlert
        open={deleted}
        onClose={() => setDeleted(false)}
        content={t("services.alerts.deleted")}
      />
    </>
  );
};

export default Services;
