import Compressor from "compressorjs";
import { useCallback } from "react";

export const useCompressor = ({ quality, minWidth, minHeight }) => {
  const callback = useCallback(
    (file) =>
      new Promise((resolve, reject) => {
        new Compressor(file, {
          quality,
          minWidth,
          minHeight,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      }),
    [quality, minWidth, minHeight]
  );

  return callback;
};
