import { debounce } from "lodash";
import { useCallback, useEffect } from "react";

import { useFetchJson } from "@vesta/lib/react";

export const useGetPlaceAutocomplete = (input, sessionToken) => {
  const [fetchJson, json, loading] = useFetchJson();

  const getPlaceAutocomplete = useCallback(
    debounce(
      (input) =>
        fetchJson(
          new URL(
            `/google-maps/get-place-autocomplete/${input}?` +
              new URLSearchParams({
                language: "fr",
                sessionToken,
              }),
            process.env.REACT_APP_API_BASE_URL
          )
        ),
      200
    ),
    [sessionToken]
  );

  useEffect(() => {
    if (input) {
      getPlaceAutocomplete(input);
    }
  }, [input, getPlaceAutocomplete]);

  return [json, loading];
};
