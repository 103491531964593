import PropTypes from "prop-types";
import { forwardRef } from "react";

import { Divider, Grid, Paper, Skeleton, Typography } from "@mui/material";

const TitledPaper = forwardRef(
  ({ sx, title, actions, loading, children }, ref) => (
    <Paper
      ref={ref}
      sx={[
        (theme) => ({
          padding: theme.spacing(2),
          [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(3),
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      component="section"
    >
      <Grid
        sx={(theme) => ({ marginBottom: theme.spacing(2) })}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs>
          <Typography component="h2" variant="h3">
            {title}
          </Typography>
        </Grid>
        {actions && <Grid item>{actions}</Grid>}
      </Grid>
      <Divider sx={(theme) => ({ marginBottom: theme.spacing(2) })} />
      {loading ? (
        <>
          <Skeleton width="70%" />
          <Skeleton width="40%" />
        </>
      ) : (
        children
      )}
    </Paper>
  )
);

TitledPaper.displayName = "TitledPaper";

TitledPaper.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  title: PropTypes.string,
  actions: PropTypes.node,
  loading: PropTypes.bool,
  children: PropTypes.node,
};

export default TitledPaper;
