import { useCallback, useEffect } from "react";

import { defaultRawContent } from "@vesta/lib/slate";

export const useAddNoteToPatientMedicalRecord = (
  jwtToken,
  appointment,
  dispatch
) => {
  const addNoteToPatientMedicalRecord = useCallback(
    async (patientId, someoneElseId, appointmentId) => {
      dispatch({ type: "adding-note-to-patient-medical-record" });

      const url = new URL(
        "/commands/add-note-to-patient-medical-record",
        process.env.REACT_APP_API_BASE_URL
      );

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          patientId,
          someoneElseId,
          appointmentId,
          rawContent: JSON.stringify(defaultRawContent),
        }),
      });

      if (response.ok) {
        const { noteId, noteVersionId } = await response.json();

        dispatch({
          type: "add-note-to-patient-medical-record",
          payload: {
            noteId,
            noteVersionId,
            appointmentId,
            rawContent: defaultRawContent,
            attachments: [],
            created: new Date(),
            modified: new Date(),
          },
        });
      }
    },
    []
  );

  useEffect(() => {
    if (
      appointment &&
      appointment.notes.length === 0 &&
      !appointment.addingNote
    ) {
      addNoteToPatientMedicalRecord(
        appointment.patientId,
        appointment.someoneElse?.someoneElseId,
        appointment.appointmentId
      );
    }
  }, [appointment, addNoteToPatientMedicalRecord]);
};
