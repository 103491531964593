import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { Box, Grid } from "@mui/material";

import {
  ContactUsLink,
  ErrorAlert,
  SuccessAlert,
} from "@vesta/components/atoms";
import { VerifyProfilePhoneNumberDialog } from "@vesta/components/organisms";
import {
  useCompressor,
  useScrollToTopOnMount,
  useSession,
  useUpSm,
} from "@vesta/lib/react";

import {
  AboutMe,
  ContactInformation,
  CoverPicture,
  ProfilePicture,
  ServiceLanguages,
  WebReferences,
} from "./components";
import { useProfileBiographyReducer } from "./hooks";

const maxFileSize = 16777216; // 16 MB

const Biography = () => {
  const upSm = useUpSm();
  const compressCoverPicture = useCompressor({
    quality: 0.6,
    minWidth: 1200,
    minHeight: 210,
  });
  const { t } = useTranslation("profile");
  const [, jwtToken] = useSession();
  const [biography, dispatch] = useProfileBiographyReducer();
  const [saved, setSaved] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [profilePictureSizeTooLargeError, setProfilePictureSizeTooLargeError] =
    useState(false);
  const [coverPictureSizeTooLargeError, setCoverPictureSizeTooLargeError] =
    useState(false);
  const [
    openVerifyProfilePhoneNumberDialog,
    setOpenVerifyProfilePhoneNumberDialog,
  ] = useState(false);

  useScrollToTopOnMount();

  const handleSaveProfilePicture = async ({ blob }) => {
    if (blob.size > maxFileSize) {
      setProfilePictureSizeTooLargeError(true);
      return false;
    }

    const formData = new FormData();
    formData.append("profile-picture", blob);

    const uploadProfilePictureResponse = await fetch(
      new URL("upload-profile-picture", process.env.REACT_APP_API_BASE_URL),
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      }
    );

    if (!uploadProfilePictureResponse.ok) {
      setSaveError(true);
      return false;
    }

    const { profilePictureId } = await uploadProfilePictureResponse.json();

    const modifyProfilePictureResponse = await fetch(
      new URL(
        `/commands/modify-profile-picture/${profilePictureId}`,
        process.env.REACT_APP_API_BASE_URL
      ),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );

    if (!modifyProfilePictureResponse.ok) {
      setSaveError(true);
      return false;
    }

    dispatch({
      type: "modify-profile-picture",
      payload: {
        profilePictureId,
      },
    });

    setSaved(true);
    return true;
  };

  const handleSaveAboutMe = async ({
    occupationFr,
    occupationEn,
    biographyFr,
    biographyEn,
  }) => {
    const url = new URL(
      "/commands/modify-about-me",
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        occupationFr,
        occupationEn,
        biographyFr,
        biographyEn,
      }),
    });

    if (ok) {
      dispatch({
        type: "modify-about-me",
        payload: {
          occupationFr,
          occupationEn,
          biographyFr,
          biographyEn,
        },
      });
      setSaved(true);
    } else {
      setSaveError(true);
    }

    return ok;
  };

  const handleSaveServiceLanguages = async ({ serviceLanguageIds }) => {
    const url = new URL(
      "/commands/modify-service-languages",
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        serviceLanguageIds,
      }),
    });

    if (ok) {
      dispatch({
        type: "modify-service-languages",
        payload: {
          serviceLanguageIds,
        },
      });
      setSaved(true);
    } else {
      setSaveError(true);
    }

    return ok;
  };

  const handleSaveContactInformation = async ({
    phoneNumberInE164Format,
    hidePhoneNumber,
  }) => {
    const url = new URL(
      "/commands/modify-contact-information",
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        phoneNumber: phoneNumberInE164Format,
        hidePhoneNumber,
      }),
    });

    if (ok) {
      dispatch({
        type: "modify-contact-information",
        payload: {
          phoneNumberInE164Format,
          hidePhoneNumber,
        },
      });

      if (
        phoneNumberInE164Format === biography.approvedPhoneNumberInE164Format
      ) {
        setSaved(true);
      } else {
        setOpenVerifyProfilePhoneNumberDialog(true);
      }
    } else {
      setSaveError(true);
    }

    return ok;
  };

  const handleVerify = async (verificationCode) => {
    const url = new URL(
      "/commands/verify-profile-phone-number",
      process.env.REACT_APP_API_BASE_URL
    );

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        phoneNumber: biography.phoneNumberInE164Format,
        verificationCode,
      }),
    });

    if (response.ok) {
      dispatch({
        type: "verify-profile-phone-number",
        payload: {
          isPhoneNumberApproved: true,
        },
      });
      setOpenVerifyProfilePhoneNumberDialog(false);
      setSaved(true);
    } else {
      const { errorCode } = await response.json();
      return errorCode;
    }
  };

  const handleSaveWebReferences = async ({
    linkedInUri,
    websiteUri,
    otherReferences,
  }) => {
    const url = new URL(
      "/commands/modify-web-references",
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        linkedInUri: linkedInUri === "" ? null : linkedInUri,
        websiteUri: websiteUri === "" ? null : websiteUri,
        otherReferences:
          Object.keys(otherReferences).length === 0 ? null : otherReferences,
      }),
    });

    if (ok) {
      dispatch({
        type: "modify-web-references",
        payload: {
          linkedInUri,
          websiteUri,
          otherReferences,
        },
      });
      setSaved(true);
    } else {
      setSaveError(true);
    }

    return ok;
  };

  const handleSelectCoverPictureFile = async ({ file }) => {
    if (file.size > maxFileSize) {
      setCoverPictureSizeTooLargeError(true);
      return false;
    }

    const compressedFile = await compressCoverPicture(file);

    const formData = new FormData();
    formData.append("cover-picture", compressedFile);

    const uploadCoverPictureResponse = await fetch(
      new URL("upload-cover-picture", process.env.REACT_APP_API_BASE_URL),
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      }
    );

    if (!uploadCoverPictureResponse.ok) {
      setSaveError(true);
      return false;
    }

    const { coverPictureId } = await uploadCoverPictureResponse.json();

    const modifyCoverPictureResponse = await fetch(
      new URL(
        `/commands/modify-cover-picture/${coverPictureId}`,
        process.env.REACT_APP_API_BASE_URL
      ),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          unsplashPhotoId: null,
          unsplashPhotoRawUrl: null,
        }),
      }
    );

    if (!modifyCoverPictureResponse.ok) {
      setSaveError(true);
      return false;
    }

    dispatch({
      type: "modify-cover-picture",
      payload: {
        coverPictureId,
        coverPictureUnsplashPhotoId: null,
        coverPictureUnsplashPhotoRawUrl: null,
      },
    });

    setSaved(true);
    return true;
  };

  const handleSelectCoverPictureUnsplashPhoto = async ({ id, urls }) => {
    const coverPictureId = uuidv4();

    const modifyCoverPictureResponse = await fetch(
      new URL(
        `/commands/modify-cover-picture/${coverPictureId}`,
        process.env.REACT_APP_API_BASE_URL
      ),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          unsplashPhotoId: id,
          unsplashPhotoRawUrl: urls.raw,
        }),
      }
    );

    if (!modifyCoverPictureResponse.ok) {
      setSaveError(true);
      return false;
    }

    dispatch({
      type: "modify-cover-picture",
      payload: {
        coverPictureId,
        coverPictureUnsplashPhotoId: urls.id,
        coverPictureUnsplashPhotoRawUrl: urls.raw,
      },
    });

    setSaved(true);
    return true;
  };

  return (
    <>
      <Grid container direction="column" spacing={upSm ? 3 : 2}>
        <Grid item>
          <ProfilePicture
            loading={!biography}
            biography={biography}
            onSave={handleSaveProfilePicture}
          />
        </Grid>
        <Grid item>
          <AboutMe
            loading={!biography}
            biography={biography}
            onSave={handleSaveAboutMe}
          />
        </Grid>
        <Grid item>
          <ServiceLanguages
            loading={!biography}
            biography={biography}
            onSave={handleSaveServiceLanguages}
          />
        </Grid>
        <Grid item>
          <ContactInformation
            loading={!biography}
            biography={biography}
            onSave={handleSaveContactInformation}
          />
        </Grid>
        <Grid item>
          <WebReferences
            loading={!biography}
            biography={biography}
            onSave={handleSaveWebReferences}
          />
        </Grid>
        <Grid item>
          <CoverPicture
            loading={!biography}
            biography={biography}
            onSelectFile={handleSelectCoverPictureFile}
            onSelectUnsplashPhoto={handleSelectCoverPictureUnsplashPhoto}
          />
        </Grid>
      </Grid>
      <Box
        sx={(theme) => ({
          height: theme.spacing(7),
          [theme.breakpoints.up("md")]: {
            height: theme.spacing(11),
          },
        })}
      />
      <VerifyProfilePhoneNumberDialog
        submittedPhoneNumber={biography?.phoneNumberInE164Format}
        open={openVerifyProfilePhoneNumberDialog}
        onClose={() => setOpenVerifyProfilePhoneNumberDialog(false)}
        onVerify={handleVerify}
      />
      <SuccessAlert
        open={saved}
        onClose={() => setSaved(false)}
        content={t("biography.alerts.saved")}
      />
      <ErrorAlert
        open={saveError}
        onClose={() => setSaveError(false)}
        content={
          <Trans
            i18nKey="profile:biography.alerts.saveError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
      <ErrorAlert
        autoHide
        open={profilePictureSizeTooLargeError}
        onClose={() => setProfilePictureSizeTooLargeError(false)}
        content={t("biography.alerts.profilePictureSizeTooLargeError")}
      />
      <ErrorAlert
        autoHide
        open={coverPictureSizeTooLargeError}
        onClose={() => setCoverPictureSizeTooLargeError(false)}
        content={t("biography.alerts.coverPictureSizeTooLargeError")}
      />
    </>
  );
};

export default Biography;
