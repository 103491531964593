import { addYears } from "date-fns";
import { useCallback, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useMatch } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { getAdditionalBrowserProperties } from "@vesta/lib/mixpanel";
import { useSession } from "@vesta/lib/react";

const anonymousIdCookieOptions = {
  path: "/",
  domain: process.env.REACT_APP_ANONYMOUS_ID_COOKIE_DOMAIN,
  expires: addYears(new Date(), 1),
  sameSite: "strict",
  secure: process.env.REACT_APP_ANONYMOUS_ID_COOKIE_SECURE === "true",
};

export const useAnonymousId = () => {
  const [cookies, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    if (cookies["vesta-anonymous-id"]) {
      return;
    }

    setCookie("vesta-anonymous-id", uuidv4(), anonymousIdCookieOptions);
  }, [cookies]);

  const resetAnonymousId = () => {
    if (cookies["vesta-anonymous-id"]) {
      removeCookie("vesta-anonymous-id", anonymousIdCookieOptions);
    }
  };

  return [cookies["vesta-anonymous-id"], resetAnonymousId];
};

export const useTrackEvent = () => {
  const [, jwtToken] = useSession();
  const [anonymousId] = useAnonymousId();

  const callback = useCallback(
    async (eventName, properties) => {
      if (anonymousId) {
        const url = new URL(
          `/track-event/${eventName}`,
          process.env.REACT_APP_API_BASE_URL
        );

        const headers = jwtToken
          ? {
              Authorization: `Bearer ${jwtToken}`,
              "Content-Type": "application/json",
            }
          : {
              "Content-Type": "application/json",
            };

        await fetch(url, {
          method: "POST",
          headers,
          body: JSON.stringify({ anonymousId, properties }),
        });
      }
    },
    [jwtToken, anonymousId]
  );

  return callback;
};

export const usePageViewedTracking = () => {
  const trackEvent = useTrackEvent();
  const location = useLocation();

  const matchesVerifySignInToken = useMatch("/sign-in/:email/verify-token");
  const matchesVerifySignUpToken = useMatch("/sign-up/:email/verify-token");

  useEffect(() => {
    if (matchesVerifySignInToken || matchesVerifySignUpToken) {
      return;
    }

    trackEvent("Page viewed", {
      ...getAdditionalBrowserProperties(),
      "Active locale": "fr",
    });
  }, [
    location, // tracks Page viewed every time the location changes
    matchesVerifySignInToken,
    matchesVerifySignUpToken,
    trackEvent,
  ]);
};

/**
 * This callback should be called whenever a visitor provides her email address to sign in. This will associate her pre-signin events with her email adress.
 * For more details, see https://help.mixpanel.com/hc/en-us/articles/115004497803-Identity-Management-Best-Practices
 */
export const useIdentifyAnonymous = () => {
  const trackEvent = useTrackEvent();
  const [anonymousId] = useAnonymousId();

  const callback = useCallback(
    async (email) => {
      await trackEvent("$identify", {
        $identified_id: email.toLowerCase(),
        $anon_id: anonymousId,
      });
    },
    [trackEvent, anonymousId]
  );

  return callback;
};
