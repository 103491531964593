import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Container, Grid } from "@mui/material";

import { BackButton } from "@vesta/components/atoms";
import { useUpSm } from "@vesta/lib/react";

const Body = ({ sx, alert, information, geography }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("territory");

  return (
    <Container sx={sx} maxWidth="xl">
      <Grid container direction="column" spacing={upSm ? 3 : 2}>
        <Grid item>
          <BackButton to="/profile/territories" label={t("body.backLabel")} />
        </Grid>
        {alert && <Grid item>{alert}</Grid>}
        <Grid item>{information}</Grid>
        <Grid item>{geography}</Grid>
      </Grid>
    </Container>
  );
};

Body.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  alert: PropTypes.node,
  geography: PropTypes.node.isRequired,
  information: PropTypes.node.isRequired,
};

export default Body;
