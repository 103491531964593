import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ServiceAgreementPaper } from "@vesta/components/molecules";

const CollectivePrescriptionToTreatStreptococcusAgreement = ({
  agreement,
  onReject,
  onAccept,
}) => {
  const { t } = useTranslation("service");

  return (
    <ServiceAgreementPaper
      agreement={agreement}
      context={t(
        "collectivePrescriptionToTreatStreptococcusAgreement.context.text"
      )}
      requirements={t(
        "collectivePrescriptionToTreatStreptococcusAgreement.requirement.text"
      )}
      onReject={onReject}
      onAccept={onAccept}
    />
  );
};

CollectivePrescriptionToTreatStreptococcusAgreement.propTypes = {
  agreement: PropTypes.object.isRequired,
  onReject: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default CollectivePrescriptionToTreatStreptococcusAgreement;
