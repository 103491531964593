import L from "leaflet";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const greaterMontrealBounds = [
  [45.3106317275415, -74.0382385253906],
  [45.3106317275415, -73.372192382812],
  [45.743568608913, -73.372192382812],
  [45.743568608913, -74.0382385253906],
];

const NewPolygonMap = ({ id, onCreate }) => {
  const { t } = useTranslation("components");

  useEffect(() => {
    const map = L.map(id).fitBounds(greaterMontrealBounds);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const currentPosition = [
          position.coords.latitude,
          position.coords.longitude,
        ];
        map.flyTo(currentPosition);
      });
    }

    map.on("pm:create", (event) => {
      const { geometry } = event.layer.toGeoJSON();
      onCreate([...geometry.coordinates[0]]);
    });

    map.pm.setLang("fr");
    map.pm.enableDraw("Polygon", {
      allowSelfIntersection: false,
    });

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: `&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">${t(
        "molecules.newPolygonMap.attribution"
      )}</a>`,
    }).addTo(map);
  }, []);

  // Avoid the sx prop because it will prevent leaflet from setting its own classes
  return <div id={id} style={{ height: "100%" }} />;
};

NewPolygonMap.propTypes = {
  id: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default NewPolygonMap;
