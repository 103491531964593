import { addHours, isFuture, subDays } from "date-fns";
import { useCallback, useEffect, useState } from "react";

import { origin } from "@vesta/lib/enum";
import { useGoogleMapsScript, useSession } from "@vesta/lib/react";

export const usePreviousAppointment = (appointment) => {
  const [, jwtToken] = useSession();
  const [noPreviousAppointment, setNoPreviousAppointment] = useState(false);
  const [previousAppointment, setPreviousAppointment] = useState(null);

  const getLatestAppointmentDetailsBetweenTwoDates = useCallback(
    async (start, end) => {
      const url = new URL(
        `/queries/get-latest-appointment-details-between-two-dates?` +
          new URLSearchParams({
            start: start.toISOString(),
            end: end.toISOString(),
          }),
        process.env.REACT_APP_API_BASE_URL
      );

      const response = await fetch(url, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });

      if (response.status === 404) {
        setNoPreviousAppointment(true);
        setPreviousAppointment(null);
        return;
      }

      if (!response.ok) {
        return;
      }

      const latestAppointmentDetails = await response.json();
      const {
        patientBirthday,
        someoneElse,
        notes,
        start: appointmentStart,
        end: appointmentEnd,
        submitted,
        cancelled,
      } = latestAppointmentDetails;

      setNoPreviousAppointment(false);
      setPreviousAppointment({
        ...latestAppointmentDetails,
        patientBirthday: patientBirthday ? new Date(patientBirthday) : null,
        someoneElse: someoneElse
          ? {
              ...someoneElse,
              birthday: new Date(someoneElse.birthday),
            }
          : null,
        notes: notes.map((x) => ({
          ...x,
          rawContent: JSON.parse(x.rawContent),
          attachments: x.attachments.map((y) => ({
            ...y,
            created: new Date(y.created),
            deleted: y.deleted ? new Date(y.deleted) : null,
          })),
          created: new Date(x.created),
          modified: new Date(x.modified),
          completed: x.completed ? new Date(x.completed) : null,
        })),
        start: new Date(appointmentStart),
        end: new Date(appointmentEnd),
        submitted: new Date(submitted),
        cancelled: cancelled ? new Date(cancelled) : null,
        canCancel: !cancelled && new Date(start) >= Date.now(),
      });
    },
    [jwtToken]
  );

  useEffect(() => {
    if (appointment) {
      getLatestAppointmentDetailsBetweenTwoDates(
        subDays(appointment.start, 1),
        appointment.start
      );
    }
  }, [appointment, getLatestAppointmentDetailsBetweenTwoDates]);

  return [previousAppointment, noPreviousAppointment];
};

export const useDirections = (
  selectedOrigin,
  geoLocationPosition,
  previousAppointment,
  appointment
) => {
  const status = useGoogleMapsScript();
  const [directions, setDirections] = useState();
  const [loading, setLoading] = useState(false);
  const [directionsNotOk, setDirectionsNotOk] = useState(false);

  useEffect(() => {
    if (status !== "ready") {
      return;
    }

    if (!appointment) {
      return;
    }

    if (selectedOrigin === origin.none) {
      return;
    }

    setDirections(null);
    setLoading(true);

    let request = {
      destination: appointment.patientFormattedAddress,
      travelMode: "DRIVING",
      drivingOptions: {
        trafficModel: "pessimistic",
      },
    };

    if (selectedOrigin === origin.previousAppointment) {
      if (!previousAppointment) {
        return;
      }

      const departureTime = previousAppointment.end;

      request = {
        ...request,
        origin: previousAppointment.patientFormattedAddress,
        drivingOptions: {
          ...request.drivingOptions,
          ...(isFuture(departureTime)
            ? { departureTime }
            : { departureTime: new Date() }),
        },
      };
    }

    if (selectedOrigin === origin.currentPosition) {
      if (!geoLocationPosition) {
        return;
      }

      const departureTime = addHours(appointment.start, -1);

      request = {
        ...request,
        origin: {
          lat: geoLocationPosition.coords.latitude,
          lng: geoLocationPosition.coords.longitude,
        },
        drivingOptions: {
          ...request.drivingOptions,
          ...(isFuture(departureTime)
            ? { departureTime }
            : { departureTime: new Date() }),
        },
      };
    }

    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(request, (result, directionsStatus) => {
      if (directionsStatus === "OK") {
        setDirections(result);
      } else {
        setDirectionsNotOk(true);
      }
      setLoading(false);
    });
  }, [
    status,
    selectedOrigin,
    geoLocationPosition,
    previousAppointment,
    appointment,
  ]);

  return [directions, loading, directionsNotOk, setDirectionsNotOk];
};

export const useGeocode = (appointment) => {
  const status = useGoogleMapsScript();
  const [geocode, setGeocode] = useState();
  const [loading, setLoading] = useState(false);
  const [geocodeNotOk, setGeocodeNotOk] = useState(false);

  useEffect(() => {
    if (status !== "ready") {
      return;
    }

    if (!appointment) {
      return;
    }

    setGeocode(null);
    setLoading(true);

    const address = appointment.patientFormattedAddress;

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address }, (results, geocodeStatus) => {
      if (geocodeStatus === "OK") {
        setGeocode(results);
      } else {
        setGeocodeNotOk(true);
      }
      setLoading(false);
    });
  }, [status, appointment]);

  return [geocode, loading, geocodeNotOk, setGeocodeNotOk];
};

export const usePanorama = (geocode) => {
  const status = useGoogleMapsScript();
  const [panorama, setPanorama] = useState();
  const [loading, setLoading] = useState(false);
  const [panoramaNotOk, setPanoramaNotOk] = useState(false);

  useEffect(() => {
    if (status !== "ready") {
      return;
    }

    if (!geocode) {
      return;
    }

    setPanorama(null);
    setLoading(true);

    const location = geocode[0].geometry.location;
    const source = window.google.maps.StreetViewSource.OUTDOOR;

    const streetViewService = new window.google.maps.StreetViewService();
    streetViewService.getPanorama(
      {
        location,
        radius: 500,
        source,
      },
      (results, panoramaStatus) => {
        if (panoramaStatus === "OK") {
          setPanorama(results);
        } else {
          setPanoramaNotOk(true);
        }
        setLoading(false);
      }
    );
  }, [status, geocode]);

  return [panorama, loading, panoramaNotOk, setPanoramaNotOk];
};
