import PropTypes from "prop-types";

import { Grid, Skeleton, Typography } from "@mui/material";

const Step = ({ loading, title, text, form, actions }) => (
  <Grid container direction="column" spacing={4}>
    <Grid item>
      <Typography variant="h1">{title}</Typography>
    </Grid>
    {loading ? (
      <Grid item>
        <Skeleton width="60%" />
        <Skeleton width="35%" />
      </Grid>
    ) : (
      <>
        {text && (
          <Grid item sx={{ whiteSpace: "pre-line" }}>
            <Typography>{text}</Typography>
          </Grid>
        )}
        {form && <Grid item>{form}</Grid>}
        {actions && (
          <Grid item container spacing={2}>
            {actions.map((action, index) => (
              <Grid key={index} item>
                {action}
              </Grid>
            ))}
          </Grid>
        )}
      </>
    )}
  </Grid>
);

Step.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.node.isRequired,
  text: PropTypes.node,
  form: PropTypes.node,
  actions: PropTypes.arrayOf(PropTypes.node),
};

export default Step;
