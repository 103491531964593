import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ButtonBase } from "@mui/material";
import { Add } from "@mui/icons-material";

import { useUpMd } from "@vesta/lib/react";

const AddFileButton = ({ sx, accept, multiple, onChange }) => {
  const upMd = useUpMd();
  const { t } = useTranslation("components");

  // TODO make this component accessible via the keyboard
  // Ex. https://accessabilly.com/a-styled-accessible-file-upload/

  return (
    <>
      <input
        id="add-file-button"
        type="file"
        accept={accept}
        multiple={multiple}
        style={{ display: "none" }}
        onChange={onChange}
      />
      <label htmlFor="add-file-button">
        <ButtonBase
          sx={[
            (theme) => ({
              backgroundColor: theme.palette.action.selected,
              borderRadius: theme.spacing(1 / 2),
            }),
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
          component="span"
          aria-label={t("atoms.addFileButton.label")}
        >
          <Add fontSize={upMd ? "large" : undefined} />
        </ButtonBase>
      </label>
    </>
  );
};

AddFileButton.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default AddFileButton;
