import PropTypes from "prop-types";

import { Alert, Snackbar } from "@mui/material";

const ErrorAlert = ({ autoHide, open, onClose, content }) => {
  const handleClose = (_, reason) => {
    if (reason !== "clickaway") {
      onClose();
    }
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHide ? 6000 : null}
      onClose={handleClose}
    >
      <Alert
        elevation={6}
        variant="filled"
        severity="error"
        onClose={handleClose}
      >
        {content}
      </Alert>
    </Snackbar>
  );
};

ErrorAlert.propTypes = {
  autoHide: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.node.isRequired,
};

export default ErrorAlert;
