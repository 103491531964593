import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";

import { Button, Grid, IconButton, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

import {
  ContactUsLink,
  ErrorAlert,
  LoadingButton,
} from "@vesta/components/atoms";
import { ServiceLanguageIdSelect } from "@vesta/components/molecules";
import {
  useOnboardingSessionV1,
  useScrollToTopOnMount,
  useSession,
  useUpSm,
} from "@vesta/lib/react";
import { serviceLanguageIds } from "@vesta/lib/utils";

const nullServiceLanguageId = "";

const Step2 = ({ onPrevious, onNext }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("onboarding-v1");
  const [, jwtToken] = useSession();
  const [onboardingSession, dispatch] = useOnboardingSessionV1();
  const [selectedServiceLanguageIds, setSelectedServiceLanguageIds] = useState(
    onboardingSession.serviceLanguageIds
  );
  const [submitting, setSubmitting] = useState(false);
  const [modifyServiceLanguagesError, setModifyServiceLanguagesError] =
    useState(false);

  useScrollToTopOnMount();

  const handleChange = (i) => (event) =>
    setSelectedServiceLanguageIds((prevValues) =>
      prevValues.map((x, j) => (j === i ? event.target.value : x))
    );

  const handleDelete = (i) => () =>
    setSelectedServiceLanguageIds((prevValues) =>
      prevValues.filter((_, j) => j !== i)
    );

  const handleAdd = () =>
    setSelectedServiceLanguageIds((prevValues) => [
      ...prevValues,
      nullServiceLanguageId,
    ]);

  const handleNextClick = async () => {
    setSubmitting(true);

    const url = new URL(
      "/commands/modify-service-languages",
      process.env.REACT_APP_API_BASE_URL
    );

    const serviceLanguageIds = selectedServiceLanguageIds.filter(
      (x) => x !== nullServiceLanguageId
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        serviceLanguageIds,
      }),
    });

    if (ok) {
      dispatch({
        type: "modify-service-languages",
        payload: {
          serviceLanguageIds,
        },
      });
      await onNext();
    } else {
      setSubmitting(false);
      setModifyServiceLanguagesError(true);
    }
  };

  return (
    <>
      <Grid container direction="column" spacing={4} data-aos="fade-up">
        <Grid item>
          <Typography gutterBottom variant="h1">
            {t("onboardingSteps.step2.title")}
          </Typography>
        </Grid>
        <Grid container direction="column" spacing={upSm ? 3 : 2} item>
          {selectedServiceLanguageIds.map((x, i) => {
            const excludedValues = selectedServiceLanguageIds.filter(
              (y) => y !== x
            );

            const isLastSelect = i === selectedServiceLanguageIds.length - 1;
            const notEveryServiceLanguageIdsSelected =
              selectedServiceLanguageIds.length !== serviceLanguageIds.length;

            const isNotFirstSelect = i !== 0;

            return (
              <Grid
                key={i}
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
                item
              >
                <ServiceLanguageIdSelect
                  id={`serviceLanguageId-${i}`}
                  label={t(`onboardingSteps.step2.label.${i + 1}`)}
                  value={x}
                  excludedValues={excludedValues}
                  onChange={handleChange(i)}
                />
                {isLastSelect &&
                  notEveryServiceLanguageIdsSelected &&
                  (upSm ? (
                    <Button
                      sx={(theme) => ({
                        marginLeft: theme.spacing(2),
                        marginRight: theme.spacing(1),
                      })}
                      onClick={() => handleAdd()}
                      startIcon={<Add color="primary" />}
                    >
                      {t("onboardingSteps.step2.addAriaLabel")}
                    </Button>
                  ) : (
                    <IconButton
                      edge="end"
                      aria-label={t("onboardingSteps.step2.addAriaLabel")}
                      onClick={() => handleAdd()}
                      size="large"
                    >
                      <Add color="primary" fontSize="small" />
                    </IconButton>
                  ))}
                {isNotFirstSelect && (
                  <IconButton
                    edge="end"
                    aria-label={t("onboardingSteps.step2.deleteAriaLabel")}
                    onClick={handleDelete(i)}
                    size="large"
                  >
                    <Delete color="primary" fontSize="small" />
                  </IconButton>
                )}
              </Grid>
            );
          })}
        </Grid>
        <Grid container justifyContent="space-between" item>
          <Grid item>
            <Button onClick={onPrevious} variant="outlined">
              {t("onboardingSteps.step2.actions.previous")}
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              disabled={
                submitting || !setSelectedServiceLanguageIds.length === 0
              }
              loading={submitting}
              onClick={handleNextClick}
              variant="contained"
            >
              {t("onboardingSteps.step2.actions.next")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
      <ErrorAlert
        open={modifyServiceLanguagesError}
        onClose={() => setModifyServiceLanguagesError(false)}
        content={
          <Trans
            i18nKey="onboarding-v1:onboardingSteps.step2.alerts.modifyServiceLanguagesError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
    </>
  );
};

Step2.propTypes = {
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default Step2;
