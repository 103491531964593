import { fr } from "date-fns/locale";
import { formatDuration, intervalToDuration } from "date-fns";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Alert, AlertTitle, Skeleton } from "@mui/material";

const formattedDurationInTraffic = (directions) =>
  formatDuration(
    intervalToDuration({
      start: 0,
      end: durationInTrafficInSeconds(directions) * 1000,
    }),
    { locale: fr }
  );

const durationInTrafficInSeconds = (directions) =>
  directions.routes[0].legs[0].duration_in_traffic.value;

const DurationInTrafficAlert = ({ sx, loading, directions }) => {
  const { t } = useTranslation("appointment");

  const content = loading ? (
    <Skeleton />
  ) : durationInTrafficInSeconds(directions) === 0 ? (
    t("directions.durationInTrafficAlert.empty")
  ) : (
    formattedDurationInTraffic(directions)
  );

  return (
    <Alert
      sx={[
        (theme) => ({ backgroundColor: theme.palette.background.paper }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      component="aside"
      severity="info"
      variant="outlined"
    >
      <AlertTitle>{t("directions.durationInTrafficAlert.title")}</AlertTitle>
      {content}
    </Alert>
  );
};

DurationInTrafficAlert.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  loading: PropTypes.bool,
  directions: PropTypes.object,
};

export default DurationInTrafficAlert;
