import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import { Button, Grid, Typography } from "@mui/material";

import {
  useOnboardingSessionV1,
  useScrollToTopOnMount,
} from "@vesta/lib/react";

const Step7 = ({ onPrevious, onFinish }) => {
  const { t } = useTranslation("onboarding-v1");
  const [onboardingSession] = useOnboardingSessionV1();

  useScrollToTopOnMount();

  return (
    <Grid container direction="column" spacing={4} data-aos="fade-up">
      <Grid item>
        <Typography gutterBottom variant="h1">
          {t("onboardingSteps.step7.title", {
            firstName: onboardingSession.firstName,
          })}
        </Typography>
        <Typography>
          <Trans
            i18nKey="onboarding-v1:onboardingSteps.step7.subtitle"
            components={{
              emphasize: <b />,
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
            }}
          />
        </Typography>
      </Grid>
      <Grid container justifyContent="space-between" item>
        <Grid item>
          <Button onClick={onPrevious} variant="outlined">
            {t("onboardingSteps.step7.actions.previous")}
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={onFinish} variant="contained">
            {t("onboardingSteps.step7.actions.next")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

Step7.propTypes = {
  onPrevious: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default Step7;
