import { useMatch } from "react-router-dom";
import { useCallback, useEffect, useReducer } from "react";

import {
  useAuthorizedFetchJson,
  useIsAuthorized,
  useSession,
} from "@vesta/lib/react";

const useGetAppContext = (isAuthorized) => {
  const [, jwtToken] = useSession();
  const [fetchJson, json] = useAuthorizedFetchJson(jwtToken);

  const getAppContext = useCallback(async () => {
    if (isAuthorized) {
      await fetchJson(
        new URL("queries/get-app-context", process.env.REACT_APP_API_BASE_URL)
      );
    }
  }, [isAuthorized]);

  useEffect(() => {
    getAppContext();
  }, []);

  return json;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "init": {
      const { onboardingSessions, reminders } = action.payload;

      return {
        ...action.payload,
        onboardingSessions: onboardingSessions.map((x) => ({
          ...x,
          created: new Date(x.created),
        })),
        reminders: reminders.map((x) => ({
          ...x,
          created: new Date(x.created),
          remindOn: x.remindOn ? new Date(x.remindOn) : null,
          completed: x.completed ? new Date(x.completed) : null,
          deleted: x.deleted ? new Date(x.deleted) : null,
        })),
      };
    }
    case "finish-onboarding-session": {
      const { onboardingSessionId, reminders } = action.payload;

      return {
        ...state,
        onboardingSessions: state.onboardingSessions.map((x) => ({
          ...x,
          isFinished: x.id === onboardingSessionId ? true : x.isFinished,
        })),
        reminders: [
          ...state.reminders,
          ...reminders.map((x) => ({
            ...x,
            created: new Date(x.created),
            remindOn: x.remindOn ? new Date(x.remindOn) : null,
            completed: x.completed ? new Date(x.completed) : null,
            deleted: x.deleted ? new Date(x.deleted) : null,
          })),
        ],
      };
    }
    case "refresh-reminders": {
      return {
        ...state,
      };
    }
    case "create-reminder": {
      const { reminderId, created, title } = action.payload;

      return {
        ...state,
        reminders: [
          ...state.reminders,
          {
            id: reminderId,
            created,
            title,
          },
        ],
      };
    }
    case "complete-reminder": {
      const { reminderId, completed } = action.payload;

      return {
        ...state,
        reminders: state.reminders.map((x) => ({
          ...x,
          completed: x.id === reminderId ? completed : x.completed,
        })),
      };
    }
    case "revert-complete-reminder": {
      const { reminderId } = action.payload;

      return {
        ...state,
        reminders: state.reminders.map((x) => ({
          ...x,
          completed: x.id === reminderId ? null : x.completed,
        })),
      };
    }
    case "delete-reminder": {
      const { reminderId, deleted } = action.payload;

      return {
        ...state,
        reminders: state.reminders.map((x) => ({
          ...x,
          deleted: x.id === reminderId ? deleted : x.deleted,
        })),
      };
    }
    case "modify-reminder": {
      const { reminderId, title, details } = action.payload;

      return {
        ...state,
        reminders: state.reminders.map((x) => ({
          ...x,
          title: x.id === reminderId ? title : x.title,
          details: x.id === reminderId ? details : x.details,
        })),
      };
    }
    case "remind-about-this": {
      const { reminderId, remindOn } = action.payload;

      return {
        ...state,
        reminders: state.reminders.map((x) => ({
          ...x,
          remindOn: x.id === reminderId ? remindOn : x.remindOn,
          completed: x.id === reminderId && x.completed ? null : x.completed,
        })),
      };
    }
    case "do-not-remind-about-this": {
      const { reminderId } = action.payload;

      return {
        ...state,
        reminders: state.reminders.map((x) => ({
          ...x,
          remindOn: x.id === reminderId ? null : x.remindOn,
          completed: x.id === reminderId && x.completed ? null : x.completed,
        })),
      };
    }
    case "create-territory-as-polygon": {
      return {
        ...state,
        nbTerritories: state.nbTerritories + 1,
      };
    }
    case "bulk-create-services-from-service-templates-and-delete-services": {
      const { createdServices, deletedServices } = action.payload;

      return {
        ...state,
        nbServices:
          state.nbServices + createdServices.length - deletedServices.length,
      };
    }
    case "create-service": {
      return {
        ...state,
        nbServices: state.nbServices + 1,
      };
    }
    case "delete-service": {
      return {
        ...state,
        nbServices: state.nbServices - 1,
      };
    }
    case "submit-permit-and-photo-identification": {
      const { oiiqPermitNumber, oiiaqPermitNumber, photoIdentificationId } =
        action.payload;

      return {
        ...state,
        oiiqPermitNumber,
        oiiaqPermitNumber,
        photoIdentificationId,
      };
    }
    default:
      throw new Error(`The action type ${action.type} cannot be handled`);
  }
};

export const useAppContextReducer = () => {
  const isAuthorized = useIsAuthorized();
  const appContext = useGetAppContext(isAuthorized);
  const [state, dispatch] = useReducer(reducer);

  useEffect(() => {
    if (appContext) {
      dispatch({ type: "init", payload: appContext });
    }
  }, [appContext]);

  return [isAuthorized, state, dispatch];
};

export const useFirstUnfinishedOnboardingSession = (onboardingSessions) => {
  const matchesOnboardingV1 = useMatch("/onboarding/v1/*");

  if (matchesOnboardingV1) {
    return null;
  }

  const firstUnfinishedOnboardingSessionV1 = onboardingSessions
    .filter((x) => !x.isFinished)
    .sort((a, b) => b.created - a.created)
    .find((x) => x.name === "v1");

  return firstUnfinishedOnboardingSessionV1;
};
