import { fr } from "date-fns/locale";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Collapse,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ExpandMore } from "@mui/icons-material";

import { ResultFiles } from "@vesta/components/organisms";
import { useMedicalRecord } from "@vesta/lib/react";

const headerSx = (theme) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  minHeight: theme.spacing(12),
});

const expandSx = (theme) => ({
  transform: "rotate(0deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
});

const StyledGrowBox = styled(Box)({
  flexGrow: 1,
});

const StyledChip = styled((props) => <Chip size="small" {...props} />)(
  ({ theme }) => ({
    "&:not(:last-of-type)": {
      marginRight: theme.spacing(1 / 2),
    },
  })
);

const ResultCard = ({ loading, resultId, order, expandInitially }) => {
  const { t } = useTranslation("patient");
  const [raised, setRaised] = useState(false);
  const [expanded, setExpanded] = useState(expandInitially);
  const [medicalRecord] = useMedicalRecord();

  const result = medicalRecord?.medicalInformation.results.find(
    (x) => x.resultId === resultId
  );

  if (loading) {
    return (
      <Card
        sx={(theme) => ({
          display: "flex",
          padding: theme.spacing(2),
        })}
        component="section"
      >
        <Box sx={headerSx}>
          <Typography variant="button">
            <Skeleton width="70%" />
          </Typography>
          <Typography variant="caption">
            <Skeleton width="40%" />
          </Typography>
          <StyledGrowBox />
          <Typography variant="caption">
            <Skeleton width="50%" />
          </Typography>
        </Box>
        <IconButton sx={expandSx} disabled edge="end" size="large">
          <ExpandMore />
        </IconButton>
      </Card>
    );
  }

  return (
    <Card
      component="section"
      raised={raised}
      onMouseOver={() => setRaised(true)}
      onMouseOut={() => setRaised(false)}
    >
      <CardActionArea
        sx={(theme) => ({
          display: "flex",
          padding: theme.spacing(2),
        })}
        component="div"
        onClick={() => setExpanded((prevValue) => !prevValue)}
      >
        <Box sx={headerSx}>
          <Typography component="h2" variant="button">
            {t("results.resultCard.title", {
              order,
            })}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            {format(result.created, "PPP", { locale: fr })}
          </Typography>
          <StyledGrowBox />
          <div>
            {result.categoryId && (
              <StyledChip
                sx={(theme) => ({
                  backgroundColor: theme.palette.primary.dark,
                  color: theme.palette.getContrastText(
                    theme.palette.primary.dark
                  ),
                })}
                label={result.categoryId}
              />
            )}
            <StyledChip
              sx={(theme) => ({
                backgroundColor: theme.palette.secondary.dark,
                color: theme.palette.getContrastText(
                  theme.palette.secondary.dark
                ),
              })}
              label={t("results.resultCard.nbResultFiles", {
                count: result.files.length,
              })}
            />
          </div>
        </Box>
        <IconButton
          sx={[expandSx, expanded && { transform: "rotate(180deg)" }]}
          edge="end"
          aria-expanded={expanded}
          aria-label={
            expanded
              ? t("results.resultCard.showLess")
              : t("results.resultCard.showMore")
          }
          size="large"
        >
          <ExpandMore />
        </IconButton>
      </CardActionArea>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent
          sx={(theme) => ({
            padding: theme.spacing(2),
            [theme.breakpoints.up("sm")]: {
              padding: theme.spacing(3),
            },
          })}
        >
          <ResultFiles
            files={result.files}
            created={result.created}
          ></ResultFiles>
        </CardContent>
      </Collapse>
    </Card>
  );
};

ResultCard.propTypes = {
  loading: PropTypes.bool,
  resultId: PropTypes.string,
  order: PropTypes.number,
  expandInitially: PropTypes.bool,
};

export default ResultCard;
