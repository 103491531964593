import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { Computer, OpenInNew } from "@mui/icons-material";

import { useUpSm } from "@vesta/lib/react";

const WebsiteUriTextField = ({ className, readOnly, value, ...rest }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("components");

  const endAdornment = value ? (
    <InputAdornment position="end">
      <IconButton
        color="primary"
        component="a"
        edge="end"
        href={value}
        target="_blank"
        aria-label={t("molecules.websiteUriTextField.openInNewLabel")}
        size="small"
      >
        <OpenInNew />
      </IconButton>
    </InputAdornment>
  ) : null;

  return (
    <Grid
      className={className}
      container
      alignItems="center"
      spacing={upSm ? 3 : 2}
    >
      <Grid item>
        <Computer fontSize="large" />
      </Grid>
      <Grid item xs>
        <TextField
          variant="standard"
          type="url"
          disabled={readOnly}
          fullWidth
          label={t("molecules.websiteUriTextField.label")}
          placeholder={t("molecules.websiteUriTextField.placeholder")}
          value={value}
          InputProps={{
            readOnly,
            endAdornment,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          {...rest}
        />
      </Grid>
    </Grid>
  );
};

WebsiteUriTextField.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

export default WebsiteUriTextField;
