import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Alert, Link as MuiLink, Typography } from "@mui/material";

import { useAppContext } from "@vesta/lib/react";

const CannotCreateAvailabilityAlert = () => {
  const { t } = useTranslation("calendar");
  const [appContext] = useAppContext();

  const { nbServices, nbTerritories } = appContext;

  return (
    <Alert
      sx={(theme) => ({ backgroundColor: theme.palette.background.paper })}
      component="aside"
      severity="warning"
      variant="outlined"
    >
      <Typography gutterBottom variant="body2">
        {nbServices === 0 && nbTerritories === 0
          ? t(
              "availabilities.createAvailabilityDialog.cannotCreateAvailabilityAlert.noServicesAndTerritories.text"
            )
          : nbServices === 0
          ? t(
              "availabilities.createAvailabilityDialog.cannotCreateAvailabilityAlert.noServices.text"
            )
          : nbTerritories == 0
          ? t(
              "availabilities.createAvailabilityDialog.cannotCreateAvailabilityAlert.noTerritories.text"
            )
          : null}
      </Typography>
      <MuiLink
        component={Link}
        to={
          nbServices === 0 && nbTerritories === 0
            ? "/profile/territories"
            : nbServices === 0
            ? "/profile/services"
            : nbTerritories == 0
            ? "/profile/territories"
            : null
        }
        color="primary"
        underline="always"
      >
        {t(
          "availabilities.createAvailabilityDialog.cannotCreateAvailabilityAlert.ctaLabel"
        )}
      </MuiLink>
    </Alert>
  );
};

export default CannotCreateAvailabilityAlert;
