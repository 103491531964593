import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import { AmountLabel } from "../";

const Pricing = ({ plan }) => {
  const { t } = useTranslation("global-modals");
  const { amount, taxes, total } = plan;

  return (
    <Grid
      container
      direction="column"
      alignContent="flex-end"
      alignItems="flex-end"
      spacing={1}
    >
      <Grid item>
        <AmountLabel
          label={t("choosePlanDialog.step1.amountLabel")}
          amount={amount}
        />
      </Grid>
      <Grid item>
        <AmountLabel
          label={t("choosePlanDialog.step1.taxLabel")}
          amount={taxes}
        />
      </Grid>
      <Grid item>
        <AmountLabel
          bold
          label={t("choosePlanDialog.step1.totalLabel")}
          amount={total}
        />
      </Grid>
    </Grid>
  );
};

Pricing.propTypes = {
  plan: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    taxes: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }),
};

export default Pricing;
