import AOS from "aos";
import { useEffect } from "react";

export const useAos = () => {
  useEffect(() => {
    const options = {
      once: true,
      delay: 0,
      duration: 500,
      easing: "ease-in-out",
    };

    AOS.init(options);
  }, []);
};
