import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

const BackButton = ({ to, label }) => (
  <Button to={to} component={Link} startIcon={<ArrowBack />}>
    {label}
  </Button>
);

BackButton.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default BackButton;
