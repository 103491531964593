import { useState } from "react";
import * as yup from "yup";

const initialSubmitCount = 0;
const initialValue = { name: "", url: "" };
const initialErrors = { name: "", url: "" };

export const useValue = () => {
  const [value, setValue] = useState(initialValue);

  return [value, setValue, () => setValue(initialValue)];
};

export const useErrors = () => {
  const [errors, setErrors] = useState(initialErrors);

  return [
    errors,
    (e) =>
      setErrors({
        name: e.inner.find((x) => x.path === "name")?.message ?? "",
        url: e.inner.find((x) => x.path === "url")?.message ?? "",
      }),
    () => setErrors(initialErrors),
  ];
};

export const useSubmitCount = () => {
  const [submitCount, setSubmitCount] = useState(initialSubmitCount);

  return [
    submitCount,
    () => setSubmitCount((prevValue) => prevValue + 1),
    () => setSubmitCount(initialSubmitCount),
  ];
};

export const useValidation = (t) => {
  const schema = yup.object().shape({
    name: yup.string().required(t("molecules.newWebReference.name.required")),
    url: yup
      .string()
      .url(t("molecules.newWebReference.url.uriValidation"))
      .required(t("molecules.newWebReference.url.required")),
  });

  const validate = (value) => schema.validate(value, { abortEarly: false });

  return validate;
};
