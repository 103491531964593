import PropTypes from "prop-types";

import { AppBar, Box, Toolbar } from "@mui/material";

import { Logo, LogoVariant } from "@vesta/components/atoms";
import { AccountMenu } from "@vesta/components/molecules";
import { useUpMd } from "@vesta/lib/react";

import { SecondarySidebarTrigger, SidebarTrigger, Tabs } from "./components";

const Header = ({ onSidebarTriggerClick, onSecondarySidebarTriggerClick }) => {
  const upMd = useUpMd();

  return (
    <AppBar
      sx={(theme) => ({ backgroundColor: theme.palette.background.paper })}
      elevation={1}
      position="fixed"
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {upMd ? (
          <>
            <Logo
              sx={(theme) => ({
                height: theme.spacing(5.5),
                marginRight: theme.spacing(5),
              })}
              variant={LogoVariant.WideRight}
            />
            <Tabs />
            <Box sx={{ flexGrow: 1 }} />
            <AccountMenu />
          </>
        ) : (
          <>
            <SidebarTrigger onClick={onSidebarTriggerClick} />
            <SecondarySidebarTrigger onClick={onSecondarySidebarTriggerClick} />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  onSidebarTriggerClick: PropTypes.func.isRequired,
  onSecondarySidebarTriggerClick: PropTypes.func.isRequired,
};

export default Header;
