import { useTranslation } from "react-i18next";

import { Badge, Box, Grid, Typography } from "@mui/material";

import { Appointment } from "@vesta/components/molecules";
import { useUpSm } from "@vesta/lib/react";

import { CollapsibleSection } from "./components";
import { useAppointmentsDailySummary } from "./hooks";

const Appointments = () => {
  const upSm = useUpSm();
  const { t } = useTranslation("home");
  const [summary] = useAppointmentsDailySummary();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <CollapsibleSection
          expandInitially
          loading={!summary}
          header={
            <Badge
              badgeContent={summary?.startingToday.length}
              color="primary"
              overlap="rectangular"
            >
              <Typography component="h2" variant="h3">
                {upSm
                  ? t("appointments.startingToday.titleUpSm")
                  : t("appointments.startingToday.title")}
              </Typography>
            </Badge>
          }
          loadingState={<Appointment loading />}
          emptyState={
            <Box p={2}>
              <Typography variant="body2">
                {t("appointments.startingToday.emptyState")}
              </Typography>
            </Box>
          }
          appointments={summary?.startingToday ?? []}
          renderAppointment={(appointment) => (
            <Appointment timeOnly appointment={appointment} />
          )}
        />
      </Grid>
      <Grid item>
        <CollapsibleSection
          expandInitially
          header={
            <Badge
              badgeContent={summary?.startingTomorrow.length}
              color="primary"
              overlap="rectangular"
            >
              <Typography component="h2" variant="h3">
                {upSm
                  ? t("appointments.startingTomorrow.titleUpSm")
                  : t("appointments.startingTomorrow.title")}
              </Typography>
            </Badge>
          }
          loading={!summary}
          loadingState={<Appointment loading />}
          emptyState={
            <Box p={2}>
              <Typography variant="body2">
                {t("appointments.startingTomorrow.emptyState")}
              </Typography>
            </Box>
          }
          appointments={summary?.startingTomorrow ?? []}
          renderAppointment={(appointment) => (
            <Appointment timeOnly appointment={appointment} />
          )}
        />
      </Grid>
      <Grid item>
        <CollapsibleSection
          header={
            <Badge
              badgeContent={summary?.submittedYesterday.length}
              color="primary"
              overlap="rectangular"
            >
              <Typography component="h2" variant="h3">
                {upSm
                  ? t("appointments.submittedYesterday.titleUpSm")
                  : t("appointments.submittedYesterday.title")}
              </Typography>
            </Badge>
          }
          loading={!summary}
          loadingState={<Appointment loading />}
          emptyState={
            <Box p={2}>
              <Typography variant="body2">
                {t("appointments.submittedYesterday.emptyState")}
              </Typography>
            </Box>
          }
          appointments={summary?.submittedYesterday ?? []}
          renderAppointment={(appointment) => (
            <Appointment appointment={appointment} />
          )}
        />
      </Grid>
      <Grid item>
        <CollapsibleSection
          header={
            <Badge
              badgeContent={summary?.cancelledYesterday.length}
              color="primary"
              overlap="rectangular"
            >
              <Typography component="h2" variant="h3">
                {upSm
                  ? t("appointments.cancelledYesterday.titleUpSm")
                  : t("appointments.cancelledYesterday.title")}
              </Typography>
            </Badge>
          }
          loading={!summary}
          loadingState={<Appointment loading />}
          emptyState={
            <Box p={2}>
              <Typography variant="body2">
                {t("appointments.cancelledYesterday.emptyState")}
              </Typography>
            </Box>
          }
          appointments={summary?.cancelledYesterday ?? []}
          renderAppointment={(appointment) => (
            <Appointment appointment={appointment} />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default Appointments;
