import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

import { Box } from "@mui/material";

import { useGoogleMapsScript } from "@vesta/lib/react";

const StreetView = ({ sx, geocode, panorama }) => {
  const streetViewRef = useRef(null);
  const status = useGoogleMapsScript();

  useEffect(() => {
    if (status !== "ready") {
      return;
    }

    const streetViewPanorama = new window.google.maps.StreetViewPanorama(
      streetViewRef.current
    );

    const from = panorama.location.latLng;
    const to = geocode[0].geometry.location;

    const heading = window.google.maps.geometry.spherical.computeHeading(
      from,
      to
    ); // To look at the front of the building

    streetViewPanorama.setPov({ heading, pitch: 0 });
    streetViewPanorama.setOptions({
      addressControl: false,
      scrollwheel: false,
    });
    streetViewPanorama.setMotionTracking(false);
    streetViewPanorama.setZoom(0);
    streetViewPanorama.setPano(panorama.location.pano);
  }, [status, geocode, panorama]);

  return (
    <Box
      sx={[
        (theme) => ({ height: theme.spacing(60) }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      ref={streetViewRef}
    />
  );
};

StreetView.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.array]),
  geocode: PropTypes.arrayOf(
    PropTypes.shape({
      geometry: PropTypes.shape({
        location: PropTypes.object.isRequired,
      }).isRequired,
    })
  ).isRequired,
  panorama: PropTypes.shape({
    location: PropTypes.shape({
      latLng: PropTypes.object.isRequired,
      pano: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default StreetView;
