import PropTypes from "prop-types";
import { forwardRef } from "react";

import { Button } from "@mui/material";

import { DelayedCircularProgress } from "@vesta/components/atoms";

const LoadingButton = forwardRef(({ sx, loading, children, ...rest }, ref) => (
  <Button
    sx={sx}
    ref={ref}
    endIcon={loading && <DelayedCircularProgress size={18} />}
    {...rest}
  >
    {children}
  </Button>
));

LoadingButton.displayName = "LoadingButton";

LoadingButton.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  loading: PropTypes.bool,
  children: PropTypes.node,
};

export default LoadingButton;
