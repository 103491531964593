import { format } from "date-fns";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import { PatientsAutocomplete } from "@vesta/components/atoms";
import {
  BirthdayDatePicker,
  EmailTextField,
  FirstNameTextField,
  LastNameTextField,
  PhoneNumberTextField,
  RamqHealthInsurance,
  RamqHealthInsuranceExpirationTextField,
  RamqHealthInsuranceNumberTextField,
  SexIdSelect,
  SpokenLanguageIdsSelect,
} from "@vesta/components/molecules";
import { useUpSm } from "@vesta/lib/react";

import { PatientSearchBar } from "./components";

const AppointmentPatientInformation = ({
  formik: {
    values: { searched, patient },
    errors,
    submitCount,
    handleChange,
    setFieldValue,
  },
}) => {
  const upSm = useUpSm();
  const { t } = useTranslation("components");

  const handlePatientAutocompleteChange = (
    _,
    {
      isNewPatient,
      isDemoPatient,
      firstName,
      lastName,
      email,
      sexId,
      birthday,
      spokenLanguageIds,
      ramqHealthInsuranceNumber,
      ramqHealthInsuranceExpiration,
      phoneNumberInE164Format,
    }
  ) => {
    setFieldValue("searched", true);
    setFieldValue("patient.isNewPatient", isNewPatient);
    setFieldValue("patient.isDemoPatient", isDemoPatient);
    setFieldValue("patient.firstName", firstName);
    setFieldValue("patient.lastName", lastName);
    setFieldValue("patient.email", email);
    setFieldValue("patient.sexId", sexId);
    setFieldValue("patient.birthday", birthday);
    setFieldValue("patient.spokenLanguageIds", spokenLanguageIds);
    setFieldValue(
      "patient.ramqHealthInsuranceNumber",
      ramqHealthInsuranceNumber
    );
    setFieldValue(
      "patient.ramqHealthInsuranceExpiration",
      ramqHealthInsuranceExpiration
        ? format(ramqHealthInsuranceExpiration, "yyyy MM")
        : ""
    );
    setFieldValue("patient.phoneNumberInE164Format", phoneNumberInE164Format);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h3">
          {t("molecules.appointmentPatientInformation.title")}
        </Typography>
      </Grid>
      {searched ? (
        <>
          <Grid item xs={12}>
            <RamqHealthInsurance
              number={
                <RamqHealthInsuranceNumberTextField
                  id="patient.ramqHealthInsuranceNumber"
                  value={patient.ramqHealthInsuranceNumber}
                  onChange={handleChange}
                  error={
                    submitCount > 0 &&
                    Boolean(errors.patient?.ramqHealthInsuranceNumber)
                  }
                  helperText={
                    submitCount > 0
                      ? errors.patient?.ramqHealthInsuranceNumber
                      : null
                  }
                />
              }
              expiration={
                <RamqHealthInsuranceExpirationTextField
                  id="patient.ramqHealthInsuranceExpiration"
                  value={patient.ramqHealthInsuranceExpiration}
                  onChange={handleChange}
                  error={
                    submitCount > 0 &&
                    Boolean(errors.patient?.ramqHealthInsuranceExpiration)
                  }
                  helperText={
                    submitCount > 0
                      ? errors.patient?.ramqHealthInsuranceExpiration
                      : null
                  }
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FirstNameTextField
              id="patient.firstName"
              value={patient.firstName}
              onChange={handleChange}
              error={submitCount > 0 && Boolean(errors.patient?.firstName)}
              helperText={submitCount > 0 ? errors.patient?.firstName : null}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LastNameTextField
              id="patient.lastName"
              value={patient.lastName}
              onChange={handleChange}
              error={submitCount > 0 && Boolean(errors.patient?.lastName)}
              helperText={submitCount > 0 ? errors.patient?.lastName : null}
            />
          </Grid>
          <Grid item xs={12}>
            <EmailTextField
              id="patient.email"
              disabled={!patient.isNewPatient}
              value={patient.email}
              onChange={handleChange}
              error={submitCount > 0 && Boolean(errors.patient?.email)}
              helperText={submitCount > 0 ? errors.patient?.email : null}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SexIdSelect
              id="patient.sexId"
              value={patient.sexId}
              onChange={(event) =>
                setFieldValue("patient.sexId", event.target.value)
              }
              error={submitCount > 0 && Boolean(errors.patient?.sexId)}
              helperText={submitCount > 0 ? errors.patient?.sexId : null}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BirthdayDatePicker
              id="patient.birthday"
              value={patient.birthday}
              onChange={(value) => setFieldValue("patient.birthday", value)}
              error={submitCount > 0 && Boolean(errors.patient?.birthday)}
              helperText={submitCount > 0 ? errors.patient?.birthday : null}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SpokenLanguageIdsSelect
              id="patient.spokenLanguageIds"
              values={patient.spokenLanguageIds ?? []}
              onChange={(event) =>
                setFieldValue("patient.spokenLanguageIds", event.target.value)
              }
              error={
                submitCount > 0 && Boolean(errors.patient?.spokenLanguageIds)
              }
              helperText={
                submitCount > 0 ? errors.patient?.spokenLanguageIds : null
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PhoneNumberTextField
              id="patient.phoneNumberInE164Format"
              value={patient.phoneNumberInE164Format}
              onChange={(value) =>
                setFieldValue("patient.phoneNumberInE164Format", value)
              }
              error={
                submitCount > 0 &&
                Boolean(errors.patient?.phoneNumberInE164Format)
              }
              helperText={
                submitCount > 0 ? errors.patient?.phoneNumberInE164Format : null
              }
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <PatientsAutocomplete
            fullWidth
            disabled={searched}
            value={patient}
            onChange={handlePatientAutocompleteChange}
            renderInput={(params) => (
              <PatientSearchBar
                placeholder={
                  upSm
                    ? t(
                        "molecules.appointmentPatientInformation.searchBarPlaceholder"
                      )
                    : t(
                        "molecules.appointmentPatientInformation.shortSearchBarPlaceholder"
                      )
                }
                error={submitCount > 0 && Boolean(errors.searched)}
                helperText={submitCount > 0 ? errors.searched : null}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // Workaround to avoid autofill in Chrome: https://mui.com/components/autocomplete/#autocomplete-autofill
                }}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

AppointmentPatientInformation.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      searched: PropTypes.bool,
      patient: PropTypes.shape({
        isNewPatient: PropTypes.bool,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        sexId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        birthday: PropTypes.instanceOf(Date),
        ramqHealthInsuranceNumber: PropTypes.string,
        ramqHealthInsuranceExpiration: PropTypes.string,
        spokenLanguageIds: PropTypes.arrayOf(PropTypes.number),
        phoneNumberInE164Format: PropTypes.string,
      }).isRequired,
    }).isRequired,
    errors: PropTypes.object.isRequired,
    submitCount: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
};
export default AppointmentPatientInformation;
