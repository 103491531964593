import PropTypes from "prop-types";

import { Box } from "@mui/material";

import { GlobalModalContextProvider } from "@vesta/components/organisms";
import { useUpMd } from "@vesta/lib/react";

import { Header } from "./components";

const MainLayout = ({
  children,
  renderBottomNavigation,
  sidebar,
  onOpenSidebar,
  secondarySidebar,
  onOpenSecondarySidebar,
}) => {
  const upMd = useUpMd();

  return (
    <Box sx={{ display: "flex" }}>
      <GlobalModalContextProvider>
        <Header
          onSidebarTriggerClick={onOpenSidebar}
          onSecondarySidebarTriggerClick={onOpenSecondarySidebar}
        />
        {sidebar}
        {!upMd && secondarySidebar}
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
          })}
        >
          <Box
            sx={(theme) => ({
              ...theme.mixins.toolbar, // Moving the content below the sidebar
            })}
          />
          {children}
          {!upMd && renderBottomNavigation && (
            <>
              <Box sx={(theme) => ({ height: theme.spacing(7) })} />
              {renderBottomNavigation({
                sx: (theme) => ({
                  position: "fixed",
                  width: "100%",
                  bottom: 0,
                  borderTopColor: theme.palette.divider,
                  borderTopStyle: "solid",
                  zIndex: 12,
                }),
              })}
            </>
          )}
        </Box>
      </GlobalModalContextProvider>
    </Box>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  renderBottomNavigation: PropTypes.func,
  sidebar: PropTypes.node.isRequired,
  onOpenSidebar: PropTypes.func.isRequired,
  secondarySidebar: PropTypes.node.isRequired,
  onOpenSecondarySidebar: PropTypes.func.isRequired,
};

export default MainLayout;
