import PropTypes from "prop-types";

import { PolygonMap } from "@vesta/components/molecules";

const EditablePolygonMap = ({ id, points, onEdit, onIntersect = () => {} }) => {
  const handleAddPolygonToMap = (polygon) => {
    polygon.pm.enable({
      allowSelfIntersection: false,
    });

    polygon.on("pm:intersect", onIntersect);
    polygon.on("pm:edit", (event) => {
      const polygon = event.target;
      if (polygon.pm.hasSelfIntersection()) {
        onEdit([...points]);
        return;
      }

      const latLngs = polygon.getLatLngs()[0];
      if (latLngs.length === 0) {
        onEdit([...points]);
        return;
      }

      onEdit([...latLngs, latLngs[0]].map(({ lat, lng }) => [lng, lat]));
    });
  };

  return (
    <PolygonMap
      id={id}
      points={points}
      scrollWheelZoom
      onAddPolygonToMap={handleAddPolygonToMap}
    />
  );
};

EditablePolygonMap.propTypes = {
  id: PropTypes.string.isRequired,
  points: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onIntersect: PropTypes.func,
};

export default EditablePolygonMap;
