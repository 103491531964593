import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ArrowDropDown } from "@mui/icons-material";

import { useSexTranslation } from "@vesta/lib/react";
import { sexIds } from "@vesta/lib/utils";

const StyledSpan = styled("span")(({ theme }) => ({
  marginRight: theme.spacing(1 / 2),
}));

const SexIdSelect = ({
  id,
  className,
  readOnly,
  value,
  onChange,
  error,
  helperText,
  ...rest
}) => {
  const sexTranslation = useSexTranslation();
  const { t } = useTranslation("components");

  return (
    <FormControl
      variant="standard"
      className={className}
      fullWidth
      error={error}
      {...rest}
    >
      <InputLabel id="sexIdLabel" sx={{ display: "flex" }} shrink>
        <StyledSpan>{t("molecules.sexIdSelect.label")}</StyledSpan>
      </InputLabel>
      <Select
        variant="standard"
        id={id}
        labelId="sexIdLabel"
        autoComplete="sex"
        value={value}
        onChange={onChange}
        IconComponent={(props) =>
          readOnly ? null : <ArrowDropDown {...props} />
        }
        inputProps={{
          readOnly,
        }}
      >
        {sexIds.map((id) => (
          <MenuItem key={id} value={id}>
            {sexTranslation[id]}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

SexIdSelect.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default SexIdSelect;
