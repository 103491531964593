import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { TextField } from "@mui/material";

const ServiceNameTextField = ({
  className,
  readOnly,
  lang,
  InputProps,
  InputLabelProps,
  ...rest
}) => {
  const { t } = useTranslation("components");

  return (
    <TextField
      variant="standard"
      className={className}
      disabled={readOnly}
      fullWidth
      label={
        lang
          ? t(`molecules.serviceNameTextField.${lang}.label`)
          : t("molecules.serviceNameTextField.label")
      }
      InputProps={{
        ...InputProps,
        readOnly,
      }}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
      }}
      {...rest}
    />
  );
};

ServiceNameTextField.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  lang: PropTypes.oneOf(["en", "fr"]),
  InputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
};

export default ServiceNameTextField;
