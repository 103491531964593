import { debounce } from "lodash";
import { useCallback, useEffect } from "react";

import { useAuthorizedFetchJson, useSession } from "@vesta/lib/react";

export const useGetSearchablePatientsBySearchTerm = (term) => {
  const [, jwtToken] = useSession();
  const [fetchJson, json, loading] = useAuthorizedFetchJson(jwtToken);

  const getSearchablePatientsBySearchTerm = useCallback(
    debounce(
      (term) =>
        fetchJson(
          new URL(
            `/queries/get-searchable-patients-by-search-term/${term}`,
            process.env.REACT_APP_API_BASE_URL
          )
        ),
      200
    ),
    []
  );

  useEffect(() => {
    if (term) {
      getSearchablePatientsBySearchTerm(term);
    }
  }, [term, getSearchablePatientsBySearchTerm]);

  return [json, loading];
};
