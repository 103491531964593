import { useTranslation } from "react-i18next";

import { Alert, AlertTitle } from "@mui/material";

import { useMedicalRecord } from "@vesta/lib/react";

const DemoPatientAlert = () => {
  const { t } = useTranslation("patient");
  const [medicalRecord] = useMedicalRecord();

  return (
    <Alert
      sx={(theme) => ({ backgroundColor: theme.palette.background.paper })}
      component="aside"
      severity="warning"
      variant="outlined"
    >
      <AlertTitle>
        {t("demoPatientAlert.title", {
          fullName: medicalRecord.personalInformation.fullName,
        })}
      </AlertTitle>
      {t("demoPatientAlert.text")}
    </Alert>
  );
};

export default DemoPatientAlert;
