import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";

import { EmptyDirectionsIllustration } from "./components";

const EmptyDirections = ({ sx }) => {
  const { t } = useTranslation("appointment");

  return (
    <Box
      sx={[
        (theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: theme.palette.background.paper,
          borderStyle: "dashed",
          borderWidth: 1,
          borderColor: theme.palette.text.secondary,
          padding: theme.spacing(2),
          [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(3),
          },
          [theme.breakpoints.up("lg")]: {
            padding: theme.spacing(6),
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <EmptyDirectionsIllustration
        sx={(theme) => ({
          width: 200,
          [theme.breakpoints.up("sm")]: {
            width: 240,
          },
          [theme.breakpoints.up("md")]: {
            width: 280,
          },
          [theme.breakpoints.up("lg")]: {
            width: 320,
          },
        })}
      />
      <Typography
        sx={(theme) => ({
          marginTop: theme.spacing(2),
          [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(3),
          },
          [theme.breakpoints.up("lg")]: {
            marginTop: theme.spacing(4),
          },
        })}
        gutterBottom
        align="center"
        component="h2"
        variant="h3"
      >
        {t("directions.emptyDirections.title")}
      </Typography>
      <Typography
        sx={(theme) => ({ maxWidth: theme.spacing(80) })}
        align="center"
      >
        {t("directions.emptyDirections.text")}
      </Typography>
    </Box>
  );
};

EmptyDirections.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
};

export default EmptyDirections;
