import PropTypes from "prop-types";
import { Trans } from "react-i18next";

import { Alert, Link } from "@mui/material";

import { useSession } from "@vesta/lib/react";

const GoToProfileAlert = ({ sx }) => {
  const [session] = useSession();

  return (
    <Alert
      sx={[
        (theme) => ({ backgroundColor: theme.palette.background.paper }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      component="aside"
      severity="info"
      variant="outlined"
    >
      <Trans
        i18nKey="profile:goToProfileAlert.text"
        components={{
          divider: (
            <>
              <br />
              <br />
            </>
          ),
          goToLink: (
            <Link
              color="primary"
              underline="always"
              href={new URL(
                `/profile/${session.nameid}`,
                process.env.REACT_APP_WEB_BASE_URL
              ).toString()}
              target="_blank"
            />
          ),
        }}
      />
    </Alert>
  );
};

GoToProfileAlert.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
};

export default GoToProfileAlert;
