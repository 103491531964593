import { fr } from "date-fns/locale";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Grid, Paper, Typography } from "@mui/material";

import { agreementStatus } from "@vesta/lib/enum";

const AgreementPaper = ({ sx, title, agreement, children }) => {
  const { t } = useTranslation("components");

  const { statusId, acceptedOn } = agreement;

  return (
    <Paper
      sx={[
        (theme) => ({
          padding: theme.spacing(2),
          [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(3),
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      elevation={4}
    >
      <Grid container direction="column" spacing={{ xs: 2, sm: 3 }}>
        <Grid item>
          <Typography component="h2" variant="h3">
            {title}
          </Typography>
        </Grid>
        <Grid item>{children}</Grid>
        {statusId === agreementStatus.accepted && acceptedOn && (
          <Grid item>
            <Typography
              sx={{ fontStyle: "italic" }}
              color="textSecondary"
              variant="caption"
            >
              {t("molecules.agreementPaper.acceptedOn", {
                date: format(acceptedOn, "PPP", { locale: fr }),
                time: format(acceptedOn, "p", { locale: fr }),
              })}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

AgreementPaper.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  title: PropTypes.string,
  agreement: PropTypes.shape({
    statusId: PropTypes.number.isRequired,
    acceptedOn: PropTypes.instanceOf(Date),
  }).isRequired,
  children: PropTypes.node,
};

export default AgreementPaper;
