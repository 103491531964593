import { Link } from "@mui/material";

const ContactUsLink = ({ ...rest }) => (
  <Link
    sx={{ verticalAlign: "top" }}
    color="inherit"
    component="button"
    underline="always"
    variant="body2"
    onClick={() => {
      window.tidioChatApi.display(true);
      window.tidioChatApi.open();
    }}
    {...rest}
  />
);

export default ContactUsLink;
