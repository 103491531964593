import PropTypes from "prop-types";

import { AppBar, Box, Toolbar } from "@mui/material";

import { BackButton, Logo, LogoVariant } from "@vesta/components/atoms";

const MinimalNavigationHeader = ({ backTo, backLabel }) => (
  <AppBar
    sx={(theme) => ({ backgroundColor: theme.palette.background.paper })}
    elevation={1}
    position="static"
  >
    <Toolbar>
      {backTo ? (
        <BackButton to={backTo} label={backLabel} />
      ) : (
        <Logo
          sx={(theme) => ({ height: theme.spacing(5.5) })}
          variant={LogoVariant.WideRight}
        />
      )}
      <Box sx={{ flexGrow: 1 }} />
    </Toolbar>
  </AppBar>
);

MinimalNavigationHeader.propTypes = {
  backTo: PropTypes.string,
  backLabel: PropTypes.string,
};

export default MinimalNavigationHeader;
