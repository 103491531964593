import { useEffect, useState } from "react";

const isPageVisible = () => document.visibilityState === "visible";

/**
 * Documentation: https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event
 */
export const usePageVisibility = () => {
  const [state, setState] = useState(isPageVisible());

  useEffect(() => {
    const handleVisibilityChange = () => setState(isPageVisible());

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return state;
};
