import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Box, IconButton, Link, Stack, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";

import { DelayedCircularProgress } from "@vesta/components/atoms";
import { useUpSm } from "@vesta/lib/react";

const dimensionsSx = (theme) => ({
  width: "100%",
  height: theme.spacing(12),
  [theme.breakpoints.up("sm")]: {
    height: theme.spacing(16),
  },
  [theme.breakpoints.up("md")]: {
    height: theme.spacing(20),
  },
});

const File = ({
  src,
  name,
  uploading,
  uploadingProgress,
  canDelete,
  onDelete,
  renderImage,
}) => {
  const upSm = useUpSm();
  const { t } = useTranslation("components");

  const informationBar = (
    <Stack
      sx={(theme) => ({ marginTop: theme.spacing(1 / 2) })}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
    >
      <Typography noWrap={upSm} variant="caption">
        {name}
      </Typography>
      {canDelete && (
        <IconButton
          aria-label={t("molecules.file.deleteLabel")}
          disabled={uploading}
          onClick={(e) => {
            e.preventDefault();
            onDelete();
          }}
          edge="end"
          size="small"
        >
          <Delete />
        </IconButton>
      )}
    </Stack>
  );

  return (
    <Link href={uploading ? null : src} target="_blank">
      <Box
        sx={[
          (theme) => ({ display: "flex", marginBottom: theme.spacing(1) }),
          dimensionsSx,
        ]}
      >
        {uploading ? (
          <DelayedCircularProgress
            sx={{ margin: "auto" }}
            value={uploadingProgress}
            variant={uploadingProgress >= 0 ? "determinate" : "indeterminate"}
          />
        ) : (
          renderImage({ dimensionsSx })
        )}
      </Box>
      {informationBar}
    </Link>
  );
};

File.propTypes = {
  src: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  uploading: PropTypes.bool,
  uploadingProgress: PropTypes.number,
  canDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  renderImage: PropTypes.func.isRequired,
};

export default File;
