import { useCallback } from "react";

import { useMedicalRecord } from "@vesta/lib/react";

export const useAddResultToPatientMedicalRecord = (jwtToken) => {
  const [medicalRecord] = useMedicalRecord();

  const { patientId, someoneElseId } = medicalRecord;

  return useCallback(
    (files) => {
      const url = new URL(
        "commands/add-result-to-patient-medical-record",
        process.env.REACT_APP_API_BASE_URL
      );

      return fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          patientId,
          someoneElseId,
          files,
        }),
      });
    },
    [patientId, someoneElseId, jwtToken]
  );
};
