import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { TextField } from "@mui/material";

const ServiceDescriptionTextField = ({
  className,
  readOnly,
  lang,
  ...rest
}) => {
  const { t } = useTranslation("components");

  return (
    <TextField
      variant="standard"
      className={className}
      disabled={readOnly}
      fullWidth
      multiline
      label={t(`molecules.serviceDescriptionTextField.${lang}.label`)}
      InputProps={{
        readOnly,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      {...rest}
    />
  );
};

ServiceDescriptionTextField.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  lang: PropTypes.oneOf(["en", "fr"]).isRequired,
};

export default ServiceDescriptionTextField;
