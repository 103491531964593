import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";
import { Add } from "@mui/icons-material";

import { LoadingFloatingButton } from "@vesta/components/atoms";

const StyledSpan = styled("span")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const AddNoteFloatingButton = ({ sx, saving, onClick }) => {
  const { t } = useTranslation("patient");

  return (
    <LoadingFloatingButton
      sx={sx}
      loading={saving}
      label={
        <>
          <Add />
          <StyledSpan>{t("notes.addNoteFloatingButton.addLabel")}</StyledSpan>
        </>
      }
      onClick={onClick}
    />
  );
};

AddNoteFloatingButton.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  saving: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default AddNoteFloatingButton;
