import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import {
  AddressTextField,
  ApartmentUnitSuiteOrFloorNbTextField,
  BirthdayDatePicker,
  EmailTextField,
  FormattedFullNameTextField,
  PhoneNumberTextField,
  RamqHealthInsurance,
  RamqHealthInsuranceExpirationTextField,
  RamqHealthInsuranceNumberTextField,
  SexIdSelect,
  SpokenLanguageIdsSelect,
  TitledPaper,
} from "@vesta/components/molecules";
import { useAppointment } from "@vesta/lib/react";

const PersonalInformationForm = () => {
  const { t } = useTranslation("appointment");
  const [appointment] = useAppointment();

  const firstName = appointment?.isAppointmentForSomeoneElse
    ? appointment?.someoneElse.firstName
    : appointment?.patientFirstName;

  const lastName = appointment?.isAppointmentForSomeoneElse
    ? appointment?.someoneElse.lastName
    : appointment?.patientLastName;

  const birthday = appointment?.isAppointmentForSomeoneElse
    ? appointment?.someoneElse.birthday
    : appointment?.patientBirthday;

  const sexId = appointment?.isAppointmentForSomeoneElse
    ? appointment?.someoneElse.sexId
    : appointment?.patientSexId;

  const spokenLanguageIds = appointment?.isAppointmentForSomeoneElse
    ? appointment?.someoneElse.spokenLanguageIds
    : appointment?.patientSpokenLanguageIds;

  const ramqHealthInsuranceNumber = appointment?.isAppointmentForSomeoneElse
    ? appointment?.someoneElse.ramqHealthInsuranceNumber ?? ""
    : appointment?.patientRamqHealthInsuranceNumber ?? "";

  const ramqHealthInsuranceExpiration = appointment?.isAppointmentForSomeoneElse
    ? appointment?.someoneElse.ramqHealthInsuranceExpiration
      ? format(
          appointment?.someoneElse.ramqHealthInsuranceExpiration,
          "yyyy MM"
        )
      : ""
    : appointment?.patientRamqHealthInsuranceExpiration
    ? format(appointment?.patientRamqHealthInsuranceExpiration, "yyyy MM")
    : "";

  return (
    <TitledPaper
      title={t("preparation.personalInformationForm.title")}
      loading={!appointment}
    >
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>
          <RamqHealthInsurance
            number={
              <RamqHealthInsuranceNumberTextField
                value={ramqHealthInsuranceNumber}
                readOnly
              />
            }
            expiration={
              <RamqHealthInsuranceExpirationTextField
                value={ramqHealthInsuranceExpiration}
                readOnly
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <FormattedFullNameTextField
            firstName={firstName}
            lastName={lastName}
            readOnly
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <BirthdayDatePicker value={birthday} readOnly />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <SexIdSelect value={sexId} readOnly />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <SpokenLanguageIdsSelect values={spokenLanguageIds} readOnly />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <EmailTextField value={appointment?.patientEmail} readOnly />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <PhoneNumberTextField
            value={appointment?.patientPhoneNumberInE164Format}
            isApproved={appointment?.isPatientPhoneNumberApproved}
            readOnly
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <AddressTextField
            value={appointment?.patientFormattedAddress}
            readOnly
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <ApartmentUnitSuiteOrFloorNbTextField
            value={appointment?.patientApartmentUnitSuiteOrFloorNb ?? ""}
            readOnly
          />
        </Grid>
      </Grid>
    </TitledPaper>
  );
};

export default PersonalInformationForm;
