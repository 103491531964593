export const recommendedPlan = "development";

export default Object.freeze(
  process.env.NODE_ENV === "production"
    ? [
        {
          id: "price_1LpCgKGin1Vc002Gx13oKeUc",
          name: "starter",
          cycle: "monthly",
          amount: 19.0,
          taxes: 2.94,
          total: 21.94,
        },
        {
          id: "price_1LpCgKGin1Vc002G3HNjK5Q3",
          name: "starter",
          cycle: "annually",
          amount: 228.0,
          taxes: 35.28,
          total: 263.28,
          annualDiscount: null,
        },
        {
          id: "price_1LpCkZGin1Vc002GoIRMiNWY",
          name: recommendedPlan,
          cycle: "monthly",
          amount: 49.0,
          taxes: 7.58,
          total: 56.58,
        },
        {
          id: "price_1LpCkZGin1Vc002GMi2jL9Hz",
          name: recommendedPlan,
          cycle: "annually",
          amount: 530.0,
          taxes: 82.01,
          total: 612.01,
          annualDiscount: "10",
        },
        {
          id: "price_1LpCnmGin1Vc002GQVEsmEGA",
          name: "growth",
          cycle: "monthly",
          amount: 99.0,
          taxes: 15.32,
          total: 114.32,
        },
        {
          id: "price_1LpCnmGin1Vc002G8vf2RuEt",
          name: "growth",
          cycle: "annually",
          amount: 1010.0,
          taxes: 156.25,
          total: 1166.05,
          annualDiscount: "15",
        },
      ]
    : [
        {
          id: "price_1LkAgRGin1Vc002GKZ6OLB5i",
          name: "starter",
          cycle: "monthly",
          amount: 19.0,
          taxes: 2.94,
          total: 21.94,
        },
        {
          id: "price_1LkAgRGin1Vc002G4DcEea81",
          name: "starter",
          cycle: "annually",
          amount: 228.0,
          taxes: 35.28,
          total: 263.28,
          annualDiscount: null,
        },
        {
          id: "price_1LkAcfGin1Vc002G9ngskxzp",
          name: recommendedPlan,
          cycle: "monthly",
          amount: 49.0,
          taxes: 7.58,
          total: 56.58,
        },
        {
          id: "price_1LkAcfGin1Vc002G2Lqz9Xmr",
          name: recommendedPlan,
          cycle: "annually",
          amount: 530.0,
          taxes: 82.01,
          total: 612.01,
          annualDiscount: "10",
        },
        {
          id: "price_1LkAYTGin1Vc002GwhmDxllR",
          name: "growth",
          cycle: "monthly",
          amount: 99.0,
          taxes: 15.32,
          total: 114.32,
        },
        {
          id: "price_1LkAYTGin1Vc002GN4HxtduM",
          name: "growth",
          cycle: "annually",
          amount: 1010.0,
          taxes: 156.25,
          total: 1166.05,
          annualDiscount: "15",
        },
      ]
);
