import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { Button, Grid, Typography } from "@mui/material";

import {
  ContactUsLink,
  ErrorAlert,
  LoadingButton,
} from "@vesta/components/atoms";
import { CheckNewServiceCard } from "@vesta/components/molecules";
import {
  useAppContext,
  useAuthorizedFetchJson,
  useOnboardingSessionV1,
  useScrollToTopOnMount,
  useSession,
  useUpLg,
} from "@vesta/lib/react";

const useServiceTemplates = (jwtToken) => {
  const [fetchJson, json, loading] = useAuthorizedFetchJson(jwtToken);

  useEffect(() => {
    fetchJson(
      new URL(
        "/queries/get-service-templates",
        process.env.REACT_APP_API_BASE_URL
      )
    );
  }, []);

  return [json?.filter((x) => !x.isDeleted) ?? [], loading];
};

const Step3 = ({ onPrevious, onSkip, onNext }) => {
  const upLg = useUpLg();
  const { t } = useTranslation("onboarding-v1");
  const [, jwtToken] = useSession();
  const [serviceTemplates, loading] = useServiceTemplates(jwtToken);
  const [, dispatchAppContext] = useAppContext();
  const [onboardingSession, dispatch] = useOnboardingSessionV1();
  const [submitting, setSubmitting] = useState(false);
  const [saveServicesError, setSaveServicesError] = useState(false);

  useScrollToTopOnMount();

  const servicesNotDeleted = onboardingSession.services.filter(
    (x) => !x.isDeleted
  );

  const [selectedServiceTemplateIds, setSelectedServiceTemplateIds] = useState([
    ...new Set(servicesNotDeleted.map((x) => x.serviceTemplateId)), // a set because we support having many services for one service template
  ]);

  const loadingState = (
    <>
      <Grid item xs={12}>
        <CheckNewServiceCard loading />
      </Grid>
      <Grid item xs={12}>
        <CheckNewServiceCard loading />
      </Grid>
    </>
  );

  const handleChange = (serviceTemplateId) => (event) => {
    if (event.target.checked) {
      setSelectedServiceTemplateIds((prevValues) => [
        ...prevValues,
        serviceTemplateId,
      ]);
      return;
    }

    setSelectedServiceTemplateIds((prevValues) =>
      prevValues.filter((x) => x !== serviceTemplateId)
    );
  };

  const handleNextClick = async () => {
    setSubmitting(true);

    const url = new URL(
      "commands/bulk-create-services-from-service-templates-and-delete-services",
      process.env.REACT_APP_API_BASE_URL
    );

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        serviceTemplateIdOfServicesToCreate:
          getServiceTemplateIdOfServicesToCreate(),
        serviceIdOfServicesToDelete: getServiceIdOfServicesToDelete(),
      }),
    });

    if (response.ok) {
      const { createdServices, deletedServices } = await response.json();
      dispatchAppContext({
        type: "bulk-create-services-from-service-templates-and-delete-services",
        payload: {
          createdServices,
          deletedServices,
        },
      });
      dispatch({
        type: "bulk-create-services-from-service-templates-and-delete-services",
        payload: {
          createdServices,
          deletedServices,
        },
      });
      await onNext();
    } else {
      setSubmitting(false);
      setSaveServicesError(true);
    }
  };

  const getServiceTemplateIdOfServicesToCreate = () => {
    const serviceTemplateIdOfServicesNotDeleted = servicesNotDeleted.map(
      (x) => x.serviceTemplateId
    );

    const serviceTemplateIdOfServicesToCreate =
      selectedServiceTemplateIds.filter(
        (x) => !serviceTemplateIdOfServicesNotDeleted.some((y) => y === x)
      );

    return serviceTemplateIdOfServicesToCreate;
  };

  const getServiceIdOfServicesToDelete = () => {
    const serviceTemplatesNotSelected = serviceTemplates.filter(
      (x) => !selectedServiceTemplateIds.some((y) => y === x.serviceTemplateId)
    );

    const serviceIdOfServicesToDelete = servicesNotDeleted
      .filter((x) =>
        serviceTemplatesNotSelected.some(
          (y) => y.serviceTemplateId === x.serviceTemplateId
        )
      )
      .map((x) => x.id);

    return serviceIdOfServicesToDelete;
  };

  const canSkip = servicesNotDeleted.length === 0;

  return (
    <>
      <Grid container direction="column" spacing={4} data-aos="fade-up">
        <Grid item>
          <Typography gutterBottom variant="h1">
            {t("onboardingSteps.step3.title")}
          </Typography>
          <Typography>
            <Trans
              i18nKey="onboarding-v1:onboardingSteps.step3.subtitle"
              components={{
                contactUsLink: (
                  <ContactUsLink color="primary" variant="body1" />
                ),
              }}
            />
          </Typography>
        </Grid>
        <Grid container spacing={2} item>
          {loading
            ? loadingState
            : serviceTemplates
                .sort((a, b) => a.nameFr.localeCompare(b.nameFr, "fr"))
                .map((serviceTemplate) => (
                  <Grid key={serviceTemplate.serviceTemplateId} item xs={12}>
                    <CheckNewServiceCard
                      serviceTemplate={serviceTemplate}
                      checked={selectedServiceTemplateIds.some(
                        (x) => x === serviceTemplate.serviceTemplateId
                      )}
                      onChange={handleChange(serviceTemplate.serviceTemplateId)}
                    />
                  </Grid>
                ))}
        </Grid>
        <Grid container justifyContent="space-between" item>
          <Grid item>
            <Button onClick={onPrevious} variant="outlined">
              {t("onboardingSteps.step3.actions.previous")}
            </Button>
          </Grid>
          <Grid item>
            {upLg && canSkip && (
              <Button
                sx={(theme) => ({ marginRight: theme.spacing(2) })}
                onClick={onSkip}
              >
                {t("onboardingSteps.step3.actions.skip")}
              </Button>
            )}
            <LoadingButton
              disabled={submitting}
              loading={submitting}
              onClick={handleNextClick}
              variant="contained"
            >
              {t("onboardingSteps.step3.actions.next")}
            </LoadingButton>
          </Grid>
        </Grid>
        {!upLg && canSkip && (
          <Grid container justifyContent="flex-end" item>
            <Grid item>
              <Button onClick={onSkip}>
                {t("onboardingSteps.step3.actions.skip")}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      <ErrorAlert
        open={saveServicesError}
        onClose={() => setSaveServicesError(false)}
        content={
          <Trans
            i18nKey="onboarding-v1:onboardingSteps.step3.alerts.saveServicesError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
    </>
  );
};

Step3.propTypes = {
  onPrevious: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default Step3;
