import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

export const useSearchPhotos = (open, query) => {
  const [photos, setPhotos] = useState([]);
  const [searchingPhotos, setSearchingPhotos] = useState(false);

  const searchPhotos = useCallback(async (query) => {
    const url = new URL(
      `unsplash/search-photos/${query}?` +
        new URLSearchParams({
          page: 1,
          perPage: 24,
          language: "fr",
          contentFilter: "high",
          orientation: "landscape",
        }),
      process.env.REACT_APP_API_BASE_URL
    );

    const response = await fetch(url);
    if (response.ok) {
      const { results: photos } = await response.json();
      return photos;
    }

    return [];
  }, []);

  const getRecommendedCoverPictures = useCallback(async () => {
    const url = new URL(
      `unsplash/get-collection-photos/${process.env.REACT_APP_RECOMMENDED_COVER_PICTURES_UNSPLASH_COLLECTION_ID}?` +
        new URLSearchParams({
          page: 1,
          perPage: 24,
          orientation: "landscape",
        }),
      process.env.REACT_APP_API_BASE_URL
    );

    const response = await fetch(url);
    if (response.ok) {
      const photos = await response.json();
      return photos;
    }

    return [];
  }, []);

  const debounceCallback = useCallback(
    debounce(async (query) => {
      setSearchingPhotos(true);

      const photos = query
        ? await searchPhotos(query)
        : await getRecommendedCoverPictures();

      setPhotos(photos);
      setSearchingPhotos(false);
    }, 200),
    [searchPhotos, getRecommendedCoverPictures]
  );

  useEffect(() => {
    if (open) {
      debounceCallback(query);
    }
  }, [open, query, debounceCallback]);

  const trackPhotoDownload = useCallback(async (photoId) => {
    const url = new URL(
      `unsplash/track-photo-download/${photoId}`,
      process.env.REACT_APP_API_BASE_URL
    );

    await fetch(url);
  }, []);

  return [photos, searchingPhotos, trackPhotoDownload];
};
