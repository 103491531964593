import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { Alert, AlertTitle } from "@mui/material";
import { styled } from "@mui/material/styles";
import { EmojiObjectsOutlined } from "@mui/icons-material";

import {
  onboardingV1ViewStep1,
  onboardingV1ViewStep7,
} from "@vesta/assets/images";
import { ContactUsLink, ErrorAlert } from "@vesta/components/atoms";
import { BodyWithTips } from "@vesta/components/organisms";
import {
  useAppContext,
  useOnboardingSessionV1,
  useSession,
} from "@vesta/lib/react";

import {
  ProfilePictureTipAlert,
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
} from "./components";

const StyledImage = styled("img")({
  width: "100%",
  objectFit: "cover",
});

const OnboardingSteps = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("onboarding-v1");
  const { step } = useParams();
  const [, jwtToken] = useSession();
  const [, dispatchAppContext] = useAppContext();
  const [onboardingSession, dispatch] = useOnboardingSessionV1();
  const [goToPreviousOnboardingStepError, setGoToPreviousOnboardingStepError] =
    useState(false);
  const [goToNextOnboardingStepError, setGoToNextOnboardingStepError] =
    useState(false);
  const [finishOnboardingSessionError, setFinishOnboardingSessionError] =
    useState(false);

  const { currentStep } = onboardingSession;

  useEffect(() => {
    if (parseInt(step) !== currentStep + 1) {
      navigate(`/onboarding/v1/${currentStep + 1}`, {
        replace: true,
      });
    }
  }, []);

  const handlePrevious = (step) => async () => {
    const url = new URL(
      `/commands/go-to-previous-onboarding-step/${onboardingSession.onboardingSessionId}`,
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    if (ok) {
      dispatch({
        type: "go-to-previous-onboarding-step",
      });
      navigate(`/onboarding/v1/${step - 1}`);
    } else {
      setGoToPreviousOnboardingStepError(true);
    }
  };

  const handleNext = (step) => async () => {
    const url = new URL(
      `/commands/go-to-next-onboarding-step/${onboardingSession.onboardingSessionId}`,
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    if (ok) {
      dispatch({
        type: "go-to-next-onboarding-step",
      });
      navigate(`/onboarding/v1/${step + 1}`);
    } else {
      setGoToNextOnboardingStepError(true);
    }
  };

  const handleFinish = async () => {
    const url = new URL(
      `/commands/finish-onboarding-session/${onboardingSession.onboardingSessionId}`,
      process.env.REACT_APP_API_BASE_URL
    );

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    if (response.ok) {
      const { reminders } = await response.json();
      dispatchAppContext({
        type: "finish-onboarding-session",
        payload: {
          onboardingSessionId: onboardingSession.onboardingSessionId,
          reminders,
        },
      });
      dispatch({
        type: "finish-onboarding-session",
      });
    } else {
      setFinishOnboardingSessionError(true);
    }
  };

  const step1 = (
    <BodyWithTips
      progressValue={onboardingSession.progress}
      image={
        <StyledImage
          src={onboardingV1ViewStep1}
          alt={t("onboardingSteps.step1.imageAlt")}
        />
      }
    >
      <Step1
        sx={(theme) => ({
          [theme.breakpoints.up("md")]: {
            marginTop: theme.spacing(10),
          },
        })}
        onNext={handleNext(1)}
      />
    </BodyWithTips>
  );

  const step2 = (
    <BodyWithTips
      progressValue={onboardingSession.progress}
      tips={[
        <Alert
          key="tip"
          component="aside"
          icon={<EmojiObjectsOutlined fontSize="inherit" />}
          severity="info"
        >
          <AlertTitle>{t("onboardingSteps.step2.tip.title")}</AlertTitle>
          {t("onboardingSteps.step2.tip.text")}
        </Alert>,
      ]}
    >
      <Step2 onPrevious={handlePrevious(2)} onNext={handleNext(2)} />
    </BodyWithTips>
  );

  const step3 = (
    <BodyWithTips
      progressValue={onboardingSession.progress}
      tips={[
        <Alert
          key="tip"
          component="aside"
          icon={<EmojiObjectsOutlined fontSize="inherit" />}
          severity="info"
        >
          <AlertTitle>{t("onboardingSteps.step3.tip.title")}</AlertTitle>
          {t("onboardingSteps.step3.tip.text")}
        </Alert>,
      ]}
    >
      <Step3
        onPrevious={handlePrevious(3)}
        onSkip={handleNext(3)}
        onNext={handleNext(3)}
      />
    </BodyWithTips>
  );

  const step4 = (
    <BodyWithTips
      progressValue={onboardingSession.progress}
      tips={[
        <Alert
          key="tip"
          component="aside"
          icon={<EmojiObjectsOutlined fontSize="inherit" />}
          severity="info"
        >
          <AlertTitle>{t("onboardingSteps.step4.tip.title")}</AlertTitle>
          {t("onboardingSteps.step4.tip.text")}
        </Alert>,
      ]}
    >
      <Step4
        onPrevious={handlePrevious(4)}
        onSkip={handleNext(4)}
        onNext={handleNext(4)}
      />
    </BodyWithTips>
  );

  const step5 = (
    <BodyWithTips
      progressValue={onboardingSession.progress}
      tips={[<ProfilePictureTipAlert key="tip" />]}
    >
      <Step5
        onPrevious={handlePrevious(5)}
        onSkip={handleNext(5)}
        onNext={handleNext(5)}
      />
    </BodyWithTips>
  );

  const step6 = (
    <BodyWithTips progressValue={onboardingSession.progress}>
      <Step6
        onPrevious={handlePrevious(6)}
        onSkip={handleNext(6)}
        onNext={handleNext(6)}
      />
    </BodyWithTips>
  );

  const step7 = (
    <BodyWithTips
      progressValue={onboardingSession.progress}
      image={
        <StyledImage
          src={onboardingV1ViewStep7}
          alt={t("onboardingSteps.step7.imageAlt")}
        />
      }
    >
      <Step7 onPrevious={handlePrevious(7)} onFinish={handleFinish} />
    </BodyWithTips>
  );

  return (
    <>
      {currentStep === 0
        ? step1
        : currentStep === 1
        ? step2
        : currentStep === 2
        ? step3
        : currentStep === 3
        ? step4
        : currentStep === 4
        ? step5
        : currentStep === 5
        ? step6
        : currentStep === 6
        ? step7
        : null}
      <ErrorAlert
        open={goToPreviousOnboardingStepError}
        onClose={() => setGoToPreviousOnboardingStepError(false)}
        content={
          <Trans
            i18nKey="onboarding-v1:onboardingSteps.alerts.goToPreviousOnboardingStepError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
      <ErrorAlert
        open={goToNextOnboardingStepError}
        onClose={() => setGoToNextOnboardingStepError(false)}
        content={
          <Trans
            i18nKey="onboarding-v1:onboardingSteps.alerts.goToNextOnboardingStepError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
      <ErrorAlert
        open={finishOnboardingSessionError}
        onClose={() => setFinishOnboardingSessionError(false)}
        content={
          <Trans
            i18nKey="onboarding-v1:onboardingSteps.alerts.finishOnboardingSessionError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
    </>
  );
};

export default OnboardingSteps;
