import { useMediaQuery } from "@mui/material";

export const useUpSm = () =>
  useMediaQuery((theme) => theme.breakpoints.up("sm"));

export const useUpMd = () =>
  useMediaQuery((theme) => theme.breakpoints.up("md"));

export const useUpLg = () =>
  useMediaQuery((theme) => theme.breakpoints.up("lg"));

export const useUpXl = () =>
  useMediaQuery((theme) => theme.breakpoints.up("xl"));
