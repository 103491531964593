import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";

import {
  ContactUsLink,
  ErrorAlert,
  SuccessAlert,
} from "@vesta/components/atoms";
import { SecondaryHeader } from "@vesta/components/molecules";
import { useSession } from "@vesta/lib/react";

import {
  Body,
  Geography,
  Information,
  ModifyAvailabilityAlert,
} from "./components";
import { useTerritory } from "./hooks";

const TerritoryView = () => {
  const { t } = useTranslation("territory");
  const [, jwtToken] = useSession();
  const [searchParams] = useSearchParams();
  const [territory, setTerritory] = useTerritory(jwtToken, searchParams);
  const [added, setAdded] = useState(searchParams.has("added"));
  const [saved, setSaved] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const handleSaveInformation = async ({ name }) => {
    const url = new URL(
      `/commands/modify-territory-as-polygon/${territory.territoryId}`,
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        name,
        points: territory.polygonPoints,
      }),
    });

    if (ok) {
      setTerritory((prevValue) => ({ ...prevValue, name }));
      setSaved(true);
    } else {
      setSaveError(true);
    }

    return ok;
  };

  const handleSaveGeography = async ({ points }) => {
    const url = new URL(
      `/commands/modify-territory-as-polygon/${territory.territoryId}`,
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        name: territory.name,
        points,
      }),
    });

    if (ok) {
      setTerritory((prevValue) => ({ ...prevValue, polygonPoints: points }));
      setSaved(true);
    } else {
      setSaveError(true);
    }

    return ok;
  };

  return (
    <>
      <Helmet>
        <title>{t("title", { name: territory?.name ?? "" })}</title>
        <meta
          property="og:title"
          content={t("title", { name: territory?.name ?? "" })}
        />
        <meta property="og:description" content={t("description")} />
      </Helmet>
      <SecondaryHeader loading={!territory} title={territory?.name} />
      <Body
        sx={(theme) => ({
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
          [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
          },
        })}
        alert={<ModifyAvailabilityAlert />}
        information={
          <Information
            loading={!territory}
            territory={territory}
            onSave={handleSaveInformation}
          />
        }
        geography={
          <Geography
            loading={!territory}
            territory={territory}
            onSave={handleSaveGeography}
          />
        }
      />
      <SuccessAlert
        open={added}
        onClose={() => setAdded(false)}
        content={t("alerts.added")}
      />
      <SuccessAlert
        open={saved}
        onClose={() => setSaved(false)}
        content={t("alerts.saved")}
      />
      <ErrorAlert
        open={saveError}
        onClose={() => setSaveError(false)}
        content={
          <Trans
            i18nKey="territory:alerts.saveError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
    </>
  );
};

export default TerritoryView;
