import { useMatch } from "react-router-dom";

export const useTitle = (t, medicalRecord) => {
  const matchesPatientNotes = useMatch("/patients/:patientId/notes");

  const matchesSomeoneElseNotes = useMatch(
    "/patients/:patientId/s/:someoneElseId/notes"
  );

  const matchesPatientResults = useMatch("/patients/:patientId/results");

  const matchesSomeoneElseResults = useMatch(
    "/patients/:patientId/s/:someoneElseId/results"
  );

  const matchesPatientAppointments = useMatch(
    "/patients/:patientId/appointments"
  );

  const matchesSomeoneElseAppointments = useMatch(
    "/patients/:patientId/s/:someoneElseId/appointments"
  );

  return matchesPatientNotes || matchesSomeoneElseNotes
    ? t("patientHeader.notesTitle", {
        fullName: medicalRecord?.personalInformation.fullName ?? "",
      })
    : matchesPatientResults || matchesSomeoneElseResults
    ? t("patientHeader.resultsTitle", {
        fullName: medicalRecord?.personalInformation.fullName ?? "",
      })
    : matchesPatientAppointments || matchesSomeoneElseAppointments
    ? t("patientHeader.appointmentsTitle", {
        fullName: medicalRecord?.personalInformation.fullName ?? "",
      })
    : null;
};
