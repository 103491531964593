import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import * as yup from "yup";

import { Divider, Grid } from "@mui/material";

import {
  LinkedInUriTextField,
  NewWebReference,
  TitledFormPaper,
  WebReference,
  WebsiteUriTextField,
} from "@vesta/components/molecules";
import { useUpSm } from "@vesta/lib/react";

const WebReferences = ({ loading, biography, onSave }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("profile");
  const [editing, setEditing] = useState(false);

  const validationSchema = yup.object().shape({
    linkedInUri: yup
      .string()
      .url(t("biography.webReferences.linkedInUri.uriValidation"))
      .test(
        "linkedin-uri-validation",
        t("biography.webReferences.linkedInUri.linkedInUriValidation"),
        (value) => value?.includes("linkedin.com/in/") ?? true
      )
      .nullable(),
    websiteUri: yup
      .string()
      .url(t("biography.webReferences.websiteUri.uriValidation"))
      .nullable(),
    otherReferences: yup.array(),
  });

  const {
    values,
    errors,
    isSubmitting,
    submitCount,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      linkedInUri: "",
      websiteUri: "",
      otherReferences: [],
    },
    validationSchema,
    onSubmit: async ({ linkedInUri, websiteUri, otherReferences }) => {
      const ok = await onSave({
        linkedInUri,
        websiteUri,
        otherReferences: Object.fromEntries(otherReferences),
      });
      if (ok) {
        setEditing(false);
      }
    },
  });

  useEffect(() => {
    if (!editing && biography) {
      setFieldValue("linkedInUri", biography.linkedInUri);
      setFieldValue("websiteUri", biography.websiteUri);
      setFieldValue(
        "otherReferences",
        Object.entries(biography.otherReferences)
      );
    }
  }, [editing, biography]);

  const handleCancel = () => {
    resetForm();
    setFieldValue("linkedInUri", biography.linkedInUri);
    setFieldValue("websiteUri", biography.websiteUri);
    setFieldValue("otherReferences", Object.entries(biography.otherReferences));
    setEditing(false);
  };

  const handleAddOtherReference = (value) =>
    setFieldValue("otherReferences", [
      ...values.otherReferences,
      [value.name, value.url],
    ]);

  const handleDeleteOtherReference = (i) =>
    setFieldValue(
      "otherReferences",
      values.otherReferences.filter((_, j) => j !== i)
    );

  return (
    <TitledFormPaper
      title={t("biography.webReferences.title")}
      loading={loading}
      editing={editing}
      saving={isSubmitting}
      onEdit={() => setEditing(true)}
      onCancel={handleCancel}
      onSave={handleSubmit}
    >
      <Grid container direction="column" spacing={upSm ? 3 : 2}>
        <Grid item>
          <LinkedInUriTextField
            id="linkedInUri"
            readOnly={!editing}
            value={values.linkedInUri}
            onChange={handleChange}
            error={submitCount > 0 && Boolean(errors.linkedInUri)}
            helperText={submitCount > 0 && errors.linkedInUri}
          />
        </Grid>
        <Grid item>
          <WebsiteUriTextField
            id="websiteUri"
            readOnly={!editing}
            value={values.websiteUri}
            onChange={handleChange}
            error={submitCount > 0 && Boolean(errors.websiteUri)}
            helperText={submitCount > 0 && errors.websiteUri}
          />
        </Grid>
        <Grid container item direction="column" spacing={upSm ? 3 : 2}>
          {values.otherReferences.map((x, i) => (
            <Fragment key={i}>
              <WebReference
                editing={editing}
                name={x[0]}
                url={x[1]}
                onDelete={() => handleDeleteOtherReference(i)}
              />
              {i !== values.otherReferences.length - 1 && (
                <Grid item>
                  <Divider />
                </Grid>
              )}
            </Fragment>
          ))}
          {editing && (
            <Grid item>
              <NewWebReference onAdd={handleAddOtherReference} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </TitledFormPaper>
  );
};

WebReferences.propTypes = {
  loading: PropTypes.bool,
  biography: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};

export default WebReferences;
