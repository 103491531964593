import { fr } from "date-fns/locale";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";

import { useSession } from "@vesta/lib/react";

const NoteEditorFooter = ({ note }) => {
  const { t } = useTranslation("components");
  const [session] = useSession();

  const { modified, completed, completedBy, addendumTo } = note;

  let footerText;

  if (completed) {
    const isCompletedByMe = completedBy?.userId === session.nameid;
    const completedDate = format(completed, "PPP", { locale: fr });
    const completedTime = format(completed, "p", { locale: fr });

    footerText = addendumTo
      ? isCompletedByMe
        ? t("molecules.noteEditorFooter.addendumCompletedBy.me", {
            date: completedDate,
            time: completedTime,
          })
        : t("molecules.noteEditorFooter.addendumCompletedBy.someoneElse", {
            date: completedDate,
            time: completedTime,
            fullName: `${completedBy.firstName} ${completedBy.lastName}`,
          })
      : isCompletedByMe
      ? t("molecules.noteEditorFooter.completedBy.me", {
          date: completedDate,
          time: completedTime,
        })
      : t("molecules.noteEditorFooter.completedBy.someoneElse", {
          date: completedDate,
          time: completedTime,
          fullName: `${completedBy.firstName} ${completedBy.lastName}`,
        });
  } else {
    footerText = t("molecules.noteEditorFooter.modified", {
      date: format(modified, "PPP", { locale: fr }),
      time: format(modified, "p", { locale: fr }),
    });
  }

  return (
    <Typography
      sx={{ fontStyle: "italic" }}
      color="textSecondary"
      variant="caption"
    >
      {footerText}
    </Typography>
  );
};

NoteEditorFooter.propTypes = {
  note: PropTypes.shape({
    addendumTo: PropTypes.string,
    modified: PropTypes.instanceOf(Date).isRequired,
    completed: PropTypes.instanceOf(Date),
    completedBy: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default NoteEditorFooter;
