import { fr } from "date-fns/locale";
import PropTypes from "prop-types";

import {
  frFR,
  LocalizationProvider,
  StaticDatePicker as MuiStaticDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const StaticDatePicker = ({ autoComplete, ...rest }) => (
  <LocalizationProvider
    dateAdapter={AdapterDateFns}
    adapterLocale={fr}
    localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
  >
    <MuiStaticDatePicker
      showDaysOutsideCurrentMonth
      displayStaticWrapperAs="desktop"
      openTo="day"
      orientation="landscape"
      slotProps={{
        textField: {
          inputProps: {
            autoComplete,
          },
        },
      }}
      {...rest}
    />
  </LocalizationProvider>
);

StaticDatePicker.propTypes = {
  autoComplete: PropTypes.string,
};

export default StaticDatePicker;
