import { useTranslation } from "react-i18next";

import { AccountMenuAlert } from "../";

const AccountMenuSubscriptionCancelledAlert = () => {
  const { t } = useTranslation("components");

  return (
    <AccountMenuAlert
      severity={"error"}
      title={t(
        "molecules.accountMenu.accountMenuSubscriptionCancelledAlert.title"
      )}
      description={t(
        "molecules.accountMenu.accountMenuSubscriptionCancelledAlert.description"
      )}
      action={{
        label: t(
          "molecules.accountMenu.accountMenuSubscriptionCancelledAlert.ctaLabel"
        ),
        func: () => {
          window.tidioChatApi.display(true);
          window.tidioChatApi.open();
        },
      }}
    />
  );
};

export default AccountMenuSubscriptionCancelledAlert;
