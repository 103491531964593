import PropTypes from "prop-types";

import { Box, Container, Typography } from "@mui/material";

const Header = ({ sx, title }) => (
  <Box
    sx={[
      (theme) => ({
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
        height: theme.spacing(14),
        [theme.breakpoints.up("md")]: {
          height: theme.spacing(18),
        },
      }),
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    <Container maxWidth="lg">
      <Typography
        sx={(theme) => ({
          color: theme.palette.getContrastText(theme.palette.primary.main),
        })}
        variant="h1"
      >
        {title}
      </Typography>
    </Container>
  </Box>
);

Header.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default Header;
