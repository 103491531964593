import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Link as MuiLink, Skeleton, Stack } from "@mui/material";

const PreviousAppointmentSubheader = ({ appointment }) => {
  const { t } = useTranslation("appointment");

  return (
    <Stack direction="column">
      <span>
        {appointment ? (
          <MuiLink
            component={Link}
            color="inherit"
            to={
              appointment
                ? `/calendar/appointments/${appointment.appointmentId}/preparation`
                : "#"
            }
          >
            {t("directions.previousAppointmentSubheader.linkText")}
          </MuiLink>
        ) : (
          <Skeleton width="70%" />
        )}
      </span>
      <span>
        {appointment ? (
          appointment.patientFormattedAddress
        ) : (
          <Skeleton width="40%" />
        )}
      </span>
      <span>
        {appointment ? (
          appointment.patientApartmentUnitSuiteOrFloorNb
        ) : (
          <Skeleton width="40%" />
        )}
      </span>
    </Stack>
  );
};

PreviousAppointmentSubheader.propTypes = {
  appointment: PropTypes.shape({
    appointmentId: PropTypes.string.isRequired,
    patientFormattedAddress: PropTypes.string.isRequired,
    patientApartmentUnitSuiteOrFloorNb: PropTypes.string,
  }),
};

export default PreviousAppointmentSubheader;
