import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Grid, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";

import { LoadingButton } from "@vesta/components/atoms";
import {
  AvailabilityRecurrenceEnd,
  AvailabilityRecurrenceFrequency,
  AvailabilitySubtitle,
  AvailabilityTime,
  CheckboxFilters,
  Dialog,
  DrivingTimeBetweenAppointmentsAlert,
} from "@vesta/components/molecules";
import { agreementStatus } from "@vesta/lib/enum";
import { useUpSm } from "@vesta/lib/react";

import { useGetAvailability, useServices, useTerritories } from "./hooks";

const ModifyAvailabilityDialog = ({
  open,
  availabilityId,
  occurrenceId,
  onCancel,
  onModify,
  onDelete,
}) => {
  const upSm = useUpSm();
  const [availability, loading] = useGetAvailability(
    open,
    availabilityId,
    occurrenceId
  );
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [isRecurring, setIsRecurring] = useState();
  const [recurrenceFrequencyId, setRecurrenceFrequencyId] = useState();
  const [
    recurrenceEndsAfterMaxNbOccurrences,
    setRecurrenceEndsAfterMaxNbOccurrences,
  ] = useState();
  const [recurrenceEndsOn, setRecurrenceEndsOn] = useState();
  const [territories, setTerritories, territoriesError, handleTerritoryChange] =
    useTerritories();
  const [services, setServices, servicesError, handleServiceChange] =
    useServices();
  const [modifying, setModifying] = useState(false);
  const { t } = useTranslation("calendar");

  useEffect(() => {
    if (availability) {
      setStart(new Date(availability.start));
      setEnd(new Date(availability.end));
      setIsRecurring(availability.isRecurring);
      setRecurrenceFrequencyId(availability.recurrenceFrequencyId ?? "");
      setRecurrenceEndsAfterMaxNbOccurrences(
        availability.recurrenceEndsAfterMaxNbOccurrences
      );
      setRecurrenceEndsOn(new Date(availability.recurrenceEndsOn));
      setTerritories(
        availability.territories.map((x) => ({
          id: x.territoryId,
          name: x.name,
          include: x.isIncluded,
        }))
      );
      setServices(
        availability.services
          .filter((x) => !x.isRemoved)
          .map((x) => ({
            id: x.serviceId,
            name: x.nameFr,
            include: x.isIncluded,
            disabled: x.agreements.some(
              (y) => y.statusId !== agreementStatus.accepted
            ),
          }))
      );
    }
  }, [availability]);

  const handleClose = (_, reason) => {
    if (reason !== "backdropClick") {
      onCancel();
    }
  };

  const handleModify = async () => {
    setModifying(true);

    await onModify({
      isRecurring,
      start,
      end,
      territories: territories.reduce(
        (obj, item) => ({
          ...obj,
          [item.id]: item.include,
        }),
        {}
      ),
      services: services.reduce(
        (obj, item) => ({
          ...obj,
          [item.id]: item.include,
        }),
        {}
      ),
    });

    setModifying(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      loading={loading}
      onClose={handleClose}
      title={t(
        "availabilities.modifyAvailability.modifyAvailabilityDialog.title"
      )}
      subtitle={
        start &&
        end && (
          <AvailabilitySubtitle
            start={start}
            end={end}
            recurrenceFrequencyId={recurrenceFrequencyId}
            recurrenceMode={recurrenceEndsAfterMaxNbOccurrences ? 1 : 2}
            recurrenceEndsAfterMaxNbOccurrences={
              recurrenceEndsAfterMaxNbOccurrences
            }
            recurrenceEndsOn={recurrenceEndsOn}
          />
        )
      }
      content={
        <Grid container direction="column" spacing={4}>
          {start && end && (
            <Grid item>
              <AvailabilityTime
                modifying
                start={start}
                end={end}
                onStartChange={setStart}
                onEndChange={setEnd}
              />
            </Grid>
          )}
          <Grid item>
            <AvailabilityRecurrenceFrequency
              disabled
              start={start}
              recurrenceFrequencyId={recurrenceFrequencyId}
              onRecurrenceFrequencyIdChange={setRecurrenceFrequencyId}
            />
          </Grid>
          {recurrenceFrequencyId && (
            <Grid item>
              <AvailabilityRecurrenceEnd
                disabled
                start={start}
                recurrenceMode={recurrenceEndsAfterMaxNbOccurrences ? 1 : 2}
                recurrenceEndsAfterMaxNbOccurrences={
                  recurrenceEndsAfterMaxNbOccurrences
                }
                recurrenceEndsOn={recurrenceEndsOn}
              />
            </Grid>
          )}
          <Grid item>
            <CheckboxFilters
              label={t(
                "availabilities.modifyAvailability.modifyAvailabilityDialog.territories.label"
              )}
              error={territoriesError}
              errorText={t(
                "availabilities.modifyAvailability.modifyAvailabilityDialog.territories.errorText"
              )}
              values={territories}
              onChange={handleTerritoryChange}
            />
          </Grid>
          <Grid item>
            <CheckboxFilters
              label={t(
                "availabilities.modifyAvailability.modifyAvailabilityDialog.services.label"
              )}
              error={servicesError}
              errorText={t(
                "availabilities.modifyAvailability.modifyAvailabilityDialog.services.errorText"
              )}
              values={services}
              onChange={handleServiceChange}
            />
          </Grid>
          <Grid item>
            <DrivingTimeBetweenAppointmentsAlert />
          </Grid>
        </Grid>
      }
      actions={
        <>
          {upSm ? (
            <Button onClick={() => onDelete(isRecurring)} size="large">
              {t(
                "availabilities.modifyAvailability.modifyAvailabilityDialog.delete"
              )}
            </Button>
          ) : (
            <IconButton
              aria-label={t(
                "availabilities.modifyAvailability.modifyAvailabilityDialog.delete"
              )}
              onClick={() => onDelete(isRecurring)}
              size="large"
            >
              <Delete />
            </IconButton>
          )}
          <Button onClick={onCancel} size="large" variant="outlined">
            {t(
              "availabilities.modifyAvailability.modifyAvailabilityDialog.cancel"
            )}
          </Button>
          <LoadingButton
            loading={modifying}
            disabled={modifying || territoriesError || servicesError}
            onClick={handleModify}
            size="large"
            variant="contained"
          >
            {t(
              "availabilities.modifyAvailability.modifyAvailabilityDialog.modify"
            )}
          </LoadingButton>
        </>
      }
    />
  );
};

ModifyAvailabilityDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  availabilityId: PropTypes.string,
  occurrenceId: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ModifyAvailabilityDialog;
