import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import WithLayout, { MinimalLayout } from "@vesta/layouts";
import { usePageViewedTracking } from "@vesta/lib/react";

import {
  AuthorizedElement,
  MainLayoutWithAppointmentNavigation,
  MainLayoutWithPatientNavigation,
  MainLayoutWithRootNavigation,
  MainLayoutWithSidebar,
} from "./components";

const AddServiceView = lazy(() => import("@vesta/views/AddServiceView"));
const AddTerritoryView = lazy(() => import("@vesta/views/AddTerritoryView"));
const AppointmentView = lazy(() => import("@vesta/views/AppointmentView"));
const CalendarView = lazy(() => import("@vesta/views/CalendarView"));
const HomeView = lazy(() => import("@vesta/views/HomeView"));
const OnboardingV1View = lazy(() => import("@vesta/views/OnboardingV1View"));
const PatientsView = lazy(() => import("@vesta/views/PatientsView"));
const PatientView = lazy(() => import("@vesta/views/PatientView"));
const ProfileView = lazy(() => import("@vesta/views/ProfileView"));
const ServiceView = lazy(() => import("@vesta/views/ServiceView"));
const SignInView = lazy(() => import("@vesta/views/SignInView"));
const SignUpView = lazy(() => import("@vesta/views/SignUpView"));
const TerritoryView = lazy(() => import("@vesta/views/TerritoryView"));

const AppRoutes = () => {
  usePageViewedTracking();

  return (
    <Routes>
      <Route
        path="/sign-up/*"
        element={
          <WithLayout
            renderView={() => <SignUpView />}
            renderLayout={({ view }) => <MinimalLayout>{view}</MinimalLayout>}
          />
        }
      />
      <Route
        path="/sign-in/*"
        element={
          <WithLayout
            renderView={() => <SignInView />}
            renderLayout={({ view }) => <MinimalLayout>{view}</MinimalLayout>}
          />
        }
      />
      <Route
        path="/onboarding/v1/*"
        element={
          <AuthorizedElement>
            <WithLayout
              renderView={() => <OnboardingV1View />}
              renderLayout={({ view }) => <MinimalLayout>{view}</MinimalLayout>}
            />
          </AuthorizedElement>
        }
      />
      <Route
        path="/calendar/*"
        element={
          <AuthorizedElement>
            <WithLayout
              renderView={() => <CalendarView />}
              renderLayout={({ view }) => (
                <MainLayoutWithRootNavigation>
                  {view}
                </MainLayoutWithRootNavigation>
              )}
            />
          </AuthorizedElement>
        }
      />
      <Route
        path="/calendar/appointments/:appointmentId/*"
        element={
          <AuthorizedElement>
            <WithLayout
              renderView={() => <AppointmentView />}
              renderLayout={({ view }) => (
                <MainLayoutWithAppointmentNavigation>
                  {view}
                </MainLayoutWithAppointmentNavigation>
              )}
            />
          </AuthorizedElement>
        }
      />
      <Route
        path="/patients/*"
        element={
          <AuthorizedElement>
            <WithLayout
              renderView={() => <PatientsView />}
              renderLayout={({ view }) => (
                <MainLayoutWithRootNavigation>
                  {view}
                </MainLayoutWithRootNavigation>
              )}
            />
          </AuthorizedElement>
        }
      />
      <Route path="/patients/:patientId">
        <Route
          path="s/:someoneElseId/*"
          element={
            <AuthorizedElement>
              <WithLayout
                renderView={() => <PatientView />}
                renderLayout={({ view }) => (
                  <MainLayoutWithPatientNavigation>
                    {view}
                  </MainLayoutWithPatientNavigation>
                )}
              />
            </AuthorizedElement>
          }
        />
        <Route
          path="*"
          element={
            <AuthorizedElement>
              <WithLayout
                renderView={() => <PatientView />}
                renderLayout={({ view }) => (
                  <MainLayoutWithPatientNavigation>
                    {view}
                  </MainLayoutWithPatientNavigation>
                )}
              />
            </AuthorizedElement>
          }
        />
      </Route>
      <Route
        path="/profile/*"
        element={
          <AuthorizedElement>
            <WithLayout
              renderView={() => <ProfileView />}
              renderLayout={({ view }) => (
                <MainLayoutWithRootNavigation>
                  {view}
                </MainLayoutWithRootNavigation>
              )}
            />
          </AuthorizedElement>
        }
      />
      <Route
        path="/profile/territories/:territoryId"
        element={
          <AuthorizedElement>
            <WithLayout
              renderView={() => <TerritoryView />}
              renderLayout={({ view }) => (
                <MainLayoutWithSidebar>{view}</MainLayoutWithSidebar>
              )}
            />
          </AuthorizedElement>
        }
      />
      <Route
        path="/profile/add-territory/*"
        element={
          <AuthorizedElement>
            <WithLayout
              renderView={() => <AddTerritoryView />}
              renderLayout={({ view }) => <MinimalLayout>{view}</MinimalLayout>}
            />
          </AuthorizedElement>
        }
      />
      <Route
        path="/profile/services/:serviceId"
        element={
          <AuthorizedElement>
            <WithLayout
              renderView={() => <ServiceView />}
              renderLayout={({ view }) => (
                <MainLayoutWithSidebar>{view}</MainLayoutWithSidebar>
              )}
            />
          </AuthorizedElement>
        }
      />
      <Route
        path="/profile/add-service/*"
        element={
          <AuthorizedElement>
            <WithLayout
              renderView={() => <AddServiceView />}
              renderLayout={({ view }) => <MinimalLayout>{view}</MinimalLayout>}
            />
          </AuthorizedElement>
        }
      />
      <Route
        index
        element={
          <AuthorizedElement>
            <WithLayout
              renderView={() => <HomeView />}
              renderLayout={({ view }) => (
                <MainLayoutWithRootNavigation>
                  {view}
                </MainLayoutWithRootNavigation>
              )}
            />
          </AuthorizedElement>
        }
      />
      <Route
        path="*"
        element={
          <AuthorizedElement>
            <Navigate to="/" replace />
          </AuthorizedElement>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
