import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { formatPhoneNumber } from "react-phone-number-input";
import * as yup from "yup";

import { Button, DialogContentText, TextField } from "@mui/material";

import { ContactUsLink, LoadingButton } from "@vesta/components/atoms";
import { Dialog } from "@vesta/components/molecules";

// TODO add an option to resend the verification code

const VerifyProfilePhoneNumberDialog = ({
  submittedPhoneNumber,
  open,
  onClose,
  onVerify,
}) => {
  const { t } = useTranslation("components");
  const [errorCode, setErrorCode] = useState(null);

  const validationSchema = yup.object().shape({
    verificationCode: yup
      .string()
      .test(
        "verification-code-length",
        t(
          "organisms.verifyProfilePhoneNumberDialog.verificationCodeLengthValidation"
        ),
        (value) => value?.length === 6
      ),
  });

  const {
    values,
    errors,
    isSubmitting,
    submitCount,
    handleSubmit,
    handleChange,
    resetForm,
  } = useFormik({
    initialValues: {
      verificationCode: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const errorCode = await onVerify(values.verificationCode);
      if (errorCode) {
        setErrorCode(errorCode);
      } else {
        resetForm();
      }
    },
  });

  const handleClose = () => {
    onClose();
    resetForm();
    setErrorCode(null);
  };

  return (
    <Dialog
      maxWidth="md"
      fullScreen={false}
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title={t("organisms.verifyProfilePhoneNumberDialog.title")}
      content={
        <>
          <DialogContentText
            sx={(theme) => ({ marginBottom: theme.spacing(1) })}
          >
            <Trans
              i18nKey="components:organisms.verifyProfilePhoneNumberDialog.text"
              components={{
                emphasize: <b />,
              }}
              values={{
                phoneNumber: formatPhoneNumber(submittedPhoneNumber),
              }}
            />
          </DialogContentText>
          <TextField
            variant="standard"
            id="verificationCode"
            sx={(theme) => ({
              width: "100%",
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(3),
              [theme.breakpoints.up("sm")]: {
                width: "50%",
              },
            })}
            value={values.verificationCode}
            onChange={handleChange}
            error={
              (submitCount > 0 && Boolean(errors.verificationCode)) ||
              !!errorCode
            }
            helperText={
              (submitCount > 0 && errors.verificationCode) ||
              (errorCode &&
                (errorCode.id === 3 ? (
                  <Trans
                    i18nKey={
                      "components:organisms.verifyProfilePhoneNumberDialog.errorCode.3"
                    }
                    components={{
                      contactUsLink: <ContactUsLink variant="caption" />,
                    }}
                  />
                ) : (
                  t(
                    `organisms.verifyProfilePhoneNumberDialog.errorCode.${errorCode.id}`
                  )
                )))
            }
            label={t(
              "organisms.verifyProfilePhoneNumberDialog.verificationCodeLabel"
            )}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </>
      }
      actions={
        <>
          <Button size="large" onClick={handleClose}>
            {t("organisms.verifyProfilePhoneNumberDialog.actions.close")}
          </Button>
          <LoadingButton
            loading={isSubmitting}
            disabled={isSubmitting}
            type="submit"
            size="large"
            variant="contained"
          >
            {t("organisms.verifyProfilePhoneNumberDialog.actions.verify")}
          </LoadingButton>
        </>
      }
    />
  );
};

VerifyProfilePhoneNumberDialog.propTypes = {
  submittedPhoneNumber: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onVerify: PropTypes.func.isRequired,
};

export default VerifyProfilePhoneNumberDialog;
