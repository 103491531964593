const getQueryParam = function (url, param) {
  // Expects a raw URL
  param = param.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regexS = "[\\?&]" + param + "=([^&#]*)";
  const regex = new RegExp(regexS);
  const results = regex.exec(url);

  if (
    results === null ||
    (results && typeof results[1] !== "string" && results[1].length)
  ) {
    return "";
  } else {
    let result = results[1];
    try {
      result = decodeURIComponent(result);
    } catch (err) {
      console.error("Skipping decoding for malformed query param: " + result);
    }
    return result.replace(/\+/g, " ");
  }
};

const getCampaignParams = (url) => {
  let campaign_keywords =
    "utm_source utm_medium utm_campaign utm_content utm_term".split(" ");
  let kw = "";
  const params = {};

  campaign_keywords.forEach(function (kwkey) {
    kw = getQueryParam(url, kwkey);
    if (kw.length) {
      params[kwkey] = kw;
    }
  });

  return params;
};

const getSearchInfo = (referrer) => {
  let search = null;
  if (referrer.search("https?://(.*)google.([^/?]*)") === 0) {
    search = "google";
  } else if (referrer.search("https?://(.*)bing.com") === 0) {
    search = "bing";
  } else if (referrer.search("https?://(.*)yahoo.com") === 0) {
    search = "yahoo";
  } else if (referrer.search("https?://(.*)duckduckgo.com") === 0) {
    search = "duckduckgo";
  }

  const param = search !== "yahoo" ? "q" : "p";
  const ret = {};

  if (search !== null) {
    ret["$search_engine"] = search;

    const keyword = getQueryParam(referrer, param);
    if (keyword.length) {
      ret["mp_keyword"] = keyword;
    }
  }

  return ret;
};

/**
 * This function detects which browser is running this script.
 * The order of the checks are important since many user agents
 * include key words used in later checks.
 */
const getBrowser = (userAgent, vendor, opera) => {
  vendor = vendor || ""; // vendor is undefined for at least IE9
  if (opera || userAgent.includes(" OPR/")) {
    return userAgent.includes("Mini") ? "Opera Mini" : "Opera";
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return "BlackBerry";
  } else if (userAgent.includes("IEMobile")) {
    return "Internet Explorer Mobile";
  } else if (userAgent.includes("WPDesktop")) {
    return "Internet Explorer Mobile";
  } else if (userAgent.includes("SamsungBrowser/")) {
    return "Samsung Internet"; // https://developer.samsung.com/internet/user-agent-string-format
  } else if (userAgent.includes("Edge") || userAgent.includes("Edg/")) {
    return "Microsoft Edge";
  } else if (userAgent.includes("FBIOS")) {
    return "Facebook Mobile";
  } else if (userAgent.includes("Chrome")) {
    return "Chrome";
  } else if (userAgent.includes("CriOS")) {
    return "Chrome iOS";
  } else if (userAgent.includes("UCWEB") || userAgent.includes("UCBrowser")) {
    return "UC Browser";
  } else if (userAgent.includes("FxiOS")) {
    return "Firefox iOS";
  } else if (vendor.includes("Apple")) {
    return userAgent.includes("Mobile") ? "Mobile Safari" : "Safari";
  } else if (userAgent.includes("Android")) {
    return "Android Mobile";
  } else if (userAgent.includes("Konqueror")) {
    return "Konqueror";
  } else if (userAgent.includes("Firefox")) {
    return "Firefox";
  } else if (userAgent.includes("MSIE") || userAgent.includes("Trident/")) {
    return "Internet Explorer";
  } else if (userAgent.includes("Gecko")) {
    return "Mozilla";
  } else {
    return "";
  }
};

/**
 * This function detects which browser version is running this script,
 * parsing major and minor version (e.g., 42.1). User agent strings from:
 * http://www.useragentstring.com/pages/useragentstring.php
 */
const getBrowserVersion = (userAgent, vendor, opera) => {
  const browser = getBrowser(userAgent, vendor, opera);
  const versionRegexs = {
    "Internet Explorer Mobile": /rv:(\d+(\.\d+)?)/,
    "Microsoft Edge": /Edge?\/(\d+(\.\d+)?)/,
    Chrome: /Chrome\/(\d+(\.\d+)?)/,
    "Chrome iOS": /CriOS\/(\d+(\.\d+)?)/,
    "UC Browser": /(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/,
    Safari: /Version\/(\d+(\.\d+)?)/,
    "Mobile Safari": /Version\/(\d+(\.\d+)?)/,
    Opera: /(Opera|OPR)\/(\d+(\.\d+)?)/,
    Firefox: /Firefox\/(\d+(\.\d+)?)/,
    "Firefox iOS": /FxiOS\/(\d+(\.\d+)?)/,
    Konqueror: /Konqueror:(\d+(\.\d+)?)/,
    BlackBerry: /BlackBerry (\d+(\.\d+)?)/,
    "Android Mobile": /android\s(\d+(\.\d+)?)/,
    "Samsung Internet": /SamsungBrowser\/(\d+(\.\d+)?)/,
    "Internet Explorer": /(rv:|MSIE )(\d+(\.\d+)?)/,
    Mozilla: /rv:(\d+(\.\d+)?)/,
  };
  const regex = versionRegexs[browser];
  if (regex === undefined) {
    return null;
  }
  const matches = userAgent.match(regex);
  if (!matches) {
    return null;
  }
  return parseFloat(matches[matches.length - 2]);
};

const getOs = (userAgent) => {
  if (/Windows/i.test(userAgent)) {
    return /Phone/.test(userAgent) || /WPDesktop/.test(userAgent)
      ? "Windows Phone"
      : "Windows";
  } else if (/(iPhone|iPad|iPod)/.test(userAgent)) {
    return "iOS";
  } else if (/Android/.test(userAgent)) {
    return "Android";
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return "BlackBerry";
  } else if (/Mac/i.test(userAgent)) {
    return "Mac OS X";
  } else if (/Linux/.test(userAgent)) {
    return "Linux";
  } else if (/CrOS/.test(userAgent)) {
    return "Chrome OS";
  } else {
    return "";
  }
};

const getDevice = (userAgent) => {
  if (/iPad/.test(userAgent)) {
    return "iPad";
  } else if (/iPod/.test(userAgent)) {
    return "iPod Touch";
  } else if (/iPhone/.test(userAgent)) {
    return "iPhone";
  } else if (/Android/.test(userAgent)) {
    return "Android";
  } else if (/WPDesktop/.test(userAgent)) {
    return "Windows Phone";
  } else if (/Windows Phone/i.test(userAgent)) {
    return "Windows Phone";
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return "BlackBerry";
  } else {
    return "";
  }
};

const getReferringDomain = (referrer) => {
  const split = referrer.split("/");
  if (split.length >= 3) {
    return split[2];
  }
  return "";
};

/**
 * Because client-side tracking is often blocked by Ad Blockers, we handle tracking server-side.
 *
 * This function was taken from mixpanel-js: https://github.com/mixpanel/mixpanel-js/blob/master/src/utils.js
 * It complements event properties with properties of the client (ex. browser, screen size, campaign parameters) that cannot be detected server-side.
 */
export const getAdditionalBrowserProperties = () => {
  const { userAgent, vendor } = navigator;
  const { referrer, URL } = document;
  const {
    location: { href },
    opera,
  } = window;

  const pathExlusions = ["verify-token"];
  const cleanReferer = pathExlusions.every(
    (exclusion) => !referrer.includes(exclusion)
  )
    ? referrer
    : referrer.split("?")[0];

  return {
    ...getCampaignParams(URL),
    ...getSearchInfo(referrer),
    $browser: getBrowser(userAgent, vendor, opera),
    $browser_version: getBrowserVersion(userAgent, vendor, opera),
    $current_url: href,
    $device: getDevice(userAgent),
    $os: getOs(userAgent),
    $referrer: cleanReferer,
    $referring_domain: getReferringDomain(referrer),
    $screen_height: screen.height,
    $screen_width: screen.width,
  };
};
