import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ServiceAgreementPaper } from "@vesta/components/molecules";

const CollectivePrescriptionToTreatEarInfectionAgreement = ({
  agreement,
  onReject,
  onAccept,
}) => {
  const { t } = useTranslation("service");

  return (
    <ServiceAgreementPaper
      agreement={agreement}
      context={t(
        "collectivePrescriptionToTreatEarInfectionAgreement.context.text"
      )}
      requirements={t(
        "collectivePrescriptionToTreatEarInfectionAgreement.requirement.text"
      )}
      onReject={onReject}
      onAccept={onAccept}
    />
  );
};

CollectivePrescriptionToTreatEarInfectionAgreement.propTypes = {
  agreement: PropTypes.object.isRequired,
  onReject: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default CollectivePrescriptionToTreatEarInfectionAgreement;
