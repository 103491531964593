import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { TextField } from "@mui/material";

const BiographyTextField = ({ className, readOnly, lang, ...rest }) => {
  const { t } = useTranslation("components");

  return (
    <TextField
      variant="standard"
      className={className}
      disabled={readOnly}
      fullWidth
      multiline
      label={t(`molecules.biographyTextField.${lang}.label`)}
      placeholder={t(`molecules.biographyTextField.${lang}.placeholder`)}
      InputProps={{
        readOnly,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      {...rest}
    />
  );
};

BiographyTextField.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  lang: PropTypes.oneOf(["en", "fr"]).isRequired,
};

export default BiographyTextField;
