import PropTypes from "prop-types";
import { useState } from "react";

import { MainSidebar, SecondarySidebar } from "@vesta/components/organisms";
import { MainLayout } from "@vesta/layouts";

const MainLayoutWithSidebar = ({ children }) => {
  const [primarySidebarOpen, setPrimarySidebarOpen] = useState(false);
  const [secondarySidebarOpen, setSecondarySidebarOpen] = useState(false);

  const primarySidebar = (
    <MainSidebar
      isOpen={primarySidebarOpen}
      onOpen={() => setPrimarySidebarOpen(true)}
      onClose={() => setPrimarySidebarOpen(false)}
    />
  );

  const secondarySidebar = (
    <SecondarySidebar
      isOpen={secondarySidebarOpen}
      onOpen={() => setSecondarySidebarOpen(true)}
      onClose={() => setSecondarySidebarOpen(false)}
    />
  );

  return (
    <MainLayout
      sidebar={primarySidebar}
      onOpenSidebar={() => setPrimarySidebarOpen(true)}
      secondarySidebar={secondarySidebar}
      onOpenSecondarySidebar={() => setSecondarySidebarOpen(true)}
    >
      {children}
    </MainLayout>
  );
};

MainLayoutWithSidebar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayoutWithSidebar;
