import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const durations = [180, 360, 720, 1440, 2880, 4320, 5760, 7200, 8640, 10080];

const MinimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutesSelect =
  ({ id, sx, readOnly, value, onChange, error, helperText }) => {
    const { t } = useTranslation("components");

    return (
      <Box
        sx={[
          (theme) => ({
            padding: theme.spacing(2),
            borderStyle: "dashed",
            borderWidth: 1,
            borderColor: theme.palette.text.secondary,
            borderRadius: 4,
            [theme.breakpoints.up("sm")]: {
              padding: theme.spacing(3),
            },
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              sx={(theme) => ({
                color: readOnly
                  ? theme.palette.text.disabled
                  : theme.palette.text.primary,
              })}
              variant="body2"
            >
              <Trans
                i18nKey="components:atoms.minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutesSelect.label"
                components={{
                  emphasize: <b />,
                }}
                values={{
                  value: t(
                    `atoms.minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutesSelect.durations.${value}`
                  ),
                }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              variant="standard"
              disabled={readOnly}
              fullWidth
              error={error}
            >
              <Select
                variant="standard"
                id={id}
                value={value}
                onChange={(event) => onChange(parseInt(event.target.value))}
                inputProps={{
                  "aria-label": t(
                    "atoms.minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutesSelect.ariaLabel"
                  ),
                  readOnly,
                }}
              >
                {durations.map((x, i) => (
                  <MenuItem key={`${id}-menu-item-${i}`} value={x}>
                    {t(
                      `atoms.minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutesSelect.durations.${x}`
                    )}
                  </MenuItem>
                ))}
              </Select>
              {error && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    );
  };

MinimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutesSelect.propTypes =
  {
    id: PropTypes.string.isRequired,
    sx: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.func,
    ]),
    readOnly: PropTypes.bool,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool,
    helperText: PropTypes.string,
  };

export default MinimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutesSelect;
