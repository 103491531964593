import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";

import { List } from "@mui/material";

import { MainSidebarNavigationListItem } from "./components";

const MainSidebarNavigation = ({ onClick }) => {
  const { t } = useTranslation("components");

  const matchesHome = useMatch("/");
  const matchesCalendar = useMatch("/calendar/*");
  const matchesPatients = useMatch("/patients/*");
  const matchesProfile = useMatch("/profile/*");

  return (
    <List
      sx={(theme) => ({
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up("md")]: {
          backgroundColor: "inherit",
        },
      })}
    >
      <MainSidebarNavigationListItem
        to="/"
        label={t("organisms.mainSidebar.mainSidebarNavigation.homeLabel")}
        selected={!!matchesHome}
        onClick={onClick}
      />
      <MainSidebarNavigationListItem
        to="/calendar/appointments"
        label={t("organisms.mainSidebar.mainSidebarNavigation.calendarLabel")}
        selected={!!matchesCalendar}
        onClick={onClick}
      />
      <MainSidebarNavigationListItem
        to="/patients"
        label={t("organisms.mainSidebar.mainSidebarNavigation.patientsLabel")}
        selected={!!matchesPatients}
        onClick={onClick}
      />
      <MainSidebarNavigationListItem
        to="/profile/biography"
        label={t("organisms.mainSidebar.mainSidebarNavigation.profileLabel")}
        selected={!!matchesProfile}
        onClick={onClick}
      />
    </List>
  );
};

MainSidebarNavigation.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default MainSidebarNavigation;
