import PropTypes from "prop-types";

import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const EmptyBagIllustration = ({ sx }) => {
  const theme = useTheme();

  return (
    <Box
      sx={sx}
      component="svg"
      viewBox="0 0 450 316"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={theme.palette.grey[100]}
        stroke={theme.palette.grey[400]}
        d="M40.264 16.438c.959-.293 1.897-.259 2.632.01.736.268 1.24.756 1.424 1.365.184.609.035 1.296-.427 1.93-.463.634-1.223 1.186-2.182 1.478-.958.293-1.896.259-2.632-.01-.735-.268-1.24-.756-1.423-1.365-.184-.609-.035-1.296.427-1.93.462-.634 1.223-1.186 2.181-1.478ZM43.737 27.92c.958-.293 1.896-.259 2.632.01.735.268 1.24.756 1.423 1.365.185.609.036 1.295-.427 1.93-.462.633-1.223 1.186-2.181 1.478-.959.293-1.897.258-2.632-.01-.736-.268-1.24-.756-1.424-1.365-.184-.609-.035-1.296.427-1.93.463-.634 1.224-1.186 2.182-1.479Z"
      />
      <ellipse
        fill={theme.palette.grey[500]}
        rx="3.999"
        ry="6.972"
        transform="rotate(-106.827 30.481 -3.572) skewX(.141)"
      />
      <path
        name="circle"
        fill={theme.palette.primary.contrastText}
        stroke={theme.palette.grey[400]}
        strokeWidth="2"
        d="M372.341 158c0 86.716-69.763 157-155.803 157-86.041 0-155.804-70.284-155.804-157S130.497 1 216.538 1c86.04 0 155.803 70.284 155.803 157Z"
      />
      <path
        fill={theme.palette.grey[200]}
        d="M280.133 138.799c-.511 5.08 3.995 8.841 12.286 16.522 9.493 8.793 11.152 12.269 10.417 22.916-.763 7.764 3.19 11.098 3.205 14.123.006 1.216.197 2.156-1.603 5.862-1.505 3.731 1.752 5.33 2.404 9.593 1.814 11.853-12.569 10.387-14.156 11.725-17.795 15.003-35.246 20.676-54.485 24.248-36.988 17.258-61.045 15.277-94.281 0-4.541-1.332-9.433-2.772-10.417-14.122-6.066-21.982-8.074-32.375-1.068-36.24-4.417-12.399-2.208-19.519 9.081-32.51 6.269-6.781 9.594-13.424 9.984-23.916l10.5 31c-3.5 3.5-5.686 7.459-5 10 1.453 4.358 4 5.5 10.5 5.5 31.16-25.953 56.739-37.044 112.633-44.701Z"
      />
      <path
        stroke={theme.palette.grey[500]}
        strokeWidth="2"
        fill={theme.palette.grey[100]}
        d="M143.92 243.788c33.236 15.277 57.293 17.258 94.281 0 19.239-3.572 36.69-9.245 54.485-24.248 1.587-1.338 15.97.128 14.156-11.725-.652-4.263-3.909-5.862-2.404-9.593 1.8-3.706 1.609-4.646 1.603-5.862-.015-3.025-3.968-6.359-3.205-14.123.735-10.647-.924-14.123-10.417-22.916-8.291-7.681-12.797-11.442-12.286-16.522-55.894 7.657-81.473 18.748-112.633 44.701m-23.58 60.288c-4.541-1.332-9.433-2.772-10.417-14.122m10.417 14.122c-3.801-26.602-.087-38.518 23.58-60.288m-33.997 46.166c-6.066-21.982-8.074-32.375-1.068-36.24-4.417-12.399-2.208-19.519 9.081-32.51 6.269-6.781 9.594-13.424 9.984-23.916l10.5 31c-3.5 3.5-5.686 7.459-5 10m-23.497 51.666c0-4.53.534-22.384 6.945-28.779 6.41-6.395 17.949-18.697 16.552-22.887m0 0c1.453 4.358 4 5.5 10.5 5.5"
      />
      <path
        fill={theme.palette.grey[500]}
        d="M176.921 204.977c5.585 19.12-25.528 22.89-27.921 1.616 24.198-35.278 59.034-58.976 131.363-67.593-1.489 4.575 7.18 12.388 10.637 15.619-49.136-.748-72.772 5.06-92.805 26.66-10.99 11.849-21.008.808-15.689-7.54-8.219 5.99-7.168 20.296-5.585 31.238Z"
        opacity=".5"
      />
      <path
        fill={theme.palette.primary.light}
        stroke={theme.palette.primary.main}
        d="M259.768 177v17.794l13.02-4.044v15.368l-13.02 4.044v17.794L245.984 232v-17.794l-13.019 4.044v-15.368l13.019-4.044v-17.794L259.768 177Z"
      />
      <path
        fill={theme.palette.grey[500]}
        d="M143 167.5c6.463-7.575 11.057-19.154 10-31l8 32.884c-3.091 3.561-4.296 5.405-3.501 7.846 2.424 4.329-12.075 21.852-14.499 20.77-8.887-5.141-11.042-17.784 0-30.5Z"
        opacity=".5"
      />
      <path
        fill={theme.palette.grey[400]}
        d="M250 97.5c-31.269 1.59-48.13 3.342-76.607 7.445-16.166 2.329-17.372 8.646-13.63 24.919 2.939 10.868 6.794 22.772 9.621 33.136 25.656-16.171 68.882-30.789 99.616-34.5-2.94-22.798-5.103-31.798-19-31Z"
      />
      <path
        fill={theme.palette.grey[500]}
        d="M177.158 112.5c-12.658 3.753-18.758 8.63-8.307 50.5 33.543-18.96 58.633-27.426 99.149-34.892-3.216-11.004-5.955-25.641-21.5-25.108-18.238.625-42.5 3.253-69.342 9.5Z"
      />
      <path
        fill={theme.palette.primary.light}
        stroke={theme.palette.primary.main}
        strokeWidth="2"
        d="M292.822 132.766C283.214 112.82 144.5 159.5 159.5 179.5c2.256 2.003 6.865 3.596 9 2C191 161 233.053 140.949 286 138c5.07.266 7.622-.447 6.822-5.234Z"
      />
      <path
        fill={theme.palette.primary.light}
        stroke={theme.palette.primary.main}
        strokeWidth="2"
        d="M148.094 128.317c4.48 13.003 13.493 39.545 13.708 41.683l7.257-6.68c-12.095-42.752-19.889-55.845 4.3-58.784 29.585-4.345 46.865-6.018 79.289-7.215 11.606.312 13.964 10.085 16.664 30.996H272c-2.149-22.082-4.838-35.141-19.352-35.271-16.994-.152-22.577 0-42.198 1.603-61.28 5.611-69.351 13.36-62.356 33.668Z"
      />
      <path
        stroke="#BDBDBD"
        strokeDasharray="4 4"
        strokeWidth="2"
        fill="none"
        d="M194.239 148c14.92-17.034.939-41.8-6.963-51.655-8.455-10.544-29.343-17.44-34.316-5.272-4.974 12.167 47.247 3.65 53.712-29.201C213.138 29.02 122.622 5.902 52 22.532"
      />
    </Box>
  );
};

EmptyBagIllustration.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
};

export default EmptyBagIllustration;
