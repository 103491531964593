import PropTypes from "prop-types";
import { useState } from "react";
import { Trans } from "react-i18next";

import { ContactUsLink, ErrorAlert } from "@vesta/components/atoms";
import { useSession } from "@vesta/lib/react";

import {
  DeleteNonRecurringAvailabilityConfirmationDialog,
  DeleteRecurringAvailabilityConfirmationDialog,
  ModifyAvailabilityDialog,
  ModifyRecurringAvailabilityConfirmationDialog,
} from "./components";

const ModifyAvailability = ({
  modifying,
  availabilityId,
  occurrenceId,
  onCancel,
  onModify,
  onDelete,
}) => {
  const [modifiedAvailability, setModifiedAvailability] = useState();
  const [
    openModifyRecurringAvailabilityConfirmationDialog,
    setOpenModifyRecurringAvailabilityConfirmationDialog,
  ] = useState(false);
  const [
    openDeleteRecurringAvailabilityConfirmationDialog,
    setOpenDeleteRecurringAvailabilityConfirmationDialog,
  ] = useState(false);
  const [
    openDeleteNonRecurringAvailabilityConfirmationDialog,
    setOpenDeleteNonRecurringAvailabilityConfirmationDialog,
  ] = useState(false);
  const [openModifyErrorAlert, setOpenModifyErrorAlert] = useState(false);
  const [openDeleteErrorAlert, setOpenDeleteErrorAlert] = useState(false);
  const [, jwtToken] = useSession();

  const modifyAvailability = async (availability, mode) => {
    let path;
    switch (mode) {
      case 3:
        path = `/commands/modify-all-availability-occurrences/${availabilityId}/${occurrenceId}`;
        break;
      case 2:
        path = `/commands/modify-this-and-following-availability-occurrences/${availabilityId}/${occurrenceId}`;
        break;
      case 1:
      default:
        path = `/commands/modify-one-availability-occurrence/${availabilityId}/${occurrenceId}`;
        break;
    }

    const { ok } = await fetch(
      new URL(path, process.env.REACT_APP_API_BASE_URL),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(availability),
      }
    );

    if (ok) {
      onModify();
    } else {
      setOpenModifyErrorAlert(true);
    }
  };

  const deleteAvailability = async (mode) => {
    let path;
    switch (mode) {
      case 3:
        path = `/commands/delete-all-availability-occurrences/${availabilityId}`;
        break;
      case 2:
        path = `/commands/delete-this-and-following-availability-occurrences/${availabilityId}/${occurrenceId}`;
        break;
      case 1:
      default:
        path = `/commands/delete-one-availability-occurrence/${availabilityId}/${occurrenceId}`;
        break;
    }

    const { ok } = await fetch(
      new URL(path, process.env.REACT_APP_API_BASE_URL),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );

    if (ok) {
      onDelete();
    } else {
      setOpenDeleteErrorAlert(true);
    }
  };

  return (
    <>
      <ModifyAvailabilityDialog
        open={modifying}
        availabilityId={availabilityId}
        occurrenceId={occurrenceId}
        onCancel={onCancel}
        onModify={async ({ isRecurring, ...rest }) => {
          if (isRecurring) {
            setModifiedAvailability({ ...rest });
            setOpenModifyRecurringAvailabilityConfirmationDialog(true);
          } else {
            await modifyAvailability({ ...rest });
          }
        }}
        onDelete={(isRecurring) => {
          if (isRecurring) {
            setOpenDeleteRecurringAvailabilityConfirmationDialog(true);
          } else {
            setOpenDeleteNonRecurringAvailabilityConfirmationDialog(true);
          }
        }}
      />
      <ModifyRecurringAvailabilityConfirmationDialog
        open={openModifyRecurringAvailabilityConfirmationDialog}
        onCancel={() =>
          setOpenModifyRecurringAvailabilityConfirmationDialog(false)
        }
        onModify={async (mode) => {
          await modifyAvailability(modifiedAvailability, mode);
          setOpenModifyRecurringAvailabilityConfirmationDialog(false);
        }}
      />
      <DeleteRecurringAvailabilityConfirmationDialog
        open={openDeleteRecurringAvailabilityConfirmationDialog}
        onCancel={() =>
          setOpenDeleteRecurringAvailabilityConfirmationDialog(false)
        }
        onDelete={async (mode) => {
          await deleteAvailability(mode);
          setOpenDeleteRecurringAvailabilityConfirmationDialog(false);
        }}
      />
      <DeleteNonRecurringAvailabilityConfirmationDialog
        open={openDeleteNonRecurringAvailabilityConfirmationDialog}
        onCancel={() =>
          setOpenDeleteNonRecurringAvailabilityConfirmationDialog(false)
        }
        onDelete={async () => {
          await deleteAvailability();
          setOpenDeleteNonRecurringAvailabilityConfirmationDialog(false);
        }}
      />
      <ErrorAlert
        open={openModifyErrorAlert}
        onClose={() => setOpenModifyErrorAlert(false)}
        content={
          <Trans
            i18nKey="calendar:availabilities.modifyAvailability.modifyErrorAlert"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
      <ErrorAlert
        open={openDeleteErrorAlert}
        onClose={() => setOpenDeleteErrorAlert(false)}
        content={
          <Trans
            i18nKey="calendar:availabilities.modifyAvailability.deleteErrorAlert"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
    </>
  );
};

ModifyAvailability.propTypes = {
  modifying: PropTypes.bool.isRequired,
  availabilityId: PropTypes.string,
  occurrenceId: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
};

export default ModifyAvailability;
