import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Link as MuiLink, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Reminder } from "@vesta/components/molecules";
import {
  useAppContext,
  useGlobalModalContext,
  useSession,
} from "@vesta/lib/react";

const TemplateReminder = ({ reminder, ...rest }) => {
  switch (reminder.templateId) {
    case 1:
      return <ModifyProfilePictureReminder reminder={reminder} {...rest} />;
    case 2:
      return <CreateFirstTerritoryReminder reminder={reminder} {...rest} />;
    case 3:
      return <CreateFirstServiceReminder reminder={reminder} {...rest} />;
    case 4:
      return <CreateFirstAvailabilityReminder reminder={reminder} {...rest} />;
    case 5:
      return <ChoosePlanReminder reminder={reminder} {...rest} />;
    case 6:
      return <BookSuccessSessionReminder reminder={reminder} {...rest} />;
    default:
      return null;
  }
};

TemplateReminder.propTypes = {
  reminder: PropTypes.shape({
    templateId: PropTypes.number.isRequired,
  }).isRequired,
};

export default TemplateReminder;

const StyledTitleTypography = styled(({ completed, ...rest }) => (
  <Typography
    sx={[
      (theme) => ({
        marginTop: theme.spacing(3 / 4),
      }),
      completed && { textDecoration: "line-through" },
    ]}
    {...rest}
  />
))({});

const ModifyProfilePictureReminder = ({ reminder, ...rest }) => {
  const { t } = useTranslation("components");

  return (
    <Reminder
      {...reminder}
      {...rest}
      template
      title={
        <StyledTitleTypography completed={reminder.completed}>
          {t("molecules.templateReminder.modifyProfilePictureReminder.title")}
        </StyledTitleTypography>
      }
      details={
        <Typography variant="caption">
          <Trans
            i18nKey="components:molecules.templateReminder.modifyProfilePictureReminder.details"
            components={{
              biographyLink: (
                <MuiLink
                  component={Link}
                  to="/profile/biography"
                  underline="always"
                />
              ),
            }}
          />
        </Typography>
      }
    />
  );
};

ModifyProfilePictureReminder.propTypes = {
  reminder: PropTypes.object.isRequired,
};

const CreateFirstTerritoryReminder = ({ reminder, ...rest }) => {
  const { t } = useTranslation("components");

  return (
    <Reminder
      {...reminder}
      {...rest}
      template
      title={
        <StyledTitleTypography completed={reminder.completed}>
          {t("molecules.templateReminder.createFirstTerritoryReminder.title")}
        </StyledTitleTypography>
      }
      details={
        <Typography variant="caption">
          <Trans
            i18nKey="components:molecules.templateReminder.createFirstTerritoryReminder.details"
            components={{
              territoriesLink: (
                <MuiLink
                  component={Link}
                  to="/profile/territories"
                  underline="always"
                />
              ),
            }}
          />
        </Typography>
      }
    />
  );
};

CreateFirstTerritoryReminder.propTypes = {
  reminder: PropTypes.object.isRequired,
};

const CreateFirstServiceReminder = ({ reminder, ...rest }) => {
  const { t } = useTranslation("components");

  return (
    <Reminder
      {...reminder}
      {...rest}
      template
      title={
        <StyledTitleTypography completed={reminder.completed}>
          {t("molecules.templateReminder.createFirstServiceReminder.title")}
        </StyledTitleTypography>
      }
      details={
        <Typography variant="caption">
          <Trans
            i18nKey="components:molecules.templateReminder.createFirstServiceReminder.details"
            components={{
              servicesLink: (
                <MuiLink
                  component={Link}
                  to="/profile/services"
                  underline="always"
                />
              ),
            }}
          />
        </Typography>
      }
    />
  );
};

CreateFirstServiceReminder.propTypes = {
  reminder: PropTypes.object.isRequired,
};

const CreateFirstAvailabilityReminder = ({ reminder, ...rest }) => {
  const { t } = useTranslation("components");

  return (
    <Reminder
      {...reminder}
      {...rest}
      template
      title={
        <StyledTitleTypography completed={reminder.completed}>
          {t(
            "molecules.templateReminder.createFirstAvailabilityReminder.title"
          )}
        </StyledTitleTypography>
      }
      details={
        <Typography variant="caption">
          <Trans
            i18nKey="components:molecules.templateReminder.createFirstAvailabilityReminder.details"
            components={{
              availabilitiesLink: (
                <MuiLink
                  component={Link}
                  to="/calendar/availabilities"
                  underline="always"
                />
              ),
            }}
          />
        </Typography>
      }
    />
  );
};

CreateFirstAvailabilityReminder.propTypes = {
  reminder: PropTypes.object.isRequired,
};

const ChoosePlanReminder = ({ reminder, ...rest }) => {
  const { t } = useTranslation("components");
  const { openChooseSubscriptionDialog } = useGlobalModalContext();
  const [appContext] = useAppContext();
  const [, jwtToken] = useSession();

  const handleClick = async () => {
    if (appContext.subscription?.hasSubscription) {
      await openCustomerPortal();
    } else {
      openChooseSubscriptionDialog();
    }
  };

  const openCustomerPortal = async () => {
    const response = await fetch(
      new URL(
        "/queries/get-customer-portal-link?" +
          new URLSearchParams({
            returnUrl: window.location.href,
          }),
        process.env.REACT_APP_API_BASE_URL
      ),
      {
        headers: { Authorization: `Bearer ${jwtToken}` },
      }
    );

    const url = await response.text();
    window.location.href = url;
  };

  return (
    <Reminder
      {...reminder}
      {...rest}
      template
      title={
        <StyledTitleTypography completed={reminder.completed}>
          {t("molecules.templateReminder.choosePlanReminder.title")}
        </StyledTitleTypography>
      }
      details={
        <Typography variant="caption">
          <Trans
            i18nKey="components:molecules.templateReminder.choosePlanReminder.details"
            components={{
              choosePlanLink: (
                <MuiLink
                  sx={{ cursor: "pointer" }}
                  onClick={handleClick}
                  underline="always"
                />
              ),
            }}
          />
        </Typography>
      }
    />
  );
};

ChoosePlanReminder.propTypes = {
  reminder: PropTypes.object.isRequired,
};

const BookSuccessSessionReminder = ({ reminder, ...rest }) => {
  const { t } = useTranslation("components");

  return (
    <Reminder
      {...reminder}
      {...rest}
      template
      title={
        <StyledTitleTypography completed={reminder.completed}>
          {t("molecules.templateReminder.bookSuccessSessionReminder.title")}
        </StyledTitleTypography>
      }
      details={
        <Typography variant="caption">
          <Trans
            i18nKey="components:molecules.templateReminder.bookSuccessSessionReminder.details"
            components={{
              bookingLink: (
                <MuiLink
                  href={process.env.REACT_APP_BOOK_SUCCESS_SESSION_LINK}
                  target="_blank"
                  underline="always"
                />
              ),
            }}
          />
        </Typography>
      }
    />
  );
};

BookSuccessSessionReminder.propTypes = {
  reminder: PropTypes.object.isRequired,
};
