import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Avatar, Card, CardHeader } from "@mui/material";

const OriginCard = ({ sx, subheader, action }) => {
  const { t } = useTranslation("appointment");

  return (
    <Card sx={sx}>
      <CardHeader
        avatar={
          <Avatar
            sx={(theme) => ({ bgcolor: theme.palette.error.main })}
            alt={t("directions.originCard.title")}
          >
            A
          </Avatar>
        }
        title={t("directions.originCard.title")}
        subheader={subheader}
        action={action}
      />
    </Card>
  );
};

OriginCard.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  subheader: PropTypes.node,
  action: PropTypes.node.isRequired,
};

export default OriginCard;
