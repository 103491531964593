import PropTypes from "prop-types";

import { Box, SwipeableDrawer } from "@mui/material";

import { Logo, LogoVariant } from "@vesta/components/atoms";
import { useUpMd } from "@vesta/lib/react";

import { MainSidebarNavigation } from "./components";

const MainSidebar = ({ isOpen, onClose, onOpen, subLayout }) => {
  const upMd = useUpMd();

  if (upMd && !subLayout) {
    return null;
  }

  return (
    <Box
      sx={(theme) => ({
        flexShrink: 0,
        maxWidth: theme.spacing(45),
        [theme.breakpoints.up("md")]: {
          width: theme.spacing(30),
        },
      })}
    >
      <SwipeableDrawer
        variant={upMd ? "permanent" : "temporary"}
        anchor="left"
        open={upMd ? true : isOpen}
        onClose={upMd ? () => {} : onClose}
        onOpen={upMd ? () => {} : onOpen}
        ModalProps={{
          keepMounted: upMd ? true : false, // Better open performance on mobile.
        }}
        PaperProps={{
          sx: (theme) => ({
            width: "85%",
            maxWidth: theme.spacing(45),
            [theme.breakpoints.up("md")]: {
              width: theme.spacing(30),
              zIndex: theme.zIndex.appBar - 1,
            },
          }),
        }}
      >
        <Box
          sx={(theme) => ({
            ...theme.mixins.toolbar, // Moving the content below the sidebar
            display: "flex",
            justifyContent: "center",
            maxWidth: theme.spacing(45),
          })}
        >
          {!upMd && (
            <Box sx={{ alignSelf: "center" }} onClick={onClose}>
              <Logo
                sx={(theme) => ({ height: theme.spacing(5.5) })}
                variant={LogoVariant.WideRight}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!upMd && <MainSidebarNavigation onClick={onClose} />}
          {subLayout}
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};

MainSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  subLayout: PropTypes.element,
};

export default MainSidebar;
