import { fr } from "date-fns/locale";
import { format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  Chip,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  useMedicalRecord,
  useScrollToTopOnMount,
  useUpSm,
} from "@vesta/lib/react";

const StyledSortLabelSpan = styled("span")({
  border: 0,
  clip: "rect(0 0 0 0)",
  height: 1,
  margin: -1,
  overflow: "hidden",
  padding: 0,
  position: "absolute",
  top: 20,
  width: 1,
});

const Appointments = () => {
  const upSm = useUpSm();
  const { t } = useTranslation("patient");
  const [medicalRecord] = useMedicalRecord();
  const [sort, setSort] = useState({
    sortByStart: true,
    isDescending: true,
  });

  useScrollToTopOnMount();

  if (!medicalRecord) {
    return <Skeleton variant="rectangular" height={64} />;
  }

  const sortDirection = sort.isDescending ? "desc" : "asc";

  const handleSortByStart = () =>
    setSort({
      sortByStart: true,
      isDescending: sort.sortByStart ? !sort.isDescending : true,
    });

  const handleSortByCancelled = () =>
    setSort({
      sortByCancelled: true,
      isDescending: sort.sortByCancelled ? !sort.isDescending : true,
    });

  const sortLabel = (
    <StyledSortLabelSpan>
      {sort.isDescending
        ? t("appointments.head.sortOrder.descending")
        : t("appointments.head.sortOrder.ascending")}
    </StyledSortLabelSpan>
  );

  const sortedAppointments = medicalRecord.medicalInformation.appointments.sort(
    (a, b) =>
      sort.sortByStart
        ? sort.isDescending
          ? b.start - a.start
          : a.start - b.start
        : sort.sortByCancelled
        ? sort.isDescending
          ? b.cancelled - a.cancelled
          : a.cancelled - b.cancelled
        : 0
  );

  return (
    <TableContainer component={Paper}>
      <Table aria-label={t("appointments.label")} component="div">
        <TableHead component="div">
          <TableRow component="div">
            <TableCell
              component="div"
              sortDirection={sort.sortByStart ? sortDirection : false}
            >
              <TableSortLabel
                active={sort.sortByStart}
                direction={sortDirection}
                onClick={handleSortByStart}
              >
                {t("appointments.head.start")}
                {sort.sortByStart && sortLabel}
              </TableSortLabel>
            </TableCell>
            <TableCell component="div">
              {t("appointments.head.services")}
            </TableCell>
            {upSm && (
              <TableCell
                align="right"
                component="div"
                sortDirection={sort.sortByCancelled ? sortDirection : false}
              >
                <TableSortLabel
                  active={sort.sortByCancelled}
                  direction={sortDirection}
                  onClick={handleSortByCancelled}
                >
                  {t("appointments.head.cancelled")}
                  {sort.sortByCancelled && sortLabel}
                </TableSortLabel>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody component="div">
          {sortedAppointments.map((x) => {
            const date = format(x.start, "PPP", {
              locale: fr,
            });
            const startTime = format(x.start, "p", {
              locale: fr,
            });
            const endTime = format(x.end, "p", {
              locale: fr,
            });

            return (
              <TableRow
                key={x.appointmentId}
                sx={{ textDecoration: "none" }}
                hover
                component={Link}
                to={`/calendar/appointments/${x.appointmentId}/preparation`}
              >
                <TableCell component="div">
                  <Typography variant="body2">
                    {t("appointments.body.date", {
                      date,
                    })}
                    <Typography
                      component="span"
                      display="block"
                      variant="caption"
                      color="textSecondary"
                    >
                      {t("appointments.body.time", {
                        startTime,
                        endTime,
                      })}
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell component="div">
                  {x.services.length > 0
                    ? t("appointments.body.services", {
                        count: x.services.length,
                        firstServiceName: x.services[0].nameFr,
                        delta: x.services.length - 1,
                        interpolation: { escapeValue: false },
                      })
                    : t("appointments.body.services", {
                        count: 0,
                      })}
                </TableCell>
                {upSm && (
                  <TableCell align="right" component="div">
                    {x.cancelled && (
                      <Chip
                        sx={(theme) => ({
                          backgroundColor: theme.palette.error.dark,
                          color: theme.palette.getContrastText(
                            theme.palette.error.dark
                          ),
                        })}
                        label={t("appointments.body.cancelledLabel")}
                        size="small"
                      />
                    )}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Appointments;
