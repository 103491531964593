import PropTypes from "prop-types";

import { IconButton } from "@mui/material";

import { AccountAvatar } from "@vesta/components/molecules";

const SecondarySidebarTrigger = ({ onClick }) => {
  return (
    <>
      <IconButton
        aria-controls={open ? "account-sidebar-menu-items" : undefined}
        edge="end"
        onClick={onClick}
        size="large"
      >
        <AccountAvatar />
      </IconButton>
    </>
  );
};

SecondarySidebarTrigger.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SecondarySidebarTrigger;
