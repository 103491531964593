import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { useServiceLanguageTranslation } from "@vesta/lib/react";
import { serviceLanguageIds } from "@vesta/lib/utils";

const ServiceLanguageIdSelect = ({
  id,
  className,
  readOnly,
  label,
  value,
  excludedValues,
  onChange,
  error,
  helperText,
  ...rest
}) => {
  const serviceLanguageTranslation = useServiceLanguageTranslation();
  const { t } = useTranslation("components");

  return (
    <FormControl
      variant="standard"
      className={className}
      disabled={readOnly}
      fullWidth
      error={error}
      {...rest}
    >
      <InputLabel id="serviceLanguageIdLabel" shrink>
        {label ? label : t("molecules.serviceLanguageIdSelect.label")}
      </InputLabel>
      <Select
        variant="standard"
        id={id}
        labelId="serviceLanguageIdLabel"
        value={value}
        onChange={onChange}
        inputProps={{
          readOnly,
        }}
      >
        {serviceLanguageIds.map((id) => (
          <MenuItem key={id} disabled={excludedValues.includes(id)} value={id}>
            {serviceLanguageTranslation[id]}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

ServiceLanguageIdSelect.defaultProps = {
  excludedValues: [],
};

ServiceLanguageIdSelect.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  excludedValues: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default ServiceLanguageIdSelect;
