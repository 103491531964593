import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";

import { LoadingButton } from "@vesta/components/atoms";
import { Dialog } from "@vesta/components/molecules";
import { useAppointment, useUpSm } from "@vesta/lib/react";

const CancelYesNoDialog = ({ open, onConfirm, onCancel }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("appointment");
  const [appointment] = useAppointment();

  const patientFirstName = appointment?.isAppointmentForSomeoneElse
    ? appointment?.someoneElse.firstName
    : appointment?.patientFirstName;

  return (
    <Dialog
      fullScreen={false}
      maxWidth="md"
      open={open}
      onClose={onCancel}
      title={t("preparation.cancelYesNoDialog.title")}
      text={t("preparation.cancelYesNoDialog.subtitle", {
        patientFirstName,
      })}
      actions={
        <>
          <Button
            disabled={appointment?.cancelling}
            onClick={onCancel}
            size="large"
            variant="outlined"
          >
            {t("preparation.cancelYesNoDialog.noLabel")}
          </Button>
          <LoadingButton
            loading={appointment?.cancelling}
            disabled={appointment?.cancelling}
            onClick={onConfirm}
            size="large"
            variant="contained"
          >
            {upSm
              ? t("preparation.cancelYesNoDialog.yesLabel")
              : t("preparation.cancelYesNoDialog.yesLabelShort")}
          </LoadingButton>
        </>
      }
    />
  );
};

CancelYesNoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CancelYesNoDialog;
