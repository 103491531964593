import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { TextField } from "@mui/material";

const PermitNumberTextField = ({ sx, readOnly, ...rest }) => {
  const { t } = useTranslation("components");

  return (
    <TextField
      sx={sx}
      variant="standard"
      fullWidth
      placeholder={t("molecules.permitNumberTextField.placeholder")}
      InputProps={{
        readOnly,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      {...rest}
    />
  );
};

PermitNumberTextField.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  readOnly: PropTypes.bool,
};

export default PermitNumberTextField;
