import { useTranslation } from "react-i18next";

import { useSession } from "@vesta/lib/react";

import { AccountMenuAlert } from "../";

const AccountMenuPaymentRequiredAlert = () => {
  const { t } = useTranslation("components");
  const [, jwtToken] = useSession();

  const openCustomerPortal = async () => {
    const response = await fetch(
      new URL(
        "/queries/get-customer-portal-link?" +
          new URLSearchParams({
            returnUrl: window.location.href,
          }),
        process.env.REACT_APP_API_BASE_URL
      ),
      {
        headers: { Authorization: `Bearer ${jwtToken}` },
      }
    );

    const url = await response.text();
    window.location.href = url;
  };

  return (
    <AccountMenuAlert
      severity={"error"}
      title={t("molecules.accountMenu.accountMenuPaymentRequiredAlert.title")}
      description={t(
        "molecules.accountMenu.accountMenuPaymentRequiredAlert.description"
      )}
      action={{
        label: t(
          "molecules.accountMenu.accountMenuPaymentRequiredAlert.ctaLabel"
        ),
        func: openCustomerPortal,
      }}
    />
  );
};

export default AccountMenuPaymentRequiredAlert;
