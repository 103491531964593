import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import {
  useAuthorizedFetchJson,
  useInterval,
  usePageVisibility,
} from "@vesta/lib/react";

export const useTerritory = (jwtToken, searchParams) => {
  const isVisible = usePageVisibility();
  const { territoryId } = useParams();
  const [territory, setTerritory] = useState();
  const [fetchJson, json] = useAuthorizedFetchJson(jwtToken);

  const getTerritory = useCallback(
    () =>
      fetchJson(
        new URL(
          `/queries/get-territory-by-territory-id/${territoryId}`,
          process.env.REACT_APP_API_BASE_URL
        )
      ),
    [territoryId]
  );

  useEffect(() => {
    getTerritory();
  }, []);

  const added = searchParams.has("added");

  const refreshTerritory = useCallback(async () => {
    if (territory) {
      return;
    }

    if (added) {
      await getTerritory();
    }
  }, [territory, added, getTerritory]);

  useInterval(refreshTerritory, isVisible ? 2000 : null);

  useEffect(() => {
    if (json) {
      setTerritory(json);
    }
  }, [json]);

  return [territory, setTerritory];
};
