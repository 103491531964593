import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import {
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction,
} from "@mui/material";

const BottomNavigation = ({ sx, value, onChange, actions }) => (
  <MuiBottomNavigation
    sx={sx}
    showLabels
    value={value}
    onChange={onChange}
    onClick={() =>
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }
  >
    {actions.map((x) => (
      <BottomNavigationAction
        key={x.value}
        sx={(theme) => ({ paddingX: theme.spacing(1) })}
        component={Link}
        to={x.to}
        icon={x.icon}
        label={x.label}
        value={x.value}
      />
    ))}
  </MuiBottomNavigation>
);

BottomNavigation.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
    })
  ).isRequired,
};

export default BottomNavigation;
