import { fr } from "date-fns/locale";
import { format, getDay, parse, startOfWeek } from "date-fns";
import PropTypes from "prop-types";
import { useState } from "react";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";

import { Box, Drawer } from "@mui/material";

import { CalendarHeader, StaticDatePicker } from "@vesta/components/atoms";
import { CalendarToolbar } from "@vesta/components/molecules";

import { TitleButton } from "./components";
import { useSwipeableHandlers, useView } from "./hooks";

const locales = {
  fr,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const rootSx = (theme) => {
  const toolbarTop = theme.mixins.toolbar.minHeight;
  const toolbarTop1 =
    theme.mixins.toolbar[theme.breakpoints.up("xs")][
      "@media (orientation: landscape)"
    ].minHeight;
  const toolbarTop2 =
    theme.mixins.toolbar[theme.breakpoints.up("sm")].minHeight;

  return {
    "& .rbc-time-view": {
      border: "none",
      borderLeft: "1px solid #ddd",
      borderRight: "1px solid #ddd",
    },
    "& .rbc-time-header": {
      backgroundColor: theme.palette.background.paper,
      borderTop: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
      position: "sticky",
      zIndex: 4,
      top: toolbarTop + toolbarTop,
      [theme.breakpoints.up("xs")]: {
        ["@media (orientation: landscape)"]: {
          top: toolbarTop1 + toolbarTop1,
        },
      },
      [theme.breakpoints.up("sm")]: {
        top: toolbarTop2 + toolbarTop2,
      },
    },
    "& .rbc-header": {
      borderBottom: "none",
    },
    "& .rbc-time-content": {
      borderTop: "none",
    },
    "& .rbc-allday-cell": {
      display: "none",
    },
  };
};

const calendarToolbarSx = (theme) => {
  const toolbarTop = theme.mixins.toolbar.minHeight;
  const toolbarTop1 =
    theme.mixins.toolbar[theme.breakpoints.up("xs")][
      "@media (orientation: landscape)"
    ].minHeight;
  const toolbarTop2 =
    theme.mixins.toolbar[theme.breakpoints.up("sm")].minHeight;

  return {
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    zIndex: 5,
    height: toolbarTop,
    top: toolbarTop,
    [theme.breakpoints.up("xs")]: {
      ["@media (orientation: landscape)"]: {
        height: toolbarTop1,
        top: toolbarTop1,
      },
    },
    [theme.breakpoints.up("sm")]: {
      height: toolbarTop2,
      top: toolbarTop2,
    },
  };
};

const StyledCalendar = ({
  sx,
  loading,
  date,
  onNavigate,
  components,
  ...rest
}) => {
  const [view, setView] = useView();
  const [openDrawer, setOpenDrawer] = useState(false);

  const handlers = useSwipeableHandlers(date, onNavigate, view);

  const handleCloseDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(false);
  };

  const handleChangeDate = (date) => {
    onNavigate(date);
    setOpenDrawer(false);
  };

  return (
    <Box sx={[rootSx, ...(Array.isArray(sx) ? sx : [sx])]} {...handlers}>
      <Calendar
        culture="fr"
        localizer={localizer}
        date={date}
        onNavigate={onNavigate}
        defaultView={Views.DAY}
        view={view}
        views={[Views.DAY, Views.WEEK]}
        onView={setView}
        endAccessor={({ end }) => new Date(end.getTime() - 1)} // https://github.com/jquense/react-big-calendar/issues/363
        formats={{
          eventTimeRangeFormat: () => null, // https://github.com/jquense/react-big-calendar/issues/133
        }}
        slotPropGetter={() => ({})}
        components={{
          header: (props) => <CalendarHeader {...props} />,
          timeSlotWrapper: (props) => (
            <Box sx={(theme) => ({ ...theme.typography.caption })} {...props} />
          ),
          toolbar: (props) => (
            <CalendarToolbar
              sx={calendarToolbarSx}
              title={
                <TitleButton
                  date={date}
                  view={view}
                  loading={loading}
                  onClick={() => setOpenDrawer(true)}
                />
              }
              {...props}
            />
          ),
          ...components,
        }}
        {...rest}
      />
      <Drawer anchor="right" open={openDrawer} onClose={handleCloseDrawer}>
        <StaticDatePicker
          value={date}
          onChange={handleChangeDate}
          views={["day"]}
          componentsProps={{
            actionBar: {
              actions: ["today"],
            },
          }}
        />
      </Drawer>
    </Box>
  );
};

StyledCalendar.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  loading: PropTypes.bool,
  date: PropTypes.instanceOf(Date).isRequired,
  onNavigate: PropTypes.func.isRequired,
  components: PropTypes.object.isRequired,
};

export default StyledCalendar;
