import { fr } from "date-fns/locale";
import { differenceInYears, format } from "date-fns";
import { useTranslation } from "react-i18next";

import { Container, Skeleton, Typography } from "@mui/material";

import { useMedicalRecord } from "@vesta/lib/react";

import { useTitle } from "./hooks";

const containerSx = (theme) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: theme.palette.secondary.dark,
  color: theme.palette.getContrastText(theme.palette.secondary.dark),
  zIndex: theme.zIndex.drawer - 1,
  minHeight: theme.spacing(16),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    minHeight: theme.spacing(18),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
});

const PatientHeader = () => {
  const { t } = useTranslation("patient");
  const [medicalRecord] = useMedicalRecord();

  const title = useTitle(t, medicalRecord);

  if (!medicalRecord) {
    return (
      <Container sx={containerSx} maxWidth={false}>
        <Typography component="h1" variant="h3">
          <Skeleton width="60%" />
        </Typography>
        <Typography variant="body1">
          <Skeleton width="40%" />
        </Typography>
      </Container>
    );
  }

  return (
    <Container sx={containerSx} maxWidth={false}>
      <Typography component="h1" variant="h3">
        {title}
      </Typography>
      <Typography variant="body1">
        {t("patientHeader.subtitle", {
          birthday: format(medicalRecord.personalInformation.birthday, "PPP", {
            locale: fr,
          }),
          count: differenceInYears(
            new Date(),
            medicalRecord.personalInformation.birthday
          ),
        })}
      </Typography>
    </Container>
  );
};

export default PatientHeader;
