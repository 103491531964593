import { fr } from "date-fns/locale";
import {
  addDays,
  eachMinuteOfInterval,
  format,
  isSameDay,
  startOfDay,
  subMilliseconds,
} from "date-fns";
import PropTypes from "prop-types";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const getAvailableDatesFrom = (fromValue) =>
  eachMinuteOfInterval(
    {
      start: fromValue,
      end: startOfDay(addDays(fromValue, 1)),
    },
    { step: 15 }
  )
    .filter((x) => isSameDay(fromValue, subMilliseconds(x, 1)))
    .slice(1);

const getAvailableDates = (value) =>
  eachMinuteOfInterval(
    {
      start: startOfDay(value),
      end: startOfDay(addDays(value, 1)),
    },
    { step: 15 }
  ).slice(0, -1);

const TimeSelect = ({ sx, id, label, fromValue, value, onChange }) => (
  <FormControl variant="standard" sx={sx}>
    <InputLabel id={`${id}-label`} shrink>
      {label}
    </InputLabel>
    <Select
      variant="standard"
      id={id}
      labelId={`${id}-label`}
      value={value.getTime()}
      renderValue={(value) => format(new Date(value), "p", { locale: fr })}
      onChange={(event) => onChange(new Date(event.target.value))}
    >
      {fromValue
        ? getAvailableDatesFrom(fromValue).map((date, i) => {
            const difference = date - fromValue;
            const differenceHours = Math.floor(
              (difference % 86400000) / 3600000
            );
            const differenceMinutes = Math.round(
              ((difference % 86400000) % 3600000) / 60000
            );

            const duration =
              differenceHours === 0 && differenceMinutes === 0
                ? "(24h)"
                : `(${
                    differenceHours > 0
                      ? differenceMinutes > 0
                        ? `${differenceHours}h${differenceMinutes}m`
                        : `${differenceHours}h`
                      : `${differenceMinutes}m`
                  })`;

            return (
              <MenuItem key={`${id}-menu-item-${i}`} value={date.getTime()}>
                <Typography
                  sx={(theme) => ({
                    marginRight: theme.spacing(1),
                  })}
                >
                  {`${format(date, "p", { locale: fr })}`}
                </Typography>
                <Typography color="textSecondary">{duration}</Typography>
              </MenuItem>
            );
          })
        : getAvailableDates(value).map((date, i) => (
            <MenuItem key={`${id}-menu-item-${i}`} value={date.getTime()}>
              {format(date, "p", { locale: fr })}
            </MenuItem>
          ))}
    </Select>
  </FormControl>
);

TimeSelect.propTypes = {
  id: PropTypes.string.isRequired,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  label: PropTypes.string,
  fromValue: PropTypes.instanceOf(Date),
  value: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TimeSelect;
