import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";

import { LoadingButton } from "@vesta/components/atoms";
import { Dialog } from "@vesta/components/molecules";
import { useIsMounted, useUpSm } from "@vesta/lib/react";

const RejectAndDeleteServiceDialog = ({ open, onDelete, onCancel }) => {
  const upSm = useUpSm();
  const isMounted = useIsMounted();
  const { t } = useTranslation("service");
  const [deleting, setDeleting] = useState(false);

  const handleClick = async () => {
    setDeleting(true);
    await onDelete();
    if (isMounted()) {
      setDeleting(false);
    }
  };

  return (
    <Dialog
      fullScreen={false}
      maxWidth="md"
      open={open}
      onClose={onCancel}
      title={t("rejectAndDeleteServiceDialog.title")}
      actions={
        <>
          <Button disabled={deleting} onClick={onCancel} size="large">
            {t("rejectAndDeleteServiceDialog.cancelLabel")}
          </Button>
          <LoadingButton
            sx={(theme) => ({
              backgroundColor: theme.palette.error.main,
              "&:hover": {
                backgroundColor: theme.palette.error.dark,
              },
            })}
            loading={deleting}
            disabled={deleting}
            onClick={handleClick}
            size="large"
            variant="contained"
          >
            {upSm
              ? t("rejectAndDeleteServiceDialog.deleteLabel")
              : t("rejectAndDeleteServiceDialog.deleteLabelShort")}
          </LoadingButton>
        </>
      }
    />
  );
};

RejectAndDeleteServiceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default RejectAndDeleteServiceDialog;
