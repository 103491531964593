import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";

import { Container, Typography } from "@mui/material";

import { ContactUsLink } from "@vesta/components/atoms";

const ErrorView = () => {
  const { t } = useTranslation("error");

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>{t("title")}</title>
        <meta property="og:title" content={t("title")} />
        <meta property="og:description" content={t("description")} />
      </Helmet>
      <Typography align="center" variant="h1">
        {t("body.title")}
      </Typography>
      <Typography align="center">
        <Trans
          i18nKey="error:body.description"
          components={{
            divider: (
              <>
                <br />
                <br />
              </>
            ),
            contactUsLink: <ContactUsLink color="primary" variant="body1" />,
          }}
        />
      </Typography>
    </Container>
  );
};

export default ErrorView;
