import { Box } from "@mui/material";

import { DelayedCircularProgress } from "@vesta/components/atoms";

const Loader = () => (
  <Box
    sx={{
      display: "flex",
      height: "100vh",
    }}
  >
    <DelayedCircularProgress sx={{ margin: "auto" }} />
  </Box>
);

export default Loader;
