import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { TextField } from "@mui/material";

import { useUpMd } from "@vesta/lib/react";

const ApartmentUnitSuiteOrFloorNbTextField = ({
  className,
  readOnly,
  InputProps,
  InputLabelProps,
  ...rest
}) => {
  const upMd = useUpMd();
  const { t } = useTranslation("components");

  return (
    <TextField
      variant="standard"
      autoComplete="address-line2"
      className={className}
      fullWidth
      label={
        upMd
          ? t("molecules.apartmentUnitSuiteOrFloorNbTextField.label")
          : t("molecules.apartmentUnitSuiteOrFloorNbTextField.shortLabel")
      }
      InputProps={{
        ...InputProps,
        readOnly,
      }}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
      }}
      {...rest}
    />
  );
};

ApartmentUnitSuiteOrFloorNbTextField.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  InputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
};

export default ApartmentUnitSuiteOrFloorNbTextField;
