export const getIconName = (serviceTemplateId) => {
  switch (serviceTemplateId) {
    case "987b6e81-76bc-463e-947b-848ab31f682d":
      return "baby";
    case "281b4a5d-fa2c-4738-88f0-80c1167dd265":
      return "female";
    case "f0756164-285a-44e9-a402-c0243f78714b":
      return "venus-mars";
    case "3601143c-fd55-4c31-80e0-28a5230c117d":
      return "school";
    case "d8936a0c-10fe-4cef-b15f-623cad57ac14":
      return "user-md";
    case "17b898d3-be01-4139-9951-126e3018b66b":
      return "user-md";
    case "702bfdab-3337-4b9e-94ad-6b707a4991a0":
      return "user-md";
    case "ec917ce2-0884-4746-aead-674b9ffefa7f":
      return "weight";
    case "20528542-5223-4b67-9b26-2c9aff13feea":
      return "syringe";
    case "1225b371-abd5-40ca-af1d-1302b850098a":
      return "deaf";
    case "3192fad6-d45d-47e0-bb0e-b954f0078f02":
      return "tint";
    case "f04b6f52-a81c-4f1b-b4c8-c223841b52e3":
      return "cut";
    case "acaf36b0-894e-4278-912d-07cde0f4491f":
      return "tenge";
    case "6e01c724-e3d5-4112-8a99-85c351d13889":
      return "bed";
    case "c14c4c80-359d-4656-b594-43b35a07b23a":
      return "shoe-prints";
    case "3cced61f-f381-42fa-8979-a33096d1c958":
      return "band-aid";
    case "baef50ce-2feb-42a5-b112-4583151061dd":
      return "smoking-ban";
    case "d6ede38d-46ed-4ba4-85ba-1c9d7db83b6d":
      return "brain";
    case "c5df901b-265b-46aa-ab53-50456a6b4aef":
      return "baby-carriage";
    case "7c3525e5-de1f-44a3-ba1e-ac50a8b242c1":
      return "clock";
    case "92ba00b2-4322-4aef-9e9f-6ffc85a8e8b1":
      return "bacteria";
    case "f45ce087-e968-43dd-b984-9ce746413163":
      return "thermometer";
    case "feee2a71-5b46-4e9a-b373-d10268c0dd4c":
      return "brain";
    case "0e3d4938-9d7c-4383-8be9-9033f35e9086":
      return "venus";
    case "12ac3252-6c5d-4f34-a478-22a0c5bde695":
      return "virus";
    case "e1f8d7ff-146d-4661-80b2-8c8d2b455610":
      return "viruses";
    case "3050f39a-a58d-4f07-a739-98738f040eb5":
      return "plane";
    case "67f5a6ef-a60e-467a-a8de-a5158a676346":
      return "head-side-mask";
    case "c2eb5322-93c4-4278-ad40-ff31066e2f51":
      return "user-md";
    case "69dc9be5-dc59-403a-9962-b9e7188cd6e7":
      return "thermometer";
    case "364979cd-f75d-42ca-bf87-5b78a34ffc11":
      return "child";
    case "17ffc7ec-4ef8-425e-aa2c-071eb2bf4079":
      return "female";
    case "a77e2cb1-6cfc-4c07-817a-bed0ae00b741":
      return "box-tissue";
    default:
      return "question";
  }
};
