import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import * as yup from "yup";

import { Button, Grid, IconButton } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

import {
  ServiceLanguageIdSelect,
  TitledFormPaper,
} from "@vesta/components/molecules";
import { useUpSm } from "@vesta/lib/react";
import { serviceLanguageIds } from "@vesta/lib/utils";

import { TipAlert } from "./components";

const nullServiceLanguageId = "";

const ServiceLanguages = ({ loading, biography, onSave }) => {
  const upSm = useUpSm();
  const { t } = useTranslation("profile");
  const [editing, setEditing] = useState(false);

  const validationSchema = yup.object().shape({
    serviceLanguageIds: yup.array().of(yup.number()),
  });

  const { values, isSubmitting, handleSubmit, setFieldValue, resetForm } =
    useFormik({
      initialValues: {
        serviceLanguageIds: [],
      },
      validationSchema,
      onSubmit: async ({ serviceLanguageIds }) => {
        const ok = await onSave({
          serviceLanguageIds: serviceLanguageIds.filter(
            (x) => x !== nullServiceLanguageId
          ),
        });
        if (ok) {
          setEditing(false);
        }
      },
    });

  useEffect(() => {
    if (!editing && biography) {
      setFieldValue("serviceLanguageIds", biography.serviceLanguageIds);
    }
  }, [editing, biography]);

  const handleCancel = () => {
    resetForm();
    setFieldValue("serviceLanguageIds", biography.serviceLanguageIds);
    setEditing(false);
  };

  const handleChange = (i) => (event) =>
    setFieldValue(
      "serviceLanguageIds",
      values.serviceLanguageIds.map((x, j) =>
        j === i ? event.target.value : x
      )
    );

  const handleDelete = (i) => () =>
    setFieldValue(
      "serviceLanguageIds",
      values.serviceLanguageIds.filter((_, j) => j !== i)
    );

  const handleAdd = () =>
    setFieldValue("serviceLanguageIds", [
      ...values.serviceLanguageIds,
      nullServiceLanguageId,
    ]);

  return (
    <TitledFormPaper
      title={t("biography.serviceLanguages.title")}
      loading={loading}
      editing={editing}
      saving={isSubmitting}
      onEdit={() => setEditing(true)}
      onCancel={handleCancel}
      onSave={handleSubmit}
    >
      <Grid container direction="row-reverse" spacing={upSm ? 3 : 2}>
        <Grid item xs={12} md={6} lg={7}>
          <TipAlert />
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <Grid container direction="column" spacing={upSm ? 3 : 2}>
            {values.serviceLanguageIds.map((x, i) => {
              const excludedValues = values.serviceLanguageIds.filter(
                (y) => y !== x
              );

              const isLastSelect = i === values.serviceLanguageIds.length - 1;
              const notEveryServiceLanguageIdsSelected =
                values.serviceLanguageIds.length !== serviceLanguageIds.length;

              const isNotFirstSelect = i !== 0;

              return (
                <Grid
                  key={i}
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                  item
                >
                  <ServiceLanguageIdSelect
                    id={`serviceLanguageId-${i}`}
                    readOnly={!editing}
                    label={t(`biography.serviceLanguages.label.${i + 1}`)}
                    value={x}
                    excludedValues={excludedValues}
                    onChange={handleChange(i)}
                  />
                  {editing && (
                    <>
                      {isLastSelect &&
                        notEveryServiceLanguageIdsSelected &&
                        (upSm ? (
                          <Button
                            sx={(theme) => ({
                              marginLeft: theme.spacing(2),
                              marginRight: theme.spacing(1),
                            })}
                            onClick={() => handleAdd()}
                            startIcon={<Add color="primary" />}
                          >
                            {t("biography.serviceLanguages.addAriaLabel")}
                          </Button>
                        ) : (
                          <IconButton
                            edge="end"
                            aria-label={t(
                              "biography.serviceLanguages.addAriaLabel"
                            )}
                            onClick={() => handleAdd()}
                            size="large"
                          >
                            <Add color="primary" fontSize="small" />
                          </IconButton>
                        ))}
                      {isNotFirstSelect && (
                        <IconButton
                          edge="end"
                          aria-label={t(
                            "biography.serviceLanguages.deleteAriaLabel"
                          )}
                          onClick={handleDelete(i)}
                          size="large"
                        >
                          <Delete color="primary" fontSize="small" />
                        </IconButton>
                      )}
                    </>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </TitledFormPaper>
  );
};

ServiceLanguages.propTypes = {
  loading: PropTypes.bool,
  biography: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};

export default ServiceLanguages;
