import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { Add, Clear } from "@mui/icons-material";

import { LoadingButton } from "@vesta/components/atoms";
import { useUpSm } from "@vesta/lib/react";

const AddReminder = ({ onAdd }) => {
  const inputRef = useRef(null);
  const upSm = useUpSm();
  const { t } = useTranslation("components");

  const validationSchema = yup.object().shape({
    title: yup.string(),
  });

  const { values, isSubmitting, handleSubmit, handleChange, setFieldValue } =
    useFormik({
      initialValues: {
        title: "",
      },
      validationSchema,
      onSubmit: async ({ title }, { resetForm }) => {
        await onAdd({ title });
        resetForm();
      },
    });

  const handleClearClick = () => {
    setFieldValue("title", "");
    inputRef.current.focus();
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(2),
      })}
      component="form"
      onSubmit={handleSubmit}
    >
      <TextField
        sx={(theme) => ({
          [theme.breakpoints.up("sm")]: {
            marginRight: theme.spacing(2),
          },
        })}
        name="title"
        fullWidth
        placeholder={t("molecules.addReminder.placeholder")}
        value={values.title}
        onChange={handleChange}
        size="small"
        variant="outlined"
        inputProps={{
          ref: inputRef,
        }}
        InputProps={{
          endAdornment: values.title ? (
            <InputAdornment position="end">
              <IconButton
                aria-label={t("molecules.addReminder.clear")}
                onClick={handleClearClick}
                edge="end"
                size="small"
              >
                <Clear color="action" />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
      {upSm ? (
        <LoadingButton
          type="submit"
          disabled={isSubmitting}
          loading={isSubmitting}
          variant="contained"
        >
          {t("molecules.addReminder.add")}
        </LoadingButton>
      ) : (
        <IconButton
          type="submit"
          disabled={isSubmitting}
          aria-label={t("molecules.addReminder.add")}
          edge="end"
          size="large"
        >
          <Add />
        </IconButton>
      )}
    </Box>
  );
};

AddReminder.propTypes = {
  onAdd: PropTypes.func.isRequired,
};

export default AddReminder;
