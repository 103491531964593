import PropTypes from "prop-types";

import { Alert, Snackbar } from "@mui/material";

const SuccessAlert = ({ open, onClose, content }) => {
  const handleClose = (_, reason) => {
    if (reason !== "clickaway") {
      onClose();
    }
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert
        elevation={6}
        variant="filled"
        severity="success"
        onClose={handleClose}
      >
        {content}
      </Alert>
    </Snackbar>
  );
};

SuccessAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.node.isRequired,
};

export default SuccessAlert;
