import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Box, Icon } from "@mui/material";

import {
  ContactUsLink,
  ErrorAlert,
  SuccessAlert,
} from "@vesta/components/atoms";
import { SecondaryHeader } from "@vesta/components/molecules";
import {
  agreementStatus,
  agreementType,
  healthInsuranceIssuingAuthority,
} from "@vesta/lib/enum";
import { getIconName } from "@vesta/lib/fontAwesome";
import {
  useAppContext,
  useFontAwesome,
  useScrollToTopOnMount,
  useSession,
} from "@vesta/lib/react";

import {
  Body,
  CollectivePrescriptionToTreatAbnormalVaginalDischargeAgreement,
  CollectivePrescriptionToTreatEarInfectionAgreement,
  CollectivePrescriptionToTreatGonococcalAndChlamydiaTrachomatisInfectionsAgreement,
  CollectivePrescriptionToTreatSinusitisAgreement,
  CollectivePrescriptionToTreatStreptococcusAgreement,
  CollectivePrescriptionToTreatUrinaryTractInfectionAgreement,
  DeleteServiceDialog,
  DeleteServiceFloatingButton,
  Durations,
  GeneralInformation,
  HealthInsuranceRequirements,
  ModifyAvailabilityAlert,
  RejectAndDeleteServiceDialog,
} from "./components";
import { useService } from "./hooks";

const ServiceView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("service");
  const [, jwtToken] = useSession();
  const [searchParams] = useSearchParams();
  const [, dispatchAppContext] = useAppContext();
  const [service, dispatch] = useService(jwtToken, searchParams);
  const [added, setAdded] = useState(searchParams.has("added"));
  const [saved, setSaved] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [
    openRejectAndDeleteServiceDialog,
    setOpenRejectAndDeleteServiceDialog,
  ] = useState(false);
  const [openDeleteServiceDialog, setOpenDeleteServiceDialog] = useState(false);
  const [deleteFailed, setDeleteFailed] = useState(false);

  useFontAwesome();
  useScrollToTopOnMount();

  useEffect(() => {
    if (service?.isDeleted) {
      navigate(`/profile/services`, { replace: true });
    }
  }, [service]);

  const handleAccept = (agreementId) => async () => {
    const url = new URL(
      `/commands/accept-service-agreement/${service.serviceId}/${agreementId}`,
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    if (ok) {
      dispatch({
        type: "accept-service-agreement",
        payload: { agreementId },
      });
      setSaved(true);
    } else {
      setSaveError(true);
    }

    return ok;
  };

  const agreements = service
    ? service.agreements.map((x) => {
        switch (x.typeId) {
          case agreementType.collectivePrescriptionToTreatStreptococcus:
            return (
              <CollectivePrescriptionToTreatStreptococcusAgreement
                key={x.agreementId}
                agreement={x}
                onReject={() => setOpenRejectAndDeleteServiceDialog(true)}
                onAccept={handleAccept(x.agreementId)}
              />
            );
          case agreementType.collectivePrescriptionToTreatUrinaryTractInfection:
            return (
              <CollectivePrescriptionToTreatUrinaryTractInfectionAgreement
                key={x.agreementId}
                agreement={x}
                onReject={() => setOpenRejectAndDeleteServiceDialog(true)}
                onAccept={handleAccept(x.agreementId)}
              />
            );
          case agreementType.collectivePrescriptionToTreatGonococcalAndChlamydiaTrachomatisInfections:
            return (
              <CollectivePrescriptionToTreatGonococcalAndChlamydiaTrachomatisInfectionsAgreement
                key={x.agreementId}
                agreement={x}
                onReject={() => setOpenRejectAndDeleteServiceDialog(true)}
                onAccept={handleAccept(x.agreementId)}
              />
            );
          case agreementType.collectivePrescriptionToTreatAbnormalVaginalDischarge:
            return (
              <CollectivePrescriptionToTreatAbnormalVaginalDischargeAgreement
                key={x.agreementId}
                agreement={x}
                onReject={() => setOpenRejectAndDeleteServiceDialog(true)}
                onAccept={handleAccept(x.agreementId)}
              />
            );
          case agreementType.collectivePrescriptionToTreatSinusitis:
            return (
              <CollectivePrescriptionToTreatSinusitisAgreement
                key={x.agreementId}
                agreement={x}
                onReject={() => setOpenRejectAndDeleteServiceDialog(true)}
                onAccept={handleAccept(x.agreementId)}
              />
            );
          case agreementType.collectivePrescriptionToTreatEarInfection:
            return (
              <CollectivePrescriptionToTreatEarInfectionAgreement
                key={x.agreementId}
                agreement={x}
                onReject={() => setOpenRejectAndDeleteServiceDialog(true)}
                onAccept={handleAccept(x.agreementId)}
              />
            );
          default:
        }
      })
    : [];

  const handleSaveGeneralInformation = async ({
    nameFr,
    nameEn,
    descriptionFr,
    descriptionEn,
    price,
    maximumPrice,
  }) => {
    const url = new URL(
      `/commands/modify-service-general-information/${service.serviceId}`,
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        nameFr,
        nameEn,
        descriptionFr,
        descriptionEn,
        price,
        maximumPrice,
      }),
    });

    if (ok) {
      dispatch({
        type: "modify-service-general-information",
        payload: {
          nameFr,
          nameEn,
          descriptionFr,
          descriptionEn,
          price,
          maximumPrice,
        },
      });
      setSaved(true);
    } else {
      setSaveError(true);
    }

    return ok;
  };

  const handleSaveDurations = async ({
    appointmentDurationInMinutes,
    breakDurationAfterEachAppointmentInMinutes,
    minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutes,
  }) => {
    const url = new URL(
      `/commands/modify-service-durations/${service.serviceId}`,
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        appointmentDurationInMinutes,
        breakDurationAfterEachAppointmentInMinutes,
        minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutes,
      }),
    });

    if (ok) {
      dispatch({
        type: "modify-service-durations",
        payload: {
          appointmentDurationInMinutes,
          breakDurationAfterEachAppointmentInMinutes,
          minimumTimeBetweenBookingAppointmentAndStartOfAppointmentInMinutes,
        },
      });
      setSaved(true);
    } else {
      setSaveError(true);
    }

    return ok;
  };

  const handleSaveHealthInsuranceRequirements = async ({
    ramqHealthInsurance,
  }) =>
    ramqHealthInsurance
      ? modifyHealthInsuranceRequirement(healthInsuranceIssuingAuthority.ramq)
      : removeHealthInsuranceRequirement(healthInsuranceIssuingAuthority.ramq);

  const modifyHealthInsuranceRequirement = async (issuingAuthorityId) => {
    const url = new URL(
      `/commands/modify-health-insurance-requirement/${service.serviceId}`,
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        issuingAuthorityId,
        isRequiredToProvideService: false,
      }),
    });

    if (ok) {
      dispatch({
        type: "modify-health-insurance-requirement",
        payload: {
          issuingAuthorityId,
          isRequiredToProvideService: false,
        },
      });
      setSaved(true);
    } else {
      setSaveError(true);
    }

    return ok;
  };

  const removeHealthInsuranceRequirement = async (issuingAuthorityId) => {
    const url = new URL(
      `/commands/remove-health-insurance-requirement/${service.serviceId}`,
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        issuingAuthorityId,
      }),
    });

    if (ok) {
      dispatch({
        type: "remove-health-insurance-requirement",
        payload: {
          issuingAuthorityId,
        },
      });
      setSaved(true);
    } else {
      setSaveError(true);
    }

    return ok;
  };

  const handleDelete = async () => {
    const url = new URL(
      `commands/delete-service/${service.serviceId}`,
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    if (ok) {
      dispatchAppContext({ type: "delete-service" });
      navigate(`/profile/services?deleted=${service.serviceId}`);
    } else {
      setDeleteFailed(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("title", { name: service?.nameFr ?? "" })}</title>
        <meta
          property="og:title"
          content={t("title", { name: service?.nameFr ?? "" })}
        />
        <meta property="og:description" content={t("description")} />
      </Helmet>
      <SecondaryHeader
        sx={(theme) => ({
          minHeight: theme.spacing(17),
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
          [theme.breakpoints.up("md")]: {
            minHeight: theme.spacing(19),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
          },
        })}
        loading={!service}
        title={
          <>
            <Icon
              className={`fa fa-${getIconName(service?.serviceTemplateId)}`}
              sx={(theme) => ({
                fontSize: theme.typography.h3.fontSize,
                width: theme.spacing(7),
                [theme.breakpoints.up("sm")]: {
                  fontSize: theme.typography.h2.fontSize,
                  width: theme.spacing(8),
                },
                [theme.breakpoints.up("md")]: {
                  fontSize: theme.typography.h1.fontSize,
                  width: theme.spacing(9),
                },
              })}
              aria-label={service?.nameFr}
            />
            <span>{service?.nameFr}</span>
          </>
        }
      />
      <Body
        sx={(theme) => ({
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
          [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
          },
        })}
        alert={
          service?.agreements.every(
            (x) => x.statusId === agreementStatus.accepted
          ) && <ModifyAvailabilityAlert />
        }
        agreements={agreements}
        generalInformation={
          <GeneralInformation
            loading={!service}
            service={service}
            onSave={handleSaveGeneralInformation}
          />
        }
        durations={
          <Durations
            loading={!service}
            service={service}
            onSave={handleSaveDurations}
          />
        }
        healthInsuranceRequirements={
          <HealthInsuranceRequirements
            loading={!service}
            service={service}
            onSave={handleSaveHealthInsuranceRequirements}
          />
        }
      />
      <Box
        sx={(theme) => ({
          height: theme.spacing(7),
          [theme.breakpoints.up("sm")]: {
            height: theme.spacing(8),
          },
          [theme.breakpoints.up("md")]: {
            height: theme.spacing(9),
          },
        })}
      />
      {service && (
        <DeleteServiceFloatingButton
          sx={(theme) => ({
            position: "fixed",
            zIndex: 4,
            right: theme.spacing(2),
            bottom: theme.spacing(2),
            [theme.breakpoints.up("sm")]: {
              bottom: theme.spacing(3),
            },
          })}
          onClick={() => setOpenDeleteServiceDialog(true)}
        />
      )}
      <RejectAndDeleteServiceDialog
        open={openRejectAndDeleteServiceDialog}
        onCancel={() => setOpenRejectAndDeleteServiceDialog(false)}
        onDelete={handleDelete}
      />
      <DeleteServiceDialog
        open={openDeleteServiceDialog}
        onCancel={() => setOpenDeleteServiceDialog(false)}
        onDelete={handleDelete}
      />
      <SuccessAlert
        open={added}
        onClose={() => setAdded(false)}
        content={t("alerts.added")}
      />
      <SuccessAlert
        open={saved}
        onClose={() => setSaved(false)}
        content={t("alerts.saved")}
      />
      <ErrorAlert
        open={saveError}
        onClose={() => setSaveError(false)}
        content={
          <Trans
            i18nKey="service:alerts.saveError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
      <ErrorAlert
        open={deleteFailed}
        onClose={() => setDeleteFailed(false)}
        content={
          <Trans
            i18nKey="service:alerts.deleteFailedError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
    </>
  );
};

export default ServiceView;
