import { fr } from "date-fns/locale";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  Container,
  Link as MuiLink,
  Skeleton,
  Typography,
} from "@mui/material";

import { useAppointment } from "@vesta/lib/react";

import { useTitle } from "./hooks";

const containerSx = (theme) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.getContrastText(theme.palette.primary.dark),
  zIndex: theme.zIndex.drawer - 1,
  minHeight: theme.spacing(16),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    minHeight: theme.spacing(18),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
});

const localizedDayOfWeek = (date) => {
  const dayOfWeek = format(date, "eeee", { locale: fr });
  return dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
};

const AppointmentHeader = () => {
  const { t } = useTranslation("appointment");
  const [appointment] = useAppointment();

  const title = useTitle(t);

  if (!appointment) {
    return (
      <Container sx={containerSx} maxWidth={false}>
        <Typography component="h1" variant="h3">
          <Skeleton width="60%" />
        </Typography>
        <Typography variant="body1">
          <Skeleton width="40%" />
        </Typography>
      </Container>
    );
  }

  const patientName = appointment?.isAppointmentForSomeoneElse
    ? `${appointment?.someoneElse.firstName} ${appointment?.someoneElse.lastName}`
    : `${appointment?.patientFirstName} ${appointment?.patientLastName}`;

  const patientLinkTo = appointment?.isAppointmentForSomeoneElse
    ? `/patients/${appointment?.patientId}/s/${appointment?.someoneElse.someoneElseId}/notes`
    : `/patients/${appointment?.patientId}/notes`;

  const dayOfWeek = localizedDayOfWeek(appointment?.start);
  const date = format(appointment?.start, "PPP", { locale: fr });
  const startTime = format(appointment?.start, "p", { locale: fr });
  const endTime = format(appointment?.end, "p", { locale: fr });

  return (
    <>
      <Helmet>
        <title>
          {t("appointmentHeader.title", {
            patientName,
            date,
            startTime,
            endTime,
          })}
        </title>
        <meta
          property="og:title"
          content={t("appointmentHeader.title", {
            patientName,
            date,
            startTime,
            endTime,
          })}
        />
        <meta
          property="og:description"
          content={t("appointmentHeader.description")}
        />
      </Helmet>
      <Container
        sx={[
          containerSx,
          appointment.cancelled &&
            ((theme) => ({
              backgroundColor: theme.palette.error.dark,
              color: theme.palette.getContrastText(theme.palette.error.dark),
            })),
        ]}
        maxWidth={false}
      >
        <Typography component="h1" variant="h3">
          {title}
        </Typography>
        <Typography
          sx={appointment.cancelled ? { textDecoration: "line-through" } : {}}
          variant="body1"
        >
          <Trans
            i18nKey="appointment:appointmentHeader.subtitle"
            components={{
              patientLink: (
                <MuiLink
                  component={Link}
                  to={patientLinkTo}
                  color="inherit"
                  underline={appointment.cancelled ? "hover" : "always"}
                />
              ),
            }}
            values={{
              patientName,
              dayOfWeek,
              date,
              startTime,
              endTime,
            }}
          />
        </Typography>
      </Container>
    </>
  );
};

export default AppointmentHeader;
