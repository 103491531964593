import PropTypes from "prop-types";
import { useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";

import { useTheme } from "@mui/material/styles";

const CropImage = ({ imgRef, imgSrc, ...rest }) => {
  const theme = useTheme();
  const [crop, setCrop] = useState();

  const handleImgLoad = (event) => {
    // To center the crop after loading the image
    // https://github.com/DominicTobias/react-image-crop#how-can-i-center-the-crop

    const { naturalWidth: width, naturalHeight: height } = event.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        1,
        width,
        height
      ),
      width,
      height
    );

    setCrop(crop);
  };

  return (
    <ReactCrop
      aspect={1}
      circularCrop
      keepSelection
      crop={crop}
      style={{ maxHeight: theme.spacing(60) }}
      onChange={(_, percentCrop) => setCrop(percentCrop)}
      {...rest}
    >
      <img ref={imgRef} src={imgSrc} onLoad={handleImgLoad} />
    </ReactCrop>
  );
};

CropImage.propTypes = {
  imgRef: PropTypes.object,
  imgSrc: PropTypes.string.isRequired,
};

export default CropImage;
