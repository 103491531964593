import PropTypes from "prop-types";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
} from "@mui/material";

const CheckboxFilters = ({ label, error, errorText, values, onChange }) => (
  <FormControl variant="standard" fullWidth error={error} component="fieldset">
    {label && (
      <Box sx={{ pb: 2 }}>
        <InputLabel component="legend" shrink>
          {label}
        </InputLabel>
      </Box>
    )}
    <FormGroup>
      {values
        .sort((a, b) => a.name.localeCompare(b.name, "fr"))
        .map((value) => (
          <FormControlLabel
            key={value.id}
            sx={(theme) => ({
              marginBottom: theme.spacing(3 / 2),
              [theme.breakpoints.up("md")]: {
                marginBottom: theme.spacing(1 / 2),
              },
            })}
            label={value.name}
            control={
              <Checkbox
                id={value.id}
                checked={value.include}
                disabled={value.disabled}
                onChange={({ target: { id, checked } }) =>
                  onChange({
                    id,
                    include: checked,
                  })
                }
              />
            }
          />
        ))}
    </FormGroup>
    {error && <FormHelperText>{errorText}</FormHelperText>}
  </FormControl>
);

CheckboxFilters.defaultProps = {
  loading: false,
  values: [],
};

CheckboxFilters.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      include: PropTypes.bool.isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
};

export default CheckboxFilters;
