import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

const durations = [
  15, 30, 45, 60, 75, 90, 105, 120, 150, 180, 210, 240, 300, 360, 420, 480,
];

const AppointmentDurationInMinutesSelect = ({
  id,
  className,
  readOnly,
  value,
  onChange,
  error,
  helperText,
  ...rest
}) => {
  const { t } = useTranslation("components");

  return (
    <FormControl
      variant="standard"
      className={className}
      disabled={readOnly}
      fullWidth
      error={error}
      {...rest}
    >
      <InputLabel id={`${id}-label`} shrink>
        {t("atoms.appointmentDurationInMinutesSelect.label")}
      </InputLabel>
      <Select
        variant="standard"
        id={id}
        labelId={`${id}-label`}
        value={value}
        onChange={(event) => onChange(parseInt(event.target.value))}
        inputProps={{
          readOnly,
        }}
      >
        {durations.map((x, i) => (
          <MenuItem key={`${id}-menu-item-${i}`} value={x}>
            {t(`atoms.appointmentDurationInMinutesSelect.durations.${x}`)}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

AppointmentDurationInMinutesSelect.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default AppointmentDurationInMinutesSelect;
