import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Box, Divider, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  AttachFile,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatIndentDecrease,
  FormatIndentIncrease,
  FormatItalic,
  FormatUnderlined,
  Image,
} from "@mui/icons-material";

import {
  AlignToolbarButton,
  BlockToolbarButton,
  ELEMENT_H1,
  ELEMENT_H2,
  focusEditor,
  getPluginType,
  HeadingToolbar,
  indent,
  MarkToolbarButton,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  outdent,
  ToolbarButton,
  usePlateEditorRef,
} from "@udecode/plate";

import { useUpSm } from "@vesta/lib/react";

import {
  AttachmentToolbarButton,
  ImageToolbarButton,
  MoreToolbarButton,
} from "./components";

const StyledHeadingTypography = styled((props) => (
  <Typography variant="caption" {...props} />
))(({ theme }) => ({
  fontWeight: "bold",
  marginTop: -theme.spacing(1 / 4),
}));

const StyledDivider = styled((props) => (
  <Divider flexItem orientation="vertical" {...props} />
))(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

const headingToolbarStyles = {
  root: {
    margin: 0,
    padding: 0,
    border: "none",
    minHeight: "initial",
  },
};

const toolbarButtonStyles = {
  root: {
    width: "initial",
  },
};

const RichTextEditorToolbar = ({ sx, readOnly, onAttachmentChange }) => {
  const upSm = useUpSm();
  const editor = usePlateEditorRef();
  const { t } = useTranslation("components");

  const moreToolbarOptions = (
    <>
      <ImageToolbarButton
        styles={toolbarButtonStyles}
        icon={
          <Tooltip
            arrow
            placement="top"
            title={t("molecules.richTextEditorToolbar.imageTooltip")}
          >
            <Image />
          </Tooltip>
        }
      />
      <StyledDivider />
      <AlignToolbarButton
        styles={toolbarButtonStyles}
        value="left"
        icon={<FormatAlignLeft />}
      />
      <AlignToolbarButton
        styles={toolbarButtonStyles}
        value="center"
        icon={<FormatAlignCenter />}
      />
      <AlignToolbarButton
        styles={toolbarButtonStyles}
        value="right"
        icon={<FormatAlignRight />}
      />
      <StyledDivider />
      <ToolbarButton
        styles={toolbarButtonStyles}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          outdent(editor);
          focusEditor(editor);
        }}
        icon={<FormatIndentDecrease />}
      />
      <ToolbarButton
        styles={toolbarButtonStyles}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          indent(editor);
          focusEditor(editor);
        }}
        icon={<FormatIndentIncrease />}
      />
    </>
  );

  return (
    !readOnly && (
      <Box
        sx={[
          (theme) => ({
            display: "flex",
            flexDirection: "row",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(2),
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <HeadingToolbar styles={headingToolbarStyles}>
          <BlockToolbarButton
            styles={toolbarButtonStyles}
            type={getPluginType(editor, ELEMENT_H1)}
            icon={<StyledHeadingTypography>H1</StyledHeadingTypography>}
          />
          <BlockToolbarButton
            styles={toolbarButtonStyles}
            type={getPluginType(editor, ELEMENT_H2)}
            icon={<StyledHeadingTypography>H2</StyledHeadingTypography>}
          />
          <StyledDivider />
          <MarkToolbarButton
            styles={toolbarButtonStyles}
            type={getPluginType(editor, MARK_BOLD)}
            icon={<FormatBold />}
          />
          <MarkToolbarButton
            styles={toolbarButtonStyles}
            type={getPluginType(editor, MARK_ITALIC)}
            icon={<FormatItalic />}
          />
          <MarkToolbarButton
            styles={toolbarButtonStyles}
            type={getPluginType(editor, MARK_UNDERLINE)}
            icon={<FormatUnderlined />}
          />
          <StyledDivider />
          <AttachmentToolbarButton
            styles={toolbarButtonStyles}
            icon={
              <Tooltip
                arrow
                placement="top"
                title={t("molecules.richTextEditorToolbar.attachmentTooltip")}
              >
                <AttachFile />
              </Tooltip>
            }
            onChange={onAttachmentChange}
          />
          {upSm ? (
            moreToolbarOptions
          ) : (
            <MoreToolbarButton>
              <HeadingToolbar styles={headingToolbarStyles}>
                {moreToolbarOptions}
              </HeadingToolbar>
            </MoreToolbarButton>
          )}
        </HeadingToolbar>
      </Box>
    )
  );
};

RichTextEditorToolbar.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  readOnly: PropTypes.bool,
  onAttachmentChange: PropTypes.func.isRequired,
};

export default RichTextEditorToolbar;
