import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { LoadingFloatingButton } from "@vesta/components/atoms";

const CompleteNoteAddendumFloatingButton = ({ sx, saving, onClick }) => {
  const { t } = useTranslation("appointment");

  return (
    <LoadingFloatingButton
      sx={sx}
      loading={saving}
      label={t("note.completeNoteAddendumFloatingButton.completeLabel")}
      onClick={onClick}
    />
  );
};

CompleteNoteAddendumFloatingButton.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  saving: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default CompleteNoteAddendumFloatingButton;
