import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";

import { Box, Grid } from "@mui/material";

import {
  ContactUsLink,
  ErrorAlert,
  SuccessAlert,
} from "@vesta/components/atoms";
import {
  useAppointment,
  useScrollToTopOnMount,
  useSession,
  useUpSm,
} from "@vesta/lib/react";

import {
  CancelAppointmentFloatingButton,
  CancelYesNoDialog,
  MedicalPrescriptionFiles,
  PersonalInformationForm,
  ServicesForm,
} from "./components";

const Preparation = () => {
  const { t } = useTranslation("appointment");
  const upSm = useUpSm();
  const [appointment, dispatch] = useAppointment();
  const [, jwtToken] = useSession();
  const [cancelled, setCancelled] = useState(false);
  const [cancelFailed, setCancelFailed] = useState(false);
  const [openCancelYesNoDialog, setOpenCancelYesNoDialog] = useState(false);

  useScrollToTopOnMount();

  const cancelAppointment = async () => {
    dispatch({ type: "cancelling-appointment" });

    const url = new URL(
      `commands/cancel-appointment-as-nurse/${appointment.appointmentId}`,
      process.env.REACT_APP_API_BASE_URL
    );

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    if (ok) {
      setCancelled(true);
      dispatch({
        type: "cancel-appointment",
        payload: {
          cancelled: new Date(),
        },
      });
    } else {
      setCancelFailed(true);
    }
  };

  return (
    <>
      <Grid container direction="column" spacing={upSm ? 3 : 2}>
        <Grid item>
          <PersonalInformationForm />
        </Grid>
        {appointment?.medicalPrescriptionFiles.length > 0 && (
          <Grid item>
            <MedicalPrescriptionFiles />
          </Grid>
        )}
        <Grid item>
          <ServicesForm />
        </Grid>
      </Grid>
      {appointment?.canCancel && (
        <>
          <Box
            sx={(theme) => ({
              height: theme.spacing(7),
              [theme.breakpoints.up("md")]: {
                height: theme.spacing(11),
              },
            })}
          />
          <CancelAppointmentFloatingButton
            sx={(theme) => ({
              position: "fixed",
              zIndex: 4,
              right: theme.spacing(2),
              bottom: theme.spacing(9),
              [theme.breakpoints.up("md")]: {
                bottom: theme.spacing(4),
              },
            })}
            onClick={() => setOpenCancelYesNoDialog(true)}
          />
        </>
      )}
      <CancelYesNoDialog
        open={openCancelYesNoDialog}
        onCancel={() => setOpenCancelYesNoDialog(false)}
        onConfirm={async () => {
          await cancelAppointment();
          setOpenCancelYesNoDialog(false);
        }}
      />
      <SuccessAlert
        open={cancelled}
        onClose={() => setCancelled(false)}
        content={t("preparation.cancelledAlert")}
      />
      <ErrorAlert
        open={cancelFailed}
        onClose={() => setCancelFailed(false)}
        content={
          <Trans
            i18nKey="appointment:preparation.cancelErrorAlert"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
    </>
  );
};

export default Preparation;
