import { useMatch } from "react-router-dom";

export const useTitle = (t) => {
  const matchesPreparation = useMatch(
    "/calendar/appointments/:appointmentId/preparation"
  );

  const matchesDirections = useMatch(
    "/calendar/appointments/:appointmentId/directions"
  );

  const matchesNote = useMatch("/calendar/appointments/:appointmentId/note");

  const matchesBilling = useMatch(
    "/calendar/appointments/:appointmentId/billing"
  );

  return matchesPreparation
    ? t("appointmentHeader.preparationTitle")
    : matchesDirections
    ? t("appointmentHeader.directionsTitle")
    : matchesNote
    ? t("appointmentHeader.noteTitle")
    : matchesBilling
    ? t("appointmentHeader.billingTitle")
    : null;
};
