import { PropTypes } from "prop-types";
import { useTranslation, Trans } from "react-i18next";

import { Alert, Button, Collapse, Divider, Grid, Link } from "@mui/material";

import { Dialog } from "@vesta/components/molecules";
import { plans } from "@vesta/lib/enum";

import { CycleRadioGroup, PlanRadioGroup, Pricing } from "./components";

const Step1 = ({
  plan,
  onPlanChange,
  cycle,
  onCycleChange,
  open,
  onClose,
  onNext,
}) => {
  const { t } = useTranslation("global-modals");

  const selectedSubscriptionPlan = plans.find(
    (x) => x.name === plan && x.cycle === cycle
  );

  return (
    <Dialog
      fullScreen={false}
      maxWidth="md"
      open={open}
      onClose={onClose}
      title={t("choosePlanDialog.step1.title")}
      content={
        <>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Alert severity="info">
                <Trans
                  i18nKey="global-modals:choosePlanDialog.step1.alertText"
                  components={{
                    planDetailsLink: (
                      <Link
                        href="https://getvesta.co/professionals/pricing"
                        target="_blank"
                        rel="noreferrer"
                        color="primary"
                        underline="always"
                      />
                    ),
                  }}
                />
              </Alert>
            </Grid>
            <Grid item xs={12} sm={6}>
              <PlanRadioGroup selectedPlan={plan} onChange={onPlanChange} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CycleRadioGroup
                selectedCycle={cycle}
                selectedPlan={plan}
                onChange={onCycleChange}
              />
            </Grid>
            {selectedSubscriptionPlan && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
            <Grid item xs={12}>
              <Collapse in={!!selectedSubscriptionPlan}>
                {selectedSubscriptionPlan && (
                  <Pricing plan={selectedSubscriptionPlan} />
                )}
              </Collapse>
            </Grid>
          </Grid>
        </>
      }
      actions={
        <Button
          onClick={onNext}
          disabled={!selectedSubscriptionPlan}
          size="large"
          variant="contained"
        >
          {t("choosePlanDialog.step1.next")}
        </Button>
      }
    />
  );
};

Step1.propTypes = {
  plan: PropTypes.string.isRequired,
  onPlanChange: PropTypes.func.isRequired,
  cycle: PropTypes.string.isRequired,
  onCycleChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default Step1;
