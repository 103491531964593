import { useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Box, Grid, Link, Typography } from "@mui/material";

import { SearchBar } from "@vesta/components/molecules";
import {
  useMedicalRecord,
  useScrollToTopOnMount,
  useSession,
} from "@vesta/lib/react";
import { defaultRawContent } from "@vesta/lib/slate";

import { AddNoteFloatingButton, NoteCard } from "./components";

const Notes = () => {
  const actionsRef = useRef(null);
  const { t } = useTranslation("patient");
  const [, jwtToken] = useSession();
  const [searchParams, setSearchParams] = useSearchParams();
  const [medicalRecord, dispatch] = useMedicalRecord();

  useScrollToTopOnMount();

  const term = searchParams.get("term") ?? "";

  const loading = !medicalRecord;

  const loadingState = (
    <>
      <Grid item>
        <NoteCard loading />
      </Grid>
      <Grid item>
        <NoteCard loading />
      </Grid>
    </>
  );

  const handleAddNote = async () => {
    dispatch({ type: "adding-note-to-patient-medical-record" });

    const url = new URL(
      "/commands/add-note-to-patient-medical-record",
      process.env.REACT_APP_API_BASE_URL
    );

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        patientId: medicalRecord.patientId,
        someoneElseId: medicalRecord.someoneElseId,
        rawContent: JSON.stringify(defaultRawContent),
      }),
    });

    if (response.ok) {
      const { noteId, noteVersionId } = await response.json();

      dispatch({
        type: "add-note-to-patient-medical-record",
        payload: {
          noteId,
          noteVersionId,
          rawContent: defaultRawContent,
          attachments: [],
          created: new Date(),
          modified: new Date(),
        },
      });

      window.scrollTo({
        top: actionsRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const emptyState = (
    <Grid item>
      <Typography
        sx={(theme) => ({ marginTop: theme.spacing(4) })}
        gutterBottom
        align="center"
        component="h2"
        variant="h3"
      >
        {t("notes.emptyState.title")}
      </Typography>
      <Typography align="center">
        <Trans
          i18nKey="patient:notes.emptyState.text"
          values={{
            fullName: medicalRecord?.personalInformation.fullName,
          }}
          components={{
            addNoteLink: (
              <Link
                sx={{ cursor: "pointer" }}
                onClick={handleAddNote}
                underline="always"
              />
            ),
          }}
        />
      </Typography>
    </Grid>
  );

  const findNoteAddenda = (noteId) => {
    const addenda = medicalRecord.medicalInformation.notes.filter(
      (x) => x.addendumTo === noteId
    );

    const addendaOfAddenda = addenda
      .map((x) => findNoteAddenda(x.noteId))
      .flatMap((x) => x);

    return [...addenda, ...addendaOfAddenda];
  };

  return (
    <>
      <Grid ref={actionsRef} container direction="column" spacing={3}>
        <Grid sx={(theme) => ({ marginBottom: theme.spacing(1) })} item>
          <SearchBar
            value={term}
            onChange={(term) => setSearchParams({ term }, { replace: true })}
          />
        </Grid>
        {loading
          ? loadingState
          : medicalRecord.medicalInformation.notes.length === 0
          ? emptyState
          : medicalRecord.medicalInformation.notes
              .filter((x) => !x.addendumTo)
              .sort((a, b) => b.created - a.created)
              .map((x, index, array) => ({ ...x, order: array.length - index }))
              .map((x) => ({
                primaryNote: x,
                primaryNoteAndAddenda: [x, ...findNoteAddenda(x.noteId)],
              }))
              .filter((x) =>
                term
                  ? x.primaryNoteAndAddenda.some((y) =>
                      JSON.stringify(y.rawContent)
                        .toLowerCase()
                        .includes(term.toLowerCase())
                    )
                  : true
              )
              .map((x) => x.primaryNote)
              .map((x, i) => (
                <Grid key={x.noteId} item>
                  <NoteCard
                    noteId={x.noteId}
                    order={x.order}
                    expandInitially={i === 0 || i === 1}
                  />
                </Grid>
              ))}
      </Grid>
      {!loading && (
        <>
          <Box
            sx={(theme) => ({
              height: theme.spacing(7),
              [theme.breakpoints.up("md")]: {
                height: theme.spacing(11),
              },
            })}
          />
          <AddNoteFloatingButton
            sx={(theme) => ({
              position: "fixed",
              zIndex: 12,
              right: theme.spacing(2),
              bottom: theme.spacing(9),
              [theme.breakpoints.up("md")]: {
                bottom: theme.spacing(4),
              },
            })}
            saving={medicalRecord.medicalInformation.addingNote}
            onClick={handleAddNote}
          />
        </>
      )}
    </>
  );
};

export default Notes;
