import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { IconButton } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

const SidebarTrigger = ({ onClick }) => {
  const { t } = useTranslation("layouts");

  return (
    <>
      <IconButton
        aria-label={t("mainLayout.header.sidebarTrigger.ariaLabel")}
        edge="start"
        onClick={onClick}
        size="large"
      >
        <MenuIcon
          sx={(theme) => ({
            height: theme.spacing(4),
            width: theme.spacing(4),
            [theme.breakpoints.up("sm")]: {
              height: theme.spacing(5),
              width: theme.spacing(5),
            },
          })}
        />
      </IconButton>
    </>
  );
};

SidebarTrigger.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SidebarTrigger;
