import { useTranslation } from "react-i18next";

import { Alert, AlertTitle } from "@mui/material";

const DemoPatientAlert = () => {
  const { t } = useTranslation("appointment");

  return (
    <Alert
      sx={(theme) => ({ backgroundColor: theme.palette.background.paper })}
      component="aside"
      severity="warning"
      variant="outlined"
    >
      <AlertTitle>{t("demoPatientAlert.title")}</AlertTitle>
      {t("demoPatientAlert.text")}
    </Alert>
  );
};

export default DemoPatientAlert;
