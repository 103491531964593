import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input/input";
import * as yup from "yup";

import { Box, Grid, Typography } from "@mui/material";

import {
  ContactUsLink,
  ErrorAlert,
  LoadingButton,
} from "@vesta/components/atoms";
import { PhoneNumberTextField } from "@vesta/components/molecules";
import { VerifyProfilePhoneNumberDialog } from "@vesta/components/organisms";
import {
  useOnboardingSessionV1,
  useScrollToTopOnMount,
  useSession,
} from "@vesta/lib/react";

const Step1 = ({ sx, onNext }) => {
  const { t } = useTranslation("onboarding-v1");
  const [, jwtToken] = useSession();
  const [onboardingSession, dispatch] = useOnboardingSessionV1();
  const [modifyContactInformationError, setModifyContactInformationError] =
    useState(false);
  const [
    openVerifyProfilePhoneNumberDialog,
    setOpenVerifyProfilePhoneNumberDialog,
  ] = useState(false);

  useScrollToTopOnMount();

  const validationSchema = yup.object().shape({
    phoneNumberInE164Format: yup.string().test(
      "is-phone-number-valid",
      <Trans
        i18nKey="onboarding-v1:onboardingSteps.step1.phoneNumberInE164Format.phoneNumberValid"
        components={{
          emphasize: <b />,
        }}
      />,
      (value) => isValidPhoneNumber(value ?? "")
    ),
    hidePhoneNumber: yup.bool(),
  });

  const {
    values,
    errors,
    isSubmitting,
    submitCount,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      phoneNumberInE164Format: onboardingSession.phoneNumberInE164Format ?? "",
      hidePhoneNumber: onboardingSession.hidePhoneNumber,
    },
    validationSchema,
    onSubmit: async ({ phoneNumberInE164Format, hidePhoneNumber }) => {
      const url = new URL(
        "/commands/modify-contact-information",
        process.env.REACT_APP_API_BASE_URL
      );

      const { ok } = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          phoneNumber: phoneNumberInE164Format,
          hidePhoneNumber,
        }),
      });

      if (ok) {
        dispatch({
          type: "modify-contact-information",
          payload: { phoneNumberInE164Format, hidePhoneNumber },
        });

        if (
          phoneNumberInE164Format ===
          onboardingSession.approvedPhoneNumberInE164Format
        ) {
          await onNext();
        } else {
          setOpenVerifyProfilePhoneNumberDialog(true);
        }
      } else {
        setModifyContactInformationError(true);
      }
    },
  });

  const handleVerify = async (verificationCode) => {
    const url = new URL(
      "/commands/verify-profile-phone-number",
      process.env.REACT_APP_API_BASE_URL
    );

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        phoneNumber: onboardingSession.phoneNumberInE164Format,
        verificationCode,
      }),
    });

    if (response.ok) {
      dispatch({
        type: "verify-profile-phone-number",
        payload: {
          isPhoneNumberApproved: true,
        },
      });
      setOpenVerifyProfilePhoneNumberDialog(false);
      await onNext();
    } else {
      const { errorCode } = await response.json();
      return errorCode;
    }
  };

  const isApproved =
    values.phoneNumberInE164Format ===
    onboardingSession.approvedPhoneNumberInE164Format;

  return (
    <Box sx={sx} component="form" onSubmit={handleSubmit}>
      <Grid container direction="column" spacing={4} data-aos="fade-up">
        <Grid item>
          <Typography gutterBottom variant="h1">
            {t("onboardingSteps.step1.title", {
              firstName: onboardingSession.firstName,
            })}
          </Typography>
          <Typography>
            <Trans
              i18nKey="onboarding-v1:onboardingSteps.step1.subtitle"
              components={{
                emphasize: <b />,
                divider: (
                  <>
                    <br />
                    <br />
                  </>
                ),
              }}
            />
          </Typography>
        </Grid>
        <Grid item>
          <PhoneNumberTextField
            id="phoneNumberInE164Format"
            isApproved={isApproved}
            value={values.phoneNumberInE164Format}
            onChange={(value) =>
              setFieldValue("phoneNumberInE164Format", value)
            }
            error={submitCount > 0 && Boolean(errors.phoneNumberInE164Format)}
            helperText={submitCount > 0 ? errors.phoneNumberInE164Format : null}
          />
        </Grid>
        <Grid container justifyContent="flex-end" item>
          <Grid item>
            <LoadingButton
              loading={isSubmitting}
              disabled={isSubmitting}
              type="submit"
              variant="contained"
            >
              {t(
                isApproved
                  ? "onboardingSteps.step1.actions.next.alreadyVerified"
                  : "onboardingSteps.step1.actions.next.verify"
              )}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
      <VerifyProfilePhoneNumberDialog
        submittedPhoneNumber={onboardingSession.phoneNumberInE164Format}
        open={openVerifyProfilePhoneNumberDialog}
        onClose={() => setOpenVerifyProfilePhoneNumberDialog(false)}
        onVerify={handleVerify}
      />
      <ErrorAlert
        open={modifyContactInformationError}
        onClose={() => setModifyContactInformationError(false)}
        content={
          <Trans
            i18nKey="onboarding-v1:onboardingSteps.step1.alerts.modifyContactInformationError"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
    </Box>
  );
};

Step1.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
  onNext: PropTypes.func.isRequired,
};

export default Step1;
