import PropTypes from "prop-types";
import { useRef } from "react";

import { ToolbarButton } from "@udecode/plate";

const AttachmentToolbarButton = ({ onChange, ...rest }) => {
  const inputRef = useRef(null);

  return (
    <>
      <input
        type="file"
        accept="application/pdf"
        multiple
        ref={inputRef}
        style={{ display: "none" }}
        onChange={onChange}
      />
      <ToolbarButton onMouseDown={() => inputRef.current.click()} {...rest} />
    </>
  );
};

AttachmentToolbarButton.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default AttachmentToolbarButton;
