import { useTranslation } from "react-i18next";

import { Alert, AlertTitle } from "@mui/material";

const WhatAlert = () => {
  const { t } = useTranslation("profile");

  return (
    <Alert component="aside" severity="info">
      <AlertTitle>{t("biography.coverPicture.whatAlert.title")}</AlertTitle>
      {t("biography.coverPicture.whatAlert.text")}
    </Alert>
  );
};

export default WhatAlert;
