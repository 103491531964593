import PropTypes from "prop-types";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button, Grid } from "@mui/material";

import {
  ContactUsLink,
  ErrorAlert,
  LoadingButton,
} from "@vesta/components/atoms";
import {
  AvailabilityRecurrenceEnd,
  AvailabilityRecurrenceFrequency,
  AvailabilitySubtitle,
  AvailabilityTime,
  CheckboxFilters,
  Dialog,
  DrivingTimeBetweenAppointmentsAlert,
} from "@vesta/components/molecules";
import { useAppContext, useSession } from "@vesta/lib/react";

import { CannotCreateAvailabilityAlert } from "./components";
import {
  useAvailableTerritoriesAndServices,
  useEnd,
  useRecurrenceEndsOn,
  useServices,
  useStart,
  useTerritories,
} from "./hooks";

const CreateAvailabilityDialog = ({
  open,
  initialStart,
  initialEnd,
  onCancel,
  onCreate,
}) => {
  const { t } = useTranslation("calendar");
  const [appContext] = useAppContext();
  const [availableTerritories, availableServices, loading] =
    useAvailableTerritoriesAndServices();
  const [start, setStart] = useStart(initialStart);
  const [end, setEnd] = useEnd(initialEnd);
  const [recurrenceFrequencyId, setRecurrenceFrequencyId] = useState("");
  const [recurrenceMode, setRecurrenceMode] = useState(2);
  const [
    recurrenceEndsAfterMaxNbOccurrences,
    setRecurrenceEndsAfterMaxNbOccurrences,
  ] = useState(5);
  const [recurrenceEndsOn, setRecurrenceEndsOn] = useRecurrenceEndsOn(start);
  const [territories, territoriesError, handleTerritoryChange] =
    useTerritories(availableTerritories);
  const [services, servicesError, handleServiceChange] =
    useServices(availableServices);
  const [creating, setCreating] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [, jwtToken] = useSession();

  const handleClose = (_, reason) => {
    if (reason !== "backdropClick") {
      onCancel();
    }
  };

  const canCreate = appContext.nbServices > 0 && appContext.nbTerritories > 0;

  const handleCreate = async () => {
    const url = new URL(
      recurrenceFrequencyId
        ? "/commands/create-recurring-availability"
        : "/commands/create-non-recurring-availability",
      process.env.REACT_APP_API_BASE_URL
    );

    setCreating(true);

    const { ok } = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        start: start.toISOString(),
        end: end.toISOString(),
        ...(recurrenceFrequencyId && {
          recurrenceFrequencyId,
          ...(recurrenceMode === 1 && {
            recurrenceEndsAfterMaxNbOccurrences,
          }),
          ...(recurrenceMode === 2 && {
            recurrenceEndsOn: recurrenceEndsOn.toISOString(),
          }),
        }),
        territories: territories.reduce(
          (obj, item) => ({
            ...obj,
            [item.id]: item.include,
          }),
          {}
        ),
        services: services.reduce(
          (obj, item) => ({
            ...obj,
            [item.id]: item.include,
          }),
          {}
        ),
      }),
    });

    setCreating(false);

    if (ok) {
      onCreate();
    } else {
      setOpenErrorAlert(true);
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        loading={loading}
        onClose={handleClose}
        title={t("availabilities.createAvailabilityDialog.title")}
        subtitle={
          start &&
          end && (
            <AvailabilitySubtitle
              start={start}
              end={end}
              recurrenceFrequencyId={recurrenceFrequencyId}
              recurrenceMode={recurrenceMode}
              recurrenceEndsAfterMaxNbOccurrences={
                recurrenceEndsAfterMaxNbOccurrences
              }
              recurrenceEndsOn={recurrenceEndsOn}
            />
          )
        }
        content={
          canCreate ? (
            <Grid container direction="column" spacing={4}>
              {start && end && (
                <Grid item>
                  <AvailabilityTime
                    start={start}
                    end={end}
                    onStartChange={setStart}
                    onEndChange={setEnd}
                  />
                </Grid>
              )}
              <Grid item>
                <AvailabilityRecurrenceFrequency
                  start={start}
                  recurrenceFrequencyId={recurrenceFrequencyId}
                  onRecurrenceFrequencyIdChange={setRecurrenceFrequencyId}
                />
              </Grid>
              {recurrenceFrequencyId && (
                <Grid item>
                  <AvailabilityRecurrenceEnd
                    start={start}
                    recurrenceMode={recurrenceMode}
                    onRecurrenceModeChange={setRecurrenceMode}
                    recurrenceEndsAfterMaxNbOccurrences={
                      recurrenceEndsAfterMaxNbOccurrences
                    }
                    onRecurrenceEndsAfterMaxNbOccurrencesChange={
                      setRecurrenceEndsAfterMaxNbOccurrences
                    }
                    recurrenceEndsOn={recurrenceEndsOn}
                    onRecurrenceEndsOnChange={setRecurrenceEndsOn}
                  />
                </Grid>
              )}
              <Grid item>
                <CheckboxFilters
                  label={t(
                    "availabilities.createAvailabilityDialog.territories.label"
                  )}
                  error={territoriesError}
                  errorText={t(
                    "availabilities.createAvailabilityDialog.territories.errorText"
                  )}
                  values={territories}
                  onChange={handleTerritoryChange}
                />
              </Grid>
              <Grid item>
                <CheckboxFilters
                  label={t(
                    "availabilities.createAvailabilityDialog.services.label"
                  )}
                  error={servicesError}
                  errorText={t(
                    "availabilities.createAvailabilityDialog.services.errorText"
                  )}
                  values={services}
                  onChange={handleServiceChange}
                />
              </Grid>
              <Grid item>
                <DrivingTimeBetweenAppointmentsAlert />
              </Grid>
            </Grid>
          ) : (
            <CannotCreateAvailabilityAlert />
          )
        }
        actions={
          <>
            <Button onClick={onCancel} size="large" variant="outlined">
              {t("availabilities.createAvailabilityDialog.cancel")}
            </Button>
            <LoadingButton
              loading={creating}
              disabled={creating || territoriesError || servicesError}
              onClick={handleCreate}
              size="large"
              variant="contained"
            >
              {t("availabilities.createAvailabilityDialog.create")}
            </LoadingButton>
          </>
        }
      />
      <ErrorAlert
        open={openErrorAlert}
        onClose={() => setOpenErrorAlert(false)}
        content={
          <Trans
            i18nKey="calendar:availabilities.createAvailabilityDialog.errorAlert"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
    </>
  );
};

CreateAvailabilityDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  initialStart: PropTypes.instanceOf(Date),
  initialEnd: PropTypes.instanceOf(Date),
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default CreateAvailabilityDialog;
