import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardActionArea,
  Checkbox,
  Icon,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";

import { getIconName } from "@vesta/lib/fontAwesome";
import { useFontAwesome } from "@vesta/lib/react";

export const AddNewServiceCard = ({ serviceTemplate, loading, onClick }) => {
  const { t } = useTranslation("components");

  return (
    <NewServiceCard
      serviceTemplate={serviceTemplate}
      loading={loading}
      onClick={onClick}
      cta={
        <IconButton
          aria-label={t("molecules.newServiceCard.add")}
          disabled={loading}
          color="primary"
          edge="end"
          size="large"
        >
          <Add />
        </IconButton>
      }
    />
  );
};

AddNewServiceCard.propTypes = {
  loading: PropTypes.bool,
  serviceTemplate: PropTypes.object,
  onClick: PropTypes.func,
};

export const CheckNewServiceCard = ({
  serviceTemplate,
  loading,
  checked,
  onChange,
}) => {
  const checkboxRef = useRef(null);
  const { t } = useTranslation("components");

  const handleClick = (event) => {
    if (event.target.checked === undefined) {
      // The area outside the checkbox has been clicked
      checkboxRef.current.click();
    }
  };

  return (
    <NewServiceCard
      serviceTemplate={serviceTemplate}
      loading={loading}
      onClick={handleClick}
      cta={
        <Checkbox
          name={serviceTemplate?.serviceTemplateId}
          checked={checked}
          disabled={loading}
          onChange={onChange}
          inputProps={{
            ref: checkboxRef,
            "aria-label": t("molecules.newServiceCard.check"),
          }}
        />
      }
    />
  );
};

CheckNewServiceCard.propTypes = {
  loading: PropTypes.bool,
  serviceTemplate: PropTypes.object,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

const actionAreaSx = (theme) => ({
  display: "flex",
  padding: theme.spacing(2),
});

const nameSx = (theme) => ({
  marginRight: theme.spacing(2),
  width: "100%",
});

const NewServiceCard = ({ serviceTemplate, loading, onClick, cta }) => {
  const [raised, setRaised] = useState(false);

  useFontAwesome();

  if (loading) {
    return (
      <Card component="section" elevation={2}>
        <CardActionArea sx={actionAreaSx} component="div">
          <Typography sx={nameSx} component="h2" variant="button">
            <Skeleton width="70%" />
          </Typography>
          {cta}
        </CardActionArea>
      </Card>
    );
  }

  return (
    <Card
      component="section"
      elevation={2}
      raised={raised}
      onMouseOver={() => setRaised(true)}
      onMouseOut={() => setRaised(false)}
    >
      <CardActionArea sx={actionAreaSx} component="div" onClick={onClick}>
        <Icon
          className={`fa fa-${getIconName(serviceTemplate.serviceTemplateId)}`}
          sx={(theme) => ({
            marginRight: theme.spacing(2),
            width: "auto",
          })}
          aria-label={serviceTemplate.nameFr}
        />
        <Typography sx={nameSx} component="h2" variant="button">
          {serviceTemplate.nameFr}
        </Typography>
        {cta}
      </CardActionArea>
    </Card>
  );
};

NewServiceCard.propTypes = {
  loading: PropTypes.bool,
  serviceTemplate: PropTypes.object,
  onClick: PropTypes.func,
  cta: PropTypes.node,
};

export default NewServiceCard;
