import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { ListItemButton, ListItemText, ListItemIcon } from "@mui/material";

const SubNavigationListItem = ({ to, label, icon, selected, onClick }) => (
  <ListItemButton
    sx={(theme) => ({
      color: theme.palette.text.secondary,
      "&.Mui-selected": {
        backgroundColor: "rgb(2, 119, 189, 0.1)",
        color: "rgb(2, 119, 189)",
        fontWeight: theme.typography.fontWeightBold,
      },
      "&.Mui-selected:hover": {
        backgroundColor: "rgb(2, 119, 189, 0.1)",
        color: "rgb(2, 119, 189)",
        fontWeight: theme.typography.fontWeightBold,
      },
    })}
    component={Link}
    to={to}
    selected={selected}
    onClick={onClick}
  >
    <ListItemIcon
      sx={(theme) => ({
        minWidth: theme.spacing(4),
        "&.MuiListItemIcon-root": {
          color: "inherit",
        },
      })}
    >
      {icon}
    </ListItemIcon>
    <ListItemText
      primary={label}
      primaryTypographyProps={{
        sx: { fontWeight: "bold" },
        variant: "body2",
      }}
    />
  </ListItemButton>
);

SubNavigationListItem.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.any,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default SubNavigationListItem;
