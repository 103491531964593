import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  Paper,
  Popper,
} from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";

const MoreToolbarButton = ({ children }) => {
  const anchorRef = useRef(null);
  const { t } = useTranslation("components");
  const [open, setOpen] = useState(false);

  const handleClickAway = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        size="small"
        aria-label={t(
          "molecules.richTextEditorToolbar.moreToolbarButton.label"
        )}
        aria-haspopup="true"
        aria-controls={open ? "more-toolbar-options" : undefined}
        onClick={() => setOpen((prevValue) => !prevValue)}
      >
        <MoreHoriz />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="left"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} sx={{ transformOrigin: "right bottom" }}>
            <Paper elevation={4}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box
                  id="more-toolbar-options"
                  sx={(theme) => ({ padding: theme.spacing(1) })}
                >
                  {children}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

MoreToolbarButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MoreToolbarButton;
