import PropTypes from "prop-types";
import { ErrorBoundary } from "react-error-boundary";

import WithLayout, { MinimalLayout } from "@vesta/layouts";
import { ErrorView } from "@vesta/views";

import { logLevel } from "@vesta/lib/enum";

const LogErrorBoundary = ({ children }) => {
  const fallback = (
    <WithLayout
      renderLayout={({ view }) => <MinimalLayout>{view}</MinimalLayout>}
      renderView={() => <ErrorView />}
    />
  );

  const handleError = async (error, info) => {
    const url = new URL("/log", process.env.REACT_APP_API_BASE_URL);
    const headers = {
      "Content-Type": "application/json",
    };

    await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify({
        logLevel: logLevel.error,
        message:
          "An error occurred in app: {Message}. The component stack is {ComponentStack}.",
        arguments: [error.message, info.componentStack],
      }),
    });
  };

  return (
    <ErrorBoundary fallback={fallback} onError={handleError}>
      {children}
    </ErrorBoundary>
  );
};

LogErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LogErrorBoundary;
