import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Alert, Link as MuiLink, Typography } from "@mui/material";

const ModifyAvailabilityAlert = () => {
  const { t } = useTranslation("service");

  return (
    <Alert
      sx={(theme) => ({ backgroundColor: theme.palette.background.paper })}
      component="aside"
      severity="info"
      variant="outlined"
    >
      <Typography gutterBottom variant="body2">
        {t("modifyAvailabilityAlert.text")}
      </Typography>
      <MuiLink
        component={Link}
        to="/calendar/availabilities"
        color="primary"
        underline="always"
      >
        {t("modifyAvailabilityAlert.ctaLabel")}
      </MuiLink>
    </Alert>
  );
};

export default ModifyAvailabilityAlert;
