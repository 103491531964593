import { fr } from "date-fns/locale";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { Button, Grid } from "@mui/material";

import {
  ContactUsLink,
  ErrorAlert,
  LoadingButton,
} from "@vesta/components/atoms";
import {
  AppointmentAddress,
  AppointmentPatientInformation,
  AppointmentServices,
  AppointmentTime,
  Dialog,
} from "@vesta/components/molecules";
import { useAppContext, useSession } from "@vesta/lib/react";

import { CannotCreateAppointmentAlert } from "./components";
import { useForm } from "./hooks";

const localizedDayOfWeek = (date) => {
  const dayOfWeek = format(date, "eeee", { locale: fr });
  return dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
};

const CreateAppointmentDialog = ({
  open,
  initialStart,
  onCancel,
  onCreate,
}) => {
  const { t } = useTranslation("calendar");
  const [, jwtToken] = useSession();
  const [appContext] = useAppContext();
  const [openErrorAlert, setOpenErrorAlert] = useState(false);

  const canCreate = appContext.nbServices > 0;

  const [loading, formik] = useForm(
    jwtToken,
    open,
    initialStart,
    async ({
      patient: {
        email,
        firstName,
        lastName,
        birthday,
        sexId,
        spokenLanguageIds,
        ramqHealthInsuranceNumber,
        ramqHealthInsuranceExpiration,
        phoneNumberInE164Format,
      },
      services,
      additionalInformation,
      start,
      place,
      apartmentUnitSuiteOrFloorNb,
    }) => {
      const url = new URL(
        "commands/create-and-submit-appointment-as-nurse",
        process.env.REACT_APP_API_BASE_URL
      );

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          patientEmail: email,
          patientGooglePlaceId: place.place_id,
          patientApartmentUnitSuiteOrFloorNb: apartmentUnitSuiteOrFloorNb,
          patientFirstName: firstName,
          patientLastName: lastName,
          patientBirthday: birthday,
          patientSexId: sexId,
          patientSpokenLanguageIds: spokenLanguageIds,
          patientRamqHealthInsuranceNumber: ramqHealthInsuranceNumber,
          patientRamqHealthInsuranceExpiration: ramqHealthInsuranceExpiration
            ? new Date(ramqHealthInsuranceExpiration)
            : null,
          patientPhoneNumber: phoneNumberInE164Format,
          start: start.toISOString(),
          servicesIds: services.filter((x) => x.include).map((x) => x.id),
          additionalInformation,
        }),
      });

      if (response.ok) {
        const { appointmentId } = await response.json();
        onCreate(appointmentId);
      } else {
        setOpenErrorAlert(true);
      }
    }
  );

  const handleClose = (_, reason) => {
    if (reason !== "backdropClick") {
      onCancel();
    }
  };

  const {
    values: { start },
    isSubmitting,
    handleSubmit,
  } = formik;

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        loading={loading}
        onClose={handleClose}
        onSubmit={handleSubmit}
        title={t("appointments.createAppointmentDialog.title")}
        subtitle={t("appointments.createAppointmentDialog.subtitle", {
          dayOfWeek: localizedDayOfWeek(start),
          date: format(start, "PPP", { locale: fr }),
          time: format(start, "p", { locale: fr }),
        })}
        content={
          canCreate ? (
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <AppointmentPatientInformation formik={formik} />
              </Grid>
              <Grid item>
                <AppointmentServices formik={formik} />
              </Grid>
              <Grid item>
                <AppointmentTime formik={formik} />
              </Grid>
              <Grid item>
                <AppointmentAddress sessionToken={uuidv4()} formik={formik} />
              </Grid>
            </Grid>
          ) : (
            <CannotCreateAppointmentAlert />
          )
        }
        actions={
          <>
            <Button onClick={onCancel} size="large" variant="outlined">
              {t("appointments.createAppointmentDialog.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              disabled={isSubmitting || !canCreate}
              type="submit"
              size="large"
              variant="contained"
            >
              {t("appointments.createAppointmentDialog.create")}
            </LoadingButton>
          </>
        }
      />
      <ErrorAlert
        open={openErrorAlert}
        onClose={() => setOpenErrorAlert(false)}
        content={
          <Trans
            i18nKey="calendar:appointments.createAppointmentDialog.errorAlert"
            components={{
              divider: (
                <>
                  <br />
                  <br />
                </>
              ),
              contactUsLink: <ContactUsLink />,
            }}
          />
        }
      />
    </>
  );
};

CreateAppointmentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  initialStart: PropTypes.instanceOf(Date),
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default CreateAppointmentDialog;
